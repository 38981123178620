import React from 'react';
import { analytics } from '../config/firebase';
import { logEvent } from 'firebase/analytics';
import { auth } from '../config/firebase';

// Error categories for better organization and filtering
export enum ErrorCategory {
  UI = 'ui_error',
  API = 'api_error',
  AUTH = 'auth_error',
  DATA = 'data_error',
  NETWORK = 'network_error',
  UNKNOWN = 'unknown_error'
}

// Severity levels for errors
export enum ErrorSeverity {
  P0 = 'p0_critical',
  P1 = 'p1_high',
  P2 = 'p2_medium',
  P3 = 'p3_low'
}

interface ErrorContext {
  category: ErrorCategory;
  severity: ErrorSeverity;
  action?: string;
  component?: string;
  additionalData?: Record<string, any>;
}

/**
 * Main error tracking function that logs errors to Firebase Analytics
 */
export function trackError(error: Error, context: ErrorContext) {
  // Get current user info if available
  const user = auth.currentUser;
  const userId = user?.uid;
  const userEmail = user?.email;

  // Basic error information
  const errorInfo = {
    error_name: error.name,
    error_message: error.message,
    error_category: context.category,
    error_severity: context.severity,
    timestamp: new Date().toISOString(),
    
    // User context
    user_id: userId || 'anonymous',
    user_email: userEmail || 'anonymous',
    
    // Action context
    action: context.action || 'unknown_action',
    component: context.component || 'unknown_component',
    
    // Environment context
    url: window.location.href,
    user_agent: navigator.userAgent,
    platform: navigator.platform,
    screen_size: `${window.innerWidth}x${window.innerHeight}`,
    
    // Additional context
    ...context.additionalData
  };

  // Log to Firebase Analytics
  logEvent(analytics, 'app_error', errorInfo);

  // For critical errors (P0), also log to console for immediate visibility
  if (context.severity === ErrorSeverity.P0) {
    console.error('CRITICAL ERROR:', {
      error,
      context: errorInfo
    });
  }
}

/**
 * Utility function to track API errors
 */
export function trackApiError(error: Error, endpoint: string, method: string) {
  trackError(error, {
    category: ErrorCategory.API,
    severity: ErrorSeverity.P1,
    action: `${method}_${endpoint}`,
    additionalData: {
      endpoint,
      method
    }
  });
}

/**
 * Utility function to track authentication errors
 */
export function trackAuthError(error: Error, action: string) {
  trackError(error, {
    category: ErrorCategory.AUTH,
    severity: ErrorSeverity.P1,
    action,
    additionalData: {
      auth_action: action
    }
  });
}

/**
 * Utility function to track data validation errors
 */
export function trackDataError(error: Error, dataType: string, operation: string) {
  trackError(error, {
    category: ErrorCategory.DATA,
    severity: ErrorSeverity.P2,
    action: `${operation}_${dataType}`,
    additionalData: {
      data_type: dataType,
      operation
    }
  });
}

/**
 * Utility function to track network errors
 */
export function trackNetworkError(error: Error, url: string) {
  trackError(error, {
    category: ErrorCategory.NETWORK,
    severity: ErrorSeverity.P1,
    action: 'network_request',
    additionalData: {
      url,
      online: navigator.onLine
    }
  });
}

/**
 * Global error boundary for React components
 */
export class ErrorBoundary extends React.Component<
  { children: React.ReactNode },
  { hasError: boolean }
> {
  constructor(props: { children: React.ReactNode }) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    trackError(error, {
      category: ErrorCategory.UI,
      severity: ErrorSeverity.P1,
      component: errorInfo.componentStack,
      additionalData: {
        react_error_info: errorInfo
      }
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="p-4 text-center">
          <h2 className="text-xl font-bold text-red-500 mb-2">Something went wrong</h2>
          <p className="text-gray-400 mb-4">
            We've been notified and will fix this as soon as possible.
          </p>
          <button
            onClick={() => window.location.reload()}
            className="px-4 py-2 bg-primary-500 text-white rounded-md hover:bg-primary-600"
          >
            Reload Page
          </button>
        </div>
      );
    }

    return this.props.children;
  }
}

/**
 * Initialize error tracking
 */
export function initErrorTracking() {
  // Add global error handler for uncaught exceptions
  window.addEventListener('error', (event) => {
    trackError(event.error, {
      category: ErrorCategory.UNKNOWN,
      severity: ErrorSeverity.P1,
      action: 'uncaught_error',
      additionalData: {
        error_type: event.type,
        error_message: event.message,
        error_filename: event.filename,
        error_lineno: event.lineno,
        error_colno: event.colno
      }
    });
  });

  // Add global handler for unhandled promise rejections
  window.addEventListener('unhandledrejection', (event) => {
    const error = event.reason instanceof Error ? event.reason : new Error(String(event.reason));
    trackError(error, {
      category: ErrorCategory.UNKNOWN,
      severity: ErrorSeverity.P1,
      action: 'unhandled_promise_rejection',
      additionalData: {
        promise_error: event.reason
      }
    });
  });
} 