import React from 'react';
import { Pencil, Trash2, Clock } from 'lucide-react';
import type { TransactionItemProps } from './types';
import { EXPENSE_CATEGORY_ICONS } from './constants';

export const TransactionItem = React.memo(({ 
  item, 
  onEdit, 
  onDelete,
  categoryIcons,
  categoryColors,
  amountColor
}: TransactionItemProps) => (
  <div className="flex items-center px-6 py-2 hover:bg-dark-100 gap-3 group">
    <div className="flex-1 min-w-0 flex items-center gap-2">
      <span className="text-sm text-gray-300 truncate flex-shrink">
        {item.description || item.category}
      </span>
      <div 
        className="inline-flex items-center gap-1 px-1.5 py-0.5 rounded-full text-xs"
        style={categoryColors ? {
          backgroundColor: `${categoryColors[item.category as keyof typeof categoryColors]}15`,
          color: categoryColors[item.category as keyof typeof categoryColors]
        } : undefined}
      >
        {categoryIcons[item.category as keyof typeof categoryIcons]}
        <span className="hidden sm:inline">{item.category}</span>
      </div>
      {item.frequency && item.frequency !== 'one-time' && (
        <div className="hidden sm:flex items-center gap-1 text-xs text-gray-500">
          <Clock className="w-3 h-3" />
          <span>{item.frequency.replace('-', ' ')}</span>
        </div>
      )}
    </div>
    <div className="flex items-center gap-2">
      <p className={`text-sm font-display font-light whitespace-nowrap ${amountColor}`}>
        ${item.amount.toLocaleString()}
      </p>
      <div className="flex items-center opacity-0 group-hover:opacity-100 transition-opacity">
        <button
          onClick={() => onEdit(item)}
          className="p-1 text-gray-400 hover:text-white"
        >
          <Pencil className="w-3.5 h-3.5" />
        </button>
        <button
          onClick={() => onDelete(item.id, item.category in EXPENSE_CATEGORY_ICONS ? 'expense' : 'income')}
          className="p-1 text-gray-400 hover:text-red-500"
        >
          <Trash2 className="w-3.5 h-3.5" />
        </button>
      </div>
    </div>
  </div>
));

TransactionItem.displayName = 'TransactionItem'; 