import React from 'react';
import { DollarSign } from 'lucide-react';
import type { IncomeListProps } from './types';
import { TransactionList } from './TransactionList';
import { INCOME_CATEGORY_ICONS } from './constants';

export default function IncomeList({ budget, onDeleteBudgetItem, onOpenIncomeModal, onEditIncome }: IncomeListProps) {
  return (
    <TransactionList
      budget={budget}
      onDeleteBudgetItem={onDeleteBudgetItem}
      onOpenModal={onOpenIncomeModal}
      onEditItem={onEditIncome}
      type="income"
      title="Income"
      emptyStateIcon={<DollarSign className="w-12 h-12 text-gray-400 mx-auto mb-3" />}
      emptyStateMessage="No income recorded yet"
      addButtonText="Add Income"
      categoryIcons={INCOME_CATEGORY_ICONS}
      amountColor="text-green-500"
    />
  );
} 