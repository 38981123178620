import React, { useState } from 'react';
import {
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  eachDayOfInterval,
  format,
  isSameMonth,
  isSameDay,
  parseISO
} from 'date-fns';
import { Calendar, X } from 'lucide-react';
import type { Event } from '../../types/event';

interface MonthViewProps {
  currentDate: Date;
  events: Event[];
  onSelectDate: (date: Date) => void;
  onSelectEvent: (event: Event) => void;
}

export function MonthView({ currentDate, events, onSelectDate, onSelectEvent }: MonthViewProps) {
  const [selectedDay, setSelectedDay] = useState<Date | null>(null);
  const monthStart = startOfMonth(currentDate);
  const monthEnd = endOfMonth(monthStart);
  const calendarStart = startOfWeek(monthStart);
  const calendarEnd = endOfWeek(monthEnd);

  const days = eachDayOfInterval({ start: calendarStart, end: calendarEnd });

  // Function to get events for a specific day
  const getDayEvents = (day: Date) => {
    return events.filter(event => isSameDay(parseISO(event.date), day));
  };

  // Function to get event color based on type
  const getEventColor = (type: Event['type']) => {
    switch (type) {
      case 'race':
        return 'bg-red-500/20 text-red-400';
      case 'practice':
        return 'bg-blue-500/20 text-blue-400';
      case 'maintenance':
        return 'bg-yellow-500/20 text-yellow-400';
      default:
        return 'bg-gray-500/20 text-gray-400';
    }
  };

  return (
    <>
      <div className="grid grid-cols-7 gap-px bg-dark-50">
        {/* Day headers */}
        {['S', 'M', 'T', 'W', 'T', 'F', 'S'].map(day => (
          <div 
            key={day} 
            className="text-center text-gray-400 font-medium p-2 bg-dark-200"
          >
            {day}
          </div>
        ))}

        {/* Calendar days */}
        {days.map((day, index) => {
          const dayEvents = getDayEvents(day);
          const isCurrentMonth = isSameMonth(day, currentDate);
          const isToday = isSameDay(day, new Date());
          const hasEvents = dayEvents.length > 0;

          return (
            <button
              key={index}
              onClick={() => {
                if (hasEvents) {
                  setSelectedDay(day);
                } else {
                  onSelectDate(day);
                }
              }}
              className={`
                relative flex flex-col items-stretch
                min-h-[80px] sm:min-h-[100px] p-1
                ${isCurrentMonth ? 'bg-dark-200' : 'bg-dark-300'}
                hover:bg-dark-100 transition-colors
              `}
            >
              {/* Date number */}
              <div className={`
                text-right p-1
                ${isToday ? 'text-primary-500 font-bold' : isCurrentMonth ? 'text-white' : 'text-gray-600'}
              `}>
                {format(day, 'd')}
              </div>

              {/* Event indicators for mobile */}
              <div className="sm:hidden flex flex-wrap gap-1 mt-auto">
                {dayEvents.length > 0 && (
                  <div className={`
                    w-2 h-2 rounded-full
                    ${getEventColor(dayEvents[0].type).replace('text-', 'bg-').replace('/20', '')}
                  `} />
                )}
                {dayEvents.length > 1 && (
                  <div className="text-xs text-gray-400">
                    +{dayEvents.length - 1}
                  </div>
                )}
              </div>

              {/* Event list for desktop */}
              <div className="hidden sm:flex flex-col gap-1 mt-1">
                {dayEvents.slice(0, 3).map((event, eventIndex) => (
                  <div
                    key={eventIndex}
                    onClick={(e) => {
                      e.stopPropagation();
                      onSelectEvent(event);
                    }}
                    className={`
                      text-xs px-1 py-0.5 rounded truncate cursor-pointer
                      ${getEventColor(event.type)}
                    `}
                  >
                    {event.title}
                  </div>
                ))}
                {dayEvents.length > 3 && (
                  <div className="text-xs text-gray-400 px-1">
                    +{dayEvents.length - 3} more
                  </div>
                )}
              </div>
            </button>
          );
        })}
      </div>

      {/* Mobile Event Modal */}
      {selectedDay && (
        <div className="fixed inset-0 bg-black/50 flex items-end sm:items-center justify-center p-4 z-50">
          <div className="bg-dark-200 rounded-t-lg sm:rounded-lg w-full max-w-md">
            <div className="flex items-center justify-between p-4 border-b border-dark-50">
              <h3 className="text-lg font-semibold text-white">
                {format(selectedDay, 'MMMM d, yyyy')}
              </h3>
              <button
                onClick={() => setSelectedDay(null)}
                className="p-2 text-gray-400 hover:text-white"
              >
                <X className="w-5 h-5" />
              </button>
            </div>
            <div className="p-4 max-h-[60vh] overflow-y-auto">
              {getDayEvents(selectedDay).length === 0 ? (
                <div className="text-center py-8">
                  <Calendar className="w-12 h-12 text-gray-400 mx-auto mb-3" />
                  <p className="text-gray-400">No events scheduled</p>
                  <button
                    onClick={() => {
                      onSelectDate(selectedDay);
                      setSelectedDay(null);
                    }}
                    className="mt-4 text-primary-500 hover:text-primary-400"
                  >
                    Add event
                  </button>
                </div>
              ) : (
                <div className="space-y-3">
                  {getDayEvents(selectedDay).map((event, index) => (
                    <button
                      key={index}
                      onClick={() => {
                        onSelectEvent(event);
                        setSelectedDay(null);
                      }}
                      className="w-full flex items-start gap-3 p-3 bg-dark-100 rounded-lg hover:bg-dark-50 transition-colors text-left"
                    >
                      <div className={`p-2 rounded-lg shrink-0 ${getEventColor(event.type)}`}>
                        <Calendar className="w-5 h-5" />
                      </div>
                      <div>
                        <h4 className="text-white font-medium">{event.title}</h4>
                        {event.location && (
                          <p className="text-sm text-gray-400 mt-1">{event.location}</p>
                        )}
                      </div>
                    </button>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}