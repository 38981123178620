import type { ExpenseCategory } from '../types/budget';

// Define consistent colors for each expense category
export const CATEGORY_COLORS: Record<ExpenseCategory, string> = {
  'Entry Fees': '#3B82F6',              // Blue
  'Travel & Lodging': '#10B981',        // Green
  'Fuel & Oil': '#F59E0B',              // Amber
  'Tires': '#6366F1',                   // Indigo
  'Parts & Repairs': '#EF4444',         // Red
  'Scheduled Maintenance': '#F97316',    // Orange
  'Tools & Equipment': '#EC4899',        // Pink
  'Safety Gear': '#14B8A6',             // Teal
  'Marketing & Sponsorship': '#8B5CF6',  // Purple
  'Insurance': '#64748B',               // Slate
  'Other': '#6B7280'                    // Gray
};

// Helper function to get color for a category
export const getCategoryColor = (category: ExpenseCategory): string => {
  return CATEGORY_COLORS[category] || CATEGORY_COLORS['Other'];
};

// Helper function to get category color with fallback
export const getCategoryColorWithFallback = (category: string): string => {
  return CATEGORY_COLORS[category as ExpenseCategory] || CATEGORY_COLORS['Other'];
}; 