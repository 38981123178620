import type { PartCategory, PartSubcategory } from '../types/part';

// Define the types of specification fields
export type SpecFieldType = 'text' | 'number' | 'select' | 'date' | 'location';

// Define common location options for parts that can be installed in specific positions
export type PartLocation = 'front' | 'rear' | 'both' | 'front_left' | 'front_right' | 'rear_left' | 'rear_right';

export interface SpecFieldOption {
  value: string;
  label: string;
}

export interface SpecField {
  id: string;
  label: string;
  type: SpecFieldType;
  unit?: string;
  options?: SpecFieldOption[];
  description?: string;
  placeholder?: string;
  required?: boolean;
  // Additional metadata for specialized fields
  isLocationField?: boolean;        // Indicates if this field specifies the part's installation location
  isCertificationField?: boolean;   // Indicates if this field is related to certification/expiry
  isWearTrackingField?: boolean;    // Indicates if this field is used for wear tracking
  wearMetric?: 'treadDepth' | 'thickness' | 'hours' | 'miles' | 'months'; // The unit for wear tracking
}

// Define a type that maps each subcategory to its specification fields
export type PartSpecsMap = {
  [Category in PartCategory]?: {
    [Subcategory in PartSubcategory]?: {
      specs: SpecField[];
      isConsumable?: boolean;
      hasLocation?: boolean;        // Indicates if this part type has a location (e.g., tires, brake pads)
      hasCertification?: boolean;   // Indicates if this part type requires certification (e.g., safety equipment)
      hasWearTracking?: boolean;    // Indicates if this part type should track wear
    };
  };
};

// Define the specifications for each part subcategory
export const PART_SPECS: PartSpecsMap = {
  'Engine': {
    'Oil': {
      specs: [
        {
          id: 'viscosity',
          label: 'Viscosity',
          type: 'select',
          options: [
            { value: '0w-20', label: '0W-20' },
            { value: '0w-30', label: '0W-30' },
            { value: '5w-20', label: '5W-20' },
            { value: '5w-30', label: '5W-30' },
            { value: '5w-40', label: '5W-40' },
            { value: '10w-30', label: '10W-30' },
            { value: '10w-40', label: '10W-40' },
            { value: '15w-40', label: '15W-40' },
            { value: '20w-50', label: '20W-50' },
          ],
          description: 'The viscosity rating of the oil',
          required: true
        },
        {
          id: 'oilType',
          label: 'Oil Type',
          type: 'select',
          options: [
            { value: 'conventional', label: 'Conventional' },
            { value: 'synthetic', label: 'Full Synthetic' },
            { value: 'synthetic-blend', label: 'Synthetic Blend' },
            { value: 'high-mileage', label: 'High Mileage' },
            { value: 'racing', label: 'Racing Oil' },
          ],
          description: 'The type of oil',
        },
        {
          id: 'capacity',
          label: 'Capacity',
          type: 'number',
          unit: 'quarts',
          description: 'The oil capacity',
        },
        {
          id: 'changeInterval',
          label: 'Change Interval',
          type: 'number',
          unit: 'miles',
          description: 'Recommended change interval',
          isWearTrackingField: true,
          wearMetric: 'miles'
        }
      ],
      isConsumable: true,
      hasWearTracking: true
    },
    'Filter': {
      specs: [
        {
          id: 'filterType',
          label: 'Filter Type',
          type: 'select',
          options: [
            { value: 'paper', label: 'Paper' },
            { value: 'synthetic', label: 'Synthetic' },
            { value: 'high-flow', label: 'High Flow' },
          ],
          description: 'The type of oil filter',
        }
      ],
      isConsumable: true
    },
    'Spark Plugs': {
      specs: [
        {
          id: 'heatRange',
          label: 'Heat Range',
          type: 'select',
          options: [
            { value: 'stock', label: 'Stock' },
            { value: '1-step-colder', label: '1 Step Colder' },
            { value: '2-step-colder', label: '2 Step Colder' },
            { value: '3-step-colder', label: '3 Step Colder' },
            { value: '1-step-hotter', label: '1 Step Hotter' },
          ],
          description: 'The heat range of the spark plug',
        },
        {
          id: 'gapSize',
          label: 'Gap Size',
          type: 'number',
          unit: 'in',
          description: 'The gap size of the spark plug',
        },
        {
          id: 'material',
          label: 'Electrode Material',
          type: 'select',
          options: [
            { value: 'copper', label: 'Copper' },
            { value: 'platinum', label: 'Platinum' },
            { value: 'double-platinum', label: 'Double Platinum' },
            { value: 'iridium', label: 'Iridium' },
            { value: 'ruthenium', label: 'Ruthenium' },
          ],
          description: 'The electrode material of the spark plug',
        }
      ],
      isConsumable: true
    },
    'Intake': {
      specs: [
        {
          id: 'filterType',
          label: 'Filter Type',
          type: 'select',
          options: [
            { value: 'panel', label: 'Panel Filter' },
            { value: 'cone', label: 'Cone Filter' },
            { value: 'dry', label: 'Dry Filter' },
            { value: 'oiled', label: 'Oiled Filter' },
          ],
          description: 'The type of air filter',
        },
        {
          id: 'intakeTubeSize',
          label: 'Intake Tube Size',
          type: 'number',
          unit: 'mm',
          description: 'The diameter of the intake tube',
        }
      ]
    }
  },
  'Tires': {
    'Summer': {
      specs: [
        {
          id: 'treadwear',
          label: 'Treadwear Rating',
          type: 'number',
          description: 'The treadwear rating of the tire',
        },
        {
          id: 'size',
          label: 'Size',
          type: 'text',
          description: 'The size of the tire (e.g., 245/40R18)',
          placeholder: '245/40R18',
        },
        {
          id: 'location',
          label: 'Location',
          type: 'select',
          options: [
            { value: 'front', label: 'Front' },
            { value: 'rear', label: 'Rear' },
            { value: 'both', label: 'All Four' },
            { value: 'front_left', label: 'Front Left' },
            { value: 'front_right', label: 'Front Right' },
            { value: 'rear_left', label: 'Rear Left' },
            { value: 'rear_right', label: 'Rear Right' },
          ],
          description: 'Where the tire is installed on the vehicle',
          isLocationField: true
        },
        {
          id: 'treadDepth',
          label: 'Tread Depth',
          type: 'number',
          unit: 'mm',
          description: 'Current tread depth',
          isWearTrackingField: true,
          wearMetric: 'treadDepth'
        },
        {
          id: 'treadDepthLimit',
          label: 'Minimum Tread Depth',
          type: 'number',
          unit: 'mm',
          description: 'Minimum safe tread depth',
          isWearTrackingField: true
        }
      ],
      isConsumable: true,
      hasLocation: true,
      hasWearTracking: true
    },
    'Winter': {
      specs: [
        {
          id: 'snowRated',
          label: 'Snow Rated',
          type: 'select',
          options: [
            { value: 'yes', label: 'Yes' },
            { value: 'no', label: 'No' },
          ],
          description: 'Whether the tire is snow rated',
        },
        {
          id: 'studdable',
          label: 'Studdable',
          type: 'select',
          options: [
            { value: 'yes', label: 'Yes' },
            { value: 'no', label: 'No' },
          ],
          description: 'Whether the tire can be studded',
        },
        {
          id: 'size',
          label: 'Size',
          type: 'text',
          description: 'The size of the tire (e.g., 245/40R18)',
          placeholder: '245/40R18',
        }
      ],
      isConsumable: true
    },
    'Track': {
      specs: [
        {
          id: 'treadwear',
          label: 'Treadwear Rating',
          type: 'number',
          description: 'The treadwear rating of the tire',
        },
        {
          id: 'compound',
          label: 'Compound',
          type: 'select',
          options: [
            { value: 'hard', label: 'Hard' },
            { value: 'medium', label: 'Medium' },
            { value: 'soft', label: 'Soft' },
          ],
          description: 'The compound of the tire',
        },
        {
          id: 'size',
          label: 'Size',
          type: 'text',
          description: 'The size of the tire (e.g., 245/40R18)',
          placeholder: '245/40R18',
        }
      ],
      isConsumable: true
    },
    'All Season': {
      specs: [
        {
          id: 'treadwear',
          label: 'Treadwear Rating',
          type: 'number',
          description: 'The treadwear rating of the tire',
        },
        {
          id: 'size',
          label: 'Size',
          type: 'text',
          description: 'The size of the tire (e.g., 245/40R18)',
          placeholder: '245/40R18',
        }
      ],
      isConsumable: true
    },
    'Rain': {
      specs: [
        {
          id: 'treadwear',
          label: 'Treadwear Rating',
          type: 'number',
          description: 'The treadwear rating of the tire',
        },
        {
          id: 'size',
          label: 'Size',
          type: 'text',
          description: 'The size of the tire (e.g., 245/40R18)',
          placeholder: '245/40R18',
        },
        {
          id: 'treadDepth',
          label: 'Tread Depth',
          type: 'number',
          unit: 'mm',
          description: 'The tread depth of the tire',
        }
      ],
      isConsumable: true
    },
    'Performance': {
      specs: [
        {
          id: 'treadwear',
          label: 'Treadwear Rating',
          type: 'number',
          description: 'The treadwear rating of the tire',
        },
        {
          id: 'size',
          label: 'Size',
          type: 'text',
          description: 'The size of the tire (e.g., 245/40R18)',
          placeholder: '245/40R18',
        }
      ],
      isConsumable: true
    }
  },
  'Brakes': {
    'Pads': {
      specs: [
        {
          id: 'compound',
          label: 'Compound',
          type: 'select',
          options: [
            { value: 'organic', label: 'Organic' },
            { value: 'semi-metallic', label: 'Semi-Metallic' },
            { value: 'ceramic', label: 'Ceramic' },
            { value: 'race', label: 'Racing' },
          ],
          description: 'The compound of the brake pads',
        },
        {
          id: 'frictionRating',
          label: 'Friction Rating',
          type: 'text',
          description: 'The friction rating of the brake pads (e.g., FF, HH)',
          placeholder: 'FF, HH, etc.',
        },
        {
          id: 'location',
          label: 'Location',
          type: 'select',
          options: [
            { value: 'front', label: 'Front' },
            { value: 'rear', label: 'Rear' },
          ],
          description: 'Where the pads are installed on the vehicle',
          isLocationField: true
        },
        {
          id: 'thickness',
          label: 'Current Thickness',
          type: 'number',
          unit: 'mm',
          description: 'Current pad thickness',
          isWearTrackingField: true,
          wearMetric: 'thickness'
        },
        {
          id: 'minimumThickness',
          label: 'Minimum Thickness',
          type: 'number',
          unit: 'mm',
          description: 'Minimum safe pad thickness',
          isWearTrackingField: true
        }
      ],
      isConsumable: true,
      hasLocation: true,
      hasWearTracking: true
    },
    'Rotors': {
      specs: [
        {
          id: 'type',
          label: 'Rotor Type',
          type: 'select',
          options: [
            { value: 'solid', label: 'Solid' },
            { value: 'vented', label: 'Vented' },
            { value: 'drilled', label: 'Drilled' },
            { value: 'slotted', label: 'Slotted' },
            { value: 'drilled-slotted', label: 'Drilled & Slotted' },
            { value: 'two-piece', label: 'Two-Piece' },
          ],
          description: 'The type of brake rotor',
        },
        {
          id: 'diameter',
          label: 'Diameter',
          type: 'number',
          unit: 'mm',
          description: 'The diameter of the brake rotor',
        },
        {
          id: 'thickness',
          label: 'Thickness',
          type: 'number',
          unit: 'mm',
          description: 'The thickness of the brake rotor',
        }
      ],
      isConsumable: true
    },
    'Fluid': {
      specs: [
        {
          id: 'dotRating',
          label: 'DOT Rating',
          type: 'select',
          options: [
            { value: 'dot3', label: 'DOT 3' },
            { value: 'dot4', label: 'DOT 4' },
            { value: 'dot5', label: 'DOT 5' },
            { value: 'dot5.1', label: 'DOT 5.1' },
          ],
          description: 'The DOT rating of the brake fluid',
        },
        {
          id: 'boilingPoint',
          label: 'Dry Boiling Point',
          type: 'number',
          unit: '°C',
          description: 'The dry boiling point of the brake fluid',
        },
        {
          id: 'wetBoilingPoint',
          label: 'Wet Boiling Point',
          type: 'number',
          unit: '°C',
          description: 'The wet boiling point of the brake fluid',
        }
      ],
      isConsumable: true
    }
  },
  'Suspension': {
    'Coilovers': {
      specs: [
        {
          id: 'springRate',
          label: 'Spring Rate (Front)',
          type: 'number',
          unit: 'kg/mm',
          description: 'The spring rate of the front coilovers',
        },
        {
          id: 'springRateRear',
          label: 'Spring Rate (Rear)',
          type: 'number',
          unit: 'kg/mm',
          description: 'The spring rate of the rear coilovers',
        },
        {
          id: 'damperAdjustable',
          label: 'Damper Adjustability',
          type: 'select',
          options: [
            { value: 'none', label: 'Not Adjustable' },
            { value: 'single', label: 'Single Adjustable' },
            { value: 'double', label: 'Double Adjustable' },
            { value: 'triple', label: 'Triple Adjustable' },
            { value: 'quadruple', label: 'Quadruple Adjustable' },
          ],
          description: 'The adjustability of the dampers',
        }
      ]
    },
    'Springs': {
      specs: [
        {
          id: 'springRate',
          label: 'Spring Rate',
          type: 'number',
          unit: 'kg/mm',
          description: 'The spring rate',
        },
        {
          id: 'length',
          label: 'Length',
          type: 'number',
          unit: 'mm',
          description: 'The length of the spring',
        }
      ]
    },
    'Sway Bars': {
      specs: [
        {
          id: 'thickness',
          label: 'Thickness',
          type: 'number',
          unit: 'mm',
          description: 'The thickness of the sway bar',
        },
        {
          id: 'adjustable',
          label: 'Adjustable',
          type: 'select',
          options: [
            { value: 'yes', label: 'Yes' },
            { value: 'no', label: 'No' },
          ],
          description: 'Whether the sway bar is adjustable',
        }
      ]
    }
  },
  'Transmission': {
    'Fluid': {
      specs: [
        {
          id: 'type',
          label: 'Fluid Type',
          type: 'select',
          options: [
            { value: 'atf', label: 'Automatic Transmission Fluid' },
            { value: 'mtf', label: 'Manual Transmission Fluid' },
            { value: 'cvt', label: 'CVT Fluid' },
            { value: 'dct', label: 'Dual Clutch Transmission Fluid' },
          ],
          description: 'The type of transmission fluid',
        },
        {
          id: 'capacity',
          label: 'Capacity',
          type: 'number',
          unit: 'quarts',
          description: 'The fluid capacity',
        }
      ],
      isConsumable: true
    }
  },
  'Interior': {
    'Harnesses': {
      specs: [
        {
          id: 'points',
          label: 'Points',
          type: 'select',
          options: [
            { value: '3', label: '3-Point' },
            { value: '4', label: '4-Point' },
            { value: '5', label: '5-Point' },
            { value: '6', label: '6-Point' },
          ],
          description: 'The number of attachment points',
        },
        {
          id: 'certification',
          label: 'Certification',
          type: 'select',
          options: [
            { value: 'fia', label: 'FIA Approved' },
            { value: 'sfi', label: 'SFI Approved' },
            { value: 'dot', label: 'DOT Approved' },
            { value: 'none', label: 'Not Certified' },
          ],
          description: 'The certification standard',
        },
        {
          id: 'certificationDate',
          label: 'Certification Date',
          type: 'date',
          description: 'When the harness was certified',
          isCertificationField: true
        },
        {
          id: 'expiryDate',
          label: 'Expiry Date',
          type: 'date',
          description: 'When the certification expires',
          isCertificationField: true
        }
      ],
      isConsumable: true,
      hasCertification: true
    },
    'Seats': {
      specs: [
        {
          id: 'type',
          label: 'Seat Type',
          type: 'select',
          options: [
            { value: 'racing', label: 'Racing Seat' },
            { value: 'bucket', label: 'Bucket Seat' },
            { value: 'stock', label: 'Stock Seat' },
          ],
          description: 'The type of seat',
        },
        {
          id: 'material',
          label: 'Material',
          type: 'select',
          options: [
            { value: 'fabric', label: 'Fabric' },
            { value: 'leather', label: 'Leather' },
            { value: 'vinyl', label: 'Vinyl' },
            { value: 'alcantara', label: 'Alcantara' },
            { value: 'carbon', label: 'Carbon Fiber' },
          ],
          description: 'The seat material',
        },
        {
          id: 'certification',
          label: 'Certification',
          type: 'select',
          options: [
            { value: 'fia', label: 'FIA Approved' },
            { value: 'sfi', label: 'SFI Approved' },
            { value: 'none', label: 'Not Certified' },
          ],
          description: 'The certification standard',
        },
        {
          id: 'certificationDate',
          label: 'Certification Date',
          type: 'date',
          description: 'When the seat was certified',
          isCertificationField: true
        },
        {
          id: 'expiryDate',
          label: 'Expiry Date',
          type: 'date',
          description: 'When the certification expires',
          isCertificationField: true
        }
      ],
      hasCertification: true
    }
  },
  'Cooling': {
    'Fluid': {
      specs: [
        {
          id: 'type',
          label: 'Coolant Type',
          type: 'select',
          options: [
            { value: 'oat', label: 'OAT (Organic Acid Technology)' },
            { value: 'hoat', label: 'HOAT (Hybrid OAT)' },
            { value: 'iat', label: 'IAT (Inorganic Acid Technology)' },
            { value: 'water', label: 'Water' },
            { value: 'water-wetter', label: 'Water with Additive' },
          ],
          description: 'The type of coolant',
        },
        {
          id: 'capacity',
          label: 'Capacity',
          type: 'number',
          unit: 'liters',
          description: 'The coolant capacity',
        }
      ],
      isConsumable: true
    }
  }
};

// Helper function to get specification fields for a part category and subcategory
export function getPartSpecs(category: PartCategory, subcategory?: PartSubcategory): SpecField[] {
  if (!category) return [];
  
  const categorySpecs = PART_SPECS[category];
  if (!categorySpecs) return [];
  
  if (subcategory && categorySpecs[subcategory]) {
    return categorySpecs[subcategory]?.specs || [];
  }
  
  return [];
}

// Helper function to check if a part is a consumable based on its category and subcategory
export function isConsumable(category: PartCategory, subcategory?: PartSubcategory): boolean {
  if (!category) return false;
  
  const categorySpecs = PART_SPECS[category];
  if (!categorySpecs) return false;
  
  if (subcategory && categorySpecs[subcategory]) {
    return !!categorySpecs[subcategory]?.isConsumable;
  }
  
  return false;
}

// Helper function to check if a part has location fields
export function hasLocation(category: PartCategory, subcategory?: PartSubcategory): boolean {
  if (!category) return false;
  
  const categorySpecs = PART_SPECS[category];
  if (!categorySpecs) return false;
  
  if (subcategory && categorySpecs[subcategory]) {
    return !!categorySpecs[subcategory]?.hasLocation;
  }
  
  return false;
}

// Helper function to check if a part has certification fields
export function hasCertification(category: PartCategory, subcategory?: PartSubcategory): boolean {
  if (!category) return false;
  
  const categorySpecs = PART_SPECS[category];
  if (!categorySpecs) return false;
  
  if (subcategory && categorySpecs[subcategory]) {
    return !!categorySpecs[subcategory]?.hasCertification;
  }
  
  return false;
}

// Helper function to check if a part has wear tracking fields
export function hasWearTracking(category: PartCategory, subcategory?: PartSubcategory): boolean {
  if (!category) return false;
  
  const categorySpecs = PART_SPECS[category];
  if (!categorySpecs) return false;
  
  if (subcategory && categorySpecs[subcategory]) {
    return !!categorySpecs[subcategory]?.hasWearTracking;
  }
  
  return false;
}

// Helper function to get location fields for a part
export function getLocationFields(category: PartCategory, subcategory?: PartSubcategory): SpecField[] {
  const specs = getPartSpecs(category, subcategory);
  return specs.filter(spec => spec.isLocationField);
}

// Helper function to get certification fields for a part
export function getCertificationFields(category: PartCategory, subcategory?: PartSubcategory): SpecField[] {
  const specs = getPartSpecs(category, subcategory);
  return specs.filter(spec => spec.isCertificationField);
}

// Helper function to get wear tracking fields for a part
export function getWearTrackingFields(category: PartCategory, subcategory?: PartSubcategory): SpecField[] {
  const specs = getPartSpecs(category, subcategory);
  return specs.filter(spec => spec.isWearTrackingField);
} 