import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { ExternalLink, Loader2, Calendar, CheckCircle2, Clock, Award, XCircle, ArrowRight } from 'lucide-react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { Navigate } from 'react-router-dom';
import { format, differenceInDays } from 'date-fns';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../config/firebase';

export default function Subscription() {
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [isExtendingTrial, setIsExtendingTrial] = useState(false);

  // Only users with any subscription status can access this page
  if (!user || !user.subscription) {
    return <Navigate to="/login" replace />;
  }

  const isTrialExpired = user.subscription.status === 'trial' && 
    user.subscription.currentPeriodEnd && 
    new Date(user.subscription.currentPeriodEnd) < new Date();

  const trialDaysRemaining = user.subscription.status === 'trial' && 
    user.subscription.currentPeriodEnd ? 
    Math.max(0, differenceInDays(new Date(user.subscription.currentPeriodEnd), new Date())) : 
    0;

  const trialDaysUsed = user.subscription.trialDaysUsed || 0;
  const lastFreeDayGranted = user.subscription.lastFreeDayGranted;

  const canExtendTrial = isTrialExpired && 
    ((trialDaysUsed >= 7 && trialDaysUsed < 10) || 
    (lastFreeDayGranted && 
      differenceInDays(new Date(), new Date(lastFreeDayGranted)) > 180 && 
      trialDaysUsed < 14));

  const handleManageSubscription = async () => {
    setIsLoading(true);
    try {
      const functions = getFunctions();
      const createPortalSession = httpsCallable(functions, 'createPortalSession');
      const { data } = await createPortalSession();
      window.location.href = (data as { url: string }).url;
    } catch (error) {
      console.error('Error creating portal session:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRedirectToCheckout = async () => {
    setIsLoading(true);
    try {
      const functions = getFunctions();
      const createCheckoutSession = httpsCallable(functions, 'createCheckoutSession');
      const { data } = await createCheckoutSession();
      window.location.href = (data as { url: string }).url;
    } catch (error) {
      console.error('Error redirecting to checkout:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleExtendTrial = async () => {
    if (!user?.id) return;
    
    setIsExtendingTrial(true);
    try {
      const userRef = doc(db, 'users', user.id);
      
      // Extend trial by 1 day
      const extendedDate = new Date();
      extendedDate.setDate(extendedDate.getDate() + 1);
      
      await updateDoc(userRef, {
        'subscription.status': 'trial',
        'subscription.currentPeriodEnd': extendedDate.toISOString(),
        'subscription.trialDaysUsed': trialDaysUsed + 1,
        'subscription.lastFreeDayGranted': new Date().toISOString()
      });
      
      // Reload page to reflect changes
      window.location.reload();
    } catch (error) {
      console.error('Error extending trial:', error);
    } finally {
      setIsExtendingTrial(false);
    }
  };

  return (
    <div className="space-y-6">
      <h1 className="text-2xl font-bold text-white">Subscription</h1>

      {user.subscription.status === 'active' && (
        <div className="bg-gradient-to-br from-dark-200 to-dark-300 rounded-lg p-6 shadow-lg border border-dark-50">
          <div className="flex items-center justify-between mb-8">
            <h2 className="text-lg font-semibold text-white">Current Plan</h2>
            <div className="flex items-center gap-2 px-3 py-1 bg-green-500/10 text-green-400 rounded-full">
              <CheckCircle2 className="w-4 h-4" />
              <span className="text-sm font-medium">Active</span>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="space-y-6">
              <div className="flex items-start gap-4">
                <Calendar className="w-5 h-5 text-primary-500 mt-1" />
                <div>
                  <p className="text-sm text-gray-400">Member Since</p>
                  <p className="text-lg font-medium text-white">
                    {user.createdAt ? 
                      format(new Date(user.createdAt), 'MMMM d, yyyy') :
                      'N/A'
                    }
                  </p>
                </div>
              </div>

              <div className="flex items-start gap-4">
                <Clock className="w-5 h-5 text-primary-500 mt-1" />
                <div>
                  <p className="text-sm text-gray-400">Renews On</p>
                  <p className="text-lg font-medium text-white">
                    {user.subscription.currentPeriodEnd ? 
                      format(new Date(user.subscription.currentPeriodEnd), 'MMMM d, yyyy') :
                      'N/A'
                    }
                  </p>
                </div>
              </div>
            </div>

            <div className="flex items-end justify-end md:border-l md:border-dark-50 md:pl-8">
              <button
                onClick={handleManageSubscription}
                disabled={isLoading}
                className="inline-flex items-center gap-2 px-6 py-3 bg-primary-500 text-white rounded-md hover:bg-primary-400 disabled:opacity-50 transition-colors duration-200"
              >
                {isLoading ? (
                  <>
                    <Loader2 className="w-4 h-4 animate-spin" />
                    Processing...
                  </>
                ) : (
                  <>
                    Manage Subscription
                    <ExternalLink className="w-4 h-4" />
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      )}

      {user.subscription.status === 'trial' && !isTrialExpired && (
        <div className="bg-gradient-to-br from-dark-200 to-dark-300 rounded-lg p-6 shadow-lg border border-dark-50">
          <div className="flex items-center justify-between mb-6">
            <h2 className="text-lg font-semibold text-white">Trial Subscription</h2>
            <div className="flex items-center gap-2 px-3 py-1 bg-blue-500/10 text-blue-400 rounded-full">
              <Award className="w-4 h-4" />
              <span className="text-sm font-medium">Trial Active</span>
            </div>
          </div>

          <div className="space-y-6">
            <div className="flex items-start gap-4">
              <Calendar className="w-5 h-5 text-primary-500 mt-1" />
              <div>
                <p className="text-sm text-gray-400">Trial Started</p>
                <p className="text-lg font-medium text-white">
                  {user.subscription.trialStartDate ? 
                    format(new Date(user.subscription.trialStartDate), 'MMMM d, yyyy') :
                    format(new Date(user.createdAt || ''), 'MMMM d, yyyy')
                  }
                </p>
              </div>
            </div>

            <div className="flex items-start gap-4">
              <Clock className="w-5 h-5 text-primary-500 mt-1" />
              <div>
                <p className="text-sm text-gray-400">Trial Expires</p>
                <p className="text-lg font-medium text-white">
                  {user.subscription.currentPeriodEnd ? 
                    format(new Date(user.subscription.currentPeriodEnd), 'MMMM d, yyyy') :
                    'N/A'
                  }
                  <span className="ml-2 text-sm text-blue-400">
                    ({trialDaysRemaining} days remaining)
                  </span>
                </p>
              </div>
            </div>

            <div className="mt-8 p-4 bg-dark-300 rounded-lg">
              <h3 className="text-md font-medium text-white mb-2">Ready to upgrade?</h3>
              <p className="text-sm text-gray-400 mb-4">
                Subscribe now to continue using PitPrep without interruption after your trial ends.
              </p>
              <button
                onClick={handleRedirectToCheckout}
                disabled={isLoading}
                className="inline-flex items-center gap-2 px-6 py-3 bg-primary-500 text-white rounded-md hover:bg-primary-400 disabled:opacity-50 transition-colors duration-200"
              >
                {isLoading ? (
                  <>
                    <Loader2 className="w-4 h-4 animate-spin" />
                    Processing...
                  </>
                ) : (
                  <>
                    Upgrade Now
                    <ArrowRight className="w-4 h-4" />
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      )}

      {isTrialExpired && (
        <div className="bg-gradient-to-br from-dark-200 to-dark-300 rounded-lg p-6 shadow-lg border border-dark-50">
          <div className="flex items-center justify-between mb-6">
            <h2 className="text-lg font-semibold text-white">Trial Expired</h2>
            <div className="flex items-center gap-2 px-3 py-1 bg-red-500/10 text-red-400 rounded-full">
              <XCircle className="w-4 h-4" />
              <span className="text-sm font-medium">Expired</span>
            </div>
          </div>

          <div className="space-y-6">
            <div className="flex items-start gap-4">
              <Calendar className="w-5 h-5 text-primary-500 mt-1" />
              <div>
                <p className="text-sm text-gray-400">Trial Started</p>
                <p className="text-lg font-medium text-white">
                  {user.subscription.trialStartDate ? 
                    format(new Date(user.subscription.trialStartDate), 'MMMM d, yyyy') :
                    format(new Date(user.createdAt || ''), 'MMMM d, yyyy')
                  }
                </p>
              </div>
            </div>

            <div className="flex items-start gap-4">
              <Clock className="w-5 h-5 text-primary-500 mt-1" />
              <div>
                <p className="text-sm text-gray-400">Trial Expired</p>
                <p className="text-lg font-medium text-white">
                  {user.subscription.currentPeriodEnd ? 
                    format(new Date(user.subscription.currentPeriodEnd), 'MMMM d, yyyy') :
                    'N/A'
                  }
                </p>
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-8">
              <div className="p-4 bg-dark-300 rounded-lg">
                <h3 className="text-md font-medium text-white mb-2">Subscribe Now</h3>
                <p className="text-sm text-gray-400 mb-4">
                  Get full access to PitPrep and continue managing your racing season.
                </p>
                <button
                  onClick={handleRedirectToCheckout}
                  disabled={isLoading}
                  className="inline-flex items-center gap-2 px-6 py-3 bg-primary-500 text-white rounded-md hover:bg-primary-400 disabled:opacity-50 transition-colors duration-200 w-full justify-center"
                >
                  {isLoading ? (
                    <>
                      <Loader2 className="w-4 h-4 animate-spin" />
                      Processing...
                    </>
                  ) : (
                    <>
                      Subscribe Now
                      <ArrowRight className="w-4 h-4" />
                    </>
                  )}
                </button>
              </div>
              
              {canExtendTrial && (
                <div className="p-4 bg-dark-300 rounded-lg">
                  <h3 className="text-md font-medium text-white mb-2">Need More Time?</h3>
                  <p className="text-sm text-gray-400 mb-4">
                    You can extend your trial for one more day to explore PitPrep further.
                  </p>
                  <button
                    onClick={handleExtendTrial}
                    disabled={isExtendingTrial}
                    className="inline-flex items-center gap-2 px-6 py-3 bg-white/10 text-white rounded-md hover:bg-white/20 disabled:opacity-50 transition-colors duration-200 w-full justify-center"
                  >
                    {isExtendingTrial ? (
                      <>
                        <Loader2 className="w-4 h-4 animate-spin" />
                        Processing...
                      </>
                    ) : (
                      <>
                        Get 1 More Free Day
                        <Clock className="w-4 h-4" />
                      </>
                    )}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}