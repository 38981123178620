import React, { useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Calendar, ChevronRight, DollarSign, TrendingUp } from 'lucide-react';
import type { Budget } from '../../types/budget';
import type { Part } from '../../types/part';
import { getPartIcon } from '../../config/partIcons';

interface PartsOverviewProps {
  budget: Budget;
  recentParts: Part[];
}

export default function PartsOverview({ budget, recentParts }: PartsOverviewProps) {
  const [activeTab, setActiveTab] = useState<'recent' | 'categories'>('recent');

  // Group parts by category
  const partsByCategory = useMemo(() => {
    return recentParts.reduce((acc, part) => {
      const category = part.category || 'Uncategorized';
      if (!acc[category]) {
        acc[category] = {
          parts: [],
          totalCost: 0
        };
      }
      acc[category].parts.push(part);
      acc[category].totalCost += part.cost || 0;
      return acc;
    }, {} as Record<string, { parts: Part[], totalCost: number }>);
  }, [recentParts]);

  // Calculate total parts cost
  const totalPartsCost = useMemo(() => {
    return recentParts.reduce((sum, part) => sum + (part.cost || 0), 0);
  }, [recentParts]);

  // Get 5 most recent installations
  const recentInstallations = useMemo(() => {
    return recentParts
      .filter(part => part.metadata?.isInstalled && part.metadata?.installationDate)
      .sort((a, b) => new Date(b.metadata!.installationDate!).getTime() - new Date(a.metadata!.installationDate!).getTime())
      .slice(0, 5);
  }, [recentParts]);

  return (
    <div className="bg-dark-200 rounded-lg p-4">
      <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4 mb-4">
        <div>
          <h3 className="text-lg font-bold text-white">Parts Overview</h3>
          <p className="text-sm text-gray-400">Track your parts spending and costs</p>
        </div>
        <div className="flex gap-2">
          <button
            onClick={() => setActiveTab('recent')}
            className={`flex-1 sm:flex-none px-3 py-1 rounded-md text-sm font-medium transition-colors ${
              activeTab === 'recent'
                ? 'bg-primary-500 text-white'
                : 'text-gray-400 hover:text-white hover:bg-dark-300'
            }`}
          >
            Recent
          </button>
          <button
            onClick={() => setActiveTab('categories')}
            className={`flex-1 sm:flex-none px-3 py-1 rounded-md text-sm font-medium transition-colors ${
              activeTab === 'categories'
                ? 'bg-primary-500 text-white'
                : 'text-gray-400 hover:text-white hover:bg-dark-300'
            }`}
          >
            By Category
          </button>
        </div>
      </div>

      {activeTab === 'recent' ? (
        <div className="space-y-4">
          <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between p-3 bg-dark-300 rounded-lg gap-4">
            <div className="flex items-center gap-2">
              <DollarSign className="w-5 h-5 text-green-500" />
              <div>
                <p className="text-sm text-gray-400">Total Parts Cost</p>
                <p className="text-xl font-bold text-white">${totalPartsCost.toLocaleString()}</p>
              </div>
            </div>
            <div className="text-left sm:text-right">
              <p className="text-sm text-gray-400">Recent Installations</p>
              <p className="text-xl font-bold text-white">{recentInstallations.length}</p>
            </div>
          </div>

          <div className="space-y-3">
            <h4 className="text-sm font-medium text-gray-400">Recent Installations</h4>
            {recentInstallations.length > 0 ? (
              recentInstallations.map(part => (
                <Link
                  key={part.id}
                  to={`/app/parts/${part.id}`}
                  className="flex items-center justify-between p-3 rounded-md bg-dark-300 hover:bg-dark-400 transition-colors group"
                >
                  <div className="flex items-center gap-3 min-w-0">
                    <div className="p-2 rounded-md bg-dark-400 group-hover:bg-dark-500">
                      <img 
                        src={`/icons/${getPartIcon(part.category, part.subcategory)}`}
                        alt={part.category}
                        className="w-5 h-5 text-gray-400 group-hover:text-primary-500"
                      />
                    </div>
                    <div className="min-w-0">
                      <p className="text-sm font-medium text-white group-hover:text-primary-500 truncate">{part.name}</p>
                      <div className="flex items-center gap-2 mt-1">
                        <span className="text-xs text-gray-400">
                          {new Date(part.metadata!.installationDate!).toLocaleDateString()}
                        </span>
                        <span className="text-xs text-gray-500">•</span>
                        <span className="text-xs text-gray-400">{part.category}</span>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center gap-2 ml-4">
                    <span className="text-sm font-medium text-white whitespace-nowrap">${(part.cost || 0).toLocaleString()}</span>
                    <ChevronRight className="w-4 h-4 text-gray-400 group-hover:text-primary-500 flex-shrink-0" />
                  </div>
                </Link>
              ))
            ) : (
              <p className="text-sm text-gray-400">No recent installations</p>
            )}
          </div>
        </div>
      ) : (
        <div className="space-y-4">
          {Object.entries(partsByCategory).map(([category, { parts, totalCost }]) => (
            <div key={category} className="space-y-2">
              <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between p-2 bg-dark-300 rounded-md gap-2">
                <div className="flex items-center gap-2">
                  <img 
                    src={`/icons/${getPartIcon(category)}`}
                    alt={category}
                    className="w-4 h-4 text-gray-400"
                  />
                  <h4 className="text-sm font-medium text-white">{category}</h4>
                </div>
                <div className="text-left sm:text-right">
                  <p className="text-sm font-medium text-white">${totalCost.toLocaleString()}</p>
                  <p className="text-xs text-gray-400">{parts.length} parts</p>
                </div>
              </div>
              <div className="space-y-2">
                {parts.map(part => (
                  <Link
                    key={part.id}
                    to={`/app/parts/${part.id}`}
                    className="flex items-center justify-between p-2 rounded-md bg-dark-300/50 hover:bg-dark-400 transition-colors group"
                  >
                    <div className="flex items-center gap-2 min-w-0">
                      <img 
                        src={`/icons/${getPartIcon(part.category, part.subcategory)}`}
                        alt={part.category}
                        className="w-4 h-4 text-gray-400 group-hover:text-primary-500"
                      />
                      <div className="min-w-0">
                        <p className="text-sm font-medium text-white group-hover:text-primary-500 truncate">{part.name}</p>
                        <p className="text-xs text-gray-400">
                          {part.metadata?.isInstalled ? 'Installed' : 'Not Installed'}
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center gap-2 ml-4">
                      <span className="text-sm font-medium text-white whitespace-nowrap">${(part.cost || 0).toLocaleString()}</span>
                      <ChevronRight className="w-4 h-4 text-gray-400 group-hover:text-primary-500 flex-shrink-0" />
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
} 