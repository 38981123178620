import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import PartCard from './PartCard';
import type { Part } from '../../types/part';
import type { Vehicle } from '../../types/vehicle';

interface DraggablePartCardProps {
  part: Part;
  onUpdate: (partId: string, updates: Partial<Part>) => Promise<void>;
  onDelete?: (partId: string) => Promise<boolean | undefined>;
  vehicles?: Vehicle[];
  isGarageView?: boolean;
}

export default function DraggablePartCard({ part, onUpdate, onDelete, vehicles = [], isGarageView = false }: DraggablePartCardProps) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({
    id: part.id,
    data: {
      type: 'part',
      part
    }
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
    zIndex: isDragging ? 10 : 1
  };

  // Wrapper for onDelete to match the expected type
  const handleDelete = onDelete ? async (partId: string) => {
    await onDelete(partId);
  } : undefined;

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className={`${isDragging ? 'cursor-grabbing' : 'cursor-grab'}`}
    >
      <PartCard
        part={part}
        onUpdate={onUpdate}
        onDelete={handleDelete}
        vehicles={vehicles}
        isGarageView={isGarageView}
        isDragging={isDragging}
      />
    </div>
  );
} 