/**
 * Shared styling utilities for card components
 * This ensures consistent sizing and responsive behavior across different card types
 */

// Icon container classes for consistent sizing across different screen sizes
export const iconContainerClasses = "w-14 xs:w-16 sm:w-20 ipad:w-14 md:w-24 lg:w-28 flex-shrink-0 mr-2 sm:mr-3 ipad:mr-2 md:mr-4 flex items-center justify-center self-center";

// Icon image classes for consistent sizing
export const iconImageClasses = "w-full max-h-16 xs:max-h-20 sm:max-h-24 ipad:max-h-16 md:max-h-28 object-contain";

// Card title classes
export const cardTitleClasses = "font-medium text-white text-xs xs:text-sm sm:text-base ipad:text-xs md:text-lg mb-1 line-clamp-2 pr-6 sm:pr-8 ipad:pr-6";

// Card padding classes
export const cardPaddingClasses = "p-2 sm:p-3 ipad:p-2.5 md:p-4";

// Badge classes - improved for better text wrapping and smaller font on iPad Pro
export const badgeClasses = "inline-flex items-center px-1 sm:px-1.5 ipad:px-1 py-0.5 rounded text-xs ipad:text-[10px] font-medium whitespace-normal break-words max-w-full";

// Badge container classes
export const badgeContainerClasses = "flex flex-wrap gap-1 sm:gap-1.5 ipad:gap-0.5 max-w-full";

// Action button classes
export const actionButtonClasses = "flex items-center rounded-md px-2 sm:px-3 ipad:px-2 py-0.5 xs:py-1 sm:py-1.5 ipad:py-1 text-white transition-colors text-xs sm:text-sm ipad:text-xs";

// Delete/dismiss button position classes
export const cornerButtonPositionClasses = "absolute top-2 right-2 sm:top-3 sm:right-3 ipad:top-2 ipad:right-2"; 