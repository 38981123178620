import React, { useState, useEffect } from 'react';
import { AuthProvider } from './contexts/AuthContext';
import { BrowserRouter as Router, Routes, Route, Navigate, useParams } from 'react-router-dom';
import Layout from './components/Layout';
import ProtectedRoute from './components/auth/ProtectedRoute';
import PublicRoute from './components/auth/PublicRoute';
import { collection, query, orderBy, onSnapshot, doc, getDoc, updateDoc, setDoc, addDoc, deleteDoc } from 'firebase/firestore';
import { db, analytics } from './config/firebase';
import { useAuth } from './contexts/AuthContext';
import { Toaster } from './components';
import { toast } from 'react-hot-toast';
import { Loader2 } from 'lucide-react';
import { ErrorBoundary, initErrorTracking } from './services/errorTracking.tsx';
import { initPerformanceTracking } from './services/performanceTracking';
import { initAnalyticsTracking } from './services/analyticsTracking';
import { logEvent } from 'firebase/analytics';
import PitBot from './components/chat/PitBot';

// Pages
import LandingPage from './pages/LandingPage';
import LoginPage from './pages/auth/LoginPage';
import Dashboard from './pages/Dashboard';
import VehicleDetail from './pages/VehicleDetail';
import VehicleForm from './pages/VehicleForm';
import Schedule from './pages/Schedule';
import EventDetail from './pages/EventDetail';
import Budget from './pages/Budget';
import Tasks from './pages/Tasks';
import TaskDetail from './pages/TaskDetail';
import Checklists from './pages/Checklists';
import ChecklistDetail from './pages/ChecklistDetail';
import Maintenance from './pages/Maintenance';
import Account from './pages/Account';
import Subscription from './pages/Subscription';
import Settings from './pages/Settings';
import ErrorPage from './pages/ErrorPage';
import PartDetail from './pages/PartDetail';

// Types
import type { Vehicle } from './types/vehicle';
import type { Task } from './types/task';
import type { Event } from './types/event';
import type { Checklist } from './types/checklist';
import type { Budget as BudgetType } from './types/budget';
import type { Part, PartMetadata } from './types/part';
import Garage from './pages/Garage.tsx';

// Initialize error tracking, performance tracking, and analytics
initErrorTracking();
initPerformanceTracking();
initAnalyticsTracking();

function AppContent() {
  const { user, programId } = useAuth();
  const [vehicles, setVehicles] = useState<Vehicle[]>([]);
  const [vehicleParts, setVehicleParts] = useState<Part[]>([]);
  const [checklists, setChecklists] = useState<Checklist[]>([]);
  const [events, setEvents] = useState<Event[]>([]);
  const [tasks, setTasks] = useState<Task[]>([]);
  const [budget, setBudget] = useState<BudgetType | null>(null);

  // Track initial app mount
  useEffect(() => {
    // Log app initialization
    logEvent(analytics, 'app_initialized', {
      timestamp: new Date().toISOString(),
      user_agent: navigator.userAgent,
      screen_size: `${window.innerWidth}x${window.innerHeight}`,
      platform: navigator.platform
    });
  }, []);

  // Fetch vehicles from Firestore
  useEffect(() => {
    if (!programId) return;

    const vehiclesRef = collection(db, 'programs', programId, 'vehicles');
    const q = query(vehiclesRef, orderBy('make'));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const fetchedVehicles = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as Vehicle[];

      setVehicles(fetchedVehicles);
    });

    return () => unsubscribe();
  }, [programId]);

  // Fetch budget from Firestore
  useEffect(() => {
    if (!programId) return;

    const budgetRef = doc(db, 'programs', programId, 'budget', 'main');
    
    const unsubscribe = onSnapshot(budgetRef, (snapshot) => {
      if (snapshot.exists()) {
        setBudget(snapshot.data() as BudgetType);
      } else {
        setBudget(null);
      }
    });

    return () => unsubscribe();
  }, [programId]);

  // Fetch events from Firestore
  useEffect(() => {
    if (!programId) return;

    const eventsRef = collection(db, 'programs', programId, 'events');
    const q = query(eventsRef, orderBy('date'));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const fetchedEvents = snapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          title: data.title || '',
          type: data.type || 'race',
          status: data.status || 'planned',
          description: data.description,
          date: data.date || new Date().toISOString().split('T')[0],
          location: data.location,
          budgetItemIds: data.budgetItemIds || [],
          taskIds: data.taskIds || [],
          checklistIds: data.checklistIds || [],
          createdAt: data.createdAt,
          updatedAt: data.updatedAt,
          importSource: data.importSource
        } as Event;
      });

      console.log('App.tsx - Events fetched from Firestore:', fetchedEvents);
      setEvents(fetchedEvents);
    });

    return () => unsubscribe();
  }, [programId]);

  // Fetch tasks from Firestore
  useEffect(() => {
    if (!programId) return;

    const tasksRef = collection(db, 'programs', programId, 'tasks');
    const q = query(tasksRef, orderBy('dueDate'));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const fetchedTasks = snapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          ...data,
          dueDate: data.dueDate ? data.dueDate.toDate?.() || data.dueDate : null,
        };
      }) as Task[];

      setTasks(fetchedTasks);
    });

    return () => unsubscribe();
  }, [programId]);

  // Fetch checklists from Firestore
  useEffect(() => {
    if (!programId) return;

    const checklistsRef = collection(db, 'programs', programId, 'checklists');
    const q = query(checklistsRef, orderBy('name'));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const fetchedChecklists = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as Checklist[];

      setChecklists(fetchedChecklists);
    });

    return () => unsubscribe();
  }, [programId]);

  // Fetch parts from Firestore
  useEffect(() => {
    if (!programId) return;

    const partsRef = collection(db, 'programs', programId, 'parts');
    const q = query(partsRef, orderBy('name'));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const fetchedParts = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as Part[];

      setVehicleParts(fetchedParts);
    });

    return () => unsubscribe();
  }, [programId]);

  const handleUpdateVehicle = async (updates: Partial<Vehicle>) => {
    if (!programId) return;

    try {
      const vehicle = vehicles.find(v => v.id === updates.id);
      if (!vehicle) return;

      const vehicleRef = doc(db, 'programs', programId, 'vehicles', vehicle.id);
      await updateDoc(vehicleRef, {
        ...updates,
        updatedAt: new Date().toISOString()
      });

      // Update local state
      setVehicles(prev => prev.map(v => 
        v.id === vehicle.id 
          ? { ...v, ...updates }
          : v
      ));

    } catch (error) {
      console.error('Error updating vehicle:', error);
      toast.error('Failed to update vehicle');
    }
  };

  const handleUpdateEvent = async (event: Event) => {
    if (!programId) return;

    try {
      const now = new Date().toISOString();
      
      // Create a clean version of the event without undefined values
      const cleanEvent = Object.entries(event).reduce((acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = value;
        }
        return acc;
      }, {} as Record<string, any>);
      
      if (!event.id) {
        // Create new event
        const eventsRef = collection(db, 'programs', programId, 'events');
        const eventToCreate = {
          ...cleanEvent,
          createdAt: now,
          updatedAt: now
        };
        await addDoc(eventsRef, eventToCreate);
      } else {
        // Update existing event
        const eventRef = doc(db, 'programs', programId, 'events', event.id);
        const eventToUpdate = {
          ...cleanEvent,
          updatedAt: now
        };
        await updateDoc(eventRef, eventToUpdate);
      }
    } catch (error) {
      console.error('Error updating event:', error);
      toast.error('Failed to save event');
      throw error;  // Propagate error to calling component
    }
  };

  const handleUpdateTask = async (task: Task) => {
    if (!programId) return;

    try {
      if (!task.id) {
        // Create new task
        const tasksRef = collection(db, 'programs', programId, 'tasks');
        const { id, ...taskWithoutId } = task;
        await addDoc(tasksRef, {
          ...taskWithoutId,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString()
        });
      } else {
        // Update existing task
        const taskRef = doc(db, 'programs', programId, 'tasks', task.id);
        await setDoc(taskRef, {
          ...task,
          updatedAt: new Date().toISOString()
        });
      }
    } catch (error) {
      console.error('Error updating task:', error);
      throw error;
    }
  };

  const handleDeleteTask = async (taskId: string) => {
    if (!programId) return;

    try {
      const taskRef = doc(db, 'programs', programId, 'tasks', taskId);
      await deleteDoc(taskRef);
      toast.success('Task deleted');
    } catch (error) {
      console.error('Error deleting task:', error);
      toast.error('Failed to delete task');
      throw error;
    }
  };

  const handleUpdateChecklist = async (checklist: Checklist) => {
    if (!programId) return;

    try {
      if (!checklist.id) {
        // Create new checklist
        const checklistsRef = collection(db, 'programs', programId, 'checklists');
        const { id, ...checklistWithoutId } = checklist;
        await addDoc(checklistsRef, {
          ...checklistWithoutId,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString()
        });
      } else {
        // Update existing checklist
        const checklistRef = doc(db, 'programs', programId, 'checklists', checklist.id);
        await setDoc(checklistRef, {
          ...checklist,
          updatedAt: new Date().toISOString()
        });
      }
    } catch (error) {
      console.error('Error updating checklist:', error);
      throw error;
    }
  };

  const handleInstallPart = async (partId: string | null, slot: string, vehicleId: string) => {
    if (!programId) return;

    try {
      // Find the vehicle by ID
      const vehicle = vehicles.find(v => v.id === vehicleId);
      if (!vehicle) return;

      // Create new installations object with the update
      const newInstallations = {
        ...vehicle.installations,
        [slot]: partId
      };

      // Update local state immediately (optimistic update)
      setVehicles(prev => prev.map(v => 
        v.id === vehicleId 
          ? { ...v, installations: newInstallations }
          : v
      ));

      // Update vehicle in Firestore in the background
      const vehicleRef = doc(db, 'programs', programId, 'vehicles', vehicleId);
      await updateDoc(vehicleRef, {
        installations: newInstallations,
        updatedAt: new Date().toISOString()
      });

      // Show success toast
      const part = vehicleParts.find(p => p.id === partId);
      if (partId && part) {
        toast.success(`Installed ${part.name}`);
      } else {
        toast.success('Removed part');
      }

    } catch (error) {
      // Revert optimistic update on error
      const vehicle = vehicles.find(v => v.id === vehicleId);
      if (vehicle) {
        setVehicles(prev => prev.map(v => 
          v.id === vehicleId ? vehicle : v
        ));
      }
      console.error('Error installing part:', error);
      toast.error('Failed to install part');
    }
  };

  const handleUpdatePartMetadata = async (partId: string, metadata: Partial<PartMetadata>) => {
    if (!programId) return;

    try {
      // Find the part to update
      const partIndex = vehicleParts.findIndex(p => p.id === partId);
      if (partIndex === -1) return;

      // Create updated part with merged metadata
      const updatedPart = {
        ...vehicleParts[partIndex],
        metadata: {
          ...vehicleParts[partIndex].metadata,
          ...metadata
        }
      };

      // Update part in Firestore
      const partRef = doc(db, 'programs', programId, 'parts', partId);
      await updateDoc(partRef, {
        metadata: updatedPart.metadata,
        updatedAt: new Date().toISOString()
      });

      // Update local state
      const newParts = [...vehicleParts];
      newParts[partIndex] = updatedPart;
      setVehicleParts(newParts);

    } catch (error) {
      console.error('Error updating part metadata:', error);
      toast.error('Failed to update part');
    }
  };

  const handleUpdatePart = async (partId: string | null, updates: Partial<Part>) => {
    if (!programId) return;

    try {
      if (partId) {
        // Update existing part
        const partRef = doc(db, 'programs', programId, 'parts', partId);
        await updateDoc(partRef, {
          ...updates,
          updatedAt: new Date().toISOString()
        });
        
        // Update local state
        setVehicleParts(prev => prev.map(p => 
          p.id === partId ? { ...p, ...updates } : p
        ));
      } else {
        // Create new part
        await addDoc(collection(db, 'programs', programId, 'parts'), {
          ...updates,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString()
        });
      }
    } catch (error) {
      console.error('Error updating part:', error);
      toast.error('Failed to update part');
      throw error;
    }
  };

  const handleDeletePart = async (partId: string) => {
    if (!programId) return;
    
    try {
      const partRef = doc(db, 'programs', programId, 'parts', partId);
      await deleteDoc(partRef);
      
      // Update local state
      setVehicleParts(prev => prev.filter(p => p.id !== partId));
      
      toast.success('Part deleted successfully');
      return true;
    } catch (error) {
      console.error('Error deleting part:', error);
      toast.error('Failed to delete part');
      return false;
    }
  };

  return (
    <div className="min-h-screen bg-dark-100 text-white">
      <Routes>
        {/* Public routes */}
        <Route path="/" element={
          <PublicRoute>
            <LandingPage />
          </PublicRoute>
        } />
        <Route path="/login" element={<LoginPage />} />

        {/* Protected routes */}
        <Route path="/app" element={<ProtectedRoute><Layout /></ProtectedRoute>}>
          <Route index element={<Dashboard vehicles={vehicles} tasks={tasks} events={events} budget={budget} />} />
          <Route path="dashboard" element={<Dashboard vehicles={vehicles} tasks={tasks} events={events} budget={budget} />} />
          <Route path="vehicle" element={
            <Garage 
              vehicles={vehicles} 
              setVehicles={setVehicles} 
              vehicleParts={vehicleParts} 
              onUpdatePart={handleUpdatePart}
              onDeletePart={handleDeletePart}
            />
          } />
          <Route path="vehicle/:id" element={<VehicleDetail />} />
          <Route path="parts/:id" element={<PartDetail parts={vehicleParts} vehicles={vehicles} onUpdatePart={handleUpdatePart} onDeletePart={handleDeletePart} />} />
          <Route path="schedule" element={<Schedule events={events} onUpdateEvent={handleUpdateEvent} />} />
          <Route path="schedule/:id" element={<EventDetail events={events} onUpdateEvent={handleUpdateEvent} />} />
          <Route path="budget" element={<Budget budget={budget} setBudget={setBudget} events={events} />} />
          <Route path="tasks" element={<Tasks tasks={tasks} setTasks={setTasks} onDeleteTask={handleDeleteTask} />} />
          <Route path="tasks/new" element={<TaskDetail tasks={tasks} onUpdateTask={handleUpdateTask} onDeleteTask={handleDeleteTask} />} />
          <Route path="tasks/:id" element={<TaskDetail tasks={tasks} onUpdateTask={handleUpdateTask} onDeleteTask={handleDeleteTask} />} />
          <Route path="checklists" element={<Checklists checklists={checklists} setChecklists={setChecklists} />} />
          <Route path="checklists/:id" element={<ChecklistDetail checklists={checklists} onUpdateChecklist={handleUpdateChecklist} />} />
          <Route path="checklists/new" element={<ChecklistDetail checklists={checklists} onUpdateChecklist={handleUpdateChecklist} />} />
          <Route path="maintenance" element={<Maintenance />} />
          <Route path="account" element={<Account />} />
          <Route path="subscription" element={<Subscription />} />
          <Route path="settings" element={<Settings />} />
        </Route>

        {/* Error routes */}
        <Route path="/unauthorized" element={<ErrorPage type="unauthorized" />} />
        <Route path="*" element={<ErrorPage type="404" />} />
      </Routes>

      {/* Add PitBot component */}
      <PitBot
        context={{
          vehicles: vehicles.map(v => ({
            id: v.id,
            year: v.year,
            make: v.make,
            model: v.model,
            primaryUse: v.primaryUse
          })),
          budget: budget ? {
            totalExpenses: budget.expenses.reduce((sum, item) => sum + (item.amount * item.quantity), 0),
            totalIncome: budget.income.reduce((sum, item) => sum + (item.amount * item.quantity), 0),
            balance: budget.income.reduce((sum, item) => sum + (item.amount * item.quantity), 0) -
                    budget.expenses.reduce((sum, item) => sum + (item.amount * item.quantity), 0),
            expensesByCategory: budget.expenses.reduce((acc, item) => {
              if (typeof item.category === 'string' && item.category in acc) {
                acc[item.category] = (acc[item.category] || 0) + (item.amount * item.quantity);
              }
              return acc;
            }, {} as Record<string, number>)
          } : null,
          events: events
            .filter(e => e.id)
            .map(e => ({
              id: e.id!,
              title: e.title,
              date: e.date,
              type: e.type
            })),
          recentParts: vehicleParts
            .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
            .slice(0, 10)
            .map(p => ({
              id: p.id,
              name: p.name,
              category: p.category,
              cost: p.cost
            }))
        }} />
    </div>
  );
}

export default function App() {
  return (
    <ErrorBoundary>
      <AuthProvider>
        <Router>
          <AppContent />
        </Router>
      </AuthProvider>
    </ErrorBoundary>
  );
}