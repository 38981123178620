import React from 'react';
import { SpecField } from '../../config/partSpecs';

interface PartSpecificationsProps {
  specFields: SpecField[];
  specifications: Record<string, string | number>;
  readOnly?: boolean;
  onChange?: (key: string, value: string | number) => void;
}

const PartSpecifications: React.FC<PartSpecificationsProps> = ({
  specFields,
  specifications,
  readOnly = false,
  onChange
}) => {
  if (specFields.length === 0) {
    return null;
  }

  const handleChange = (id: string, value: string) => {
    if (onChange) {
      // Convert to number if the field type is number
      const field = specFields.find(f => f.id === id);
      if (field?.type === 'number' && value !== '') {
        onChange(id, parseFloat(value));
      } else {
        onChange(id, value);
      }
    }
  };

  // If we're in read-only mode, only show fields that have values
  const fieldsToRender = readOnly
    ? specFields.filter(field => specifications[field.id] !== undefined && specifications[field.id] !== '')
    : specFields;

  if (readOnly && fieldsToRender.length === 0) {
    return null;
  }

  return (
    <div className="space-y-4">
      {fieldsToRender.map(field => (
        <div key={field.id} className={readOnly ? "bg-dark-100/50 p-4 rounded-md" : ""}>
          {readOnly ? (
            <>
              <p className="text-sm font-medium text-gray-400 mb-1">{field.label}</p>
              <p className="text-lg text-white">
                {field.type === 'select' && field.options 
                  ? field.options.find(opt => opt.value === specifications[field.id])?.label || specifications[field.id]
                  : specifications[field.id]}
                {field.unit && ` ${field.unit}`}
              </p>
            </>
          ) : (
            <div>
              <label 
                htmlFor={`spec-${field.id}`} 
                className="block text-sm font-medium text-gray-400 mb-1"
              >
                {field.label}
                {field.required && <span className="text-red-500 ml-1">*</span>}
              </label>
              
              {field.type === 'select' ? (
                <select
                  id={`spec-${field.id}`}
                  value={specifications[field.id] !== undefined ? String(specifications[field.id]) : ''}
                  onChange={(e) => handleChange(field.id, e.target.value)}
                  className="w-full bg-dark-300 border border-dark-50 rounded-md px-4 py-3 text-white focus:ring-2 focus:ring-primary-500"
                  disabled={readOnly}
                >
                  <option value="">Select {field.label}</option>
                  {field.options?.map(option => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              ) : field.type === 'number' ? (
                <div className="relative">
                  <input
                    type="number"
                    id={`spec-${field.id}`}
                    value={specifications[field.id] !== undefined ? specifications[field.id] : ''}
                    onChange={(e) => handleChange(field.id, e.target.value)}
                    className="w-full bg-dark-300 border border-dark-50 rounded-md px-4 py-3 text-white focus:ring-2 focus:ring-primary-500"
                    placeholder={field.placeholder}
                    disabled={readOnly}
                  />
                  {field.unit && (
                    <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-gray-400">
                      {field.unit}
                    </span>
                  )}
                </div>
              ) : (
                <input
                  type="text"
                  id={`spec-${field.id}`}
                  value={specifications[field.id] !== undefined ? specifications[field.id] : ''}
                  onChange={(e) => handleChange(field.id, e.target.value)}
                  className="w-full bg-dark-300 border border-dark-50 rounded-md px-4 py-3 text-white focus:ring-2 focus:ring-primary-500"
                  placeholder={field.placeholder}
                  disabled={readOnly}
                />
              )}
              
              {field.description && (
                <p className="text-xs text-gray-400 mt-1">
                  {field.description}
                </p>
              )}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default PartSpecifications; 