import React, { useState } from 'react';
import { Trash2, Plus, X, Settings } from 'lucide-react';
import type { Vehicle } from '../../types/vehicle';
import type { DrivetrainType, FuelType, VehicleCategory } from '../../types/vehicle';

interface VehicleSummaryProps {
  vehicle: Vehicle;
  totalSpent: number;
  partsCount: number;
  onUpdateVehicle: (updates: Partial<Vehicle>) => Promise<void>;
  onDeleteClick: () => void;
}

export default function VehicleSummary({ 
  vehicle, 
  totalSpent, 
  partsCount, 
  onUpdateVehicle, 
  onDeleteClick 
}: VehicleSummaryProps) {
  const [isEditingPower, setIsEditingPower] = useState(false);
  const [isEditingWeight, setIsEditingWeight] = useState(false);
  const [isEditingInitialPrice, setIsEditingInitialPrice] = useState(false);
  const [isEditingSpecs, setIsEditingSpecs] = useState(false);
  
  // Dropdown options
  const drivetrainOptions: DrivetrainType[] = ['FWD', 'RWD', 'AWD', '4WD'];
  const fuelTypeOptions: FuelType[] = [
    'Regular Gasoline',
    'Premium Gasoline',
    'E85/Flex Fuel',
    'Race Gas',
    'Diesel',
    'Electric'
  ];
  const vehicleCategoryOptions: VehicleCategory[] = [
    'Time Attack',
    'Drift',
    'Race',
    'Street',
    'Rally',
    'Endurance',
    'Autocross'
  ];

  const calculatePowerToWeight = () => {
    if (!vehicle) return '0.00';
    return (vehicle.weight / vehicle.power).toFixed(2);
  };

  const handleRemovePrimaryUse = (category: VehicleCategory) => {
    if (!vehicle.primaryUse) return;
    const updatedPrimaryUse = vehicle.primaryUse.filter(c => c !== category);
    onUpdateVehicle({ primaryUse: updatedPrimaryUse });
  };

  const handleAddPrimaryUse = (category: VehicleCategory) => {
    if (!vehicle.primaryUse) {
      onUpdateVehicle({ primaryUse: [category] });
    } else if (!vehicle.primaryUse.includes(category)) {
      onUpdateVehicle({ primaryUse: [...vehicle.primaryUse, category] });
    }
  };

  return (
    <div className="bg-dark-200 rounded-lg p-6">
      {/* Vehicle Header */}
      <div className="flex justify-between items-start mb-4">
        <div>
          <h2 className="text-xl font-semibold text-white">{vehicle.year} {vehicle.make} {vehicle.model}</h2>
          {vehicle.nickname && (
            <p className="text-gray-400 text-sm">"{vehicle.nickname}"</p>
          )}
        </div>
        <div className="flex items-center gap-3">
          <button
            onClick={() => setIsEditingSpecs(!isEditingSpecs)}
            className={`p-1.5 rounded-md ${isEditingSpecs ? 'bg-primary-500 text-white' : 'text-gray-400 hover:text-white hover:bg-dark-100'}`}
            aria-label="Edit vehicle specifications"
          >
            <Settings className="h-4 w-4" />
          </button>
          <button
            onClick={onDeleteClick}
            className="p-1.5 rounded-md text-gray-400 hover:text-red-500 hover:bg-dark-100"
            aria-label="Delete vehicle"
          >
            <Trash2 className="h-4 w-4" />
          </button>
        </div>
      </div>

      {/* Configuration Edit Section - Only visible when editing */}
      {isEditingSpecs && (
        <div className="mb-6 space-y-6 bg-dark-100/50 p-4 rounded-lg">
          {/* Drivetrain */}
          <div className="space-y-2">
            <h3 className="text-sm font-medium text-gray-400">Drivetrain</h3>
            <div className="flex flex-wrap gap-2">
              {drivetrainOptions.map(option => (
                <button
                  key={option}
                  onClick={() => onUpdateVehicle({ drivetrain: option })}
                  className={`px-3 py-1.5 rounded-full text-sm ${
                    vehicle.drivetrain === option
                      ? 'bg-primary-500 text-white'
                      : 'bg-dark-300 text-gray-400 hover:bg-dark-200 hover:text-white'
                  }`}
                >
                  {option}
                </button>
              ))}
            </div>
          </div>
          
          {/* Fuel Type */}
          <div className="space-y-2">
            <h3 className="text-sm font-medium text-gray-400">Fuel Type</h3>
            <div className="flex flex-wrap gap-2">
              {fuelTypeOptions.map(option => (
                <button
                  key={option}
                  onClick={() => onUpdateVehicle({ fuelType: option })}
                  className={`px-3 py-1.5 rounded-full text-sm ${
                    vehicle.fuelType === option
                      ? 'bg-primary-500 text-white'
                      : 'bg-dark-300 text-gray-400 hover:bg-dark-200 hover:text-white'
                  }`}
                >
                  {option}
                </button>
              ))}
            </div>
          </div>
          
          {/* Primary Use */}
          <div className="space-y-2">
            <h3 className="text-sm font-medium text-gray-400">Primary Use</h3>
            <div className="flex flex-wrap gap-2">
              {vehicle.primaryUse?.map(category => (
                <div key={category} className="bg-primary-500 rounded-full px-3 py-1.5 flex items-center gap-2">
                  <span className="text-sm text-white">{category}</span>
                  <button
                    onClick={() => handleRemovePrimaryUse(category)}
                    className="text-white/70 hover:text-white"
                  >
                    <X className="h-4 w-4" />
                  </button>
                </div>
              ))}
            </div>
            <div className="mt-3">
              <div className="flex flex-wrap gap-2">
                {vehicleCategoryOptions
                  .filter(option => !vehicle.primaryUse?.includes(option))
                  .map(option => (
                    <button
                      key={option}
                      onClick={() => handleAddPrimaryUse(option)}
                      className="px-3 py-1.5 bg-dark-300 rounded-full text-sm text-gray-400 hover:bg-dark-200 hover:text-white"
                    >
                      {option}
                    </button>
                  ))}
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4 md:gap-6">
        {/* Performance Stats */}
        <div className="bg-dark-100/50 rounded-lg p-4 space-y-3">
          <h3 className="text-sm font-medium text-gray-400">Performance</h3>
          <div className="space-y-2">
            <div className="group relative flex items-center justify-between">
              <span className="text-sm text-gray-400">Power</span>
              <div className="flex items-center gap-2">
                {isEditingPower ? (
                  <input
                    type="number"
                    value={vehicle.power || ''}
                    onChange={(e) => {
                      const power = parseInt(e.target.value);
                      if (!isNaN(power)) {
                        onUpdateVehicle({ power });
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === 'Escape') {
                        setIsEditingPower(false);
                      }
                    }}
                    onBlur={() => setIsEditingPower(false)}
                    placeholder="0"
                    className="w-16 bg-dark-200 text-white border border-dark-300 rounded px-2 py-0.5 text-sm focus:outline-none focus:border-primary-500"
                    autoFocus
                  />
                ) : (
                  <button
                    onClick={() => setIsEditingPower(true)}
                    className="flex items-center gap-2 px-2 py-0.5 rounded bg-dark-200/0 hover:bg-dark-200 group"
                  >
                    <span className="text-sm font-medium text-white group-hover:border-b group-hover:border-dashed group-hover:border-gray-400">{vehicle.power || 0}</span>
                    <span className="text-sm text-gray-400">hp</span>
                  </button>
                )}
              </div>
            </div>

            <div className="flex items-center justify-between">
              <span className="text-sm text-gray-400">Weight</span>
              <div className="flex items-center gap-2">
                {isEditingWeight ? (
                  <input
                    type="number"
                    value={vehicle.weight || ''}
                    onChange={(e) => {
                      const weight = parseInt(e.target.value);
                      if (!isNaN(weight)) {
                        onUpdateVehicle({ weight });
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === 'Escape') {
                        setIsEditingWeight(false);
                      }
                    }}
                    onBlur={() => setIsEditingWeight(false)}
                    placeholder="0"
                    className="w-16 bg-dark-200 text-white border border-dark-300 rounded px-2 py-0.5 text-sm focus:outline-none focus:border-primary-500"
                    autoFocus
                  />
                ) : (
                  <button
                    onClick={() => setIsEditingWeight(true)}
                    className="flex items-center gap-2 px-2 py-0.5 rounded bg-dark-200/0 hover:bg-dark-200 group"
                  >
                    <span className="text-sm font-medium text-white group-hover:border-b group-hover:border-dashed group-hover:border-gray-400">{vehicle.weight || 0}</span>
                    <span className="text-sm text-gray-400">lbs</span>
                  </button>
                )}
              </div>
            </div>

            <div className="flex items-center justify-between">
              <span className="text-sm text-gray-400">Power/Weight</span>
              <span className="text-sm text-white">{calculatePowerToWeight()} lb/hp</span>
            </div>
          </div>
        </div>

        {/* Financial Summary */}
        <div className="bg-dark-100/50 rounded-lg p-4 space-y-3">
          <h3 className="text-sm font-medium text-gray-400">Financial Summary</h3>
          <div className="space-y-2">
            <div className="group relative flex items-center justify-between">
              <span className="text-sm text-gray-400">Initial Cost</span>
              <div className="flex items-center gap-2">
                {isEditingInitialPrice ? (
                  <div className="flex items-center gap-1">
                    <span className="text-sm text-gray-400">$</span>
                    <input
                      type="number"
                      value={vehicle.initialPrice || ''}
                      onChange={(e) => {
                        const initialPrice = parseInt(e.target.value);
                        if (!isNaN(initialPrice)) {
                          onUpdateVehicle({ initialPrice });
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' || e.key === 'Escape') {
                          setIsEditingInitialPrice(false);
                        }
                      }}
                      onBlur={() => setIsEditingInitialPrice(false)}
                      placeholder="0"
                      className="w-20 bg-dark-200 text-white border border-dark-300 rounded px-2 py-0.5 text-sm focus:outline-none focus:border-primary-500"
                      autoFocus
                    />
                  </div>
                ) : (
                  <button
                    onClick={() => setIsEditingInitialPrice(true)}
                    className="flex items-center gap-1 px-2 py-0.5 rounded bg-dark-200/0 hover:bg-dark-200 group"
                  >
                    <span className="text-sm text-gray-400">$</span>
                    <span className="text-sm font-medium text-white group-hover:border-b group-hover:border-dashed group-hover:border-gray-400">
                      {(vehicle.initialPrice || 0).toLocaleString()}
                    </span>
                  </button>
                )}
              </div>
            </div>

            <div className="flex items-center justify-between">
              <span className="text-sm text-gray-400">Parts Investment</span>
              <div className="flex items-center gap-1">
                <span className="text-sm text-gray-400">$</span>
                <span className="text-sm text-white">{totalSpent.toLocaleString()}</span>
              </div>
            </div>

            <div className="flex items-center justify-between border-t border-dark-300 pt-2 mt-2">
              <span className="text-sm font-medium text-gray-400">Total Investment</span>
              <div className="flex items-center gap-1">
                <span className="text-sm text-gray-400">$</span>
                <span className="text-sm font-medium text-white">{(totalSpent + (vehicle.initialPrice || 0)).toLocaleString()}</span>
              </div>
            </div>
          </div>
        </div>

        {/* Quick Stats */}
        <div className="bg-dark-100/50 rounded-lg p-4 space-y-3">
          <h3 className="text-sm font-medium text-gray-400">Quick Stats</h3>
          <div className="space-y-2">
            <div className="flex items-center justify-between">
              <span className="text-sm text-gray-400">Total Parts</span>
              <span className="text-sm text-white">{partsCount}</span>
            </div>
            <div className="flex items-center justify-between">
              <span className="text-sm text-gray-400">Last Updated</span>
              <span className="text-sm text-white">
                {new Date(vehicle.updatedAt).toLocaleDateString()}
              </span>
            </div>
          </div>
        </div>

        {/* Vehicle Specifications */}
        <div className="bg-dark-100/50 rounded-lg p-4 space-y-3">
          <h3 className="text-sm font-medium text-gray-400">Vehicle Specifications</h3>
          <div className="space-y-2">
            <div className="flex items-center justify-between">
              <span className="text-sm text-gray-400">Year</span>
              <span className="text-sm text-white">{vehicle.year}</span>
            </div>
            <div className="flex items-center justify-between">
              <span className="text-sm text-gray-400">Make</span>
              <span className="text-sm text-white">{vehicle.make}</span>
            </div>
            <div className="flex items-center justify-between">
              <span className="text-sm text-gray-400">Model</span>
              <span className="text-sm text-white">{vehicle.model}</span>
            </div>
            {vehicle.trim && (
              <div className="flex items-center justify-between">
                <span className="text-sm text-gray-400">Trim</span>
                <span className="text-sm text-white">{vehicle.trim}</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
} 