import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { 
  Calendar, Car, Wrench, Clock, Plus, ChevronRight, 
  AlertTriangle, CheckCircle2, Flag, Settings, 
  ArrowUpRight, DollarSign, CurrencyIcon
} from 'lucide-react';
import { format, parseISO } from 'date-fns';
import StatCard from '../components/dashboard/StatCard';
import { useAuth } from '../contexts/AuthContext';
import type { Vehicle } from '../types/vehicle';
import type { Task } from '../types/task';
import type { Event } from '../types/event';
import type { Budget } from '../types/budget';

interface DashboardProps {
  events: Event[];
  vehicles: Vehicle[];
  tasks: Task[];
  budget: Budget | null;
}

export default function Dashboard({ events, vehicles, tasks, budget }: DashboardProps) {
  const navigate = useNavigate();
  const { user } = useAuth();

  // Get upcoming events (next 30 days)
  const upcomingEvents = events
    .filter(event => {
      const eventDate = parseISO(event.date);
      const thirtyDaysFromNow = new Date();
      thirtyDaysFromNow.setDate(thirtyDaysFromNow.getDate() + 30);
      return eventDate <= thirtyDaysFromNow && eventDate >= new Date();
    })
    .sort((a, b) => parseISO(a.date).getTime() - parseISO(b.date).getTime());

  // Get all future events
  const futureEvents = events
    .filter(event => parseISO(event.date) >= new Date())
    .sort((a, b) => parseISO(a.date).getTime() - parseISO(b.date).getTime());

  // Get high priority tasks
  const highPriorityTasks = tasks
    .filter(task => task.priority >= 6 && task.status !== 'done')
    .sort((a, b) => b.priority - a.priority);

  // Get maintenance tasks
  const maintenanceTasks = tasks
    .filter(task => task.labels?.includes('maintenance') && task.status !== 'done')
    .sort((a, b) => (b.priority || 0) - (a.priority || 0));

  // Get completed tasks count
  const completedTasksCount = tasks.filter(t => t.status === 'done').length;

  // Determine which sections to show
  const showUpcomingEvents = events.length > 0;
  const showHighPriorityTasks = highPriorityTasks.length > 0;
  const showMaintenanceTasks = maintenanceTasks.length > 0;
  const showVehicleStatus = vehicles.length > 0;

  // Get the most recently updated vehicle
  const primaryVehicle = vehicles.length > 0 
    ? vehicles.reduce((latest, current) => 
        new Date(current.updatedAt) > new Date(latest.updatedAt) ? current : latest
      , vehicles[0])
    : null;

  // Update vehicle-related navigation
  const handleVehicleClick = (vehicleId: string) => {
    navigate(`/app/vehicle/${vehicleId}`);
  };

  // Get the next upcoming event
  const nextEvent = events
    .filter(event => parseISO(event.date) >= new Date())
    .sort((a, b) => parseISO(a.date).getTime() - parseISO(b.date).getTime())[0];

  // Calculate days until next event
  const getDaysUntilEvent = (event: Event) => {
    const today = new Date();
    const eventDate = parseISO(event.date);
    const diffTime = eventDate.getTime() - today.getTime();
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  };

  const vehicle = vehicles[0];

  const handleAddEvent = () => {
    navigate('/app/schedule/new', {
      state: {
        isNew: true,
        date: new Date().toISOString().split('T')[0]
      }
    });
  };

  // Calculate monthly budget data
  const calculateMonthlyBudget = (budget: Budget | null) => {
    if (!budget) return { monthlyBudget: 0, spentThisMonth: 0, earnedThisMonth: 0 };

    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();

    // Calculate monthly recurring income
    const monthlyIncome = budget.income
      .filter(income => {
        // Only include recurring income items
        return income.frequency === 'monthly' ||
               (income.frequency === 'yearly' && currentMonth === 0) ||
               (income.frequency === 'quarterly' && currentMonth % 3 === 0);
      })
      .reduce((sum, income) => {
        const amount = income.amount * income.quantity;
        if (income.frequency === 'yearly') return sum + (amount / 12);
        if (income.frequency === 'quarterly') return sum + (amount / 3);
        return sum + amount;
      }, 0);

    // Calculate monthly recurring expenses
    const monthlyExpenses = budget.expenses
      .filter(expense => {
        // Only include recurring expense items
        return expense.frequency === 'monthly' ||
               (expense.frequency === 'yearly' && currentMonth === 0) ||
               (expense.frequency === 'quarterly' && currentMonth % 3 === 0);
      })
      .reduce((sum, expense) => {
        const amount = expense.amount * expense.quantity;
        if (expense.frequency === 'yearly') return sum + (amount / 12);
        if (expense.frequency === 'quarterly') return sum + (amount / 3);
        return sum + amount;
      }, 0);

    // Calculate this month's one-time expenses and income
    const thisMonthOneTimeExpenses = budget.expenses
      .filter(expense => {
        // Only include one-time items from this month
        if (expense.eventId) {
          const event = events.find(e => e.id === expense.eventId);
          if (event) {
            return new Date(event.date).getMonth() === currentMonth;
          }
        }
        return expense.frequency === 'one-time';
      })
      .reduce((sum, expense) => sum + (expense.amount * expense.quantity), 0);

    const thisMonthOneTimeIncome = budget.income
      .filter(income => {
        // Only include one-time items from this month
        if (income.eventId) {
          const event = events.find(e => e.id === income.eventId);
          if (event) {
            return new Date(event.date).getMonth() === currentMonth;
          }
        }
        return income.frequency === 'one-time';
      })
      .reduce((sum, income) => sum + (income.amount * income.quantity), 0);

    return {
      monthlyBudget: monthlyIncome - monthlyExpenses,
      spentThisMonth: thisMonthOneTimeExpenses + monthlyExpenses,
      earnedThisMonth: thisMonthOneTimeIncome + monthlyIncome
    };
  };

  const monthlyBudgetData = calculateMonthlyBudget(budget);

  const formatCurrency = (value: number) => {
    return `$${value.toLocaleString()}`;
  };

  return (
    <div className="space-y-6">
      {/* Quick Actions Bar - Mobile Optimized */}
      <div className="bg-dark-200 rounded-lg p-4 lg:p-4">
        <div className="flex lg:hidden overflow-x-auto pb-2 -mb-2 scrollbar-hide">
          <div className="flex gap-8 px-2">
            <button
              onClick={() => navigate('/app/tasks/new')}
              className="flex flex-col items-center gap-1 min-w-[4rem]"
            >
              <div className="p-3 bg-dark-100 rounded-lg hover:bg-dark-50 transition-colors">
                <Plus className="w-6 h-6 text-primary-500" />
              </div>
              <span className="text-xs text-gray-400 whitespace-nowrap">New Task</span>
            </button>
            <button
              onClick={handleAddEvent}
              className="flex flex-col items-center gap-1 min-w-[4rem]"
            >
              <div className="p-3 bg-dark-100 rounded-lg hover:bg-dark-50 transition-colors">
                <Calendar className="w-6 h-6 text-primary-500" />
              </div>
              <span className="text-xs text-gray-400 whitespace-nowrap">Add Event</span>
            </button>
            {!vehicles.length && (
              <button
                onClick={() => navigate('/app/vehicle')}
                className="flex flex-col items-center gap-1 min-w-[4rem]"
              >
                <div className="p-3 bg-dark-100 rounded-lg hover:bg-dark-50 transition-colors">
                  <Car className="w-6 h-6 text-primary-500" />
                </div>
                <span className="text-xs text-gray-400 whitespace-nowrap">Add Vehicle</span>
              </button>
            )}
            {!budget && (
              <button
                onClick={() => navigate('/app/budget')}
                className="flex flex-col items-center gap-1 min-w-[4rem]"
              >
                <div className="p-3 bg-dark-100 rounded-lg hover:bg-dark-50 transition-colors">
                  <CurrencyIcon className="w-6 h-6 text-primary-500" />
                </div>
                <span className="text-xs text-gray-400 whitespace-nowrap">Set Budget</span>
              </button>
            )}
          </div>
        </div>

        {/* Desktop Quick Actions */}
        <div className="hidden lg:flex flex-wrap gap-4">
          <button
            onClick={() => navigate('/app/tasks/new')}
            className="flex items-center gap-2 px-4 py-2 bg-dark-100 text-white rounded-md hover:bg-dark-50 transition-colors"
          >
            <Plus className="w-4 h-4" />
            New Task
          </button>
          <button
            onClick={handleAddEvent}
            className="flex items-center gap-2 px-4 py-2 bg-dark-100 text-white rounded-md hover:bg-dark-50 transition-colors"
          >
            <Plus className="w-4 h-4" />
            Add Event
          </button>
          {!vehicles.length && (
            <button
              onClick={() => navigate('/app/vehicle')}
              className="flex items-center gap-2 px-4 py-2 bg-dark-100 text-white rounded-md hover:bg-dark-50 transition-colors"
            >
              <Plus className="w-4 h-4" />
              Add Vehicle
            </button>
          )}
          {!budget && (
            <button
              onClick={() => navigate('/app/budget')}
              className="flex items-center gap-2 px-4 py-2 bg-dark-100 text-white rounded-md hover:bg-dark-50 transition-colors"
            >
              <Plus className="w-4 h-4" />
              Set Up Budget
            </button>
          )}
        </div>
      </div>

      {!vehicle ? (
        // Show Get Started section if no vehicle
        <div className="bg-dark-200 rounded-lg p-8 text-center">
          <h2 className="text-2xl font-display font-bold text-white mb-4">Welcome to PitPrep</h2>
          <p className="text-gray-400 mb-8 max-w-lg mx-auto">
            Let's get your motorsport journey started. Follow these steps to set up your profile:
          </p>
          <div className="grid gap-4 max-w-2xl mx-auto">
            <Link
              to="/app/vehicle"
              className="flex items-center justify-between p-4 bg-dark-100 rounded-lg hover:bg-dark-50 transition-colors group"
            >
              <div className="flex items-center gap-3">
                <div className="w-8 h-8 rounded-full bg-primary-500/10 flex items-center justify-center">
                  <span className="text-primary-500 font-semibold">1</span>
                </div>
                <div className="text-left">
                  <h3 className="text-white font-display font-light group-hover:text-primary-500">Add Your Vehicle</h3>
                  <p className="text-sm text-gray-400">Set up your vehicle specifications and details</p>
                </div>
              </div>
              <ArrowUpRight className="w-5 h-5 text-gray-400 group-hover:text-primary-500" />
            </Link>

            <div className="flex items-center justify-between p-4 bg-dark-100/50 rounded-lg">
              <div className="flex items-center gap-3">
                <div className="w-8 h-8 rounded-full bg-dark-300 flex items-center justify-center">
                  <span className="text-gray-400 font-semibold">2</span>
                </div>
                <div className="text-left">
                  <h3 className="text-gray-300 font-display font-light">Set Up Your Budget</h3>
                  <p className="text-sm text-gray-400">Plan your spending and track expenses</p>
                </div>
              </div>
            </div>

            <div className="flex items-center justify-between p-4 bg-dark-100/50 rounded-lg">
              <div className="flex items-center gap-3">
                <div className="w-8 h-8 rounded-full bg-dark-300 flex items-center justify-center">
                  <span className="text-gray-400 font-semibold">3</span>
                </div>
                <div className="text-left">
                  <h3 className="text-gray-300 font-display font-light">Schedule Your First Event</h3>
                  <p className="text-sm text-gray-400">Add track days or races to your calendar</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
          {/* Left Column - Vehicle & Next Event */}
          <div className="space-y-6">
            {/* Vehicle Quick Status */}
            <div className="bg-dark-200 rounded-lg p-6">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-lg font-display font-bold text-white">Vehicle Status</h2>
                <Link 
                  to={`/app/vehicle/${vehicle.id}`}
                  className="text-sm text-primary-500 hover:text-primary-400 flex items-center gap-1"
                >
                  View Details
                  <ChevronRight className="w-4 h-4" />
                </Link>
              </div>
              <div className="flex items-start gap-3 p-3 bg-dark-100 rounded-lg">
                <div className="p-2 bg-primary-500/10 rounded-lg">
                  <Car className="w-5 h-5 text-primary-500" />
                </div>
                <div className="flex-1 min-w-0">
                  <h3 className="text-white font-display font-light truncate">
                    {vehicle.name || `${vehicle.year} ${vehicle.make} ${vehicle.model}`}
                  </h3>
                  <div className="grid grid-cols-2 gap-2 mt-2">
                    <div>
                      <p className="text-xs text-gray-400">Power</p>
                      <p className="text-sm font-display font-light text-white">{vehicle.power} hp</p>
                    </div>
                    <div>
                      <p className="text-xs text-gray-400">Weight</p>
                      <p className="text-sm font-display font-light text-white">{vehicle.weight} lbs</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Next Event */}
            {nextEvent ? (
              <div className="bg-dark-200 rounded-lg p-6">
                <div className="flex justify-between items-center mb-4">
                  <h2 className="text-lg font-display font-bold text-white">Next Event</h2>
                  <Link 
                    to="/app/schedule"
                    className="text-sm text-primary-500 hover:text-primary-400 flex items-center gap-1"
                  >
                    View Schedule
                    <ChevronRight className="w-4 h-4" />
                  </Link>
                </div>
                <Link
                  to={`/app/schedule/${nextEvent.id}`}
                  className="flex items-start gap-3 p-3 bg-dark-100 rounded-lg hover:bg-dark-50 transition-colors group"
                >
                  <div className="p-2 bg-primary-500/10 rounded-lg">
                    <Calendar className="w-5 h-5 text-primary-500" />
                  </div>
                  <div>
                    <h3 className="text-white font-display font-light group-hover:text-primary-500">
                      {nextEvent.title}
                    </h3>
                    <p className="text-sm text-gray-400">
                      {format(parseISO(nextEvent.date), 'MMM d, yyyy')}
                    </p>
                    <p className="text-xs text-primary-500 mt-1 font-display font-light">
                      In {getDaysUntilEvent(nextEvent)} days
                    </p>
                  </div>
                </Link>
              </div>
            ) : (
              <div className="bg-dark-200 rounded-lg p-6">
                <div className="flex justify-between items-center mb-4">
                  <h2 className="text-lg font-display font-bold text-white">Schedule</h2>
                  <button
                    onClick={handleAddEvent}
                    className="text-sm text-primary-500 hover:text-primary-400 flex items-center gap-1"
                  >
                    Add Event
                    <Plus className="w-4 h-4" />
                  </button>
                </div>
                <div className="text-center py-8">
                  <Calendar className="w-12 h-12 text-gray-400 mx-auto mb-3" />
                  <p className="text-gray-400">No upcoming events</p>
                  <button
                    onClick={handleAddEvent}
                    className="mt-4 text-primary-500 hover:text-primary-400"
                  >
                    Schedule your first event
                  </button>
                </div>
              </div>
            )}
          </div>

          {/* Middle Column - Tasks & Maintenance */}
          <div className="space-y-6">
            {/* Maintenance Tasks */}
            <div className="bg-dark-200 rounded-lg p-6">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-lg font-display font-bold text-white">Maintenance</h2>
                <button
                  onClick={() => navigate('/app/tasks/new', { state: { defaultLabel: 'maintenance' } })}
                  className="text-sm text-primary-500 hover:text-primary-400 flex items-center gap-1"
                >
                  Add Task
                  <Plus className="w-4 h-4" />
                </button>
              </div>
              {maintenanceTasks.length > 0 ? (
                <div className="space-y-3">
                  {maintenanceTasks.slice(0, 3).map(task => (
                    <Link
                      key={task.id}
                      to={`/app/tasks/${task.id}`}
                      className="flex items-start gap-3 p-3 bg-dark-100 rounded-lg hover:bg-dark-50 transition-colors group"
                    >
                      <div className="p-2 bg-yellow-500/10 rounded-lg">
                        <Wrench className="w-5 h-5 text-yellow-500" />
                      </div>
                      <div>
                        <h3 className="text-white font-display font-light group-hover:text-primary-500">
                          {task.title}
                        </h3>
                        <p className="text-sm text-gray-400">
                          {task.dueDate ? format(new Date(task.dueDate), 'MMM d, yyyy') : 'No due date'}
                        </p>
                      </div>
                    </Link>
                  ))}
                </div>
              ) : (
                <div className="text-center py-8">
                  <Wrench className="w-12 h-12 text-gray-400 mx-auto mb-3" />
                  <p className="text-gray-400">No maintenance tasks</p>
                  <button
                    onClick={() => navigate('/app/tasks/new', { state: { defaultLabel: 'maintenance' } })}
                    className="mt-4 text-primary-500 hover:text-primary-400"
                  >
                    Add maintenance task
                  </button>
                </div>
              )}
            </div>
          </div>

          {/* Right Column - Budget & Stats */}
          <div className="space-y-6">
            {/* Monthly Budget Overview */}
            <div className="bg-dark-200 rounded-lg p-6">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-lg font-display font-bold text-white">Monthly Budget</h2>
                <Link 
                  to="/app/budget"
                  className="text-sm text-primary-500 hover:text-primary-400 flex items-center gap-1"
                >
                  View Details
                  <ChevronRight className="w-4 h-4" />
                </Link>
              </div>
              <div className="grid grid-cols-1 gap-4">
                <div className="p-4 bg-dark-100 rounded-lg">
                  <div className="flex items-center justify-between">
                    <span className="text-gray-400">Monthly Balance</span>
                    <span className={`text-xl font-display font-light ${monthlyBudgetData.monthlyBudget >= 0 ? 'text-green-500' : 'text-red-500'}`}>
                      {formatCurrency(monthlyBudgetData.monthlyBudget)}
                    </span>
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div className="p-4 bg-dark-100 rounded-lg">
                    <div className="flex flex-col">
                      <span className="text-gray-400 text-sm mb-1">Earned</span>
                      <span className="text-green-500 font-display font-light">
                        {formatCurrency(monthlyBudgetData.earnedThisMonth)}
                      </span>
                    </div>
                  </div>
                  <div className="p-4 bg-dark-100 rounded-lg">
                    <div className="flex flex-col">
                      <span className="text-gray-400 text-sm mb-1">Spent</span>
                      <span className="text-red-500 font-display font-light">
                        {formatCurrency(monthlyBudgetData.spentThisMonth)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}