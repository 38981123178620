import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { ArrowLeft, Save, Loader2, X, Edit, Eye, Sparkles, Trash2, Info, AlertCircle, MapPin, Shield, Link } from 'lucide-react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../config/firebase';
import { useAuth } from '../contexts/AuthContext';
import type { Part, PartCategory, PartSubcategory } from '../types/part';
import { toast } from 'react-hot-toast';
import type { Vehicle } from '../types/vehicle';
import { getPartInfo } from '../services/aiService';
import { getPartSpecs, isConsumable, hasLocation, hasCertification, hasWearTracking, getLocationFields, getCertificationFields, getWearTrackingFields, PartLocation } from '../config/partSpecs';
import PartSpecifications from '../components/parts/PartSpecifications';
import { getFunctions, httpsCallable } from 'firebase/functions';

// Default values for a new part
const DEFAULT_PART: Omit<Part, 'id' | 'createdAt' | 'updatedAt'> = {
  name: '',
  category: 'Other',
  subcategory: undefined,
  cost: 0,
  description: '',
  manufacturer: '',
  vehicleId: null,
  metadata: {
    isInstalled: true,
    installationDate: new Date().toISOString().split('T')[0],
  }
};

// Map of part categories to their subcategories
const PART_SUBCATEGORIES: Record<PartCategory, string[]> = {
  'Brakes': ['Pads', 'Rotors', 'Fluid', 'Lines', 'Calipers', 'Master Cylinder', 'Brake Ducts'],
  'Tires': ['Summer', 'Winter', 'All Season', 'Track', 'Rain', 'Performance'],
  'Engine': ['Oil', 'Filter', 'Spark Plugs', 'Belts', 'Timing Components', 'Intake', 'Turbo', 'Supercharger', 'Engine Management'],
  'Suspension': ['Coilovers', 'Springs', 'Shocks', 'Sway Bars', 'Control Arms', 'Bushings', 'Alignment Parts', 'Wheels'],
  'Transmission': ['Fluid', 'Clutch', 'Flywheel', 'Shifter', 'Differential', 'Gears'],
  'Exterior': ['Aero', 'Body', 'Lighting', 'Paint', 'Wraps'],
  'Interior': ['Seats', 'Harnesses', 'Roll Cage', 'Gauges', 'Electronics'],
  'Electrical': ['Battery', 'Alternator', 'Starter', 'Wiring', 'Sensors'],
  'Cooling': ['Radiator', 'Oil Cooler', 'Intercooler', 'Fans', 'Hoses'],
  'Fuel System': ['Pump', 'Filter', 'Injectors', 'Lines', 'Tank'],
  'Exhaust': ['Headers', 'Catalytic Converter', 'Muffler', 'Full System'],
  'Drivetrain': ['Axles', 'Driveshaft', 'CV Joints', 'Transfer Case'],
  'Steering': ['Fluid'],
  'Other': ['Miscellaneous'],
};

// Part locations
const PART_LOCATIONS: PartLocation[] = [
  'front', 'rear', 'both', 'front_left', 'front_right', 'rear_left', 'rear_right'
];

interface PartDetailProps {
  parts: Part[];
  vehicles: Vehicle[];
  onUpdatePart: (partId: string | null, updates: Partial<Part>) => Promise<void>;
  onDeletePart: (partId: string) => Promise<boolean | undefined>;
}

export default function PartDetail({ parts, vehicles, onUpdatePart, onDeletePart }: PartDetailProps) {
  const { id: partId } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const { programId } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [isAiLoading, setIsAiLoading] = useState(false);
  const [part, setPart] = useState<Partial<Part>>(DEFAULT_PART);
  const [error, setError] = useState('');
  const [isEditMode, setIsEditMode] = useState(false);
  const [aiPartDescription, setAiPartDescription] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  
  // New state for AI input field
  const [aiInput, setAiInput] = useState('');
  
  const isNewPart = partId === 'new';
  const locationState = location.state as { 
    vehicleId?: string;
    isNew?: boolean;
    noVehicle?: boolean;
    prefilledCategory?: PartCategory;
    prefilledSubcategory?: PartSubcategory;
  } | null;

  // Add new state variables
  const [showAiSetup, setShowAiSetup] = useState(false);
  const [showUrlImport, setShowUrlImport] = useState(false);
  const [importUrl, setImportUrl] = useState('');
  const [isImporting, setIsImporting] = useState(false);
  const [importError, setImportError] = useState<string | null>(null);
  const functions = getFunctions();

  // Set edit mode automatically for new parts
  useEffect(() => {
    setIsEditMode(isNewPart);
    
    // Auto-select vehicle if provided in location state
    if (isNewPart && locationState) {
      const initialPartState: Partial<Part> = {
        ...DEFAULT_PART,
        vehicleId: locationState.noVehicle ? null : locationState.vehicleId || null,
        metadata: {
          ...DEFAULT_PART.metadata,
          isInstalled: locationState.noVehicle ? false : true,
        }
      };

      // Apply prefilled category and subcategory if provided
      if (locationState.prefilledCategory) {
        initialPartState.category = locationState.prefilledCategory;
        
        if (locationState.prefilledSubcategory) {
          initialPartState.subcategory = locationState.prefilledSubcategory;
          
          // Don't set a default name - let the user enter it themselves
        }
      }
      
      setPart(initialPartState);
    }
  }, [isNewPart, locationState]);

  useEffect(() => {
    async function fetchPart() {
      if (!programId || isNewPart) {
        setIsLoading(false);
        return;
      }

      try {
        const partRef = doc(db, 'programs', programId, 'parts', partId!);
        const partSnap = await getDoc(partRef);
        
        if (partSnap.exists()) {
          const partData = { 
            id: partSnap.id, 
            ...partSnap.data() 
          } as Part;
          
          setPart(partData);
        } else {
          setError('Part not found');
          toast.error('Part not found');
          navigate('/app/parts');
        }
      } catch (err) {
        console.error('Error fetching part:', err);
        setError('Failed to load part');
        toast.error('Failed to load part');
      } finally {
        setIsLoading(false);
      }
    }

    fetchPart();
  }, [programId, partId, isNewPart, navigate]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    
    if (name.includes('.')) {
      // Handle nested properties (e.g., metadata.isInstalled)
      const [parent, child] = name.split('.');
      setPart(prev => ({
        ...prev,
        [parent]: {
          ...(prev[parent as keyof Part] as Record<string, unknown>),
          [child]: value
        }
      }));
    } else if (name === 'cost') {
      // Handle numeric inputs
      setPart(prev => ({
        ...prev,
        cost: value === '' ? 0 : Number(value)
      }));
    } else if (name === 'category') {
      // When category changes, reset subcategory
      setPart(prev => ({
        ...prev,
        category: value as PartCategory,
        subcategory: undefined
      }));
    } else {
      // Handle regular inputs
      setPart(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    
    if (name.includes('.')) {
      // Handle nested properties (e.g., metadata.isInstalled)
      const [parent, child] = name.split('.');
      setPart(prev => ({
        ...prev,
        [parent]: {
          ...(prev[parent as keyof Part] as Record<string, unknown>),
          [child]: checked
        }
      }));
    } else {
      // Handle regular checkboxes
      setPart(prev => ({
        ...prev,
        [name]: checked
      }));
    }
  };

  const handleSave = async () => {
    if (!programId) return;
    
    setIsSaving(true);
    
    try {
      // Ensure required fields are present
      if (!part.name) {
        toast.error('Part name is required');
        setIsSaving(false);
        return;
      }
      
      // Prepare part data
      const now = new Date().toISOString();
      const partData: Partial<Part> = {
        ...part,
        updatedAt: now,
      };
      
      if (isNewPart) {
        partData.createdAt = now;
      }
      
      // Save the part
      await onUpdatePart(isNewPart ? null : partId || null, partData);
      
      toast.success(`Part ${isNewPart ? 'created' : 'updated'} successfully`);
      
      // Exit edit mode after saving
      setIsEditMode(false);
      
      if (isNewPart) {
        navigate(-1);
      }
    } catch (err) {
      console.error('Error saving part:', err);
      toast.error(`Failed to ${isNewPart ? 'create' : 'update'} part`);
    } finally {
      setIsSaving(false);
    }
  };

  const handleAutoFillDetails = async () => {
    if (!aiInput) {
      toast.error('Part description is required for auto-fill');
      return;
    }
    
    setIsAiLoading(true);
    
    try {
      // Get vehicle info if available
      const vehicle = part.vehicleId ? vehicles.find(v => v.id === part.vehicleId) : null;
      const vehicleInfo = vehicle ? `${vehicle.year} ${vehicle.make} ${vehicle.model}` : 'Generic vehicle';
      
      // Get part info from AI
      const partInfo = await getPartInfo(aiInput, vehicleInfo);
      
      if (partInfo) {
        setAiPartDescription(partInfo.description || '');
        
        // Update part with AI-generated info
        setPart(prev => ({
          ...prev,
          name: partInfo.name || prev.name,
          category: partInfo.category as PartCategory || prev.category,
          subcategory: partInfo.subcategory as PartSubcategory || prev.subcategory,
          manufacturer: partInfo.manufacturer || prev.manufacturer,
          cost: partInfo.cost || prev.cost,
          description: partInfo.description || prev.description,
          metadata: {
            ...prev.metadata,
            specifications: partInfo.metadata?.specifications || prev.metadata?.specifications || {}
          }
        }));
        
        toast.success('Part details auto-filled');
      } else {
        toast.error('Failed to get part details');
      }
    } catch (err) {
      console.error('Error auto-filling part details:', err);
      toast.error('Failed to auto-fill part details');
    } finally {
      setIsAiLoading(false);
    }
  };

  const handleDelete = async () => {
    if (!partId) return;
    
    setIsDeleting(true);
    try {
      const success = await onDeletePart(partId);
      if (success) {
        navigate(-1);
      }
    } finally {
      setIsDeleting(false);
    }
  };

  const handleSpecificationChange = (key: string, value: string | number) => {
    setPart(prev => ({
      ...prev,
      metadata: {
        ...prev.metadata,
        specifications: {
          ...(prev.metadata?.specifications || {}),
          [key]: value
        }
      }
    }));
  };

  // Get available spec fields based on the current category/subcategory
  const specFields = part.category && part.subcategory 
    ? getPartSpecs(part.category as PartCategory, part.subcategory as PartSubcategory) 
    : [];
    
  // Check if the part is a consumable
  const isPartConsumable = part.category && part.subcategory 
    ? isConsumable(part.category as PartCategory, part.subcategory as PartSubcategory)
    : false;

  // Add new function for URL import
  const handleImportFromUrl = async () => {
    if (!importUrl.trim()) {
      setImportError('Please enter a URL');
      return;
    }

    setIsImporting(true);
    setImportError(null);
    try {
      // First, get the raw content from the URL using our cloud function
      const parsePartUrl = httpsCallable(functions, 'parsePartUrl');
      const result = await parsePartUrl({ url: importUrl.trim() });
      const { rawContent } = result.data as any;

      // Then, use our AI service to parse the content
      const partInfo = await getPartInfo(
        [
          rawContent.productName,
          ...rawContent.description,
          ...rawContent.specifications
        ].join('\n'),
        part.vehicleId ? `${vehicles.find(v => v.id === part.vehicleId)?.year} ${vehicles.find(v => v.id === part.vehicleId)?.make} ${vehicles.find(v => v.id === part.vehicleId)?.model}` : 'Generic vehicle'
      );

      if (partInfo) {
        setPart(prev => ({
          ...prev,
          name: partInfo.name || rawContent.productName || prev.name,
          category: partInfo.category as PartCategory || prev.category,
          subcategory: partInfo.subcategory as PartSubcategory || prev.subcategory,
          manufacturer: partInfo.manufacturer || rawContent.manufacturer || prev.manufacturer,
          cost: partInfo.cost || (rawContent.price ? parseFloat(rawContent.price) : prev.cost),
          description: partInfo.description || rawContent.description.join('\n') || prev.description,
          metadata: {
            ...prev.metadata,
            specifications: partInfo.metadata?.specifications || prev.metadata?.specifications || {}
          },
          importSource: importUrl.trim()
        }));

        setShowUrlImport(false);
        toast.success('Part details imported from URL');
      } else {
        setImportError('Unable to process the product information. Please try a different URL.');
      }
    } catch (error: any) {
      console.error('Error importing part from URL:', error);
      
      // Get the error code and message from the cloud function error
      const errorCode = error.code || error.details?.code || 'unknown';
      const errorMessage = error.details?.message;
      
      // Map error codes to user-friendly messages
      const errorMessages: Record<string, string> = {
        'invalid-argument/INVALID_URL': 'Please enter a valid URL',
        'invalid-argument/NOT_PRODUCT_PAGE': errorMessage || 'This URL appears to be from a non-product website. Please provide a URL from an automotive parts retailer or manufacturer.',
        'failed-precondition/NO_PRODUCT_CONTENT': errorMessage || 'Could not find product information on this page. Please make sure the URL points to a specific product.',
        'deadline-exceeded/TIMEOUT': errorMessage || 'The page took too long to respond. Please try again or try a different URL.',
        'permission-denied/ACCESS_DENIED': errorMessage || 'We cannot access this page. The website might be blocking automated access.',
        'not-found/PAGE_NOT_FOUND': errorMessage || 'The page could not be found. Please check the URL and try again.',
        'unknown/IMPORT_FAILED': errorMessage || 'We were unable to import the part details. Please try again or try a different URL.'
      };
      
      // Get the specific error message or fall back to a generic one
      const userMessage = errorMessages[`${error.code}/${error.details?.code}`] || 
        'We were unable to import the part details. Please try again or try a different URL.';
      
      setImportError(userMessage);
    } finally {
      setIsImporting(false);
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-full">
        <Loader2 className="w-8 h-8 text-primary-500 animate-spin" />
      </div>
    );
  }

  // View mode rendering
  if (!isEditMode && !isNewPart) {
    return (
      <div className="container mx-auto px-4 py-6 max-w-5xl">
        <div className="flex items-center mb-4">
          <button
            onClick={() => navigate(-1)}
            className="text-gray-400 hover:text-white mr-4"
            aria-label="Back"
          >
            <ArrowLeft size={24} />
          </button>
          
          <h1 className="text-2xl font-bold text-white">
            {part.name}
          </h1>
        </div>
        
        <div className="flex gap-3 mb-6">
          <button
            onClick={() => setIsEditMode(true)}
            className="flex-1 px-4 py-3 rounded-md bg-primary-500 text-white hover:bg-primary-600 transition-colors flex items-center justify-center"
          >
            <Edit size={20} className="mr-2" />
            Edit
          </button>
          
          <button
            onClick={handleDelete}
            disabled={isDeleting}
            className="px-4 py-3 rounded-md bg-red-500/20 text-red-500 hover:bg-red-500/30 transition-colors flex items-center justify-center"
          >
            {isDeleting ? (
              <Loader2 size={20} className="animate-spin" />
            ) : (
              <Trash2 size={20} />
            )}
          </button>
        </div>
        
        {error && (
          <div className="bg-red-500/20 border border-red-500 text-red-500 px-4 py-3 rounded-md mb-6">
            {error}
          </div>
        )}
        
        <div className="bg-dark-200 rounded-lg p-8 shadow-md border border-dark-50">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-10">
            {/* Basic Information */}
            <div className="space-y-6">
              <h2 className="text-xl font-semibold text-white border-b border-dark-50 pb-3 mb-4">Basic Information</h2>
              
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                <div className="bg-dark-100/50 p-4 rounded-md">
                  <p className="text-sm font-medium text-gray-400 mb-1">Part Name</p>
                  <p className="text-lg text-white font-medium">{part.name}</p>
                </div>
                
                <div className="bg-dark-100/50 p-4 rounded-md">
                  <p className="text-sm font-medium text-gray-400 mb-1">Manufacturer</p>
                  <p className="text-lg text-white">{part.manufacturer || 'Not specified'}</p>
                </div>
              </div>
              
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-6">
                <div className="bg-dark-100/50 p-4 rounded-md">
                  <p className="text-sm font-medium text-gray-400 mb-1">Category</p>
                  <p className="text-lg text-white">
                    {part.category}
                    {part.subcategory ? ` • ${part.subcategory}` : ''}
                    {isPartConsumable && (
                      <span className="ml-2 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-amber-500/20 text-amber-400">
                        <AlertCircle size={12} className="mr-1" />
                        Consumable
                      </span>
                    )}
                  </p>
                </div>
                
                <div className="bg-dark-100/50 p-4 rounded-md">
                  <p className="text-sm font-medium text-gray-400 mb-1">Cost</p>
                  <p className="text-lg text-white">${part.cost?.toFixed(2) || '0.00'}</p>
                </div>
              </div>
              
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-6">
                <div className="bg-dark-100/50 p-4 rounded-md">
                  <p className="text-sm font-medium text-gray-400 mb-1">Vehicle</p>
                  <p className="text-lg text-white">
                    {part.vehicleId 
                      ? vehicles.find(v => v.id === part.vehicleId)
                        ? `${vehicles.find(v => v.id === part.vehicleId)?.year} ${vehicles.find(v => v.id === part.vehicleId)?.make} ${vehicles.find(v => v.id === part.vehicleId)?.model}`
                        : 'Unknown Vehicle'
                      : 'Not Assigned'}
                  </p>
                </div>
              </div>
            </div>
            
            {/* Installation & Details */}
            <div className="space-y-6">
              <h2 className="text-xl font-semibold text-white border-b border-dark-50 pb-3 mb-4">Installation & Details</h2>
              
              <div className="bg-dark-100/50 p-4 rounded-md">
                <p className="text-sm font-medium text-gray-400 mb-1">Installation Status</p>
                <p className="text-lg text-white flex items-center">
                  {part.metadata?.isInstalled ? (
                    <>
                      <span className="inline-block w-3 h-3 bg-green-500 rounded-full mr-2"></span>
                      Installed
                    </>
                  ) : (
                    <>
                      <span className="inline-block w-3 h-3 bg-gray-500 rounded-full mr-2"></span>
                      Not Installed
                    </>
                  )}
                </p>
              </div>
              
              {part.metadata?.isInstalled && (
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                  {part.metadata?.installationDate && (
                    <div className="bg-dark-100/50 p-4 rounded-md">
                      <p className="text-sm font-medium text-gray-400 mb-1">Installation Date</p>
                      <p className="text-lg text-white">{part.metadata.installationDate}</p>
                    </div>
                  )}
                  
                  {/* Display location from specifications if available */}
                  {hasLocation(part.category as PartCategory, part.subcategory as PartSubcategory) && 
                   part.metadata?.specifications && 
                   getLocationFields(part.category as PartCategory, part.subcategory as PartSubcategory).some(field => 
                     part.metadata?.specifications?.[field.id]
                   ) && (
                    <div className="bg-dark-100/50 p-4 rounded-md">
                      <p className="text-sm font-medium text-gray-400 mb-1">Location</p>
                      {getLocationFields(part.category as PartCategory, part.subcategory as PartSubcategory).map(field => {
                        const locationValue = part.metadata?.specifications?.[field.id];
                        if (!locationValue) return null;
                        
                        // Find the label for the selected location value
                        const locationOption = field.options?.find(opt => opt.value === locationValue);
                        return (
                          <p key={field.id} className="text-lg text-white capitalize">
                            {locationOption?.label || locationValue.toString().replace('_', ' ')}
                          </p>
                        );
                      })}
                    </div>
                  )}
                </div>
              )}
              
              {/* Add wear tracking display for consumable parts */}
              {isPartConsumable && hasWearTracking(part.category as PartCategory, part.subcategory as PartSubcategory) && part.metadata && (
                <div className="mt-6">
                  <h3 className="text-lg font-semibold text-white mb-4 flex items-center">
                    <AlertCircle size={18} className="mr-2 text-amber-400" />
                    Wear Tracking
                  </h3>
                  
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                    {/* Display wear tracking fields from specifications */}
                    {getWearTrackingFields(part.category as PartCategory, part.subcategory as PartSubcategory).map(field => {
                      const value = part.metadata?.specifications?.[field.id];
                      if (value === undefined) return null;
                      
                      return (
                        <div key={field.id} className="bg-dark-100/50 p-4 rounded-md">
                          <p className="text-sm font-medium text-gray-400 mb-1">{field.label}</p>
                          <p className="text-lg text-white">
                            {value}
                            {field.unit && ` ${field.unit}`}
                          </p>
                        </div>
                      );
                    })}
                    
                    {/* Display current health */}
                    {part.metadata.currentHealth !== undefined && (
                      <div className="bg-dark-100/50 p-4 rounded-md">
                        <p className="text-sm font-medium text-gray-400 mb-1">Current Health</p>
                        <p className="text-lg text-white">
                          {part.metadata.currentHealth}%
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              )}
              
              {/* Add certification display for parts with certification */}
              {hasCertification(part.category as PartCategory, part.subcategory as PartSubcategory) && part.metadata && (
                <div className="mt-6">
                  <h3 className="text-lg font-semibold text-white mb-4 flex items-center">
                    <Shield size={18} className="mr-2 text-blue-400" />
                    Certification
                  </h3>
                  
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                    {/* Display certification fields from specifications */}
                    {getCertificationFields(part.category as PartCategory, part.subcategory as PartSubcategory).map(field => {
                      const value = part.metadata?.specifications?.[field.id];
                      if (value === undefined) return null;
                      
                      return (
                        <div key={field.id} className="bg-dark-100/50 p-4 rounded-md">
                          <p className="text-sm font-medium text-gray-400 mb-1">{field.label}</p>
                          <p className="text-lg text-white">
                            {value}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
              
              {/* Add specifications section if applicable */}
              {part.metadata?.specifications && specFields.length > 0 && (
                <div>
                  <h3 className="text-lg font-semibold text-white mb-4 flex items-center">
                    <Info size={16} className="mr-2" />
                    Specifications
                  </h3>
                  <PartSpecifications 
                    specFields={specFields} 
                    specifications={part.metadata.specifications}
                    readOnly={true}
                  />
                </div>
              )}
              
              {part.description && (
                <div className="bg-dark-100/50 p-4 rounded-md mt-4">
                  <p className="text-sm font-medium text-white mb-2 flex items-center">
                    <span className="mr-2">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                        <polyline points="14 2 14 8 20 8"></polyline>
                        <line x1="16" y1="13" x2="8" y2="13"></line>
                        <line x1="16" y1="17" x2="8" y2="17"></line>
                        <polyline points="10 9 9 9 8 9"></polyline>
                      </svg>
                    </span>
                    Description & Notes
                  </p>
                  <div className="text-white whitespace-pre-wrap bg-dark-300/50 p-3 rounded border border-dark-100">
                    {part.description}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  // Edit mode rendering
  return (
    <div className="container mx-auto px-4 py-6 max-w-5xl">
      <div className="flex items-center mb-4">
        <button
          onClick={() => isNewPart ? navigate(-1) : setIsEditMode(false)}
          className="text-gray-400 hover:text-white mr-4"
          aria-label="Back"
        >
          <ArrowLeft size={24} />
        </button>
        
        <h1 className="text-2xl font-bold text-white">
          {isNewPart ? 'Add New Part' : 'Edit Part'}
        </h1>
      </div>
      
      {error && (
        <div className="bg-red-500/20 border border-red-500 text-red-500 px-4 py-3 rounded-md mb-6">
          {error}
        </div>
      )}
      
      <div className="bg-dark-200 rounded-lg p-8 shadow-md border border-dark-50">
        {/* Auto-fill section - Redesigned for better flow */}
        {isNewPart && (
          <div className="flex flex-col sm:flex-row gap-4 mb-8">
            <button
              onClick={() => setShowAiSetup(prev => !prev)}
              className="flex-1 px-4 py-3 rounded-md bg-primary-500 text-white hover:bg-primary-600 transition-colors flex items-center justify-center"
            >
              <Sparkles size={20} className="mr-2" />
              Auto-Fill with AI
            </button>
            
            <button
              onClick={() => setShowUrlImport(true)}
              className="flex-1 px-4 py-3 rounded-md bg-dark-100 text-primary-500 hover:bg-dark-50 transition-colors flex items-center justify-center"
            >
              <Link size={20} className="mr-2" />
              Import from URL
            </button>
          </div>
        )}

        {/* AI Setup Section - Now hidden by default */}
        {isNewPart && showAiSetup && (
          <div className="mb-8 p-6 bg-indigo-900/20 rounded-lg border border-indigo-500/30 relative overflow-hidden animate-in slide-in-from-top duration-300">
            <div className="absolute top-0 right-0 w-32 h-32 -mt-10 -mr-10 bg-indigo-500/10 rounded-full"></div>
            <div className="absolute bottom-0 left-0 w-24 h-24 -mb-8 -ml-8 bg-indigo-500/10 rounded-full"></div>
            
            <div className="flex justify-between items-start mb-4">
              <h3 className="text-xl font-medium text-white flex items-center">
                <Sparkles size={20} className="mr-2 text-indigo-400" />
                Quick Part Setup with AI
              </h3>
              <button
                onClick={() => setShowAiSetup(false)}
                className="text-gray-400 hover:text-white p-1"
              >
                <X size={20} />
              </button>
            </div>

            <div className="space-y-4 relative z-10">
              {/* Vehicle selection - First step */}
              <div>
                <label htmlFor="vehicleId" className="block text-sm font-medium mb-1">
                  <span className="text-white">1. Select Vehicle</span>
                  <span className="text-red-500 ml-1">*</span>
                </label>
                <select
                  id="vehicleId"
                  name="vehicleId"
                  value={part.vehicleId || ''}
                  onChange={handleInputChange}
                  className="w-full bg-dark-300 border border-dark-50 rounded-md px-4 py-3 text-white focus:ring-2 focus:ring-primary-500"
                >
                  <option value="">Select a vehicle</option>
                  {vehicles.map(vehicle => (
                    <option key={vehicle.id} value={vehicle.id}>
                      {vehicle.year} {vehicle.make} {vehicle.model}
                    </option>
                  ))}
                </select>
              </div>

              {/* Part description with inline auto-fill button - Second step */}
              <div>
                <label htmlFor="aiInput" className="block text-sm font-medium mb-1">
                  <span className="text-white">2. Describe the Part</span>
                  <span className="text-red-500 ml-1">*</span>
                </label>
                <div className="flex gap-2">
                  <div className="flex-grow relative">
                    <input
                      type="text"
                      id="aiInput"
                      value={aiInput}
                      onChange={(e) => setAiInput(e.target.value)}
                      placeholder="Enter part details (e.g. 'Brembo GT 4-piston front brake kit for BMW E46')"
                      className="w-full bg-dark-300 border border-dark-50 rounded-md px-4 py-3 text-white focus:ring-2 focus:ring-primary-500 focus:border-primary-500"
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' && aiInput && part.vehicleId) {
                          handleAutoFillDetails();
                        }
                      }}
                    />
                    {aiInput && (
                      <button 
                        onClick={() => setAiInput('')}
                        className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 hover:text-white"
                        type="button"
                      >
                        <X size={16} />
                      </button>
                    )}
                  </div>
                  <button
                    onClick={handleAutoFillDetails}
                    disabled={isAiLoading || !aiInput || !part.vehicleId}
                    className={`px-4 py-3 rounded-md flex items-center whitespace-nowrap ${
                      !aiInput || !part.vehicleId
                        ? 'bg-dark-300 text-gray-500 cursor-not-allowed'
                        : 'bg-indigo-600 text-white hover:bg-indigo-700'
                    } transition-colors shadow-md`}
                    type="button"
                  >
                    {isAiLoading ? (
                      <Loader2 className="w-5 h-5 animate-spin" />
                    ) : (
                      <>
                        <Sparkles size={18} className="mr-2" />
                        Auto-Fill
                      </>
                    )}
                  </button>
                </div>
                <p className="text-xs text-gray-400 mt-1">
                  Enter as much detail as possible for better results. Press Enter after typing to auto-fill.
                </p>

                {/* Add specification hint */}
                {part.category && part.subcategory && specFields.length > 0 && (
                  <div className="mt-3 bg-indigo-500/10 p-3 rounded-md text-indigo-300 text-sm flex items-start">
                    <Info size={16} className="mr-2 mt-0.5 flex-shrink-0" />
                    <span>
                      <span className="font-semibold text-indigo-200">Tip:</span> This part type has specific properties that will be auto-filled, such as 
                      {specFields.map((field, index) => (
                        <span key={field.id}>
                          {index > 0 && index === specFields.length - 1 ? ' and ' : index > 0 ? ', ' : ' '}
                          <span className="font-medium text-indigo-200">{field.label.toLowerCase()}</span>
                        </span>
                      ))}.
                    </span>
                  </div>
                )}
              </div>

              {/* Loading indicator */}
              {isAiLoading && (
                <div className="flex items-center text-indigo-300 bg-indigo-900/30 p-3 rounded-md">
                  <Loader2 className="w-5 h-5 mr-2 animate-spin" />
                  <span>Analyzing "{aiPartDescription}"...</span>
                </div>
              )}
            </div>
          </div>
        )}

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-10">
          {/* Basic Information - Keep part name field here */}
          <div className="space-y-6">
            <h2 className="text-xl font-semibold text-white border-b border-dark-50 pb-3 mb-4">Basic Information</h2>
            
            <div>
              <label htmlFor="name" className="block text-sm font-medium mb-1">
                <span className="text-white">Part Name</span>
                <span className="text-red-500 ml-1">*</span>
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={part.name || ''}
                onChange={handleInputChange}
                className="w-full bg-dark-300 border border-dark-50 rounded-md px-4 py-3 text-white focus:ring-2 focus:ring-primary-500 focus:border-primary-500"
                required
                placeholder="Enter part name"
              />
            </div>
            
            <div>
              <label htmlFor="manufacturer" className="block text-sm font-medium text-gray-400 mb-1">
                Manufacturer
              </label>
              <input
                type="text"
                id="manufacturer"
                name="manufacturer"
                value={part.manufacturer || ''}
                onChange={handleInputChange}
                className="w-full bg-dark-300 border border-dark-50 rounded-md px-4 py-3 text-white focus:ring-2 focus:ring-primary-500"
                placeholder="Enter manufacturer name"
              />
            </div>
            
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div>
                <label htmlFor="category" className="block text-sm font-medium text-gray-400 mb-1">
                  Category
                </label>
                <select
                  id="category"
                  name="category"
                  value={part.category || 'Other'}
                  onChange={handleInputChange}
                  className="w-full bg-dark-300 border border-dark-50 rounded-md px-4 py-3 text-white focus:ring-2 focus:ring-primary-500"
                >
                  {Object.keys(PART_SUBCATEGORIES).map(category => (
                    <option key={category} value={category}>
                      {category}
                    </option>
                  ))}
                </select>
              </div>
              
              <div>
                <label htmlFor="subcategory" className="block text-sm font-medium text-gray-400 mb-1">
                  Subcategory
                </label>
                <select
                  id="subcategory"
                  name="subcategory"
                  value={part.subcategory || ''}
                  onChange={handleInputChange}
                  className="w-full bg-dark-300 border border-dark-50 rounded-md px-4 py-3 text-white focus:ring-2 focus:ring-primary-500"
                >
                  <option value="">Select Subcategory</option>
                  {part.category && PART_SUBCATEGORIES[part.category as PartCategory]?.map(subcategory => (
                    <option key={subcategory} value={subcategory}>
                      {subcategory}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            
            <div>
              <label htmlFor="cost" className="block text-sm font-medium text-gray-400 mb-1">
                Cost
              </label>
              <div className="relative">
                <span className="absolute inset-y-0 left-0 flex items-center pl-4 text-gray-400">
                  $
                </span>
                <input
                  type="number"
                  id="cost"
                  name="cost"
                  value={part.cost === undefined ? '' : part.cost}
                  onChange={handleInputChange}
                  className="w-full bg-dark-300 border border-dark-50 rounded-md pl-8 pr-4 py-3 text-white focus:ring-2 focus:ring-primary-500"
                  min="0"
                  step="0.01"
                  placeholder="0.00"
                />
              </div>
            </div>
          </div>
          
          {/* Installation & Details */}
          <div className="space-y-6">
            <h2 className="text-xl font-semibold text-white border-b border-dark-50 pb-3 mb-4">Installation & Details</h2>
            
            <div className="bg-dark-100/30 p-5 rounded-lg border border-dark-50">
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="metadata.isInstalled"
                  name="metadata.isInstalled"
                  checked={!!part.metadata?.isInstalled}
                  onChange={handleCheckboxChange}
                  className="h-5 w-5 rounded border-dark-50 text-primary-500 focus:ring-primary-500 bg-dark-300"
                />
                <label htmlFor="metadata.isInstalled" className="ml-3 text-base font-medium text-white">
                  Currently Installed
                </label>
              </div>
              
              {part.metadata?.isInstalled && (
                <div className="mt-4 space-y-4 pl-8 border-l-2 border-dark-50">
                  <div>
                    <label htmlFor="metadata.installationDate" className="block text-sm font-medium text-gray-400 mb-1">
                      Installation Date
                    </label>
                    <input
                      type="date"
                      id="metadata.installationDate"
                      name="metadata.installationDate"
                      value={part.metadata?.installationDate || ''}
                      onChange={handleInputChange}
                      className="w-full bg-dark-300 border border-dark-50 rounded-md px-4 py-3 text-white focus:ring-2 focus:ring-primary-500"
                      disabled={!isEditMode}
                      readOnly={!isEditMode}
                    />
                  </div>
                  
                  {/* Use location fields from specifications instead of the old location field */}
                  {hasLocation(part.category as PartCategory, part.subcategory as PartSubcategory) && (
                    <div>
                      {getLocationFields(part.category as PartCategory, part.subcategory as PartSubcategory).map(field => (
                        <div key={field.id}>
                          <label htmlFor={`spec-${field.id}`} className="block text-sm font-medium text-gray-400 mb-1">
                            {field.label}
                          </label>
                          <select
                            id={`spec-${field.id}`}
                            value={part.metadata?.specifications?.[field.id] !== undefined ? String(part.metadata.specifications[field.id]) : ''}
                            onChange={(e) => handleSpecificationChange(field.id, e.target.value)}
                            className="w-full bg-dark-300 border border-dark-50 rounded-md px-4 py-3 text-white focus:ring-2 focus:ring-primary-500"
                            disabled={!isEditMode}
                          >
                            <option value="">Select {field.label}</option>
                            {field.options?.map(option => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>
            
            {/* Add wear tracking for consumable parts */}
            {isPartConsumable && hasWearTracking(part.category as PartCategory, part.subcategory as PartSubcategory) && (
              <div className="bg-amber-900/20 p-5 rounded-lg border border-amber-500/30">
                <h3 className="text-lg font-medium text-white mb-4 flex items-center">
                  <AlertCircle size={18} className="mr-2 text-amber-400" />
                  Wear Tracking
                </h3>
                
                <div className="space-y-4">
                  {getWearTrackingFields(part.category as PartCategory, part.subcategory as PartSubcategory).map(field => (
                    <div key={field.id}>
                      <label htmlFor={`spec-${field.id}`} className="block text-sm font-medium text-gray-400 mb-1">
                        {field.label}
                      </label>
                      
                      {field.type === 'select' ? (
                        <select
                          id={`spec-${field.id}`}
                          value={part.metadata?.specifications?.[field.id] !== undefined ? String(part.metadata.specifications[field.id]) : ''}
                          onChange={(e) => handleSpecificationChange(field.id, e.target.value)}
                          className="w-full bg-dark-300 border border-dark-50 rounded-md px-4 py-3 text-white focus:ring-2 focus:ring-primary-500"
                        >
                          <option value="">Select {field.label}</option>
                          {field.options?.map(option => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      ) : field.type === 'number' ? (
                        <div className="relative">
                          <input
                            type="number"
                            id={`spec-${field.id}`}
                            value={part.metadata?.specifications?.[field.id] !== undefined ? part.metadata.specifications[field.id] : ''}
                            onChange={(e) => handleSpecificationChange(field.id, e.target.value)}
                            className="w-full bg-dark-300 border border-dark-50 rounded-md px-4 py-3 text-white focus:ring-2 focus:ring-primary-500"
                            placeholder={field.placeholder}
                          />
                          {field.unit && (
                            <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-gray-400">
                              {field.unit}
                            </span>
                          )}
                        </div>
                      ) : (
                        <input
                          type={field.type}
                          id={`spec-${field.id}`}
                          value={part.metadata?.specifications?.[field.id] !== undefined ? part.metadata.specifications[field.id] : ''}
                          onChange={(e) => handleSpecificationChange(field.id, e.target.value)}
                          className="w-full bg-dark-300 border border-dark-50 rounded-md px-4 py-3 text-white focus:ring-2 focus:ring-primary-500"
                          placeholder={field.placeholder}
                        />
                      )}
                      
                      {field.description && (
                        <p className="text-xs text-gray-400 mt-1">
                          {field.description}
                        </p>
                      )}
                    </div>
                  ))}
                  
                  {/* Current health field */}
                  <div>
                    <label htmlFor="metadata.currentHealth" className="block text-sm font-medium text-gray-400 mb-1">
                      Current Health
                    </label>
                    <div className="relative">
                      <input
                        type="number"
                        id="metadata.currentHealth"
                        name="metadata.currentHealth"
                        value={part.metadata?.currentHealth !== undefined ? part.metadata.currentHealth : ''}
                        onChange={handleInputChange}
                        className="w-full bg-dark-300 border border-dark-50 rounded-md px-4 py-3 text-white focus:ring-2 focus:ring-primary-500"
                        placeholder="0-100"
                        min="0"
                        max="100"
                      />
                      <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-gray-400">
                        %
                      </span>
                    </div>
                    <p className="text-xs text-gray-400 mt-1">
                      100% = new condition, 0% = completely worn out
                    </p>
                  </div>
                </div>
              </div>
            )}
            
            {/* Add specifications section if the part has a subcategory */}
            {part.subcategory && specFields.length > 0 && (
              <div>
                <h2 className="text-xl font-semibold text-white border-b border-dark-50 pb-3 mb-4">Specifications</h2>
                <PartSpecifications
                  specFields={specFields}
                  specifications={part.metadata?.specifications || {}}
                  onChange={handleSpecificationChange}
                />
              </div>
            )}
            
            <div>
              <label htmlFor="description" className="block text-sm font-medium mb-1">
                <span className="text-white">Description</span>
                <span className="text-gray-400 ml-1">(specifications, details, notes)</span>
              </label>
              <textarea
                id="description"
                name="description"
                value={part.description || ''}
                onChange={handleInputChange}
                rows={6}
                className="w-full bg-dark-300 border border-dark-50 rounded-md px-4 py-3 text-white focus:ring-2 focus:ring-primary-500"
                placeholder="Enter all relevant details about this part including specifications, fitment information, and any notes"
              />
              <p className="text-xs text-gray-400 mt-1">
                Include any important information you want to remember about this part
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Buttons at the bottom */}
      <div className="flex flex-col sm:flex-row gap-4 mt-8">
        <button
          onClick={handleSave}
          disabled={isSaving}
          className="flex-1 px-4 py-3 rounded-md bg-primary-500 text-white hover:bg-primary-600 transition-colors flex items-center justify-center"
        >
          {isSaving ? (
            <Loader2 className="w-5 h-5 mr-2 animate-spin" />
          ) : (
            <Save size={20} className="mr-2" />
          )}
          Save
        </button>
        
        <button
          onClick={() => isNewPart ? navigate(-1) : setIsEditMode(false)}
          className="flex-1 px-4 py-3 rounded-md bg-dark-300 text-gray-300 hover:bg-dark-400 transition-colors flex items-center justify-center"
          disabled={isSaving}
        >
          <X size={20} className="mr-2" />
          Cancel
        </button>
      </div>

      {/* URL Import Modal */}
      {showUrlImport && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
          <div className="bg-dark-200 rounded-lg p-6 max-w-md w-full">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold text-white">Import Part from URL</h2>
              <button
                onClick={() => {
                  setShowUrlImport(false);
                  setImportError(null);
                  setImportUrl('');
                }}
                className="text-gray-400 hover:text-white"
              >
                <X className="w-5 h-5" />
              </button>
            </div>
            <div className="space-y-4">
              <div>
                <label className="block text-sm text-gray-400 mb-2">
                  Product URL
                </label>
                <input
                  type="url"
                  value={importUrl}
                  onChange={(e) => {
                    setImportUrl(e.target.value);
                    setImportError(null); // Clear error when input changes
                  }}
                  placeholder="https://example.com/product"
                  className={`w-full bg-dark-300 border rounded-md px-3 py-2 text-white ${
                    importError ? 'border-red-500' : 'border-dark-50'
                  }`}
                />
                <p className="text-xs text-gray-400 mt-1">
                  Enter a URL from an automotive parts retailer or manufacturer's website
                </p>
              </div>

              {/* Error Message */}
              {importError && (
                <div className="bg-red-500/10 border border-red-500/20 rounded-md p-3">
                  <div className="flex gap-2 text-red-400">
                    <Info className="w-5 h-5 shrink-0 mt-0.5" />
                    <span>{importError}</span>
                  </div>
                </div>
              )}

              <div className="flex justify-end gap-4">
                <button
                  onClick={() => {
                    setShowUrlImport(false);
                    setImportError(null);
                    setImportUrl('');
                  }}
                  className="px-4 py-2 text-gray-400 hover:text-white"
                  disabled={isImporting}
                >
                  Cancel
                </button>
                <button
                  onClick={handleImportFromUrl}
                  disabled={isImporting || !importUrl.trim()}
                  className="flex items-center gap-2 px-4 py-2 bg-primary-500 text-white rounded-md hover:bg-primary-600 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {isImporting ? (
                    <>
                      <div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin" />
                      Importing...
                    </>
                  ) : (
                    <>
                      <Link className="w-4 h-4" />
                      Import Part
                    </>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
} 