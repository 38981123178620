import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import ReactCrop, { Crop, PixelCrop, centerCrop, makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Compressor from 'compressorjs';
import { Upload, X, Check, Loader2, AlertCircle } from 'lucide-react';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { storage } from '../../config/firebase';
import { useAuth } from '../../contexts/AuthContext';
import { toast } from 'react-hot-toast';

interface ImageUploaderProps {
  vehicleId: string;
  currentImage?: string;
  onImageUploaded: (imageUrl: string) => Promise<void>;
  onCancel: (e?: React.MouseEvent) => void;
  onDragStateChange?: (isDragging: boolean) => void;
}

// Helper function to create a centered crop with a specific aspect ratio
function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number,
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  );
}

// Helper function to get a canvas with the cropped image
function getCroppedImg(image: HTMLImageElement, crop: PixelCrop): Promise<Blob> {
  const canvas = document.createElement('canvas');
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    throw new Error('No 2d context');
  }

  canvas.width = crop.width;
  canvas.height = crop.height;

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height,
  );

  return new Promise((resolve) => {
    canvas.toBlob((blob) => {
      if (!blob) {
        throw new Error('Canvas is empty');
      }
      resolve(blob);
    }, 'image/jpeg', 0.95);
  });
}

// Helper function to format file size
function formatFileSize(bytes: number): string {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
}

export default function ImageUploader({ 
  vehicleId, 
  onImageUploaded, 
  onCancel,
  onDragStateChange 
}: ImageUploaderProps) {
  const { programId } = useAuth();
  const [uploadedImage, setUploadedImage] = useState<string | null>(null);
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [error, setError] = useState<string | null>(null);
  const [fileSize, setFileSize] = useState<number | null>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const previewUrl = useRef<string | null>(null);

  // Set the aspect ratio for the crop (16:9)
  const aspect = 16 / 9;

  // Notify parent component about drag state changes
  useEffect(() => {
    const handleCropDragStart = () => {
      if (onDragStateChange) onDragStateChange(true);
    };

    const handleCropDragEnd = () => {
      if (onDragStateChange) onDragStateChange(false);
    };

    // Add event listeners to ReactCrop elements
    const cropElement = document.querySelector('.ReactCrop');
    if (cropElement) {
      cropElement.addEventListener('mousedown', handleCropDragStart);
      document.addEventListener('mouseup', handleCropDragEnd);
    }

    return () => {
      if (cropElement) {
        cropElement.removeEventListener('mousedown', handleCropDragStart);
        document.removeEventListener('mouseup', handleCropDragEnd);
      }
    };
  }, [uploadedImage, onDragStateChange]);

  // Handle file drop
  const onDrop = useCallback((acceptedFiles: File[], rejectedFiles: any[]) => {
    // Clear any previous errors
    setError(null);
    
    // Handle rejected files (too large or wrong type)
    if (rejectedFiles.length > 0) {
      const rejection = rejectedFiles[0];
      if (rejection.errors[0].code === 'file-too-large') {
        const size = formatFileSize(rejection.file.size);
        setError(`File is too large (${size}). Maximum size is 10MB.`);
        toast.error(`File is too large (${size}). Maximum size is 10MB.`);
      } else if (rejection.errors[0].code === 'file-invalid-type') {
        setError('Only image files are allowed (JPG, PNG, WEBP).');
        toast.error('Only image files are allowed (JPG, PNG, WEBP).');
      } else {
        setError('File upload failed. Please try again.');
        toast.error('File upload failed. Please try again.');
      }
      return;
    }
    
    if (acceptedFiles.length === 0) return;
    
    const file = acceptedFiles[0];
    
    // Store file size for display
    setFileSize(file.size);
    
    // Check file size (5MB limit) - this is a double-check, as Dropzone should handle this
    if (file.size > 10 * 1024 * 1024) {
      const size = formatFileSize(file.size);
      setError(`File is too large (${size}). Maximum size is 10MB.`);
      toast.error(`File is too large (${size}). Maximum size is 10MB.`);
      return;
    }
    
    // Check file type
    if (!file.type.startsWith('image/')) {
      setError('Only image files are allowed (JPG, PNG, WEBP).');
      toast.error('Only image files are allowed (JPG, PNG, WEBP).');
      return;
    }
    
    // Create a preview URL for the image
    const objectUrl = URL.createObjectURL(file);
    setUploadedImage(objectUrl);
    
    // Clean up the preview URL when component unmounts
    return () => URL.revokeObjectURL(objectUrl);
  }, []);
  
  const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
    accept: {
      'image/jpeg': [],
      'image/png': [],
      'image/webp': [],
    },
    maxSize: 10 * 1024 * 1024, // 10MB max
    multiple: false,
    onDrop,
  });

  // Handle image load to set initial crop
  const onImageLoad = useCallback((e: React.SyntheticEvent<HTMLImageElement>) => {
    const { width, height } = e.currentTarget;
    setCrop(centerAspectCrop(width, height, aspect));
  }, [aspect]);

  // Handle crop completion
  const handleCropComplete = useCallback((c: PixelCrop) => {
    setCompletedCrop(c);
  }, []);

  // Handle crop drag events
  const handleCropDragStart = useCallback(() => {
    if (onDragStateChange) onDragStateChange(true);
  }, [onDragStateChange]);

  const handleCropDragEnd = useCallback(() => {
    if (onDragStateChange) onDragStateChange(false);
  }, [onDragStateChange]);

  // Handle image upload to Firebase Storage
  const handleUpload = useCallback(async () => {
    if (!imgRef.current || !completedCrop || !uploadedImage || !programId) {
      return;
    }

    try {
      setIsUploading(true);
      setUploadProgress(0);
      setError(null);
      
      // Get the cropped image blob
      const croppedBlob = await getCroppedImg(imgRef.current, completedCrop);
      
      // Compress the image
      new Compressor(croppedBlob, {
        quality: 0.9, // 90% quality (increased from 80%)
        maxWidth: 1920, // Max width 1920px (increased from 1200px)
        maxHeight: 1080, // Max height 1080px (increased from 675px)
        success: async (compressedBlob) => {
          // Create a storage reference
          const storageRef = ref(storage, `programs/${programId}/vehicles/${vehicleId}/image.jpg`);
          
          // Upload the compressed image
          const uploadTask = uploadBytesResumable(storageRef, compressedBlob);
          
          // Monitor upload progress
          uploadTask.on(
            'state_changed',
            (snapshot) => {
              const progress = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              );
              setUploadProgress(progress);
            },
            (error) => {
              console.error('Upload error:', error);
              setError('Failed to upload image. Please try again.');
              toast.error('Failed to upload image. Please try again.');
              setIsUploading(false);
            },
            async () => {
              // Upload completed successfully
              const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
              
              // Call the callback with the download URL
              try {
                await onImageUploaded(downloadURL);
                toast.success('Image uploaded successfully!');
              } catch (error) {
                console.error('Error updating vehicle with image URL:', error);
                setError('Failed to update vehicle with image URL.');
                toast.error('Failed to update vehicle with image URL.');
              }
              
              // Clean up
              if (previewUrl.current) {
                URL.revokeObjectURL(previewUrl.current);
              }
              
              setIsUploading(false);
            }
          );
        },
        error: (err) => {
          console.error('Compression error:', err);
          setError('Failed to compress image. Please try a different image.');
          toast.error('Failed to compress image. Please try a different image.');
          setIsUploading(false);
        },
      });
    } catch (err) {
      console.error('Error processing image:', err);
      setError('Failed to process image. Please try again.');
      toast.error('Failed to process image. Please try again.');
      setIsUploading(false);
    }
  }, [completedCrop, uploadedImage, vehicleId, programId, onImageUploaded]);

  return (
    <div className="bg-dark-200 rounded-lg p-4 sm:p-6 max-w-2xl w-full max-h-[90vh] overflow-y-auto">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold text-white">Upload Vehicle Image</h2>
        <button
          onClick={onCancel}
          className="p-2 rounded-full hover:bg-dark-100 text-gray-400 hover:text-white transition-colors"
          disabled={isUploading}
        >
          <X size={20} />
        </button>
      </div>

      {error && (
        <div className="mb-4 p-3 bg-red-500/20 border border-red-500/50 rounded-lg text-red-200 flex items-start gap-2">
          <AlertCircle className="h-5 w-5 flex-shrink-0 mt-0.5" />
          <div>
            <p className="font-medium">{error}</p>
            {fileSize && fileSize > 10 * 1024 * 1024 && (
              <p className="text-sm mt-1">
                Your file is {formatFileSize(fileSize)}. Please select a smaller image or compress it before uploading.
              </p>
            )}
          </div>
        </div>
      )}

      {!uploadedImage ? (
        <div
          {...getRootProps()}
          className={`border-2 border-dashed ${
            isDragReject ? 'border-red-500 bg-red-500/10' :
            isDragActive ? 'border-primary-500 bg-primary-500/10' : 'border-dark-50'
          } rounded-lg p-4 sm:p-8 text-center cursor-pointer transition-colors`}
        >
          <input {...getInputProps()} />
          <div className="flex flex-col items-center justify-center gap-3">
            <div className={`p-4 rounded-full ${isDragReject ? 'bg-red-500/20' : 'bg-dark-300'}`}>
              <Upload className={`h-6 w-6 sm:h-8 sm:w-8 ${isDragReject ? 'text-red-500' : 'text-primary-500'}`} />
            </div>
            <p className="text-white font-medium">
              {isDragReject 
                ? 'File too large or not an image' 
                : 'Drag & drop an image here, or click to select'}
            </p>
            <p className="text-gray-400 text-sm">
              Supports JPG, PNG, WEBP • Max size: 10MB
            </p>
          </div>
        </div>
      ) : (
        <div className="space-y-4">
          <div className="bg-dark-300 rounded-lg overflow-hidden">
            <ReactCrop
              crop={crop}
              onChange={(c) => setCrop(c)}
              onComplete={handleCropComplete}
              onDragStart={handleCropDragStart}
              onDragEnd={handleCropDragEnd}
              aspect={aspect}
              className="max-h-[50vh] mx-auto"
            >
              <img
                ref={imgRef}
                src={uploadedImage}
                alt="Upload preview"
                onLoad={onImageLoad}
                className="max-w-full max-h-[50vh] mx-auto"
              />
            </ReactCrop>
          </div>
          
          <div className="text-gray-400 text-sm text-center">
            Drag to reposition • Resize handles at corners and edges
          </div>

          <div className="flex flex-col sm:flex-row justify-between gap-4 mt-4">
            <button
              onClick={() => {
                setUploadedImage(null);
                setCrop(undefined);
                setCompletedCrop(undefined);
                setError(null);
              }}
              className="px-4 py-2 bg-dark-100 text-white rounded-lg hover:bg-dark-50 transition-colors flex-1"
              disabled={isUploading}
            >
              Cancel
            </button>
            <button
              onClick={handleUpload}
              className="px-4 py-2 bg-primary-500 text-white rounded-lg hover:bg-primary-600 transition-colors flex-1 flex items-center justify-center gap-2"
              disabled={isUploading || !completedCrop}
            >
              {isUploading ? (
                <>
                  <Loader2 className="h-5 w-5 animate-spin" />
                  <span>Uploading {uploadProgress}%</span>
                </>
              ) : (
                <>
                  <Check className="h-5 w-5" />
                  <span>Save Image</span>
                </>
              )}
            </button>
          </div>
        </div>
      )}
    </div>
  );
} 