import React from 'react';
import { Calendar, List, Clock } from 'lucide-react';

type View = 'list' | 'calendar' | 'timeline';

interface ViewSelectorProps {
  currentView: View;
  onViewChange: (view: View) => void;
}

export function ViewSelector({ currentView, onViewChange }: ViewSelectorProps) {
  return (
    <div className="hidden sm:flex gap-2 bg-dark-100 p-1 rounded-lg">
      <button
        onClick={() => onViewChange('list')}
        className={`flex items-center gap-2 px-3 py-2 rounded-md transition-colors ${
          currentView === 'list'
            ? 'bg-primary-500 text-white'
            : 'text-gray-400 hover:text-white hover:bg-dark-50'
        }`}
      >
        <List className="w-4 h-4" />
        <span>List</span>
      </button>
      <button
        onClick={() => onViewChange('calendar')}
        className={`flex items-center gap-2 px-3 py-2 rounded-md transition-colors ${
          currentView === 'calendar'
            ? 'bg-primary-500 text-white'
            : 'text-gray-400 hover:text-white hover:bg-dark-50'
        }`}
      >
        <Calendar className="w-4 h-4" />
        <span>Calendar</span>
      </button>
      <button
        onClick={() => onViewChange('timeline')}
        className={`flex items-center gap-2 px-3 py-2 rounded-md transition-colors ${
          currentView === 'timeline'
            ? 'bg-primary-500 text-white'
            : 'text-gray-400 hover:text-white hover:bg-dark-50'
        }`}
      >
        <Clock className="w-4 h-4" />
        <span>Timeline</span>
      </button>
    </div>
  );
} 