import React, { useState, useMemo } from 'react';
import { X, Target, DollarSign, Calendar, AlertTriangle, Car, Wrench, Flag } from 'lucide-react';
import type { BudgetGoal, BudgetGoalType, ExpenseCategory, BudgetGoalTimeframe } from '../../types/budget';
import type { Vehicle } from '../../types/vehicle';

interface BudgetGoalModalProps {
  isOpen: boolean;
  onClose: () => void;
  onAdd: (goal: BudgetGoal) => void;
  vehicles?: Vehicle[];
}

export default function BudgetGoalModal({ isOpen, onClose, onAdd, vehicles = [] }: BudgetGoalModalProps) {
  const [type, setType] = useState<BudgetGoalType>('total');
  const [amount, setAmount] = useState('');
  const [category, setCategory] = useState<ExpenseCategory>('Entry Fees');
  const [vehicleId, setVehicleId] = useState('');
  const [timeframe, setTimeframe] = useState<BudgetGoalTimeframe>('season');
  const [rollover, setRollover] = useState(false);
  const [description, setDescription] = useState('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!amount) return;

    onAdd({
      id: crypto.randomUUID(),
      type,
      amount: parseFloat(amount),
      category: type === 'category' ? category : undefined,
      vehicleId: type === 'vehicle' ? vehicleId : undefined,
      timeframe,
      rollover,
      description,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString()
    });

    // Reset form
    setType('total');
    setAmount('');
    setCategory('Entry Fees');
    setVehicleId('');
    setTimeframe('season');
    setRollover(false);
    setDescription('');
    onClose();
  };

  // Determine which timeframes make sense for the selected goal type
  const availableTimeframes = useMemo(() => {
    switch (type) {
      case 'event':
        return ['season']; // Per event only makes sense for season
      case 'emergency':
        return ['season']; // Emergency fund is season-long
      case 'total':
      case 'category':
      case 'vehicle':
        return ['season', 'month']; // These can be either
      default:
        return ['season'];
    }
  }, [type]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-dark-200 rounded-lg p-6 max-w-md w-full space-y-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <Target className="w-5 h-5 text-primary-500" />
            <h2 className="text-xl font-bold text-white">Add Budget Goal</h2>
          </div>
          <button
            onClick={onClose}
            className="p-1 rounded-md text-gray-400 hover:text-white hover:bg-dark-300"
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          {/* Goal Type Selection */}
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-400">Goal Type</label>
            <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
              <button
                type="button"
                onClick={() => setType('total')}
                className={`flex items-center gap-2 p-2 rounded-md text-sm ${
                  type === 'total'
                    ? 'bg-primary-500 text-white'
                    : 'bg-dark-300 text-gray-400 hover:bg-dark-400 hover:text-white'
                }`}
              >
                <DollarSign className="w-4 h-4" />
                Season Total
              </button>
              <button
                type="button"
                onClick={() => setType('category')}
                className={`flex items-center gap-2 p-2 rounded-md text-sm ${
                  type === 'category'
                    ? 'bg-primary-500 text-white'
                    : 'bg-dark-300 text-gray-400 hover:bg-dark-400 hover:text-white'
                }`}
              >
                <Wrench className="w-4 h-4" />
                Category
              </button>
              <button
                type="button"
                onClick={() => setType('event')}
                className={`flex items-center gap-2 p-2 rounded-md text-sm ${
                  type === 'event'
                    ? 'bg-primary-500 text-white'
                    : 'bg-dark-300 text-gray-400 hover:bg-dark-400 hover:text-white'
                }`}
              >
                <Flag className="w-4 h-4" />
                Per Event
              </button>
              <button
                type="button"
                onClick={() => setType('emergency')}
                className={`flex items-center gap-2 p-2 rounded-md text-sm ${
                  type === 'emergency'
                    ? 'bg-primary-500 text-white'
                    : 'bg-dark-300 text-gray-400 hover:bg-dark-400 hover:text-white'
                }`}
              >
                <AlertTriangle className="w-4 h-4" />
                Emergency Fund
              </button>
              <button
                type="button"
                onClick={() => setType('vehicle')}
                className={`flex items-center gap-2 p-2 rounded-md text-sm ${
                  type === 'vehicle'
                    ? 'bg-primary-500 text-white'
                    : 'bg-dark-300 text-gray-400 hover:bg-dark-400 hover:text-white'
                }`}
              >
                <Car className="w-4 h-4" />
                Per Vehicle
              </button>
            </div>
          </div>

          {/* Category Selection (if type is category) */}
          {type === 'category' && (
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-400">Category</label>
              <select
                value={category}
                onChange={(e) => setCategory(e.target.value as ExpenseCategory)}
                className="w-full bg-dark-300 border border-dark-400 rounded-md px-3 py-2 text-white focus:ring-1 focus:ring-primary-500 focus:border-transparent"
                required
              >
                <option value="Entry Fees">Entry Fees</option>
                <option value="Travel & Lodging">Travel & Lodging</option>
                <option value="Fuel & Oil">Fuel & Oil</option>
                <option value="Tires">Tires</option>
                <option value="Parts & Repairs">Parts & Repairs</option>
                <option value="Scheduled Maintenance">Scheduled Maintenance</option>
                <option value="Tools & Equipment">Tools & Equipment</option>
                <option value="Safety Gear">Safety Gear</option>
                <option value="Marketing & Sponsorship">Marketing & Sponsorship</option>
                <option value="Insurance">Insurance</option>
                <option value="Other">Other</option>
              </select>
            </div>
          )}

          {/* Vehicle Selection (if type is vehicle) */}
          {type === 'vehicle' && (
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-400">Vehicle</label>
              <select
                value={vehicleId}
                onChange={(e) => setVehicleId(e.target.value)}
                className="w-full bg-dark-300 border border-dark-400 rounded-md px-3 py-2 text-white focus:ring-1 focus:ring-primary-500 focus:border-transparent"
                required
              >
                <option value="">Select a vehicle</option>
                {vehicles.map(vehicle => (
                  <option key={vehicle.id} value={vehicle.id}>
                    {vehicle.year} {vehicle.make} {vehicle.model}
                  </option>
                ))}
              </select>
            </div>
          )}

          {/* Amount Input */}
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-400">Budget Amount</label>
            <div className="relative">
              <span className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400">$</span>
              <input
                type="number"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                className="w-full bg-dark-300 border border-dark-400 rounded-md pl-8 pr-3 py-2 text-white focus:ring-1 focus:ring-primary-500 focus:border-transparent"
                placeholder="0.00"
                required
                min="0"
                step="0.01"
              />
            </div>
          </div>

          {/* Timeframe Selection */}
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-400">Timeframe</label>
            <div className="grid grid-cols-2 gap-2">
              {availableTimeframes.includes('season') && (
                <button
                  type="button"
                  onClick={() => setTimeframe('season')}
                  className={`flex items-center justify-center gap-2 p-2 rounded-md text-sm ${
                    timeframe === 'season'
                      ? 'bg-primary-500 text-white'
                      : 'bg-dark-300 text-gray-400 hover:bg-dark-400 hover:text-white'
                  }`}
                >
                  <Calendar className="w-4 h-4" />
                  Season
                </button>
              )}
              {availableTimeframes.includes('month') && (
                <button
                  type="button"
                  onClick={() => setTimeframe('month')}
                  className={`flex items-center justify-center gap-2 p-2 rounded-md text-sm ${
                    timeframe === 'month'
                      ? 'bg-primary-500 text-white'
                      : 'bg-dark-300 text-gray-400 hover:bg-dark-400 hover:text-white'
                  }`}
                >
                  <Calendar className="w-4 h-4" />
                  Monthly
                </button>
              )}
            </div>
          </div>

          {/* Rollover Option */}
          <div className="space-y-2">
            <label className="flex items-center gap-2 text-sm font-medium text-gray-400">
              <input
                type="checkbox"
                checked={rollover}
                onChange={(e) => setRollover(e.target.checked)}
                className="rounded border-dark-400 text-primary-500 focus:ring-primary-500"
              />
              Roll over unused budget
            </label>
            <p className="text-xs text-gray-500">
              Any unused budget will be added to next {timeframe === 'month' ? 'month' : 'season'}'s budget
            </p>
          </div>

          {/* Description */}
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-400">Description (Optional)</label>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="w-full bg-dark-300 border border-dark-400 rounded-md px-3 py-2 text-white focus:ring-1 focus:ring-primary-500 focus:border-transparent"
              placeholder="Add notes about this budget goal..."
              rows={3}
            />
          </div>

          <div className="flex justify-end gap-3 pt-4">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-gray-400 hover:text-white"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-primary-500 text-white rounded-md hover:bg-primary-600"
            >
              Add Goal
            </button>
          </div>
        </form>
      </div>
    </div>
  );
} 