import React from 'react';
import { DollarSign } from 'lucide-react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Tooltip } from 'recharts';
import type { SeasonOverviewProps } from './types';
import { getCategoryColor } from '../../constants/budget';

export default function SeasonOverview({ budget, events }: SeasonOverviewProps) {
  const totalSpent = budget.expenses.reduce((sum, expense) => 
    sum + (expense.amount * expense.quantity), 0);

  const monthlySpending = React.useMemo(() => {
    const months = Array.from({ length: 12 }, (_, i) => {
      const date = new Date();
      date.setMonth(i);
      return {
        name: date.toLocaleString('default', { month: 'short' }),
        total: 0,
        entryFees: 0,
        travel: 0,
        maintenance: 0
      };
    });

    budget.expenses.forEach(expense => {
      const date = new Date(expense.createdAt);
      if (date.getFullYear() === budget.year) {
        const month = date.getMonth();
        const amount = expense.amount * expense.quantity;
        months[month].total += amount;

        // Track specific categories
        if (expense.category === 'Entry Fees') {
          months[month].entryFees += amount;
        } else if (expense.category === 'Travel & Lodging') {
          months[month].travel += amount;
        } else if (expense.category === 'Scheduled Maintenance' || expense.category === 'Parts & Repairs') {
          months[month].maintenance += amount;
        }
      }
    });

    return months;
  }, [budget]);

  const categoryData = React.useMemo(() => {
    const categoryTotals: Record<string, number> = {};
    
    budget.expenses.forEach(expense => {
      const amount = expense.amount * expense.quantity;
      categoryTotals[expense.category] = (categoryTotals[expense.category] || 0) + amount;
    });

    return Object.entries(categoryTotals)
      .map(([name, value]) => ({ name, value }))
      .sort((a, b) => b.value - a.value);
  }, [budget]);

  return (
    <div className="lg:col-span-8 bg-gradient-to-br from-dark-200 to-dark-300 rounded-2xl p-4 sm:p-6 shadow-xl">
      <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4 mb-6">
        <h3 className="text-xl font-semibold text-white">Season Overview</h3>
        <div className="px-3 py-1 rounded-full bg-dark-100 text-sm text-gray-400">
          {budget.year}
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
        <div className="bg-dark-100 rounded-xl p-4">
          <p className="text-sm font-medium text-gray-400 mb-1">Total Spent</p>
          <p className="text-2xl font-display font-light text-white">
            {totalSpent === 0 ? (
              <span className="text-gray-500">$0</span>
            ) : (
              `$${totalSpent.toLocaleString()}`
            )}
          </p>
        </div>
        <div className="bg-dark-100 rounded-xl p-4">
          <p className="text-sm font-medium text-gray-400 mb-1">Entry Fees</p>
          <p className="text-2xl font-display font-light text-white">
            ${categoryData.find(c => c.name === 'Entry Fees')?.value.toLocaleString() || '0'}
          </p>
        </div>
        <div className="bg-dark-100 rounded-xl p-4">
          <p className="text-sm font-medium text-gray-400 mb-1">Events</p>
          <p className="text-2xl font-display font-light text-white">
            {events.filter(e => new Date(e.date).getFullYear() === budget.year).length}
          </p>
        </div>
        <div className="bg-dark-100 rounded-xl p-4">
          <p className="text-sm font-medium text-gray-400 mb-1">Avg/Event</p>
          <p className="text-2xl font-display font-light text-white">
            {events.length > 0 
              ? `$${Math.round(totalSpent / events.length).toLocaleString()}`
              : <span className="text-gray-500">$0</span>
            }
          </p>
        </div>
      </div>

      {/* Monthly Spending Chart */}
      <div className="space-y-4">
        <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4">
          <h4 className="text-sm font-medium text-gray-400">Monthly Spending</h4>
          <div className="flex flex-wrap items-center gap-4 text-xs">
            <div className="flex items-center gap-2">
              <div className="w-2 h-2 rounded-full" style={{ backgroundColor: getCategoryColor('Entry Fees') }}></div>
              <span className="text-gray-400">Entry Fees</span>
            </div>
            <div className="flex items-center gap-2">
              <div className="w-2 h-2 rounded-full" style={{ backgroundColor: getCategoryColor('Travel & Lodging') }}></div>
              <span className="text-gray-400">Travel</span>
            </div>
            <div className="flex items-center gap-2">
              <div className="w-2 h-2 rounded-full" style={{ backgroundColor: getCategoryColor('Scheduled Maintenance') }}></div>
              <span className="text-gray-400">Maintenance</span>
            </div>
          </div>
        </div>
        
        <div className="h-[300px] sm:h-[400px]">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={monthlySpending} margin={{ top: 10, right: 0, left: -20, bottom: 0 }}>
              <CartesianGrid strokeDasharray="3 3" stroke="#374151" vertical={false} />
              <XAxis 
                dataKey="name" 
                tick={{ fill: '#9CA3AF' }}
                axisLine={{ stroke: '#374151' }}
                interval="preserveStartEnd"
              />
              <YAxis 
                tick={{ fill: '#9CA3AF' }}
                axisLine={{ stroke: '#374151' }}
                tickFormatter={(value) => `$${value.toLocaleString()}`}
                width={60}
              />
              <Tooltip
                cursor={false}
                contentStyle={{
                  backgroundColor: '#111827',
                  border: '1px solid #374151',
                  borderRadius: '0.5rem',
                  padding: '0.75rem',
                  boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.5)'
                }}
                labelStyle={{
                  color: '#D1D5DB',
                  fontWeight: 600,
                  fontSize: '0.875rem',
                  marginBottom: '0.5rem',
                  textAlign: 'center'
                }}
                itemStyle={{
                  color: '#E5E7EB',
                  padding: '0.25rem 0',
                  fontSize: '0.875rem'
                }}
                formatter={(value: number, name: string) => {
                  const labels = {
                    entryFees: 'Entry Fees',
                    travel: 'Travel',
                    maintenance: 'Maintenance'
                  };
                  return [`$${value.toLocaleString()}`, labels[name as keyof typeof labels]];
                }}
              />
              <Bar dataKey="entryFees" stackId="a" fill={getCategoryColor('Entry Fees')} radius={[4, 4, 0, 0]} />
              <Bar dataKey="travel" stackId="a" fill={getCategoryColor('Travel & Lodging')} radius={[4, 4, 0, 0]} />
              <Bar dataKey="maintenance" stackId="a" fill={getCategoryColor('Scheduled Maintenance')} radius={[4, 4, 0, 0]} />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
} 