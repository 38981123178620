import React, { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Plus, Calendar, ChevronRight, Clock, MapPin, Search, ChevronDown, ArrowUpDown } from 'lucide-react';
import { MonthView } from '../components/calendar/MonthView';
import { CalendarHeader } from '../components/calendar/CalendarHeader';
import { SeasonTimeline } from '../components/calendar/SeasonTimeline';
import { ViewSelector } from '../components/calendar/ViewSelector';
import { useAuth } from '../contexts/AuthContext';
import type { Event } from '../types/event';
import { format, isSameYear, parseISO, isAfter, isBefore, addDays, isSameDay } from 'date-fns';

interface ScheduleProps {
  events: Event[];
  onUpdateEvent: (event: Event) => void;
}

type View = 'list' | 'calendar' | 'timeline';
type SortMethod = 'relevance' | 'upcoming' | 'recent' | 'future' | 'past' | 'created' | 'updated';

export default function Schedule({ events, onUpdateEvent }: ScheduleProps) {
  const navigate = useNavigate();
  const { programId } = useAuth();
  const [currentDate, setCurrentDate] = useState(new Date());
  const [currentView, setCurrentView] = useState<View>('list');
  const [searchQuery, setSearchQuery] = useState('');
  const [showAllEvents, setShowAllEvents] = useState(false);
  const [sortMethod, setSortMethod] = useState<SortMethod>('relevance');

  // Number of events to show initially
  const INITIAL_EVENT_COUNT = 10;

  // Group events by status
  const confirmedEvents = events.filter(e => e.status === 'confirmed');
  const consideringEvents = events.filter(e => e.status === 'considering');
  const cancelledEvents = events.filter(e => e.status === 'cancelled');

  // Filter events for current year
  const currentYearEvents = events.filter(event => 
    isSameYear(parseISO(event.date), new Date())
  );

  // Sort and filter events
  const sortedAndFilteredEvents = useMemo(() => {
    const now = new Date();
    const thirtyDaysAgo = addDays(now, -30);
    const thirtyDaysAhead = addDays(now, 30);

    const filteredEvents = events.filter(event => {
      // Apply search filter
      if (searchQuery) {
        return event.title.toLowerCase().includes(searchQuery.toLowerCase());
      }
      return true;
    });

    return filteredEvents.sort((a, b) => {
      const dateA = parseISO(a.date);
      const dateB = parseISO(b.date);

      switch (sortMethod) {
        case 'created':
          // Sort by creation date, newest first
          return ((b.createdAt ? new Date(b.createdAt).getTime() : 0) - 
                  (a.createdAt ? new Date(a.createdAt).getTime() : 0));
        case 'updated':
          // Sort by last updated date, newest first
          return ((b.updatedAt ? new Date(b.updatedAt).getTime() : 0) - 
                  (a.updatedAt ? new Date(a.updatedAt).getTime() : 0));
        case 'future':
          // All future events, chronologically
          if (isAfter(dateA, now) && isAfter(dateB, now)) {
            return dateA.getTime() - dateB.getTime();
          }
          return isAfter(dateA, now) ? -1 : 1;
        case 'past':
          // All past events, most recent first
          if (isBefore(dateA, now) && isBefore(dateB, now)) {
            return dateB.getTime() - dateA.getTime();
          }
          return isBefore(dateB, now) ? -1 : 1;
        case 'upcoming':
          // Next few events (within 30 days)
          if (isAfter(dateA, now) && isAfter(dateB, now) && 
              isBefore(dateA, thirtyDaysAhead) && isBefore(dateB, thirtyDaysAhead)) {
            return dateA.getTime() - dateB.getTime();
          }
          if (isBefore(dateA, thirtyDaysAhead) && isAfter(dateA, now)) return -1;
          if (isBefore(dateB, thirtyDaysAhead) && isAfter(dateB, now)) return 1;
          return dateA.getTime() - dateB.getTime();
        case 'recent':
          // Recent events (within last 30 days)
          if (isBefore(dateA, now) && isBefore(dateB, now) && 
              isAfter(dateA, thirtyDaysAgo) && isAfter(dateB, thirtyDaysAgo)) {
            return dateB.getTime() - dateA.getTime();
          }
          if (isAfter(dateA, thirtyDaysAgo) && isBefore(dateA, now)) return -1;
          if (isAfter(dateB, thirtyDaysAgo) && isBefore(dateB, now)) return 1;
          return dateB.getTime() - dateA.getTime();
        case 'relevance':
        default:
          // Original relevance-based sorting
          const getDaysDiff = (date: Date) => Math.abs(date.getTime() - now.getTime()) / (1000 * 60 * 60 * 24);
          const isANearby = isBefore(dateA, thirtyDaysAhead) && isAfter(dateA, thirtyDaysAgo);
          const isBNearby = isBefore(dateB, thirtyDaysAhead) && isAfter(dateB, thirtyDaysAgo);
          
          if (isANearby && !isBNearby) return -1;
          if (!isANearby && isBNearby) return 1;
          
          if (isANearby && isBNearby) {
            return getDaysDiff(dateA) - getDaysDiff(dateB);
          }
          
          if (isAfter(dateA, now) && isAfter(dateB, now)) {
            return dateA.getTime() - dateB.getTime();
          }
          
          if (isBefore(dateA, now) && isBefore(dateB, now)) {
            return dateB.getTime() - dateA.getTime();
          }
          
          return isAfter(dateA, now) ? -1 : 1;
      }
    });
  }, [events, searchQuery, sortMethod]);

  // Get visible events based on showAllEvents flag
  const visibleEvents = useMemo(() => {
    if (showAllEvents) {
      return sortedAndFilteredEvents;
    }
    return sortedAndFilteredEvents.slice(0, INITIAL_EVENT_COUNT);
  }, [sortedAndFilteredEvents, showAllEvents]);

  // Function to determine event tags
  const getEventTags = (event: Event) => {
    const tags: { label: string; color: string }[] = [];
    const eventDate = parseISO(event.date);
    const now = new Date();

    // Next up tag
    if (isAfter(eventDate, now) && 
        isBefore(eventDate, addDays(now, 7)) && 
        event.status === 'confirmed') {
      tags.push({ label: 'Next Up!', color: 'bg-green-500' });
    }

    // Recent event tag
    if (isBefore(eventDate, now) && 
        isAfter(eventDate, addDays(now, -7))) {
      tags.push({ label: 'Recent', color: 'bg-blue-500' });
    }

    // Today tag
    if (isSameDay(eventDate, now)) {
      tags.push({ label: 'Today', color: 'bg-yellow-500' });
    }

    // Status tags
    if (event.status === 'considering') {
      tags.push({ label: 'Considering', color: 'bg-purple-500' });
    } else if (event.status === 'cancelled') {
      tags.push({ label: 'Cancelled', color: 'bg-red-500' });
    }

    return tags;
  };

  const handleAddEvent = (date?: Date) => {
    navigate('/app/schedule/new', {
      state: {
        isNew: true,
        date: date ? date.toISOString().split('T')[0] : new Date().toISOString().split('T')[0]
      }
    });
  };

  return (
    <div className="space-y-6">
      {/* Header */}
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
        <h1 className="text-2xl font-display font-bold text-white">Season Planning</h1>
        <div className="flex items-center gap-4 w-full sm:w-auto">
          <ViewSelector currentView={currentView} onViewChange={setCurrentView} />
          <button
            onClick={() => handleAddEvent()}
            className="flex items-center justify-center gap-2 px-4 py-2 bg-primary-500 text-white rounded-md hover:bg-primary-600 w-full sm:w-auto"
          >
            <Plus className="w-5 h-5" />
            Add Event
          </button>
        </div>
      </div>

      <div className="space-y-6">
        {/* Season Overview */}
        <div className="bg-dark-200 rounded-lg p-6">
          <h2 className="text-lg font-display font-bold text-white mb-4">Season Overview</h2>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
            <div className="bg-dark-100 rounded-lg p-4">
              <p className="text-sm text-gray-400">Total Events</p>
              <p className="text-2xl font-display font-light text-white">{events.length}</p>
            </div>
            <div className="bg-dark-100 rounded-lg p-4">
              <p className="text-sm text-gray-400">Confirmed</p>
              <p className="text-2xl font-display font-light text-white">{confirmedEvents.length}</p>
            </div>
            <div className="bg-dark-100 rounded-lg p-4">
              <p className="text-sm text-gray-400">Considering</p>
              <p className="text-2xl font-display font-light text-white">{consideringEvents.length}</p>
            </div>
            <div className="bg-dark-100 rounded-lg p-4">
              <p className="text-sm text-gray-400">Cancelled</p>
              <p className="text-2xl font-display font-light text-white">{cancelledEvents.length}</p>
            </div>
          </div>
        </div>

        {/* Desktop Views */}
        <div className="hidden sm:block">
          {currentView === 'calendar' && (
            <div className="bg-dark-200 rounded-lg">
              <div className="p-4">
                <CalendarHeader
                  currentDate={currentDate}
                  onDateChange={setCurrentDate}
                />
              </div>
              <div className="p-4 border-t border-dark-50">
                <MonthView
                  currentDate={currentDate}
                  events={events}
                  onSelectDate={(date) => handleAddEvent(date)}
                  onSelectEvent={(event) => navigate(`/app/schedule/${event.id}`)}
                />
              </div>
            </div>
          )}

          {currentView === 'timeline' && currentYearEvents.length > 0 && (
            <SeasonTimeline events={currentYearEvents} />
          )}
        </div>

        {/* Events List - Always visible on mobile, optional on desktop */}
        {(currentView === 'list' || !window.matchMedia('(min-width: 640px)').matches) && (
          <div className="bg-dark-200 rounded-lg p-6">
            <div className="flex flex-col gap-4">
              <div className="flex justify-between items-center">
                <h2 className="text-lg font-display font-bold text-white">Events</h2>
                <button
                  onClick={() => handleAddEvent()}
                  className="text-sm text-primary-500 hover:text-primary-400 flex items-center gap-1 sm:hidden"
                >
                  Add Event
                  <Plus className="w-4 h-4" />
                </button>
              </div>

              {/* Search Bar and Sort Controls */}
              <div className="flex flex-col sm:flex-row gap-4">
                <div className="relative flex-1">
                  <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400" />
                  <input
                    type="text"
                    placeholder="Search events..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="w-full pl-10 pr-4 py-2.5 bg-dark-100 border border-dark-50 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-primary-500"
                  />
                </div>
                <div className="flex items-center gap-2 w-full sm:w-auto">
                  <ArrowUpDown className="w-5 h-5 text-gray-400" />
                  <select
                    value={sortMethod}
                    onChange={(e) => setSortMethod(e.target.value as SortMethod)}
                    className="flex-1 sm:flex-initial bg-dark-100 border border-dark-50 rounded-lg text-white py-2.5 px-3 focus:outline-none focus:ring-2 focus:ring-primary-500"
                  >
                    <option value="relevance">Most Relevant</option>
                    <option value="upcoming">Next 30 Days</option>
                    <option value="recent">Last 30 Days</option>
                    <option value="future">All Future Events</option>
                    <option value="past">All Past Events</option>
                    <option value="updated">Recently Updated</option>
                    <option value="created">Recently Created</option>
                  </select>
                </div>
              </div>

              {/* Events List */}
              <div className="space-y-3">
                {visibleEvents.length === 0 ? (
                  <p className="text-center text-gray-400 py-4">
                    {searchQuery ? 'No events found matching your search.' : 'No events planned yet.'}
                  </p>
                ) : (
                  <>
                    {visibleEvents.map(event => (
                      <button
                        key={event.id}
                        onClick={() => navigate(`/app/schedule/${event.id}`)}
                        className="w-full flex flex-col sm:flex-row items-start sm:items-center justify-between p-4 bg-dark-100 rounded-lg hover:bg-dark-50 transition-colors group"
                      >
                        <div className="flex items-start gap-3 w-full sm:w-auto">
                          <div className="p-2 bg-primary-500/10 rounded-lg shrink-0">
                            <Calendar className="w-5 h-5 text-primary-500" />
                          </div>
                          <div className="text-left flex-1">
                            <div className="flex flex-wrap items-center gap-2 mb-2">
                              <h3 className="text-white font-medium group-hover:text-primary-500">
                                {event.title}
                              </h3>
                              {/* Event Tags */}
                              {getEventTags(event).map((tag, index) => (
                                <span
                                  key={index}
                                  className={`px-2 py-0.5 rounded-full text-xs text-white ${tag.color}`}
                                >
                                  {tag.label}
                                </span>
                              ))}
                            </div>
                            <div className="flex flex-col sm:flex-row items-start sm:items-center gap-2 sm:gap-4 text-sm text-gray-400">
                              <div className="flex items-center gap-1">
                                <Calendar className="w-4 h-4 shrink-0" />
                                <span className="truncate">
                                  {format(parseISO(event.date), 'MMM d, yyyy')}
                                </span>
                              </div>
                              {event.location && (
                                <div className="flex items-center gap-1">
                                  <MapPin className="w-4 h-4 shrink-0" />
                                  <span className="truncate">{typeof event.location === 'string' ? event.location : event.location.name}</span>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <ChevronRight className="hidden sm:block w-4 h-4 text-gray-400 group-hover:text-primary-500 mt-2 sm:mt-0" />
                      </button>
                    ))}

                    {/* Load More Button */}
                    {!showAllEvents && sortedAndFilteredEvents.length > INITIAL_EVENT_COUNT && (
                      <button
                        onClick={() => setShowAllEvents(true)}
                        className="w-full flex items-center justify-center gap-2 py-3 text-primary-500 hover:text-primary-400 transition-colors"
                      >
                        Show More Events
                        <ChevronDown className="w-4 h-4" />
                      </button>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}