import React from 'react';
import { format } from 'date-fns';
import { ChevronLeft, ChevronRight } from 'lucide-react';

interface CalendarHeaderProps {
  currentDate: Date;
  onDateChange: (date: Date) => void;
}

export function CalendarHeader({ currentDate, onDateChange }: CalendarHeaderProps) {
  const handlePrevious = () => {
    const newDate = new Date(currentDate);
    newDate.setMonth(currentDate.getMonth() - 1);
    onDateChange(newDate);
  };

  const handleNext = () => {
    const newDate = new Date(currentDate);
    newDate.setMonth(currentDate.getMonth() + 1);
    onDateChange(newDate);
  };

  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center gap-2">
        <div className="flex items-center gap-2">
          <button
            onClick={handlePrevious}
            className="p-2 text-gray-400 hover:text-primary-500 rounded-full hover:bg-dark-50"
          >
            <ChevronLeft className="w-5 h-5" />
          </button>
          <button
            onClick={handleNext}
            className="p-2 text-gray-400 hover:text-primary-500 rounded-full hover:bg-dark-50"
          >
            <ChevronRight className="w-5 h-5" />
          </button>
        </div>
        <h2 className="text-lg sm:text-2xl font-semibold text-white ml-2">
          {format(currentDate, 'MMMM yyyy')}
        </h2>
      </div>
    </div>
  );
}