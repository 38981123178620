import { analytics } from '../config/firebase';
import { logEvent } from 'firebase/analytics';
import type { User } from 'firebase/auth';
import type { Vehicle } from '../types/vehicle';
import type { Event as RaceEvent } from '../types/event';
import type { Checklist } from '../types/checklist';
import type { BudgetItem } from '../types/budget';

// Onboarding Milestones tracking
const ONBOARDING_MILESTONES = {
  SIGNUP: 'signup_complete',
  FIRST_VEHICLE: 'first_vehicle_added',
  FIRST_EVENT: 'first_event_created',
  FIRST_CHECKLIST: 'first_checklist_created',
  FIRST_BUDGET_ITEM: 'first_budget_item_added',
  ONBOARDING_COMPLETE: 'onboarding_complete'
} as const;

// Track time between onboarding steps
const getTimeSinceSignup = (signupTime: number): number => {
  return Math.floor((Date.now() - signupTime) / 1000); // Convert to seconds
};

// User Lifecycle Events
export const trackSignUp = (user: User) => {
  // Store signup timestamp in analytics
  const signupTime = Date.now();
  localStorage.setItem('signup_timestamp', signupTime.toString());

  logEvent(analytics, 'sign_up', {
    method: user.providerData[0]?.providerId || 'email',
    userId: user.uid,
    timestamp: new Date().toISOString()
  });

  // Track first onboarding milestone
  logEvent(analytics, ONBOARDING_MILESTONES.SIGNUP, {
    userId: user.uid,
    timestamp: new Date().toISOString()
  });
};

export const trackLogin = (user: User) => {
  logEvent(analytics, 'login', {
    method: user.providerData[0]?.providerId || 'email',
    userId: user.uid
  });
};

export const trackSubscriptionStart = (tier: string, price: number) => {
  logEvent(analytics, 'subscription_start', {
    tier,
    price,
    currency: 'USD'
  });
};

export const trackSubscriptionCancel = (tier: string, reason?: string) => {
  logEvent(analytics, 'subscription_cancel', {
    tier,
    reason
  });
};

// Enhanced Core Feature Usage with Onboarding Tracking
export const trackVehicleAdd = (vehicle: Vehicle, isFirstVehicle: boolean) => {
  const signupTime = parseInt(localStorage.getItem('signup_timestamp') || '0');
  
  logEvent(analytics, 'vehicle_add', {
    make: vehicle.make,
    model: vehicle.model,
    year: vehicle.year,
    time_since_signup: getTimeSinceSignup(signupTime)
  });

  if (isFirstVehicle) {
    logEvent(analytics, ONBOARDING_MILESTONES.FIRST_VEHICLE, {
      time_to_first_vehicle: getTimeSinceSignup(signupTime),
      vehicle_type: vehicle.primaryUse || 'unknown'
    });

    checkOnboardingProgress();
  }
};

export const trackEventCreate = (event: RaceEvent, isFirstEvent: boolean) => {
  const signupTime = parseInt(localStorage.getItem('signup_timestamp') || '0');
  
  logEvent(analytics, 'event_create', {
    eventType: event.type,
    hasLocation: !!event.location,
    time_since_signup: getTimeSinceSignup(signupTime)
  });

  if (isFirstEvent) {
    logEvent(analytics, ONBOARDING_MILESTONES.FIRST_EVENT, {
      time_to_first_event: getTimeSinceSignup(signupTime),
      event_type: event.type
    });

    checkOnboardingProgress();
  }
};

export const trackEventImport = (success: boolean, source?: string) => {
  logEvent(analytics, 'event_import', {
    success,
    source
  });
};

export const trackChecklistCreate = (checklist: Checklist, isFirstChecklist: boolean) => {
  const signupTime = parseInt(localStorage.getItem('signup_timestamp') || '0');
  
  logEvent(analytics, 'checklist_create', {
    itemCount: checklist.items.length,
    time_since_signup: getTimeSinceSignup(signupTime)
  });

  if (isFirstChecklist) {
    logEvent(analytics, ONBOARDING_MILESTONES.FIRST_CHECKLIST, {
      time_to_first_checklist: getTimeSinceSignup(signupTime),
      checklist_size: checklist.items.length
    });

    checkOnboardingProgress();
  }
};

export const trackChecklistComplete = (checklistId: string, duration: number) => {
  logEvent(analytics, 'checklist_complete', {
    checklistId,
    duration // Time taken to complete in seconds
  });
};

export const trackBudgetItemAdd = (item: BudgetItem, isFirstBudgetItem: boolean) => {
  const signupTime = parseInt(localStorage.getItem('signup_timestamp') || '0');
  
  logEvent(analytics, 'budget_item_add', {
    category: item.category,
    amount: item.amount,
    time_since_signup: getTimeSinceSignup(signupTime)
  });

  if (isFirstBudgetItem) {
    logEvent(analytics, ONBOARDING_MILESTONES.FIRST_BUDGET_ITEM, {
      time_to_first_budget_item: getTimeSinceSignup(signupTime),
      item_category: item.category,
      amount: item.amount
    });

    checkOnboardingProgress();
  }
};

// Page Views & Navigation
export const trackPageView = (pageName: string, params?: Record<string, string>) => {
  logEvent(analytics, 'page_view', {
    page_title: pageName,
    ...params
  });
};

// Business Metrics
export const trackSubscriptionView = (currentTier?: string) => {
  logEvent(analytics, 'subscription_view', {
    current_tier: currentTier
  });
};

export const trackPaymentStart = (tier: string, amount: number) => {
  logEvent(analytics, 'payment_start', {
    tier,
    amount,
    currency: 'USD'
  });
};

export const trackPaymentComplete = (tier: string, amount: number) => {
  logEvent(analytics, 'payment_complete', {
    tier,
    amount,
    currency: 'USD'
  });
};

export const trackPaymentError = (error: string, tier?: string) => {
  logEvent(analytics, 'payment_error', {
    error,
    tier
  });
};

// Track feature discovery and usage patterns
export const trackFeatureEngagement = (
  featureId: string,
  action: 'view' | 'interact' | 'complete',
  metadata?: Record<string, any>
) => {
  const signupTime = parseInt(localStorage.getItem('signup_timestamp') || '0');
  
  logEvent(analytics, 'feature_engagement', {
    feature_id: featureId,
    action,
    time_since_signup: getTimeSinceSignup(signupTime),
    ...metadata
  });
};

// Track user session data
export const trackSessionStart = () => {
  const sessionStartTime = Date.now();
  localStorage.setItem('session_start_time', sessionStartTime.toString());
  
  logEvent(analytics, 'session_start', {
    timestamp: new Date().toISOString()
  });
};

export const trackSessionEnd = () => {
  const sessionStartTime = parseInt(localStorage.getItem('session_start_time') || '0');
  if (sessionStartTime) {
    const sessionDuration = Math.floor((Date.now() - sessionStartTime) / 1000);
    
    logEvent(analytics, 'session_end', {
      duration: sessionDuration,
      timestamp: new Date().toISOString()
    });
  }
};

// Helper function to check overall onboarding progress
const checkOnboardingProgress = () => {
  // Get all onboarding events from localStorage
  const onboardingEvents = Object.values(ONBOARDING_MILESTONES)
    .filter(milestone => milestone !== ONBOARDING_MILESTONES.SIGNUP && milestone !== ONBOARDING_MILESTONES.ONBOARDING_COMPLETE)
    .map(milestone => localStorage.getItem(milestone))
    .filter(Boolean);

  // If user has completed most key actions (e.g., 3 out of 4 core features)
  if (onboardingEvents.length >= 3 && !localStorage.getItem(ONBOARDING_MILESTONES.ONBOARDING_COMPLETE)) {
    const signupTime = parseInt(localStorage.getItem('signup_timestamp') || '0');
    
    logEvent(analytics, ONBOARDING_MILESTONES.ONBOARDING_COMPLETE, {
      time_to_complete: getTimeSinceSignup(signupTime),
      completed_actions: onboardingEvents.length
    });

    localStorage.setItem(ONBOARDING_MILESTONES.ONBOARDING_COMPLETE, 'true');
  }
};

// Enhanced analytics initialization
export const initAnalyticsTracking = () => {
  // Track first_open event
  logEvent(analytics, 'first_open');
  
  // Start session tracking
  trackSessionStart();
  
  // Set up session end tracking
  window.addEventListener('beforeunload', () => {
    trackSessionEnd();
  });
  
  // Track page visibility changes
  document.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'hidden') {
      trackSessionEnd();
    } else if (document.visibilityState === 'visible') {
      trackSessionStart();
    }
  });
}; 