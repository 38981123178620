import React, { useState } from 'react';
import { Plus } from 'lucide-react';
import type { ChecklistItem as ChecklistItemType } from '../../types/checklist';
import ChecklistItem from './ChecklistItem';

interface ChecklistItemsContainerProps {
  items: ChecklistItemType[];
  onAddItem: (text: string) => Promise<void>;
  onToggleItem: (id: string) => Promise<void>;
  onUpdateItem: (id: string, updates: Partial<ChecklistItemType>) => Promise<void>;
  onDeleteItem: (id: string) => Promise<void>;
  emptyStateMessage?: string;
  allowEditing?: boolean;
  addButtonLabel?: string;
  addPlaceholder?: string;
  compact?: boolean;
}

export default function ChecklistItemsContainer({
  items,
  onAddItem,
  onToggleItem,
  onUpdateItem,
  onDeleteItem,
  emptyStateMessage = "No items in this checklist yet.",
  allowEditing = true,
  addButtonLabel = "Add",
  addPlaceholder = "Add new item...",
  compact = false
}: ChecklistItemsContainerProps) {
  const [newItemText, setNewItemText] = useState('');
  const [isAddingItem, setIsAddingItem] = useState(false);

  const handleAddItem = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!newItemText.trim()) return;
    
    setIsAddingItem(true);
    try {
      await onAddItem(newItemText.trim());
      setNewItemText('');
    } finally {
      setIsAddingItem(false);
    }
  };

  return (
    <div className="space-y-6">
      {/* Add Item Form */}
      <form onSubmit={handleAddItem} className="mb-6">
        <div className="flex gap-2">
          <input
            type="text"
            value={newItemText}
            onChange={(e) => setNewItemText(e.target.value)}
            placeholder={addPlaceholder}
            className="flex-1 bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500"
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                if (newItemText.trim()) {
                  handleAddItem(e);
                }
              }
            }}
          />
          <button
            type="submit"
            disabled={!newItemText.trim() || isAddingItem}
            className="flex items-center gap-2 px-4 py-2 bg-primary-500 text-white rounded-md hover:bg-primary-600 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <Plus className="w-4 h-4" />
            {addButtonLabel}
          </button>
        </div>
      </form>

      {/* Items List */}
      <div className="space-y-2">
        {items.length === 0 ? (
          <p className="text-gray-400 text-center py-4">{emptyStateMessage}</p>
        ) : (
          items.map((item) => (
            <ChecklistItem
              key={item.id}
              item={item}
              onToggle={onToggleItem}
              onUpdate={onUpdateItem}
              onDelete={onDeleteItem}
              allowEditing={allowEditing}
              compact={compact}
            />
          ))
        )}
      </div>
    </div>
  );
} 