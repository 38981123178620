import React, { useState, useEffect, useRef } from 'react';
import { Camera, Loader2 } from 'lucide-react';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { useAuth } from '../../contexts/AuthContext';
import ImageUploader from './ImageUploader';
import { toast } from 'react-hot-toast';

interface VehicleImageUploadButtonProps {
  vehicleId: string;
  currentImage?: string;
  onImageUpdated: (imageUrl: string) => void;
  onUploaderStateChange?: (isActive: boolean) => void;
}

export default function VehicleImageUploadButton({ 
  vehicleId, 
  currentImage,
  onImageUpdated,
  onUploaderStateChange
}: VehicleImageUploadButtonProps) {
  const { programId } = useAuth();
  const [showUploader, setShowUploader] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);

  // Notify parent component when uploader state changes
  useEffect(() => {
    if (onUploaderStateChange) {
      onUploaderStateChange(showUploader);
    }
    
    // Add event listener to handle escape key to close modal
    const handleEscKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && showUploader && !isDragging) {
        setShowUploader(false);
      }
    };
    
    if (showUploader) {
      document.addEventListener('keydown', handleEscKey);
      // Prevent scrolling on the body when modal is open
      document.body.style.overflow = 'hidden';
      
      // Add global mouse event listeners to detect dragging
      document.addEventListener('mousedown', handleGlobalMouseDown);
      document.addEventListener('mouseup', handleGlobalMouseUp);
    }
    
    // Cleanup function
    return () => {
      document.removeEventListener('keydown', handleEscKey);
      document.body.style.overflow = '';
      document.removeEventListener('mousedown', handleGlobalMouseDown);
      document.removeEventListener('mouseup', handleGlobalMouseUp);
    };
  }, [showUploader, onUploaderStateChange, isDragging]);

  // Track mouse events to detect dragging
  const handleGlobalMouseDown = (e: MouseEvent) => {
    // Check if the mousedown is on a crop handle or the image
    const target = e.target as HTMLElement;
    if (target.classList.contains('ReactCrop__drag-handle') || 
        target.classList.contains('ReactCrop__crop-selection') ||
        target.tagName.toLowerCase() === 'img') {
      setIsDragging(true);
    }
  };

  const handleGlobalMouseUp = () => {
    // Add a small delay to ensure the click event is processed before resetting isDragging
    setTimeout(() => {
      setIsDragging(false);
    }, 100);
  };

  const handleImageUploaded = async (imageUrl: string) => {
    if (!programId) {
      setError('User session not found. Please try logging in again.');
      return;
    }
    
    try {
      setIsUpdating(true);
      setError(null);
      
      // Update the vehicle document in Firestore
      const vehicleRef = doc(db, 'programs', programId, 'vehicles', vehicleId);
      await updateDoc(vehicleRef, {
        image: imageUrl,
        updatedAt: new Date().toISOString()
      });
      
      // Update the local state via callback
      onImageUpdated(imageUrl);
      
      // Close the uploader
      setShowUploader(false);
      
      // Show success toast
      toast.success('Vehicle image updated successfully');
    } catch (error) {
      console.error('Error updating vehicle image:', error);
      setError('Failed to update vehicle image. Please try again.');
      toast.error('Failed to update vehicle image. Please try again.');
    } finally {
      setIsUpdating(false);
    }
  };

  // Handle modal close
  const handleCloseModal = (e?: React.MouseEvent) => {
    // Don't close if we're in the middle of a drag operation
    if (isDragging) return;
    
    // Ensure the click doesn't propagate to the card
    if (e) e.stopPropagation();
    setShowUploader(false);
    setError(null);
  };

  return (
    <>
      <button
        onClick={(e) => {
          // Make sure to stop propagation to prevent card click
          e.stopPropagation();
          e.preventDefault();
          setShowUploader(true);
          setError(null);
        }}
        className="absolute top-3 right-3 p-2.5 bg-dark-200/80 backdrop-blur-sm rounded-full hover:bg-primary-500 hover:scale-110 text-white transition-all duration-200 z-10 shadow-md"
        title="Upload vehicle image"
        aria-label="Upload vehicle image"
      >
        {isUpdating ? (
          <Loader2 className="h-5 w-5 animate-spin" />
        ) : (
          <Camera className="h-5 w-5" />
        )}
      </button>
      
      {showUploader && (
        <div 
          className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50"
          onClick={handleCloseModal} // Close when clicking the backdrop (if not dragging)
          role="dialog"
          aria-modal="true"
          aria-labelledby="vehicle-image-uploader"
        >
          <div 
            ref={modalRef}
            onClick={(e) => e.stopPropagation()} 
            className="w-full max-w-2xl"
            role="document"
          >
            <ImageUploader
              vehicleId={vehicleId}
              currentImage={currentImage}
              onImageUploaded={handleImageUploaded}
              onCancel={handleCloseModal}
              onDragStateChange={setIsDragging}
            />
            {error && (
              <div className="mt-4 p-3 bg-red-500/20 border border-red-500/50 rounded-lg text-red-200 text-center">
                {error}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
} 