import { analytics } from '../config/firebase';
import { logEvent } from 'firebase/analytics';

interface PerformanceMetric {
  name: string;
  value: number;
  unit: 'ms' | 'bytes' | 'percent' | 'count';
  context?: Record<string, any>;
}

/**
 * Track a performance metric in Firebase Analytics
 */
export function trackPerformanceMetric(metric: PerformanceMetric) {
  logEvent(analytics, 'performance_metric', {
    metric_name: metric.name,
    metric_value: metric.value,
    metric_unit: metric.unit,
    timestamp: new Date().toISOString(),
    ...metric.context
  });
}

/**
 * Measure the execution time of an async function
 */
export async function measureAsyncOperation<T>(
  operationName: string,
  operation: () => Promise<T>,
  context?: Record<string, any>
): Promise<T> {
  const startTime = performance.now();
  try {
    const result = await operation();
    const duration = performance.now() - startTime;
    
    trackPerformanceMetric({
      name: operationName,
      value: Math.round(duration),
      unit: 'ms',
      context
    });
    
    return result;
  } catch (error) {
    const duration = performance.now() - startTime;
    
    trackPerformanceMetric({
      name: `${operationName}_error`,
      value: Math.round(duration),
      unit: 'ms',
      context: {
        ...context,
        error_message: error instanceof Error ? error.message : 'Unknown error'
      }
    });
    
    throw error;
  }
}

/**
 * Measure the execution time of a synchronous function
 */
export function measureOperation<T>(
  operationName: string,
  operation: () => T,
  context?: Record<string, any>
): T {
  const startTime = performance.now();
  try {
    const result = operation();
    const duration = performance.now() - startTime;
    
    trackPerformanceMetric({
      name: operationName,
      value: Math.round(duration),
      unit: 'ms',
      context
    });
    
    return result;
  } catch (error) {
    const duration = performance.now() - startTime;
    
    trackPerformanceMetric({
      name: `${operationName}_error`,
      value: Math.round(duration),
      unit: 'ms',
      context: {
        ...context,
        error_message: error instanceof Error ? error.message : 'Unknown error'
      }
    });
    
    throw error;
  }
}

/**
 * Track memory usage
 */
export function trackMemoryUsage() {
  if ('memory' in performance) {
    const memory = (performance as any).memory;
    trackPerformanceMetric({
      name: 'memory_usage',
      value: memory.usedJSHeapSize,
      unit: 'bytes',
      context: {
        total_heap: memory.totalJSHeapSize,
        heap_limit: memory.jsHeapSizeLimit
      }
    });
  }
}

/**
 * Track page load performance metrics
 */
export function trackPageLoadMetrics() {
  if ('getEntriesByType' in performance) {
    const navigationEntry = performance.getEntriesByType('navigation')[0] as PerformanceNavigationTiming;
    
    // Time to First Byte (TTFB)
    trackPerformanceMetric({
      name: 'ttfb',
      value: navigationEntry.responseStart - navigationEntry.requestStart,
      unit: 'ms'
    });
    
    // DOM Content Loaded
    trackPerformanceMetric({
      name: 'dom_content_loaded',
      value: navigationEntry.domContentLoadedEventEnd - navigationEntry.domContentLoadedEventStart,
      unit: 'ms'
    });
    
    // Page Load Time
    trackPerformanceMetric({
      name: 'page_load',
      value: navigationEntry.loadEventEnd - navigationEntry.loadEventStart,
      unit: 'ms'
    });
  }
}

/**
 * Track network request performance
 */
export function trackNetworkPerformance() {
  if ('getEntriesByType' in performance) {
    const resources = performance.getEntriesByType('resource');
    
    resources.forEach(resource => {
      const entry = resource as PerformanceResourceTiming;
      
      trackPerformanceMetric({
        name: 'resource_load',
        value: entry.duration,
        unit: 'ms',
        context: {
          resource_type: entry.initiatorType,
          resource_name: entry.name
        }
      });
    });
  }
}

/**
 * Initialize performance tracking
 */
export function initPerformanceTracking() {
  // Track initial page load
  window.addEventListener('load', () => {
    trackPageLoadMetrics();
    trackNetworkPerformance();
  });
  
  // Track memory usage periodically
  if ('memory' in performance) {
    setInterval(trackMemoryUsage, 60000); // Every minute
  }
  
  // Clear performance entries periodically to prevent memory buildup
  if ('clearResourceTimings' in performance) {
    setInterval(() => performance.clearResourceTimings(), 300000); // Every 5 minutes
  }
} 