import React from 'react';
import { Calendar } from 'lucide-react';
import { Link } from 'react-router-dom';
import type { EventCostsProps } from './types';

export default function EventCosts({ eventRelatedItems }: EventCostsProps) {
  return (
    <div className="bg-gradient-to-br from-dark-200 to-dark-300 rounded-2xl p-6 shadow-xl">
      <div className="flex items-center justify-between mb-6">
        <h3 className="text-xl font-semibold text-white">Event Costs</h3>
        {eventRelatedItems.length > 0 && (
          <div className="text-sm text-gray-400">
            Total: <span className="font-display font-light">${eventRelatedItems.reduce((sum, item) => sum + (item.amount * item.quantity), 0).toLocaleString()}</span>
          </div>
        )}
      </div>
      {eventRelatedItems.length === 0 ? (
        <div className="text-center py-12">
          <Calendar className="w-12 h-12 text-gray-400 mx-auto mb-3" />
          <p className="text-gray-400">No event-related items found</p>
          <p className="text-sm text-gray-500 mt-1">Add expenses or income to your events to track their costs</p>
        </div>
      ) : (
        <div className="space-y-4">
          {eventRelatedItems.map(item => (
            <div 
              key={item.id}
              className="bg-dark-100 rounded-xl overflow-hidden"
            >
              <div className="flex flex-col sm:flex-row sm:items-center justify-between p-4 gap-4">
                <div className="flex items-center gap-4">
                  <div className="w-10 h-10 bg-dark-300 rounded-lg flex items-center justify-center shrink-0">
                    <Calendar className="w-5 h-5 text-primary-500" />
                  </div>
                  <div className="flex-1">
                    <Link 
                      to={`/app/schedule/${item.event?.id}`}
                      className="font-medium text-white hover:text-primary-400"
                    >
                      {item.event?.title || 'Unknown Event'}
                    </Link>
                    <p className="text-sm text-gray-400">
                      {item.event?.date ? new Date(item.event.date).toLocaleDateString() : 'Date not set'}
                    </p>
                  </div>
                </div>
                <div className="flex items-center justify-between sm:block text-right">
                  <div>
                    <p className="font-display font-light text-white">
                      ${(item.amount * item.quantity).toLocaleString()}
                    </p>
                    <p className="text-sm text-gray-400">{item.category}</p>
                  </div>
                </div>
              </div>
              <div className="px-4 pb-4">
                <div className="pt-4 border-t border-dark-300">
                  <p className="text-sm text-gray-400 line-clamp-2">{item.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
} 