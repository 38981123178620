import React from 'react';
import { DollarSign } from 'lucide-react';
import type { TopExpensesProps } from './types';
import type { ExpenseCategory } from '../../types/budget';
import { getCategoryColor } from '../../constants/budget';

export default function TopExpenses({ budget }: TopExpensesProps) {
  const categoryData = React.useMemo(() => {
    const categoryTotals: Record<ExpenseCategory, number> = {} as Record<ExpenseCategory, number>;
    
    budget.expenses.forEach((expense) => {
      const amount = expense.amount * expense.quantity;
      const category = expense.category as ExpenseCategory;
      categoryTotals[category] = (categoryTotals[category] || 0) + amount;
    });

    return Object.entries(categoryTotals)
      .map(([name, value]) => ({ name, value }))
      .sort((a, b) => b.value - a.value);
  }, [budget]);

  return (
    <div className="lg:col-span-4 bg-gradient-to-br from-dark-200 to-dark-300 rounded-2xl p-6 shadow-xl">
      <h3 className="text-xl font-semibold text-white mb-6">Top Expenses</h3>
      {categoryData.length === 0 ? (
        <div className="h-[calc(100%-2rem)] flex flex-col items-center justify-center text-center">
          <DollarSign className="w-12 h-12 text-gray-600 mb-2" />
          <p className="text-gray-400">Add expenses to see your spending breakdown</p>
        </div>
      ) : (
        <div className="space-y-4">
          {categoryData.slice(0, 5).map((category) => (
            <div 
              key={category.name} 
              className="relative bg-dark-100 rounded-lg p-4"
            >
              <div className="flex items-center justify-between mb-2">
                <span className="text-gray-300">{category.name}</span>
                <span className="text-white font-display font-light">${category.value.toLocaleString()}</span>
              </div>
              <div className="h-1 bg-dark-300 rounded-full overflow-hidden">
                <div 
                  className="h-full rounded-full"
                  style={{ 
                    width: `${(category.value / categoryData[0].value) * 100}%`,
                    backgroundColor: getCategoryColor(category.name as ExpenseCategory)
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
} 