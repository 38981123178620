import React from 'react';
import { useDroppable } from '@dnd-kit/core';
import VehicleCard from './VehicleCard';
import type { Vehicle } from '../../types/vehicle';

interface DroppableVehicleCardProps {
  vehicle: Vehicle;
  onClick: () => void;
  onEdit: () => void;
  onDelete: () => void;
  isOver?: boolean;
}

export default function DroppableVehicleCard({ vehicle, onClick, onEdit, onDelete, isOver = false }: DroppableVehicleCardProps) {
  const { setNodeRef, isOver: isDragOver } = useDroppable({
    id: vehicle.id,
    data: {
      type: 'vehicle',
      vehicle
    }
  });

  // Combine the isOver prop with the isDragOver state
  const isHighlighted = isOver || isDragOver;

  return (
    <div
      ref={setNodeRef}
      className={`transition-all duration-300 ${
        isHighlighted ? 'scale-105 shadow-xl ring-2 ring-primary-500' : ''
      }`}
    >
      <VehicleCard
        vehicle={vehicle}
        onClick={onClick}
        onEdit={onEdit}
        onDelete={onDelete}
        isDropTarget={isHighlighted}
      />
    </div>
  );
} 