// Map of part categories/subcategories to icon filenames
export const PART_ICONS: Record<string, string> = {
  // Categories
  'Brakes': 'Disc Brake.png',
  'Tires': 'Tire.png',
  'Engine': 'Car Engine.png',
  'Suspension': 'Car Suspension.png',
  'Transmission': 'Car Engine.png', // Using engine icon as placeholder
  'Exterior': 'Bumper.png',
  'Interior': 'Seat.png',
  'Electrical': 'Fuses.png',
  'Cooling': 'Radiator.png',
  'Fuel System': 'Gasoline.png',
  'Exhaust': 'Exhaust Pipe.png',
  'Drivetrain': 'Chains.png',
  'Steering': 'Steering Wheel.png',
  'Other': 'Screw.png',
  
  // Subcategories with specific icons
  'Oil': 'Oil Barrel.png',
  'Turbo': 'Turbo.png',
  'Aero': 'Aero.png',
  'Seats': 'Seat.png',
  'Spark Plugs': 'Spark Plug.png',
  'Coilovers': 'Damper.png',
  'Shocks': 'Damper.png',
  'Gauges': 'Tachometer.png',
  'Engine Management': 'Tachometer.png',
  'Electronics': 'Tachometer.png',
  'Pads': 'Disc Brake.png',
  'Rotors': 'Disc Brake.png',
  'Calipers': 'Disc Brake.png',
  'Radiator': 'Radiator.png',
  'Oil Cooler': 'Radiator.png',
  'Intercooler': 'Radiator.png',
  'Battery': 'Battery.png',
  'Wheels': 'Wheel.png',
  'Belts': 'Chains.png',
  'Timing Components': 'Chains.png',
  'Roll Cage': 'Roll Cage.png',
  'Alignment Parts': 'Car Jack.png',
  'Filter': 'Air Filter.png',
  'Intake': 'Air Filter.png',
  'Shifter': 'Shifter.png',
  'Wiring': 'Fuses.png',
  'Sensors': 'Fuses.png',
  'Alternator': 'Battery.png',
  'Starter': 'Battery.png',
  'Fluid': 'Oil Barrel.png',
};

// Helper function to get the appropriate icon for a part
export function getPartIcon(category: string, subcategory?: string): string {
  // Try to match by subcategory first
  if (subcategory && PART_ICONS[subcategory]) {
    return PART_ICONS[subcategory];
  }
  // Fall back to category
  return PART_ICONS[category] || 'Screw.png';
} 