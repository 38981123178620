export const EXPENSE_CATEGORY_ICONS = {
  'Entry Fees': '🏁',
  'Travel & Lodging': '🏨',
  'Fuel & Oil': '⛽',
  'Tires': '🛞',
  'Parts & Repairs': '🔧',
  'Scheduled Maintenance': '🔨',
  'Tools & Equipment': '🛠️',
  'Safety Gear': '⛑️',
  'Marketing & Sponsorship': '📢',
  'Insurance': '🛡️',
  'Other': '📦'
} as const;

export const EXPENSE_CATEGORY_COLORS = {
  'Entry Fees': '#3B82F6',
  'Travel & Lodging': '#10B981',
  'Fuel & Oil': '#F59E0B',
  'Tires': '#6366F1',
  'Parts & Repairs': '#EF4444',
  'Scheduled Maintenance': '#8B5CF6',
  'Tools & Equipment': '#EC4899',
  'Safety Gear': '#F97316',
  'Marketing & Sponsorship': '#14B8A6',
  'Insurance': '#6B7280',
  'Other': '#9CA3AF'
} as const;

export const INCOME_CATEGORY_ICONS = {
  'Sponsorship': '🤝',
  'Prize Money': '🏆',
  'Contingency': '💰',
  'Personal Funds': '👤',
  'Other': '📦'
} as const; 