import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { ArrowLeft, Calendar, MapPin, Car, Clock, Edit2, X, Check, ChevronDown, ChevronUp, Trash2, Link, Info, DollarSign, Plus, ClipboardList } from 'lucide-react';
import { format, differenceInDays, parseISO } from 'date-fns';
import type { Event, EventType, EventStatus, EventWithImport } from '../types/event';
import type { BudgetItem, ExpenseCategory, Budget } from '../types/budget';
import { useAuth } from '../contexts/AuthContext';
import { toast } from 'react-hot-toast';
import { doc, deleteDoc, updateDoc, collection, addDoc, getDocs, query, where, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../config/firebase';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { parseEventContent } from '../services/aiService';
import { updateEvent } from '../utils/events';
import { BudgetItemModal } from '../components/budget/BudgetItemModal';
import EventChecklist from '../components/checklists/EventChecklist';
import PlaceSearch from '../components/events/PlaceSearch';
import WeatherForecast from '../components/events/WeatherForecast';
import { trackApiError, trackDataError, ErrorCategory, ErrorSeverity, trackError } from '../services/errorTracking.tsx';

interface LocationState {
  isNew?: boolean;
  date?: string;
}

interface EventDetailProps {
  events: Event[];
  onUpdateEvent: (event: Event) => Promise<void>;
}

// Add type definitions for the budget data
interface BudgetData {
  expenses: BudgetItem[];
  income: BudgetItem[];
  updatedAt: string;
}

export default function EventDetail({ events, onUpdateEvent }: EventDetailProps) {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { programId } = useAuth();
  const [budgetItems, setBudgetItems] = useState<BudgetItem[]>([]);
  const [isBudgetModalOpen, setIsBudgetModalOpen] = useState(false);
  const [isLoadingBudget, setIsLoadingBudget] = useState(false);
  const [editingItem, setEditingItem] = useState<BudgetItem | undefined>(undefined);
  
  // Type-safe access to location state
  const locationState = location.state as LocationState | null;
  
  // For new events, create a default event with the passed date or current date
  const today = new Date().toISOString().split('T')[0];
  const defaultEvent: Event = {
    title: '',
    type: 'race',
    status: 'confirmed',
    date: locationState?.date || today,
    location: undefined,
    taskIds: [],
    checklistIds: []
  };

  // Use defaultEvent if we're creating a new event, otherwise find the existing event
  const event = locationState?.isNew === true ? defaultEvent : events.find(e => e.id === id);
  const [isEditing, setIsEditing] = useState(locationState?.isNew === true);
  const [showExpenses, setShowExpenses] = useState(false);
  const [editedEvent, setEditedEvent] = useState<EventWithImport>(() => {
    const baseEvent = locationState?.isNew === true ? defaultEvent : event || defaultEvent;
    // Convert string location to undefined if it exists (for backward compatibility)
    return {
      ...baseEvent,
      location: typeof baseEvent.location === 'string' ? undefined : baseEvent.location
    };
  });
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showUrlImport, setShowUrlImport] = useState(false);
  const [importUrl, setImportUrl] = useState('');
  const [isImporting, setIsImporting] = useState(false);
  const [importError, setImportError] = useState<string | null>(null);
  const functions = getFunctions();

  // Update editedEvent when the source event changes
  React.useEffect(() => {
    if (locationState?.isNew !== true && event) {
      setEditedEvent(event);
    }
  }, [event, locationState?.isNew]);

  // Fetch budget items associated with this event
  useEffect(() => {
    const fetchBudgetItems = async () => {
      if (!programId || !event?.id) return;
      
      setIsLoadingBudget(true);
      try {
        const budgetRef = doc(db, 'programs', programId, 'budget', 'main');
        const budgetSnap = await getDoc(budgetRef);
        
        if (budgetSnap.exists()) {
          const budget = budgetSnap.data();
          // Filter expenses and income for this event
          const eventItems = [
            ...(budget.expenses || []),
            ...(budget.income || [])
          ].filter(item => item.eventId === event.id);
          
          setBudgetItems(eventItems);
        }
      } catch (error) {
        console.error('Error fetching budget items:', error);
        toast.error('Failed to load budget items');
      } finally {
        setIsLoadingBudget(false);
      }
    };

    fetchBudgetItems();
  }, [programId, event?.id]);

  const handleAddBudgetItem = async (newItem: BudgetItem) => {
    if (!programId || !event?.id) return;

    try {
      const budgetRef = doc(db, 'programs', programId, 'budget', 'main');
      const budgetSnap = await getDoc(budgetRef);
      
      if (!budgetSnap.exists()) {
        throw new Error('Budget not found');
      }

      const budget = budgetSnap.data();
      const isExpense = [
        'Entry Fees',
        'Travel & Lodging',
        'Fuel & Oil',
        'Tires',
        'Parts & Repairs',
        'Scheduled Maintenance',
        'Tools & Equipment',
        'Safety Gear',
        'Marketing & Sponsorship',
        'Insurance',
        'Other'
      ].includes(newItem.category);

      // Clean up the item object to remove undefined values
      const cleanItem: BudgetItem = {
        id: newItem.id || crypto.randomUUID(),
        category: newItem.category || (isExpense ? 'Entry Fees' : 'Sponsorship'),
        description: newItem.description || '',
        amount: newItem.amount,
        quantity: newItem.quantity || 1,
        frequency: newItem.frequency || 'one-time',
        eventId: event.id,
        createdAt: newItem.createdAt || new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        ...(newItem.partId && { partId: newItem.partId })
      };

      const updatedBudget = {
        ...budget,
        [isExpense ? 'expenses' : 'income']: editingItem
          ? budget[isExpense ? 'expenses' : 'income'].map((item: BudgetItem) => 
              item.id === editingItem.id ? cleanItem : item
            )
          : [...(budget[isExpense ? 'expenses' : 'income'] || []), cleanItem],
        updatedAt: new Date().toISOString()
      };
      
      await setDoc(budgetRef, updatedBudget);
      setBudgetItems(prev => 
        editingItem 
          ? prev.map(item => item.id === editingItem.id ? cleanItem : item)
          : [...prev, cleanItem]
      );
      setIsBudgetModalOpen(false);
      setEditingItem(undefined);
      toast.success(`${editingItem ? 'Updated' : 'Added'} budget item successfully`);
    } catch (error) {
      console.error('Error adding budget item:', error);
      toast.error(`Failed to ${editingItem ? 'update' : 'add'} budget item`);
    }
  };

  const handleEditBudgetItem = (item: BudgetItem) => {
    setEditingItem(item);
    setIsBudgetModalOpen(true);
  };

  const handleDeleteBudgetItem = async (itemId: string) => {
    if (!programId || !event?.id) return;

    try {
      const budgetRef = doc(db, 'programs', programId, 'budget', 'main');
      const budgetSnap = await getDoc(budgetRef);
      
      if (!budgetSnap.exists()) {
        throw new Error('Budget not found');
      }

      const budget = budgetSnap.data();
      
      // Remove from both arrays since we don't know which one it's in
      const updatedBudget = {
        ...budget,
        expenses: (budget.expenses || []).filter((item: BudgetItem) => item.id !== itemId),
        income: (budget.income || []).filter((item: BudgetItem) => item.id !== itemId),
        updatedAt: new Date().toISOString()
      };
      
      await setDoc(budgetRef, updatedBudget);
      setBudgetItems(prev => prev.filter(item => item.id !== itemId));
      toast.success('Budget item deleted successfully');
    } catch (error) {
      console.error('Error deleting budget item:', error);
      toast.error('Failed to delete budget item');
    }
  };

  // Only show "Event not found" if we're looking for an existing event and can't find it
  if (locationState?.isNew !== true && !event) {
    return (
      <div className="p-6">
        <div className="text-center text-gray-400">Event not found</div>
      </div>
    );
  }

  const handleStartEdit = () => {
    console.log('Starting edit with event:', event);
    // Convert string location to undefined if it exists (for backward compatibility)
    const eventToEdit = event || defaultEvent;
    setEditedEvent({
      ...eventToEdit,
      location: typeof eventToEdit.location === 'string' ? undefined : eventToEdit.location
    });
    setIsEditing(true);
  };

  const handleCancelEdit = () => {
    if (locationState?.isNew === true) {
      navigate('/app/schedule');
    } else {
      setEditedEvent(event || defaultEvent);
      setIsEditing(false);
    }
  };

  const handleSaveEdit = async () => {
    if (!programId) return;

    try {
      // Create a clean version of the event without the confidence data
      const { importConfidence, ...cleanEvent } = editedEvent;
      
      // Ensure all required fields are present
      const eventToSave = {
        ...cleanEvent,
        title: cleanEvent.title || '',
        type: cleanEvent.type || 'race',
        status: cleanEvent.status || 'planned',
        date: cleanEvent.date || new Date().toISOString().split('T')[0],
        taskIds: cleanEvent.taskIds || [],
        checklistIds: cleanEvent.checklistIds || []
      };

      console.log('Saving event with data:', eventToSave);
      
      // Use the onUpdateEvent prop for both create and update
      await onUpdateEvent(eventToSave);

      toast.success(locationState?.isNew === true ? 'Event created successfully' : 'Event updated successfully');
      
      // Instead of waiting on the page, immediately navigate back to schedule
      navigate('/app/schedule');
    } catch (error) {
      console.error('Error saving event:', error);
      toast.error(locationState?.isNew === true ? 'Failed to create event' : 'Failed to update event');
    }
  };

  const handleDelete = async () => {
    if (!programId || !event?.id) return;

    try {
      // First get the budget to find items associated with this event
      const budgetRef = doc(db, 'programs', programId, 'budget', 'main');
      const budgetSnap = await getDoc(budgetRef);
      
      if (budgetSnap.exists()) {
        const budget = budgetSnap.data();
        
        // Filter out any items associated with this event
        const updatedBudget = {
          ...budget,
          expenses: budget.expenses.filter((item: BudgetItem) => item.eventId !== event.id),
          income: budget.income.filter((item: BudgetItem) => item.eventId !== event.id),
          updatedAt: new Date().toISOString()
        };

        // Update budget and delete event in parallel
        await Promise.all([
          setDoc(budgetRef, updatedBudget),
          deleteDoc(doc(db, 'programs', programId, 'events', event.id))
        ]);
      } else {
        // If no budget exists (edge case), just delete the event
        await deleteDoc(doc(db, 'programs', programId, 'events', event.id));
      }

      toast.success('Event deleted successfully');
      navigate('/app/schedule');
    } catch (error: any) {
      trackError(error, {
        category: ErrorCategory.DATA,
        severity: ErrorSeverity.P2,
        action: 'delete_event',
        additionalData: {
          eventId: event.id,
          programId
        }
      });
      console.error('Error deleting event:', error);
      toast.error('Failed to delete event');
    }
  };

  const handleImportFromUrl = async () => {
    if (!importUrl.trim()) {
      setImportError('Please enter a URL');
      return;
    }

    setIsImporting(true);
    setImportError(null);
    try {
      // First, get the raw content from the URL using our cloud function
      const parseEventUrl = httpsCallable(functions, 'parseEventUrl');
      const result = await parseEventUrl({ url: importUrl.trim() });
      const { rawContent } = result.data as any;

      // Then, use our AI service to parse the content
      const parsedContent = await parseEventContent(rawContent);

      // Track which fields were actually found by the AI
      const missingFields = [];
      const lowConfidenceFields = new Map();
      const veryLowConfidenceFields = new Map();

      // Check for missing fields and confidence levels
      if (!parsedContent.date) {
        missingFields.push('dates');
      } else if (parsedContent.confidence.dates < 0.05) {
        veryLowConfidenceFields.set('dates', parsedContent.confidence.dates);
      } else if (parsedContent.confidence.dates < 0.7) {
        lowConfidenceFields.set('dates', parsedContent.confidence.dates);
      }

      if (!parsedContent.location) {
        missingFields.push('location');
      } else if (parsedContent.confidence.location < 0.05) {
        veryLowConfidenceFields.set('location', parsedContent.confidence.location);
      } else if (parsedContent.confidence.location < 0.7) {
        lowConfidenceFields.set('location', parsedContent.confidence.location);
      }

      if (!parsedContent.title) {
        missingFields.push('title');
      } else if (parsedContent.confidence.title < 0.05) {
        veryLowConfidenceFields.set('title', parsedContent.confidence.title);
      } else if (parsedContent.confidence.title < 0.7) {
        lowConfidenceFields.set('title', parsedContent.confidence.title);
      }

      if (!parsedContent.type) {
        missingFields.push('type');
      } else if (parsedContent.confidence.type < 0.05) {
        veryLowConfidenceFields.set('type', parsedContent.confidence.type);
      } else if (parsedContent.confidence.type < 0.7) {
        lowConfidenceFields.set('type', parsedContent.confidence.type);
      }

      // Update the edited event with the parsed data and confidence info
      setEditedEvent({
        ...editedEvent,
        title: parsedContent.title || editedEvent.title,
        description: parsedContent.description || editedEvent.description,
        date: parsedContent.date ? new Date(parsedContent.date).toISOString().split('T')[0] : editedEvent.date,
        // Only set location if it's an EventLocation object
        ...(parsedContent.location && typeof parsedContent.location === 'object' ? { location: parsedContent.location } : {}),
        type: (parsedContent.type as EventType) || editedEvent.type,
        importSource: importUrl.trim(),
        importConfidence: {
          missingFields,
          veryLowConfidenceFields: Object.fromEntries(veryLowConfidenceFields),
          lowConfidenceFields: Object.fromEntries(lowConfidenceFields)
        }
      });

      setShowUrlImport(false);
      toast.success('Event details imported from URL');

    } catch (error: any) {
      trackError(error, {
        category: ErrorCategory.API,
        severity: ErrorSeverity.P2,
        action: 'import_event_url',
        additionalData: {
          url: importUrl,
          error_details: error.details || {}
        }
      });
      
      // Set appropriate error message based on the error type
      if (error.message?.includes('NO_EVENT')) {
        setImportError('No event found on this page. Please make sure the URL points to a specific event.');
      } else if (error.message?.includes('MULTIPLE_EVENTS')) {
        setImportError('Multiple events found on this page. Please provide a URL that points to a single specific event.');
      } else if (error.message?.includes('CONTENT_TOO_LARGE')) {
        setImportError('This page contains too much content to process. Please try a more specific event URL.');
      } else {
        setImportError(error.details?.originalError || error.message || 'Failed to import event details');
      }
    } finally {
      setIsImporting(false);
    }
  };

  // Helper function to clean up hostname display
  const getDisplayHostname = (url: string) => {
    const hostname = new URL(url).hostname;
    return hostname.replace(/^www\./i, '');
  };

  return (
    <>
      <div className="space-y-6">
        {/* Header */}
        <div className="flex flex-col gap-4">
          {/* Title Section */}
          <div className="flex items-start gap-4 w-full">
            <button
              onClick={() => navigate('/app/schedule')}
              className="p-2 text-gray-400 hover:text-primary-500 rounded-lg hover:bg-dark-50 shrink-0"
            >
              <ArrowLeft className="w-5 h-5" />
            </button>
            <div className="flex-1 min-w-0">
              <h1 className="text-2xl font-bold text-white">Event Details</h1>
            </div>
          </div>

          {/* Action Buttons */}
          <div className="flex flex-col sm:flex-row items-stretch sm:items-center gap-2 w-full sm:w-auto sm:ml-auto">
            {isEditing ? (
              <>
                <button
                  onClick={() => setShowUrlImport(true)}
                  className="flex items-center justify-center gap-2 px-4 py-2 bg-dark-100 text-primary-500 rounded-md hover:bg-dark-50 sm:order-1"
                >
                  <Link className="w-5 h-5" />
                  Import from URL
                </button>
                <button
                  onClick={handleCancelEdit}
                  className="flex items-center justify-center gap-2 px-4 py-2 bg-dark-100 text-white rounded-md hover:bg-dark-50 sm:order-2"
                >
                  <X className="w-5 h-5" />
                  Cancel
                </button>
                <button
                  onClick={handleSaveEdit}
                  className="flex items-center justify-center gap-2 px-4 py-2 bg-primary-500 text-white rounded-md hover:bg-primary-600 sm:order-3"
                >
                  <Check className="w-5 h-5" />
                  {locationState?.isNew === true ? 'Create Event' : 'Save Changes'}
                </button>
              </>
            ) : (
              <>
                {!locationState?.isNew && (
                  <button
                    onClick={() => setShowDeleteConfirm(true)}
                    className="flex items-center justify-center gap-2 px-4 py-2 bg-dark-100 text-red-500 rounded-md hover:bg-dark-50"
                  >
                    <Trash2 className="w-5 h-5" />
                    Delete Event
                  </button>
                )}
                <button
                  onClick={handleStartEdit}
                  className="flex items-center justify-center gap-2 px-4 py-2 bg-dark-100 text-white rounded-md hover:bg-dark-50"
                >
                  <Edit2 className="w-5 h-5" />
                  Edit Event
                </button>
              </>
            )}
          </div>
        </div>

        {/* Main Content */}
        <div className="space-y-6">
          {/* Import Summary Message - Only show during initial import */}
          {editedEvent.importSource && isEditing && locationState?.isNew && (
            <div className="bg-dark-300 rounded-lg p-4 border border-primary-500/20">
              <div className="flex items-start gap-3">
                <div className="text-primary-500 mt-0.5">
                  <Info className="w-5 h-5" />
                </div>
                <div>
                  <p className="text-white mb-1">Hey there! We grabbed what we could from {getDisplayHostname(editedEvent.importSource)}</p>
                  <p className="text-gray-400 text-sm">
                    Keep an eye out for the <Info className="w-3.5 h-3.5 inline-block text-primary-500 mx-1" /> icon – 
                    those fields might need a quick check to make sure everything's spot on.
                  </p>
                </div>
              </div>
            </div>
          )}

          {/* Event Details */}
          <div className="bg-dark-200 rounded-lg p-6">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
              {/* Event Title */}
              <div className="col-span-1 sm:col-span-2">
                <label className="block text-sm text-gray-400 mb-2 flex items-center gap-2">
                  Event Title {isEditing && locationState?.isNew && <span className="text-primary-500">*</span>}
                  {editedEvent.importConfidence?.missingFields.includes('title') && (
                    <span className="text-red-500 text-xs flex items-center gap-1">
                      <Info className="w-3.5 h-3.5" />
                      Please set this
                    </span>
                  )}
                  {editedEvent.importConfidence?.veryLowConfidenceFields?.title !== undefined && (
                    <span className="text-orange-500 text-xs flex items-center gap-1">
                      <Info className="w-3.5 h-3.5" />
                      Double-check this
                    </span>
                  )}
                  {editedEvent.importConfidence?.lowConfidenceFields?.title !== undefined && (
                    <span className="text-yellow-500 text-xs flex items-center gap-1">
                      <Info className="w-3.5 h-3.5" />
                      Worth a quick check
                    </span>
                  )}
                </label>
                {isEditing ? (
                  <input
                    type="text"
                    value={editedEvent.title}
                    onChange={(e) => {
                      const newEvent = { ...editedEvent };
                      newEvent.title = e.target.value;
                      // Clear any warnings for title
                      if (newEvent.importConfidence) {
                        newEvent.importConfidence.missingFields = newEvent.importConfidence.missingFields.filter(f => f !== 'title');
                        delete newEvent.importConfidence.veryLowConfidenceFields.title;
                        delete newEvent.importConfidence.lowConfidenceFields.title;
                      }
                      setEditedEvent(newEvent);
                    }}
                    className={`w-full bg-dark-300 border rounded-md px-3 py-2 text-white ${
                      editedEvent.importConfidence?.missingFields.includes('title')
                        ? 'border-red-500'
                        : editedEvent.importConfidence?.veryLowConfidenceFields?.title !== undefined
                        ? 'border-orange-500'
                        : editedEvent.importConfidence?.lowConfidenceFields?.title !== undefined
                        ? 'border-yellow-500'
                        : 'border-dark-50'
                    }`}
                    placeholder="Enter event title"
                    required
                  />
                ) : (
                  <div className="text-white text-lg font-medium">{editedEvent.title || 'Untitled Event'}</div>
                )}
              </div>

              {/* Event Type */}
              <div>
                <label className="block text-sm text-gray-400 mb-2 flex items-center gap-2">
                  Event Type
                  {editedEvent.importConfidence?.missingFields.includes('type') && (
                    <span className="text-red-500 text-xs flex items-center gap-1">
                      <Info className="w-3.5 h-3.5" />
                      Please set this
                    </span>
                  )}
                  {editedEvent.importConfidence?.veryLowConfidenceFields?.type !== undefined && (
                    <span className="text-orange-500 text-xs flex items-center gap-1">
                      <Info className="w-3.5 h-3.5" />
                      Double-check this
                    </span>
                  )}
                  {editedEvent.importConfidence?.lowConfidenceFields?.type !== undefined && (
                    <span className="text-yellow-500 text-xs flex items-center gap-1">
                      <Info className="w-3.5 h-3.5" />
                      Worth a quick check
                    </span>
                  )}
                </label>
                {isEditing ? (
                  <select
                    value={editedEvent.type}
                    onChange={(e) => {
                      const newEvent = { ...editedEvent };
                      newEvent.type = e.target.value as EventType;
                      // Clear any warnings for this field
                      if (newEvent.importConfidence) {
                        newEvent.importConfidence.missingFields = newEvent.importConfidence.missingFields.filter(f => f !== 'type');
                        delete newEvent.importConfidence.veryLowConfidenceFields.type;
                        delete newEvent.importConfidence.lowConfidenceFields.type;
                      }
                      setEditedEvent(newEvent);
                    }}
                    className={`w-full bg-dark-300 border rounded-md px-3 py-2 text-white ${
                      editedEvent.importConfidence?.missingFields.includes('type')
                        ? 'border-red-500'
                        : editedEvent.importConfidence?.veryLowConfidenceFields?.type !== undefined
                        ? 'border-orange-500'
                        : editedEvent.importConfidence?.lowConfidenceFields?.type !== undefined
                        ? 'border-yellow-500'
                        : 'border-dark-50'
                    }`}
                  >
                    <option value="race">Race</option>
                    <option value="practice">Practice</option>
                    <option value="maintenance">Testing</option>
                    <option value="other">Other</option>
                  </select>
                ) : (
                  <div className="text-white capitalize">{editedEvent.type}</div>
                )}
              </div>

              {/* Event Status */}
              <div>
                <label className="block text-sm text-gray-400 mb-2">Status</label>
                {isEditing ? (
                  <select
                    value={editedEvent.status}
                    onChange={(e) => setEditedEvent({ ...editedEvent, status: e.target.value as EventStatus })}
                    className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white"
                  >
                    <option value="confirmed">Confirmed</option>
                    <option value="considering">Considering</option>
                    <option value="cancelled">Cancelled</option>
                  </select>
                ) : (
                  <div className="text-white capitalize">{editedEvent.status}</div>
                )}
              </div>

              {/* Date */}
              <div className="col-span-1 sm:col-span-2">
                <label className="block text-sm text-gray-400 mb-2 flex items-center gap-2">
                  Event Date {isEditing && locationState?.isNew && <span className="text-primary-500">*</span>}
                  {editedEvent.importConfidence?.missingFields.includes('dates') && (
                    <span className="text-red-500 text-xs flex items-center gap-1">
                      <Info className="w-3.5 h-3.5" />
                      Please set this
                    </span>
                  )}
                  {editedEvent.importConfidence?.veryLowConfidenceFields?.dates !== undefined && (
                    <span className="text-orange-500 text-xs flex items-center gap-1">
                      <Info className="w-3.5 h-3.5" />
                      Double-check this
                    </span>
                  )}
                  {editedEvent.importConfidence?.lowConfidenceFields?.dates !== undefined && (
                    <span className="text-yellow-500 text-xs flex items-center gap-1">
                      <Info className="w-3.5 h-3.5" />
                      Worth a quick check
                    </span>
                  )}
                </label>
                {isEditing ? (
                  <div>
                    <input
                      type="date"
                      value={editedEvent.date}
                      onChange={(e) => {
                        const newEvent = { ...editedEvent, date: e.target.value };
                        // Clear any warnings for dates
                        if (newEvent.importConfidence) {
                          newEvent.importConfidence.missingFields = newEvent.importConfidence.missingFields.filter(f => f !== 'dates');
                          delete newEvent.importConfidence.veryLowConfidenceFields.dates;
                          delete newEvent.importConfidence.lowConfidenceFields.dates;
                        }
                        setEditedEvent(newEvent);
                      }}
                      className={`w-full bg-dark-300 border rounded-md px-3 py-2 text-white [color-scheme:dark] ${
                        editedEvent.importConfidence?.missingFields.includes('dates')
                          ? 'border-red-500'
                          : editedEvent.importConfidence?.veryLowConfidenceFields?.dates !== undefined
                          ? 'border-orange-500'
                          : editedEvent.importConfidence?.lowConfidenceFields?.dates !== undefined
                          ? 'border-yellow-500'
                          : 'border-dark-50'
                      }`}
                      required
                    />
                  </div>
                ) : (
                  <div className="flex items-center gap-2 text-white">
                    <Calendar className="w-4 h-4 text-primary-500" />
                    {format(parseISO(editedEvent.date), 'MMM d, yyyy')}
                  </div>
                )}
              </div>

              {/* Location */}
              <div className="col-span-1 sm:col-span-2">
                <label className="block text-sm text-gray-400 mb-2 flex items-center gap-2">
                  Location
                  {editedEvent.importConfidence?.missingFields.includes('location') && (
                    <span className="text-red-500 text-xs flex items-center gap-1">
                      <Info className="w-3.5 h-3.5" />
                      Please set this
                    </span>
                  )}
                  {editedEvent.importConfidence?.veryLowConfidenceFields?.location !== undefined && (
                    <span className="text-orange-500 text-xs flex items-center gap-1">
                      <Info className="w-3.5 h-3.5" />
                      Double-check this
                    </span>
                  )}
                  {editedEvent.importConfidence?.lowConfidenceFields?.location !== undefined && (
                    <span className="text-yellow-500 text-xs flex items-center gap-1">
                      <Info className="w-3.5 h-3.5" />
                      Worth a quick check
                    </span>
                  )}
                </label>
                <PlaceSearch
                  value={editedEvent.location}
                  onChange={(location) => {
                    const newEvent = { ...editedEvent };
                    newEvent.location = location;
                    // Clear any warnings for location
                    if (newEvent.importConfidence) {
                      newEvent.importConfidence.missingFields = newEvent.importConfidence.missingFields.filter(f => f !== 'location');
                      delete newEvent.importConfidence.veryLowConfidenceFields.location;
                      delete newEvent.importConfidence.lowConfidenceFields.location;
                    }
                    setEditedEvent(newEvent);
                  }}
                  isEditing={isEditing}
                  className={editedEvent.importConfidence?.missingFields.includes('location')
                    ? 'border-red-500'
                    : editedEvent.importConfidence?.veryLowConfidenceFields?.location !== undefined
                    ? 'border-orange-500'
                    : editedEvent.importConfidence?.lowConfidenceFields?.location !== undefined
                    ? 'border-yellow-500'
                    : ''
                  }
                />
              </div>

              {/* Weather Forecast */}
              {!isEditing && editedEvent.location?.latitude && editedEvent.location?.longitude && (
                <div className="col-span-1 sm:col-span-2">
                  <WeatherForecast
                    latitude={editedEvent.location.latitude}
                    longitude={editedEvent.location.longitude}
                    eventDate={editedEvent.date}
                  />
                </div>
              )}

              {/* Description */}
              <div className="col-span-1 sm:col-span-2">
                <label className="block text-sm text-gray-400 mb-2">Description</label>
                {isEditing ? (
                  <textarea
                    value={editedEvent.description || ''}
                    onChange={(e) => setEditedEvent({ ...editedEvent, description: e.target.value })}
                    className="w-full h-32 bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white"
                    placeholder="Add event description..."
                  />
                ) : (
                  <div className="text-white whitespace-pre-wrap">
                    {editedEvent.description || 'No description'}
                  </div>
                )}
              </div>

              {/* Add the AI import disclaimer if this event was imported */}
              {editedEvent.importSource && !isEditing && (
                <div className="col-span-1 sm:col-span-2 mt-4">
                  <a
                    href={editedEvent.importSource}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-flex items-center gap-2 text-primary-500 hover:text-primary-400"
                  >
                    <Link className="w-4 h-4" />
                    View Original Event Page
                  </a>
                </div>
              )}
            </div>
          </div>

          {/* Budget Items Section */}
          <div className={`bg-dark-200 rounded-lg p-6 ${isEditing ? 'opacity-50 pointer-events-none' : ''}`}>
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-lg font-semibold text-white flex items-center gap-2">
                <DollarSign className="w-5 h-5 text-primary-500" />
                Event Budget
              </h2>
              <button
                onClick={() => {
                  setEditingItem(undefined);
                  setIsBudgetModalOpen(true);
                }}
                className="flex items-center gap-2 px-4 py-2 bg-primary-500 text-white rounded-md hover:bg-primary-600"
                disabled={isEditing}
              >
                <Plus className="w-4 h-4" />
                Add Budget Item
              </button>
            </div>

            {isLoadingBudget ? (
              <div className="text-center py-8">
                <div className="w-8 h-8 border-2 border-primary-500 border-t-transparent rounded-full animate-spin mx-auto"></div>
                <p className="text-gray-400 mt-2">Loading budget items...</p>
              </div>
            ) : budgetItems.length === 0 ? (
              <div className="text-center py-8">
                <DollarSign className="w-12 h-12 text-gray-400 mx-auto mb-3" />
                <p className="text-gray-400">No budget items added yet</p>
                <button
                  onClick={() => {
                    setEditingItem(undefined);
                    setIsBudgetModalOpen(true);
                  }}
                  className="mt-4 text-primary-500 hover:text-primary-400"
                  disabled={isEditing}
                >
                  Add your first budget item
                </button>
              </div>
            ) : (
              <div className="overflow-x-auto">
                <table className="w-full">
                  <thead>
                    <tr className="text-left border-b border-dark-50">
                      <th className="pb-2 text-gray-400 font-medium">Category</th>
                      <th className="pb-2 text-gray-400 font-medium">Description</th>
                      <th className="pb-2 text-gray-400 font-medium">Amount</th>
                      <th className="pb-2 text-gray-400 font-medium">Quantity</th>
                      <th className="pb-2 text-gray-400 font-medium">Total</th>
                      <th className="pb-2 text-gray-400 font-medium"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {budgetItems.map((item) => (
                      <tr key={item.id} className="border-b border-dark-50 last:border-0">
                        <td className="py-3 text-white">{item.category}</td>
                        <td className="py-3 text-white">{item.description}</td>
                        <td className="py-3 text-white">${item.amount.toLocaleString()}</td>
                        <td className="py-3 text-white">{item.quantity}</td>
                        <td className="py-3 text-white">
                          ${(item.amount * item.quantity).toLocaleString()}
                        </td>
                        <td className="py-3">
                          <div className="flex items-center gap-2">
                            <button
                              onClick={() => handleEditBudgetItem(item)}
                              className="p-1 text-gray-400 hover:text-white"
                              disabled={isEditing}
                            >
                              <Edit2 className="w-4 h-4" />
                            </button>
                            <button
                              onClick={() => handleDeleteBudgetItem(item.id)}
                              className="p-1 text-gray-400 hover:text-red-500"
                              disabled={isEditing}
                            >
                              <Trash2 className="w-4 h-4" />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>

          {/* Checklist Section */}
          <div className={`bg-dark-200 rounded-lg p-6 ${isEditing ? 'opacity-50 pointer-events-none' : ''}`}>
            <div className="flex items-center gap-2 mb-4">
              <ClipboardList className="w-5 h-5 text-primary-500" />
              <h2 className="text-lg font-semibold text-white">Event Checklist</h2>
            </div>

            {event?.id && !isEditing && (
              <EventChecklist
                eventId={event.id}
                eventTitle={event.title || 'Untitled Event'}
                checklistIds={event.checklistIds}
                onUpdateEvent={async (checklistIds) => {
                  if (!programId) return;
                  
                  try {
                    const updatedEvent = {
                      ...event,
                      checklistIds,
                      updatedAt: new Date().toISOString()
                    };
                    
                    await onUpdateEvent(updatedEvent);
                  } catch (error) {
                    console.error('Error updating event checklist:', error);
                    toast.error('Failed to update checklist');
                  }
                }}
              />
            )}
          </div>
        </div>
      </div>

      {/* URL Import Modal */}
      {showUrlImport && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
          <div className="bg-dark-200 rounded-lg p-6 max-w-md w-full">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold text-white">Import Event from URL</h2>
              <button
                onClick={() => {
                  setShowUrlImport(false);
                  setImportError(null);
                }}
                className="text-gray-400 hover:text-white"
              >
                <X className="w-5 h-5" />
              </button>
            </div>
            <div className="space-y-4">
              <div>
                <label className="block text-sm text-gray-400 mb-2">
                  Event URL
                </label>
                <input
                  type="url"
                  value={importUrl}
                  onChange={(e) => {
                    setImportUrl(e.target.value);
                    setImportError(null); // Clear error when input changes
                  }}
                  placeholder="https://example.com/event"
                  className={`w-full bg-dark-300 border rounded-md px-3 py-2 text-white ${
                    importError ? 'border-red-500' : 'border-dark-50'
                  }`}
                />
              </div>

              {/* Error Message */}
              {importError && (
                <div className="bg-red-500/10 border border-red-500/20 rounded-md p-3">
                  <div className="flex gap-2 text-red-400">
                    <Info className="w-5 h-5 shrink-0 mt-0.5" />
                    <span>{importError}</span>
                  </div>
                </div>
              )}

              <div className="flex justify-end gap-4">
                <button
                  onClick={() => {
                    setShowUrlImport(false);
                    setImportError(null);
                  }}
                  className="px-4 py-2 text-gray-400 hover:text-white"
                  disabled={isImporting}
                >
                  Cancel
                </button>
                <button
                  onClick={handleImportFromUrl}
                  disabled={isImporting}
                  className="flex items-center gap-2 px-4 py-2 bg-primary-500 text-white rounded-md hover:bg-primary-600 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {isImporting ? (
                    <>
                      <div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin" />
                      Importing...
                    </>
                  ) : (
                    <>
                      <Link className="w-4 h-4" />
                      Import Event
                    </>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Delete Confirmation Modal */}
      {showDeleteConfirm && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
          <div className="bg-dark-200 rounded-lg p-6 max-w-md w-full">
            <h2 className="text-xl font-bold text-white mb-4">Delete Event</h2>
            <p className="text-gray-400 mb-6">
              Are you sure you want to delete "{editedEvent.title}"? This action cannot be undone.
            </p>
            <div className="flex justify-end gap-4">
              <button
                onClick={() => setShowDeleteConfirm(false)}
                className="px-4 py-2 text-gray-400 hover:text-white"
              >
                Cancel
              </button>
              <button
                onClick={handleDelete}
                className="flex items-center gap-2 px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600"
              >
                <Trash2 className="w-4 h-4" />
                Delete Event
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Budget Item Modal */}
      <BudgetItemModal
        isOpen={isBudgetModalOpen}
        onClose={() => {
          setIsBudgetModalOpen(false);
          setEditingItem(undefined);
        }}
        onAdd={handleAddBudgetItem}
        type="expense"
        eventId={event?.id}
        item={editingItem}
      />
    </>
  );
}