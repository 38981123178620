import React, { useState, useEffect } from 'react';
import { Plus, Search, X, Filter, Loader2, Lightbulb } from 'lucide-react';
import { doc, updateDoc, addDoc, collection, deleteDoc } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { useAuth } from '../../contexts/AuthContext';
import { toast } from 'react-hot-toast';
import { getPartInfo } from '../../services/aiService';
import type { Part, PartCategory, PartSubcategory } from '../../types/part';
import { AddPartForm } from './AddPartForm';
import type { PartTemplate } from '../../config/partCategories';
import { commonParts } from '../../config/partCategories';
import type { Vehicle } from '../../types/vehicle';
import PartCard from './PartCard';
import SuggestedPartCard from './SuggestedPartCard';
import { useNavigate } from 'react-router-dom';

interface PartsCardViewProps {
  parts: Part[];
  vehicleId?: string;
  showQuickAdd?: boolean;
  mode: 'inventory' | 'installed';
  vehicle?: Vehicle;
  onDismissSuggestion?: (key: string) => void;
  onAddSuggestedPart?: (category: PartCategory, subcategory: PartSubcategory) => void;
  onAddNewPart?: () => void;
  onUpdatePart?: (partId: string, updates: Partial<Part>) => Promise<void>;
  onDeletePart?: (partId: string) => Promise<boolean | undefined>;
  vehicles?: Vehicle[];
}

// List of all part categories
const PART_CATEGORIES: PartCategory[] = [
  'Brakes',
  'Tires',
  'Engine',
  'Suspension',
  'Transmission',
  'Exterior',
  'Interior',
  'Electrical',
  'Cooling',
  'Fuel System',
  'Exhaust',
  'Drivetrain',
  'Steering',
  'Other'
];

export default function PartsCardView({ parts, vehicleId, showQuickAdd = true, mode, vehicle, onDismissSuggestion, onAddSuggestedPart, onAddNewPart, onUpdatePart: externalUpdatePart, onDeletePart: externalDeletePart, vehicles = [] }: PartsCardViewProps) {
  const { programId } = useAuth();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState<PartCategory | 'All'>('All');
  const [isAddingPart, setIsAddingPart] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<PartTemplate | null>(null);
  const [isUpdating, setIsUpdating] = useState(false);
  
  // Debug values
  console.log('PartsCardView Debug:', {
    mode,
    vehiclesProvided: !!vehicles,
    vehiclesLength: vehicles?.length || 0,
    partsLength: parts.length
  });
  
  // Group parts by category
  const groupedParts = React.useMemo(() => {
    // Filter parts based on search query and selected category
    const filteredParts = parts.filter(part => {
      const matchesSearch = searchQuery === '' || 
        part.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        (part.manufacturer && part.manufacturer.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (part.subcategory && part.subcategory.toLowerCase().includes(searchQuery.toLowerCase()));
      
      const matchesCategory = selectedCategory === 'All' || part.category === selectedCategory;
      
      return matchesSearch && matchesCategory;
    });
    
    // Group by category
    return filteredParts.reduce<Record<string, Part[]>>((groups, part) => {
      const category = part.category;
      if (!groups[category]) {
        groups[category] = [];
      }
      groups[category].push(part);
      return groups;
    }, {});
  }, [parts, searchQuery, selectedCategory]);
  
  // Handle updating a part
  const handleUpdatePart = async (partId: string, updates: Partial<Part>) => {
    // Use external handler if provided
    if (externalUpdatePart) {
      return externalUpdatePart(partId, updates);
    }
    
    if (!programId) return;
    
    try {
      setIsUpdating(true);
      
      // Special handling for installation status changes
      if (updates.metadata?.hasOwnProperty('isInstalled')) {
        const isBeingUninstalled = updates.metadata.isInstalled === false;
        
        // If the part is being uninstalled, clear the vehicleId
        if (isBeingUninstalled) {
          updates.vehicleId = null;
        }
      }
      
      const partRef = doc(db, 'programs', programId, 'parts', partId);
      await updateDoc(partRef, {
        ...updates,
        updatedAt: new Date().toISOString()
      });
      
      toast.success('Part updated successfully');
    } catch (error) {
      console.error('Error updating part:', error);
      toast.error('Failed to update part');
    } finally {
      setIsUpdating(false);
    }
  };
  
  // Handle deleting a part
  const handleDeletePart = async (partId: string) => {
    if (externalDeletePart) {
      try {
        const success = await externalDeletePart(partId);
        if (success) {
          toast.success('Part deleted successfully');
        }
      } catch (error) {
        console.error('Error deleting part:', error);
        toast.error('Failed to delete part');
      }
    } else if (programId) {
      try {
        const partRef = doc(db, 'programs', programId, 'parts', partId);
        await deleteDoc(partRef);
        toast.success('Part deleted successfully');
      } catch (error) {
        console.error('Error deleting part:', error);
        toast.error('Failed to delete part');
      }
    }
  };
  
  // Handle adding a part from template
  const handleAddPart = async (formData: Partial<Part>) => {
    if (!programId || !vehicleId) return;
    
    try {
      const newPart: Partial<Part> = {
        ...formData,
        vehicleId,
        cost: 0,
        metadata: {
          ...(formData.metadata || {}),
          isInstalled: true,
          installationDate: new Date().toISOString().split('T')[0] // Today's date in YYYY-MM-DD format
        },
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString()
      };
      
      await addDoc(collection(db, 'programs', programId, 'parts'), newPart);
      setIsAddingPart(false);
      setSelectedTemplate(null);
      toast.success('Part added successfully');
    } catch (error) {
      console.error('Error adding part:', error);
      toast.error('Failed to add part');
    }
  };
  
  // Handle template selection
  const handleTemplateSelect = (template: PartTemplate) => {
    setSelectedTemplate(template);
    setIsAddingPart(true);
  };
  
  // Clear search and filters
  const handleClearSearch = () => {
    setSearchQuery('');
    setSelectedCategory('All');
  };

  // Navigate to add new part page
  const handleAddNewPart = () => {
    if (onAddNewPart) {
      onAddNewPart();
    } else {
      navigate(`/app/parts/new`, { 
        state: { 
          isNew: true, 
          vehicleId 
        } 
      });
    }
  };

  // Handle dismissing a suggested part
  const handleDismissSuggestion = (key: string) => {
    if (onDismissSuggestion) {
      onDismissSuggestion(key);
    }
  };

  // Handle adding a suggested part
  const handleAddSuggestedPart = (category: PartCategory, subcategory: PartSubcategory) => {
    if (onAddSuggestedPart) {
      onAddSuggestedPart(category, subcategory);
    }
  };
  
  return (
    <div className="space-y-6">
      {/* Search and Filter Bar */}
      <div className="flex flex-col sm:flex-row gap-4">
        <div className="relative flex-1">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <Search className="h-5 w-5 text-gray-400" />
          </div>
          <input
            type="text"
            placeholder="Search parts..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="block w-full pl-10 pr-10 py-2 bg-dark-300 border border-dark-50 rounded-md text-white focus:ring-2 focus:ring-primary-500 focus:border-primary-500"
          />
          {searchQuery && (
            <button
              onClick={handleClearSearch}
              className="absolute inset-y-0 right-0 pr-3 flex items-center"
            >
              <X className="h-5 w-5 text-gray-400 hover:text-white" />
            </button>
          )}
        </div>
        
        <div className="relative">
          <select
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value as PartCategory | 'All')}
            className="block w-full pl-3 pr-10 py-2 bg-dark-300 border border-dark-50 rounded-md text-white focus:ring-2 focus:ring-primary-500 focus:border-primary-500 appearance-none"
          >
            <option value="All">All Categories</option>
            {PART_CATEGORIES.map(category => (
              <option key={category} value={category}>{category}</option>
            ))}
          </select>
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <Filter className="h-5 w-5 text-gray-400" />
          </div>
        </div>
      </div>
      
      {/* Add Part Button */}
      {showQuickAdd && (
        <button
          onClick={handleAddNewPart}
          className="w-full flex items-center justify-center gap-2 px-4 py-3 bg-primary-500 text-white rounded-md hover:bg-primary-600 transition-colors"
        >
          <Plus className="h-5 w-5" />
          Add New Part
        </button>
      )}
      
      {/* Parts Display */}
      {Object.keys(groupedParts).length === 0 ? (
        <div className="text-center py-8">
          <p className="text-gray-400">No parts found matching your criteria.</p>
        </div>
      ) : (
        <div className="space-y-8">
          {Object.entries(groupedParts).map(([category, categoryParts]) => (
            <div key={category} className="space-y-4">
              <h3 className="text-lg font-semibold text-white border-b border-dark-50 pb-2">
                {category} <span className="text-gray-400 text-sm font-normal">({categoryParts.length})</span>
              </h3>
              <div className="grid grid-cols-1 md:grid-cols-2 ipad:grid-cols-2 lg:grid-cols-3 gap-4">
                {categoryParts.map(part => {
                  // Check if this is a suggested part
                  const isSuggestion = 'isSuggestion' in part && part.isSuggestion;
                  
                  if (isSuggestion && onDismissSuggestion && onAddSuggestedPart) {
                    return (
                      <SuggestedPartCard
                        key={part.id}
                        part={part as Part & { isSuggestion: boolean; suggestionKey: string }}
                        onDismiss={handleDismissSuggestion}
                        onAdd={handleAddSuggestedPart}
                      />
                    );
                  }
                  
                  return (
                    <PartCard
                      key={part.id}
                      part={part}
                      onUpdate={handleUpdatePart}
                      onDelete={handleDeletePart}
                      vehicles={vehicles || []}
                      isGarageView={mode === 'inventory'}
                    />
                  );
                })}
              </div>
            </div>
          ))}
        </div>
      )}
      
      {/* Add Part Form Modal */}
      {isAddingPart && selectedTemplate && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
          <div className="bg-dark-200 rounded-lg p-6 max-w-md w-full">
            <h2 className="text-xl font-bold text-white mb-4">Add {selectedTemplate.name}</h2>
            <AddPartForm
              template={selectedTemplate}
              onSubmit={handleAddPart}
              onCancel={() => {
                setIsAddingPart(false);
                setSelectedTemplate(null);
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
} 