import React, { useEffect, useRef, useState } from 'react';
import { MapPin } from 'lucide-react';
import type { EventLocation, PlaceDetails } from '../../types/event';

// Add type definitions for Google Maps
declare global {
  interface Window {
    google: typeof google;
  }
}

// Declare google namespace
declare namespace google.maps {
  namespace places {
    class Autocomplete {
      constructor(inputField: HTMLInputElement, opts?: AutocompleteOptions);
      addListener(eventName: string, handler: () => void): void;
      getPlace(): PlaceResult;
    }
    
    interface AutocompleteOptions {
      fields?: string[];
      types?: string[];
      componentRestrictions?: {
        country: string | string[];
      };
    }
    
    interface PlaceResult {
      place_id?: string;
      name?: string;
      formatted_address?: string;
      geometry?: {
        location?: {
          lat(): number;
          lng(): number;
        };
      };
      photos?: Array<{
        getUrl(opts: { maxWidth: number; maxHeight: number }): string;
      }>;
      url?: string;
      formatted_phone_number?: string;
      website?: string;
      rating?: number;
      types?: string[];
    }
  }

  namespace event {
    function clearInstanceListeners(instance: any): void;
  }
}

interface PlaceSearchProps {
  value?: EventLocation;
  onChange: (location: EventLocation | undefined) => void;
  isEditing?: boolean;
  className?: string;
  placeholder?: string;
}

export default function PlaceSearch({
  value,
  onChange,
  isEditing = true,
  className = '',
  placeholder = 'Search for a location...'
}: PlaceSearchProps) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [searchInput, setSearchInput] = useState(value?.name || '');
  const [placeDetails, setPlaceDetails] = useState<PlaceDetails | undefined>(undefined);
  const inputRef = useRef<HTMLInputElement>(null);
  const autocompleteRef = useRef<any>(null);

  useEffect(() => {
    // Load the Google Places API script
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${import.meta.env.VITE_GOOGLE_MAPS_API_KEY}&libraries=places`;
    script.async = true;
    script.onload = () => setIsLoaded(true);
    script.onerror = () => setError('Failed to load Google Places API');
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (!isLoaded || !inputRef.current || error) return;

    try {
      // Initialize the Autocomplete service
      const options = {
        fields: [
          'place_id',
          'name',
          'formatted_address',
          'geometry',
          'photos',
          'url',
          'formatted_phone_number',
          'website',
          'rating',
          'types'
        ],
        // Restrict to US results only
        componentRestrictions: { country: 'us' }
      };

      autocompleteRef.current = new window.google.maps.places.Autocomplete(
        inputRef.current,
        options
      );

      // Add place_changed event listener
      autocompleteRef.current.addListener('place_changed', () => {
        const place = autocompleteRef.current?.getPlace();
        
        if (place && place.place_id) {
          // Store full place details for UI
          const details: PlaceDetails = {
            placeId: place.place_id,
            name: place.name || '',
            address: place.formatted_address,
            latitude: place.geometry?.location?.lat(),
            longitude: place.geometry?.location?.lng(),
            url: place.url,
            photos: place.photos?.map((photo: any) => photo.getUrl({ maxWidth: 800, maxHeight: 600 })),
            phoneNumber: place.formatted_phone_number,
            website: place.website,
            rating: place.rating,
            types: place.types
          };
          setPlaceDetails(details);
          
          // Only pass the basic location info to parent
          const location: EventLocation = {
            placeId: details.placeId,
            name: details.name,
            address: details.address,
            latitude: details.latitude,
            longitude: details.longitude,
            url: details.url
          };
          
          setSearchInput(location.name);
          onChange(location);
        } else {
          setPlaceDetails(undefined);
          onChange(undefined);
        }
      });

    } catch (err) {
      console.error('Error initializing Google Places:', err);
      setError('Failed to initialize location search');
    }

    return () => {
      if (autocompleteRef.current) {
        window.google.maps.event.clearInstanceListeners(autocompleteRef.current);
      }
    };
  }, [isLoaded, onChange, error]);

  // Update input value when value prop changes
  useEffect(() => {
    if (value?.name) {
      setSearchInput(value.name);
    }
  }, [value]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
    if (!e.target.value) {
      setPlaceDetails(undefined);
      onChange(undefined);
    }
  };

  if (!isEditing) {
    if (!value) {
      return (
        <div className="flex items-start gap-3">
          <MapPin className="w-5 h-5 text-gray-400 mt-1" />
          <div className="text-gray-400">No location set</div>
        </div>
      );
    }
    
    return (
      <div className="flex items-start gap-3">
        <MapPin className="w-5 h-5 text-gray-400 mt-1" />
        <div>
          <div className="text-white font-medium">{value.name}</div>
          {value.address && (
            <div className="text-gray-400 text-sm">{value.address}</div>
          )}
          {value.url && (
            <a
              href={value.url}
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary-500 hover:text-primary-400 text-sm mt-1 inline-block"
            >
              View on Google Maps
            </a>
          )}
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className={`relative ${className}`}>
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <MapPin className="w-5 h-5 text-red-500" />
        </div>
        <input
          type="text"
          value={searchInput}
          onChange={handleInputChange}
          placeholder="Location search unavailable"
          className="w-full bg-dark-300 border border-red-500 rounded-md pl-10 pr-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-red-500"
          disabled
        />
        <p className="mt-1 text-sm text-red-500">{error}</p>
      </div>
    );
  }

  return (
    <div className={`relative ${className}`}>
      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <MapPin className="w-5 h-5 text-gray-400" />
      </div>
      <input
        ref={inputRef}
        type="text"
        value={searchInput}
        onChange={handleInputChange}
        placeholder={placeholder}
        className="w-full bg-dark-300 border border-dark-50 rounded-md pl-10 pr-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500"
        disabled={!isLoaded || !isEditing}
      />
    </div>
  );
} 