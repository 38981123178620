import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { Car, Calendar, DollarSign, CheckSquare, ArrowRight, Star, Shield, Lock, RefreshCw, HeadsetIcon, Gift } from 'lucide-react';
import { APP_VERSION, BUILD_TIMESTAMP } from '../version';
import { Helmet } from 'react-helmet';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function LandingPage() {
  const { isLoading } = useAuth();

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  if (isLoading) {
    return null;
  }

  const mainFeatures = [
    {
      icon: CheckSquare,
      title: 'Task Management',
      description: 'Stay on top of race preparations with customizable checklists and maintenance schedules.',
      image: '/screenshots/macbook-tasks.png'
    },
    {
      icon: Calendar,
      title: 'Event Planning',
      description: 'Plan your racing season, track events, and manage practice sessions with our comprehensive calendar.',
      image: '/screenshots/imac-schedule.png'
    },
    {
      icon: Car,
      title: 'Vehicle Management',
      description: 'Track multiple vehicles, log modifications, and maintain detailed service records.',
      image: '/screenshots/brakes.png'
    }
  ];

  const additionalFeatures = [
    {
      icon: DollarSign,
      title: 'Budget Tracking',
      description: 'Monitor race expenses, track sponsorships, and manage your seasonal budget with detailed financial insights.',
    },
    {
      icon: Calendar,
      title: 'Season Planning',
      description: 'Plan your entire racing season in advance, set goals, and track your progress throughout the year.',
    },
    {
      icon: CheckSquare,
      title: 'Maintenance Tracking',
      description: 'Keep detailed maintenance logs, set service intervals, and never miss critical maintenance.',
    },
    {
      icon: Shield,
      title: 'Parts Inventory',
      description: 'Track your spare parts inventory, wear items, and maintenance supplies.',
    },
    {
      icon: Star,
      title: 'AI Assistant',
      description: 'Get intelligent suggestions for maintenance schedules, race preparation, and vehicle optimization.',
    },
    {
      icon: Calendar,
      title: 'Event Categories',
      description: 'Organize events by type - races, practice sessions, maintenance days, and more.',
    }
  ];

  return (
    <div className="min-h-screen bg-dark-300">
      <Helmet>
        <title>PitPrep - Your Personal Motorsport Manager</title>
        <meta property="og:title" content="PitPrep - Your Personal Motorsport Manager" />
        <meta property="og:description" content="The essential app for amateur motorsport drivers to organize their racing season, track maintenance, and manage their competition budget." />
        <meta property="og:image" content="/screenshots/imac-schedule.png" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      {/* Free Trial Banner */}
      <div className="bg-primary-500 text-white py-2 text-center text-sm">
        <span className="inline-flex items-center">
          <Gift className="w-4 h-4 mr-2" />
          Try PitPrep free for 7 days — No credit card required
          <Gift className="w-4 h-4 ml-2" />
        </span>
      </div>

      <nav className="bg-dark-200/50 backdrop-blur-lg border-b border-dark-50 sticky top-0 z-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16 items-center">
            <span className="text-xl font-display font-bold text-primary-500">PitPrep</span>
            <div className="space-x-4">
              <Link
                to="/login"
                className="text-gray-300 hover:text-white transition-colors"
              >
                Sign In
              </Link>
              <Link
                to="/login"
                className="bg-primary-500 text-white px-4 py-2 rounded-md text-sm font-medium hover:bg-primary-600 transition-colors"
              >
                Start Free Trial
              </Link>
            </div>
          </div>
        </div>
      </nav>

      <main>
        {/* Hero Section */}
        <div className="relative overflow-hidden">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-24">
            <div className="text-center relative z-10" data-aos="fade-up">
              <h1 className="text-4xl sm:text-6xl font-display font-bold text-white mb-6 leading-tight">
                Your Digital Pit Crew
              </h1>
              <p className="text-xl text-gray-400 max-w-2xl mx-auto mb-8">
                The essential app for amateur motorsport drivers to organize their racing season, 
                track maintenance, and manage their competition budget.
              </p>
              <div className="flex flex-col items-center gap-6">
                <div className="flex flex-col sm:flex-row items-center gap-3 text-sm text-gray-400">
                  <div className="flex items-center gap-2">
                    <Gift className="w-4 h-4 text-primary-500" />
                    <span className="font-semibold text-white">7-day free trial</span>
                  </div>
                  <div className="hidden sm:block">•</div>
                  <div className="flex items-center gap-2">
                    <Lock className="w-4 h-4 text-primary-500" />
                    <span>No credit card required</span>
                  </div>
                  <div className="hidden sm:block">•</div>
                  <div className="flex items-center gap-2">
                    <Shield className="w-4 h-4 text-primary-500" />
                    <span>$15/month after trial</span>
                  </div>
                </div>
                <Link
                  to="/login"
                  className="inline-flex items-center px-8 py-3 rounded-md text-white bg-primary-500 hover:bg-primary-600 transition-colors"
                >
                  Start Your Free Trial
                  <ArrowRight className="ml-2 w-5 h-5" />
                </Link>
              </div>
            </div>

            {/* Hero Image */}
            <div className="relative -mt-12 pb-24" data-aos="fade-up" data-aos-delay="200">
              <img
                src="/screenshots/iphone-setup.png"
                alt="PitPrep on iPhone"
                className="w-full max-w-2xl mx-auto [filter:drop-shadow(0_15px_40px_rgb(0_0_0/0.45))]"
              />
              {/* Scroll Indicator */}
              <div 
                className="absolute bottom-0 inset-x-0 text-gray-400 flex flex-col items-center animate-bounce"
                style={{ animation: 'bounce 2s infinite' }}
              >
                <span className="text-sm mb-2">Discover Features</span>
                <ArrowRight className="w-5 h-5 transform rotate-90" />
              </div>
            </div>
          </div>
        </div>

        {/* Main Feature Sections */}
        <div className="py-24 bg-dark-200">
          <div className="max-w-[1600px] mx-auto px-4 sm:px-6 lg:px-8">
            {mainFeatures.map((feature, index) => (
              <div
                key={feature.title}
                className={`flex flex-col ${
                  index % 2 === 0 ? 'lg:flex-row' : 'lg:flex-row-reverse'
                } items-center gap-12 mb-32 last:mb-0`}
                data-aos={index % 2 === 0 ? 'fade-right' : 'fade-left'}
              >
                <div className="flex-1 lg:max-w-[35%]">
                  <div className="inline-flex items-center justify-center p-3 bg-primary-500/10 rounded-lg mb-4">
                    <feature.icon className="w-6 h-6 text-primary-500" />
                  </div>
                  <h2 className="text-3xl font-display font-bold text-white mb-4">{feature.title}</h2>
                  <p className="text-lg text-gray-400 mb-6">{feature.description}</p>
                </div>
                <div className="flex-1 lg:max-w-[65%]">
                  <img
                    src={feature.image}
                    alt={feature.title}
                    className="rounded-lg w-full [filter:drop-shadow(0_15px_40px_rgb(0_0_0/0.45))]"
                  />
                </div>
              </div>
            ))}

            {/* Additional Features Grid */}
            <div className="mt-32 text-center" data-aos="fade-up">
              <h2 className="text-3xl font-display font-bold text-white mb-12">More Features In Development</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                {additionalFeatures.map((feature) => (
                  <div
                    key={feature.title}
                    className="bg-dark-300/50 rounded-xl p-8"
                  >
                    <div className="inline-flex items-center justify-center p-3 bg-primary-500/10 rounded-lg mb-4">
                      <feature.icon className="w-6 h-6 text-primary-500" />
                    </div>
                    <h3 className="text-xl font-display font-light text-white mb-3">{feature.title}</h3>
                    <p className="text-gray-400">{feature.description}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Free Trial CTA */}
        <div className="py-24 bg-gradient-to-b from-dark-200 to-dark-300">
          <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 text-center" data-aos="fade-up">
            <h2 className="text-3xl font-display font-bold text-white mb-6">
              Start Your Free 7-Day Trial Today
            </h2>
            <p className="text-lg text-gray-400 mb-8">
              Get full access to all PitPrep features. No credit card required. 
              See how PitPrep can help you organize your racing season and improve your performance.
            </p>
            <div className="bg-dark-200 p-6 rounded-lg mb-8">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                <div className="flex flex-col items-center">
                  <div className="bg-primary-500/10 p-3 rounded-full mb-3">
                    <Gift className="w-6 h-6 text-primary-500" />
                  </div>
                  <h3 className="text-white font-semibold mb-1">7-Day Free Trial</h3>
                  <p className="text-sm text-gray-400">Full access to all features</p>
                </div>
                
                <div className="flex flex-col items-center">
                  <div className="bg-primary-500/10 p-3 rounded-full mb-3">
                    <Lock className="w-6 h-6 text-primary-500" />
                  </div>
                  <h3 className="text-white font-semibold mb-1">No Credit Card</h3>
                  <p className="text-sm text-gray-400">Start without payment info</p>
                </div>
                
                <div className="flex flex-col items-center">
                  <div className="bg-primary-500/10 p-3 rounded-full mb-3">
                    <RefreshCw className="w-6 h-6 text-primary-500" />
                  </div>
                  <h3 className="text-white font-semibold mb-1">$15/month</h3>
                  <p className="text-sm text-gray-400">Cancel anytime</p>
                </div>
              </div>
            </div>
            <Link
              to="/login"
              className="inline-flex items-center px-8 py-3 rounded-md text-white bg-primary-500 hover:bg-primary-600 transition-colors"
            >
              Start Your Free Trial
              <ArrowRight className="ml-2 w-5 h-5" />
            </Link>
          </div>
        </div>

        {/* Trust Bar Section */}
        <div className="py-16 bg-dark-200">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="grid grid-cols-1 md:grid-cols-4 gap-8 text-center">
              {/* Secure Payments */}
              <div className="flex flex-col items-center">
                <div className="bg-primary-500/10 p-3 rounded-full mb-3">
                  <Shield className="w-6 h-6 text-primary-500" />
                </div>
                <h3 className="text-white font-display font-light mb-1">Secure Payments</h3>
                <p className="text-sm text-gray-400">Protected by Stripe</p>
              </div>

              {/* SSL Encryption */}
              <div className="flex flex-col items-center">
                <div className="bg-primary-500/10 p-3 rounded-full mb-3">
                  <Lock className="w-6 h-6 text-primary-500" />
                </div>
                <h3 className="text-white font-display font-light mb-1">SSL Encrypted</h3>
                <p className="text-sm text-gray-400">Bank-grade security</p>
              </div>

              {/* Money Back Guarantee */}
              <div className="flex flex-col items-center">
                <div className="bg-primary-500/10 p-3 rounded-full mb-3">
                  <RefreshCw className="w-6 h-6 text-primary-500" />
                </div>
                <h3 className="text-white font-display font-light mb-1">Money-Back Guarantee</h3>
                <p className="text-sm text-gray-400">No questions asked</p>
              </div>

              {/* US Support */}
              <div className="flex flex-col items-center">
                <div className="bg-primary-500/10 p-3 rounded-full mb-3">
                  <HeadsetIcon className="w-6 h-6 text-primary-500" />
                </div>
                <h3 className="text-white font-display font-light mb-1">US-Based Support</h3>
                <p className="text-sm text-gray-400">Here when you need us</p>
              </div>
            </div>
          </div>
        </div>

        {/* Sponsorship Section */}
        <div className="py-16 bg-gradient-to-b from-dark-300 to-dark-200">
          <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
            <div className="bg-dark-200/50 border border-primary-500/20 rounded-lg p-6 shadow-lg">
              <div className="inline-flex items-center justify-center p-3 bg-primary-500/10 rounded-full mb-4">
                <Car className="w-6 h-6 text-primary-500" />
              </div>
              <h2 className="text-2xl font-display font-bold text-white mb-3">Partner With PitPrep</h2>
              <p className="text-gray-400 mb-4">
                Got a race car? Display our logo and get PitPrep at a sponsorship rate.
              </p>
              <a 
                href="mailto:sponsorship@pitprep.com" 
                className="inline-flex items-center text-primary-500 hover:text-primary-400 font-medium"
              >
                Email sponsorship@pitprep.com with your details
                <ArrowRight className="ml-2 w-4 h-4" />
              </a>
            </div>
          </div>
        </div>
      </main>

      {/* Footer */}
      <footer className="border-t border-dark-50 bg-dark-200">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
            <div>
              <h3 className="text-lg font-display font-bold text-white mb-4">PitPrep</h3>
              <p className="text-sm text-gray-400">
                The comprehensive motorsport management platform for amateur racing drivers.
              </p>
            </div>

            <div>
              <h3 className="text-lg font-display font-bold text-white mb-4">Legal</h3>
              <ul className="space-y-2">
                <li>
                  <a
                    href="/legal/privacy-policy.html"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-sm text-gray-400 hover:text-white transition-colors"
                  >
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <a
                    href="/legal/terms-of-service.html"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-sm text-gray-400 hover:text-white transition-colors"
                  >
                    Terms of Service
                  </a>
                </li>
              </ul>
            </div>

            <div>
              <h3 className="text-lg font-display font-bold text-white mb-4">Contact</h3>
              <div className="space-y-2">
                <a
                  href="https://www.instagram.com/carbon.cinematic/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-sm text-gray-400 hover:text-white transition-colors flex items-center gap-2"
                >
                  <svg className="w-4 h-4" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/>
                  </svg>
                  DM us on Instagram
                </a>
              </div>
            </div>

            <div className="text-right">
              <div className="text-sm text-gray-400">
                <div>Version {APP_VERSION}</div>
                <div>{new Date(BUILD_TIMESTAMP).toLocaleString()}</div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}