import type { PartCategory, PartSubcategory } from '../types/part';

// Interface for suggested part entries
export interface SuggestedPart {
  category: PartCategory;
  subcategory: PartSubcategory;
  name: string;
  description: string;
}

// List of common subcategories to suggest, ordered by commonness
export const SUGGESTED_SUBCATEGORIES: SuggestedPart[] = [
  { 
    category: 'Tires', 
    subcategory: 'Performance', 
    name: 'Performance Tires',
    description: 'Add your performance tires to track wear and maintenance'
  },
  { 
    category: 'Brakes', 
    subcategory: 'Pads', 
    name: 'Brake Pads',
    description: 'Track your brake pad wear and replacement schedule'
  },
  { 
    category: 'Engine', 
    subcategory: 'Oil', 
    name: 'Engine Oil',
    description: 'Keep track of your oil changes and condition'
  },
  { 
    category: 'Suspension', 
    subcategory: 'Coilovers', 
    name: 'Coilovers',
    description: 'Document your suspension setup and adjustments'
  },
  { 
    category: 'Exhaust', 
    subcategory: 'Full System', 
    name: 'Exhaust System',
    description: 'Record details about your exhaust system'
  },
  { 
    category: 'Transmission', 
    subcategory: 'Shifter', 
    name: 'Shifter',
    description: 'Add information about your shifter setup'
  },
  { 
    category: 'Interior', 
    subcategory: 'Seats', 
    name: 'Racing Seats',
    description: 'Document your seat setup and installation'
  },
  { 
    category: 'Suspension', 
    subcategory: 'Sway Bars', 
    name: 'Sway Bars',
    description: 'Track your sway bar setup and adjustments'
  },
  { 
    category: 'Engine', 
    subcategory: 'Intake', 
    name: 'Air Intake',
    description: 'Add details about your air intake system'
  },
  { 
    category: 'Brakes', 
    subcategory: 'Rotors', 
    name: 'Brake Rotors',
    description: 'Monitor your brake rotor condition and wear'
  }
]; 