import React, { useState, useEffect } from 'react';
import { Dialog } from '@headlessui/react';
import { X } from 'lucide-react';
import type { BudgetItem, ExpenseCategory, IncomeCategory, BudgetItemFrequency } from '../../types/budget';
import type { BudgetItemModalProps } from './types';

const EXPENSE_CATEGORIES: ExpenseCategory[] = [
  'Entry Fees',
  'Travel & Lodging',
  'Fuel & Oil',
  'Tires',
  'Parts & Repairs',
  'Scheduled Maintenance',
  'Tools & Equipment',
  'Safety Gear',
  'Marketing & Sponsorship',
  'Insurance',
  'Other'
];

const INCOME_CATEGORIES: IncomeCategory[] = [
  'Sponsorship',
  'Prize Money',
  'Contingency',
  'Personal Funds',
  'Other'
];

const FREQUENCIES: BudgetItemFrequency[] = [
  'one-time',
  'monthly',
  'yearly'
];

export function BudgetItemModal({ isOpen, onClose, onAdd, type, eventId, item }: BudgetItemModalProps) {
  const [formData, setFormData] = useState<Partial<BudgetItem>>({
    category: type === 'expense' ? 'Entry Fees' as ExpenseCategory : 'Sponsorship' as IncomeCategory,
    description: '',
    amount: 0,
    quantity: 1,
    frequency: 'one-time'
  });

  // Reset form when modal opens/closes or item changes
  useEffect(() => {
    if (isOpen && item) {
      setFormData(item);
    } else {
      setFormData({
        category: type === 'expense' ? 'Entry Fees' as ExpenseCategory : 'Sponsorship' as IncomeCategory,
        description: '',
        amount: 0,
        quantity: 1,
        frequency: 'one-time'
      });
    }
  }, [isOpen, item, type]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!formData.category || !formData.description || formData.amount === undefined || formData.quantity === undefined) {
      return;
    }

    const newItem: BudgetItem = {
      id: item?.id || crypto.randomUUID(),
      category: formData.category,
      description: formData.description,
      amount: formData.amount,
      quantity: formData.quantity,
      frequency: formData.frequency || 'one-time',
      eventId: eventId,
      createdAt: item?.createdAt || new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      ...(formData.partId && { partId: formData.partId })
    };

    onAdd(newItem);
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="mx-auto max-w-md w-full bg-dark-200 rounded-lg shadow-xl">
          <div className="flex items-center justify-between p-4 border-b border-dark-300">
            <Dialog.Title className="text-lg font-medium text-white">
              {item ? 'Edit' : 'Add'} {type === 'expense' ? 'Expense' : 'Income'}
            </Dialog.Title>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-white"
            >
              <X className="w-5 h-5" />
            </button>
          </div>

          <form onSubmit={handleSubmit} className="p-4 space-y-4">
            <div>
              <label htmlFor="category" className="block text-sm font-medium text-gray-400 mb-1">
                Category
              </label>
              <select
                id="category"
                value={formData.category}
                onChange={(e) => setFormData(prev => ({ ...prev, category: e.target.value as ExpenseCategory | IncomeCategory }))}
                className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500"
                required
              >
                {(type === 'expense' ? EXPENSE_CATEGORIES : INCOME_CATEGORIES).map((category) => (
                  <option key={category} value={category}>
                    {category}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label htmlFor="description" className="block text-sm font-medium text-gray-400 mb-1">
                Description
              </label>
              <input
                type="text"
                id="description"
                value={formData.description}
                onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
                className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500"
                required
              />
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <label htmlFor="amount" className="block text-sm font-medium text-gray-400 mb-1">
                  Amount
                </label>
                <div className="relative">
                  <span className="absolute left-3 top-2 text-gray-400">$</span>
                  <input
                    type="number"
                    id="amount"
                    value={formData.amount}
                    onChange={(e) => setFormData(prev => ({ ...prev, amount: parseFloat(e.target.value) || 0 }))}
                    className="w-full bg-dark-300 border border-dark-50 rounded-md pl-7 pr-3 py-2 text-white font-display font-light focus:outline-none focus:ring-2 focus:ring-primary-500"
                    min="0"
                    step="0.01"
                    required
                  />
                </div>
              </div>

              <div>
                <label htmlFor="quantity" className="block text-sm font-medium text-gray-400 mb-1">
                  Quantity
                </label>
                <input
                  type="number"
                  id="quantity"
                  value={formData.quantity}
                  onChange={(e) => setFormData(prev => ({ ...prev, quantity: parseInt(e.target.value) || 1 }))}
                  className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white font-display font-light focus:outline-none focus:ring-2 focus:ring-primary-500"
                  min="1"
                  required
                />
              </div>
            </div>

            <div>
              <label htmlFor="frequency" className="block text-sm font-medium text-gray-400 mb-1">
                Frequency
              </label>
              <select
                id="frequency"
                value={formData.frequency}
                onChange={(e) => setFormData(prev => ({ ...prev, frequency: e.target.value as BudgetItemFrequency }))}
                className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500"
              >
                {FREQUENCIES.map((freq) => (
                  <option key={freq} value={freq}>
                    {freq.charAt(0).toUpperCase() + freq.slice(1).replace('-', ' ')}
                  </option>
                ))}
              </select>
            </div>

            <div className="flex justify-end gap-3 mt-6">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 text-gray-400 hover:text-white"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 bg-primary-500 text-white rounded-md hover:bg-primary-600"
              >
                {item ? 'Save Changes' : 'Add Item'}
              </button>
            </div>
          </form>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}

export default BudgetItemModal; 