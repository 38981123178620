import React, { useState } from 'react';
import type { PartTemplate } from '../../config/partCategories';
import type { Part, PartMetadata } from '../../types/part';

interface AddPartFormProps {
  template: PartTemplate;
  onSubmit: (data: Partial<Part>) => void;
  onCancel: () => void;
}

export function AddPartForm({ template, onSubmit, onCancel }: AddPartFormProps) {
  const [formData, setFormData] = useState<Partial<PartMetadata>>({});

  const handleSubmit = () => {
    onSubmit({
      name: template.name,
      category: template.category,
      subcategory: template.subcategory,
      metadata: {
        ...template.metadata,
        ...formData
      }
    });
  };

  return (
    <div className="space-y-4">
      {template.required?.includes('location') && (
        <div>
          <label className="block text-sm font-medium text-gray-400 mb-1">
            Location
          </label>
          <select
            value={formData.location || ''}
            onChange={(e) => setFormData(prev => ({ ...prev, location: e.target.value as PartMetadata['location'] }))}
            className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:ring-2 focus:ring-primary-500"
          >
            <option value="">Select location...</option>
            <option value="front">Front</option>
            <option value="rear">Rear</option>
            <option value="left">Left</option>
            <option value="right">Right</option>
            <option value="front left">Front Left</option>
            <option value="front right">Front Right</option>
            <option value="rear left">Rear Left</option>
            <option value="rear right">Rear Right</option>
          </select>
        </div>
      )}
      
      {template.required?.includes('currentWear') && (
        <div>
          <label className="block text-sm font-medium text-gray-400 mb-1">
            Current {template.metadata?.wearMetric || 'Wear'}
          </label>
          <input
            type="number"
            value={formData.currentWear || ''}
            onChange={(e) => setFormData(prev => ({ ...prev, currentWear: Number(e.target.value) }))}
            className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:ring-2 focus:ring-primary-500"
          />
        </div>
      )}

      {template.required?.includes('wearLimit') && (
        <div>
          <label className="block text-sm font-medium text-gray-400 mb-1">
            Wear Limit
          </label>
          <input
            type="number"
            value={formData.wearLimit || ''}
            onChange={(e) => setFormData(prev => ({ ...prev, wearLimit: Number(e.target.value) }))}
            className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:ring-2 focus:ring-primary-500"
          />
        </div>
      )}

      <div className="flex justify-end gap-3 pt-4">
        <button
          onClick={onCancel}
          className="px-4 py-2 text-gray-400 hover:text-white"
        >
          Cancel
        </button>
        <button
          onClick={handleSubmit}
          className="px-4 py-2 bg-primary-500 text-white rounded-md hover:bg-primary-600"
        >
          Add Part
        </button>
      </div>
    </div>
  );
} 