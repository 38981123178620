import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ArrowLeft, Edit, Trash2, Plus, Loader2, Car, Calendar, Settings, Wrench, Gauge, BarChart, Sparkles, List, Grid } from 'lucide-react';
import { doc, getDoc, updateDoc, collection, deleteDoc, query, where, onSnapshot, getDocs } from 'firebase/firestore';
import { db } from '../config/firebase';
import { useAuth } from '../contexts/AuthContext';
import type { Vehicle } from '../types/vehicle';
import VehicleSummary from '../components/vehicles/VehicleSummary';
import type { Part, PartMetadata, PartCategory, PartSubcategory } from '../types/part';
import PartsTable from '../components/parts/PartsTable';
import PartsCardView from '../components/parts/PartsCardView';
import { toast } from 'react-hot-toast';
import { SUGGESTED_SUBCATEGORIES } from '../config/suggestedParts';

export default function VehicleDetail() {
  const { id: routeId } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { programId } = useAuth();
  const [vehicle, setVehicle] = useState<Vehicle | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [vehicleParts, setVehicleParts] = useState<Part[]>([]);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [partsViewMode, setPartsViewMode] = useState<'table' | 'card'>('card');
  const [dismissedSuggestions, setDismissedSuggestions] = useState<string[]>([]);
  const [showAllSuggestions] = useState(true);
  const vehicleId = routeId;

  // Initialize dismissedSuggestions from vehicle data when it loads
  useEffect(() => {
    if (vehicle?.dismissedSuggestions) {
      setDismissedSuggestions(vehicle.dismissedSuggestions);
    }
  }, [vehicle]);

  useEffect(() => {
    async function fetchVehicle() {
      if (!programId || !vehicleId) return;

      try {
        const vehicleRef = doc(db, 'programs', programId, 'vehicles', vehicleId);
        
        const vehicleSnap = await getDoc(vehicleRef);
        
        if (vehicleSnap.exists()) {
          const vehicleData = { 
            id: vehicleSnap.id, 
            ...vehicleSnap.data()
          } as Vehicle;
          
          setVehicle(vehicleData);
        } else {
          setError('Vehicle not found');
        }
      } catch (err) {
        console.error('Error fetching vehicle:', err);
        setError('Failed to load vehicle');
      } finally {
        setIsLoading(false);
      }
    }

    fetchVehicle();
  }, [programId, vehicleId]);

  useEffect(() => {
    if (!programId || !vehicleId) return;

    const partsRef = collection(db, 'programs', programId, 'parts');
    const q = query(partsRef, where('vehicleId', '==', vehicleId));
    
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const parts = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as Part[];
      setVehicleParts(parts);
    });

    return () => unsubscribe();
  }, [programId, vehicleId]);

  const handleUpdateVehicle = async (updates: Partial<Vehicle>) => {
    if (!programId || !vehicleId) return;

    // Optimistically update local state first
    setVehicle(prev => prev ? { ...prev, ...updates } : null);

    try {
      const vehicleRef = doc(db, 'programs', programId, 'vehicles', vehicleId);
        await updateDoc(vehicleRef, {
          ...updates,
          updatedAt: new Date().toISOString()
          });
    } catch (err) {
      // Revert optimistic update on error
      setVehicle(prev => {
        if (!prev) return null;
        // If updating consumables, only revert those changes
        if (updates.consumables && prev.consumables) {
          const prevConsumables = prev.consumables;
          const revertedUpdates = Object.fromEntries(
            Object.entries(prevConsumables)
              .filter(([key]) => updates.consumables && key in updates.consumables)
          );

          return {
            ...prev,
            consumables: {
              ...prevConsumables,
              ...revertedUpdates
            }
          };
        }
        // Otherwise revert all changes
        return prev;
      });
      console.error('Error updating vehicle:', err);
      setError('Failed to update vehicle');
    }
  };

  const handleDeleteVehicle = async () => {
    if (!programId || !vehicleId) return;
    
    try {
      // Delete any parts associated with this vehicle
      const partsRef = collection(db, 'programs', programId, 'parts');
      const partsQuery = query(partsRef, where('vehicleId', '==', vehicleId));
      const partsSnapshot = await getDocs(partsQuery);
      const deleteParts = partsSnapshot.docs.map(doc => deleteDoc(doc.ref));
      await Promise.all(deleteParts);

      // Delete any events associated with this vehicle
      const eventsRef = collection(db, 'programs', programId, 'events');
      const eventsQuery = query(eventsRef, where('vehicleId', '==', vehicleId));
      const eventsSnapshot = await getDocs(eventsQuery);
      const deleteEvents = eventsSnapshot.docs.map(doc => deleteDoc(doc.ref));
      await Promise.all(deleteEvents);

      // Finally delete the vehicle document itself
      await deleteDoc(doc(db, 'programs', programId, 'vehicles', vehicleId));
      navigate('/app/vehicle');
    } catch (error) {
      console.error('Error deleting vehicle:', error);
      toast.error('Failed to delete vehicle');
    }
  };

  // Function to handle adding a new part with prefilled subcategory
  const handleAddSuggestedPart = (category: PartCategory, subcategory: PartSubcategory) => {
    navigate(`/app/parts/new`, { 
      state: { 
        isNew: true, 
        vehicleId,
        prefilledCategory: category,
        prefilledSubcategory: subcategory
      } 
    });
  };

  // Function to dismiss a suggestion
  const handleDismissSuggestion = async (key: string) => {
    // Update local state
    setDismissedSuggestions(prev => [...prev, key]);
    
    // Update in Firestore
    if (!programId || !vehicleId) return;
    
    try {
      const updatedDismissedSuggestions = [...(vehicle?.dismissedSuggestions || []), key];
      
      const vehicleRef = doc(db, 'programs', programId, 'vehicles', vehicleId);
      await updateDoc(vehicleRef, {
        dismissedSuggestions: updatedDismissedSuggestions,
        updatedAt: new Date().toISOString()
      });
      
      // Update local vehicle state
      if (vehicle) {
        setVehicle({
          ...vehicle,
          dismissedSuggestions: updatedDismissedSuggestions
        });
      }
    } catch (err) {
      console.error('Error updating dismissed suggestions:', err);
      toast.error('Failed to dismiss suggestion');
      
      // Revert local state on error
      setDismissedSuggestions(vehicle?.dismissedSuggestions || []);
    }
  };

  // Get suggested subcategories that haven't been added yet
  const getSuggestedSubcategories = () => {
    if (vehicleParts.length >= 10 || !showAllSuggestions) return [];

    // Check which subcategories are already in the inventory
    const existingSubcategories = new Set(
      vehicleParts.map(part => `${part.category}:${part.subcategory}`)
    );

    // Filter out suggestions that are already in inventory or have been dismissed
    return SUGGESTED_SUBCATEGORIES.filter(suggestion => {
      const key = `${suggestion.category}:${suggestion.subcategory}`;
      return !existingSubcategories.has(key) && !dismissedSuggestions.includes(key);
    });
  };

  // Create virtual suggested parts to display in the parts list
  const createSuggestedParts = (): Part[] => {
    if (vehicleParts.length >= 10 || !showAllSuggestions) return [];

    const suggestedSubcategories = getSuggestedSubcategories();
    
    // Convert suggestions to virtual Part objects
    return suggestedSubcategories.map((suggestion, index) => {
      const key = `${suggestion.category}:${suggestion.subcategory}`;
      return {
        id: `suggestion-${index}`,
        name: suggestion.name,
        category: suggestion.category,
        subcategory: suggestion.subcategory as any,
        cost: 0,
        description: suggestion.description,
        vehicleId,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        isSuggestion: true, // Custom property to identify suggestions
        suggestionKey: key // Store the key for dismissal
      } as Part & { isSuggestion: boolean; suggestionKey: string };
    });
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <Loader2 className="w-8 h-8 animate-spin text-primary-500" />
      </div>
    );
  }

  if (error || !vehicle) {
    return (
      <div className="text-center py-12">
        <p className="text-gray-400">{error || 'Vehicle not found.'}</p>
        <button
          onClick={() => navigate('/app/vehicle')}
          className="mt-4 px-4 py-2 bg-primary-500 text-white rounded-lg hover:bg-primary-600 transition-colors"
        >
          Return to Garage
        </button>
      </div>
    );
  }

  const totalSpent = vehicleParts.reduce((sum, part) => sum + (part.cost || 0), 0);
  const partsCount = vehicleParts.length;
  
  // Combine real parts with suggested parts
  const allParts = [...vehicleParts, ...createSuggestedParts()];

  return (
    <div className="space-y-8">
      <div className="flex items-center gap-4 mb-6">
        <button
          onClick={() => navigate('/app/vehicle')}
          className="p-2 rounded-full bg-dark-200 hover:bg-dark-100 transition-colors"
        >
          <ArrowLeft className="w-5 h-5 text-white" />
        </button>
        <h1 className="text-2xl font-bold text-white">
          {vehicle.year} {vehicle.make} {vehicle.model}
        </h1>
      </div>

      {/* Delete Confirmation Dialog */}
      {showDeleteConfirm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-dark-200 rounded-lg p-6 max-w-md w-full space-y-4">
            <h3 className="text-xl font-bold text-white">Delete Vehicle</h3>
            <p className="text-gray-400">
              Are you sure you want to delete this vehicle? This action cannot be undone.
            </p>
            <div className="flex justify-end gap-4">
              <button
                onClick={() => setShowDeleteConfirm(false)}
                className="px-4 py-2 text-gray-400 hover:text-white"
              >
                Cancel
              </button>
              <button
                onClick={handleDeleteVehicle}
                className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Vehicle Summary Component */}
      <VehicleSummary 
        vehicle={vehicle}
        totalSpent={totalSpent}
        partsCount={partsCount}
        onUpdateVehicle={handleUpdateVehicle}
        onDeleteClick={() => setShowDeleteConfirm(true)}
      />

      {/* Inventory Section */}
      <div className="bg-dark-200 rounded-lg p-6">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-medium text-white">Inventory</h3>
          <div className="flex items-center gap-2 bg-dark-100 rounded-md p-1">
            <button
              onClick={() => setPartsViewMode('table')}
              className={`p-1.5 rounded-md ${
                partsViewMode === 'table' 
                  ? 'bg-dark-300 text-white' 
                  : 'text-gray-400 hover:text-white hover:bg-dark-200'
              }`}
              title="Table View"
            >
              <List className="h-4 w-4" />
            </button>
            <button
              onClick={() => setPartsViewMode('card')}
              className={`p-1.5 rounded-md ${
                partsViewMode === 'card' 
                  ? 'bg-dark-300 text-white' 
                  : 'text-gray-400 hover:text-white hover:bg-dark-200'
              }`}
              title="Card View"
            >
              <Grid className="h-4 w-4" />
            </button>
          </div>
        </div>
        
        {partsViewMode === 'table' ? (
          <PartsTable 
            parts={vehicleParts}
            vehicleId={vehicleId}
            vehicle={vehicle}
            mode="installed"
            onDismissSuggestion={handleDismissSuggestion}
            onAddSuggestedPart={handleAddSuggestedPart}
          />
        ) : (
          <PartsCardView
            parts={allParts}
            vehicleId={vehicleId}
            vehicle={vehicle}
            mode="installed"
            onDismissSuggestion={handleDismissSuggestion}
            onAddSuggestedPart={handleAddSuggestedPart}
          />
        )}
      </div>
    </div>
  );
}