import { useState } from 'react';
import { Link, useLocation, Outlet } from 'react-router-dom';
import { 
  LayoutDashboard, 
  Warehouse, 
  Calendar, 
  DollarSign, 
  CheckSquare, 
  ClipboardList,
  Wrench,
  Menu,
  X,
  User,
  Settings as SettingsIcon,
  CreditCard,
  LogOut,
  Home
} from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { APP_VERSION, BUILD_TIMESTAMP } from '../version';

const navigation = [
  { name: 'Dashboard', href: '/app/dashboard', icon: Home },
  { name: 'Garage', href: '/app/vehicle', icon: Warehouse },
  { name: 'Tasks', href: '/app/tasks', icon: CheckSquare },
  { name: 'Checklist', href: '/app/checklists', icon: ClipboardList },
  { name: 'Schedule', href: '/app/schedule', icon: Calendar },
  { name: 'Budget', href: '/app/budget', icon: DollarSign },
];

const profileNavigation = [
  { name: 'Account', href: '/app/account', icon: User },
  { name: 'Subscription', href: '/app/subscription', icon: CreditCard },
  { name: 'Settings', href: '/app/settings', icon: SettingsIcon },
];

export default function Layout() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const location = useLocation();
  const { user, logout } = useAuth();

  const handleLogout = async () => {
    try {
      await logout();
    } catch (error) {
      console.error('Failed to log out:', error);
    }
  };

  const NavLinks = () => (
    <>
      {navigation.map((item) => {
        const isActive = location.pathname === item.href;
        const Icon = item.icon;
        return (
          <Link
            key={item.name}
            to={item.href}
            onClick={() => setMobileMenuOpen(false)}
            className={`flex items-center gap-x-3 rounded-md px-4 py-2.5 text-sm font-medium transition-colors ${
              isActive
                ? 'bg-dark-50 text-primary-500'
                : 'text-gray-400 hover:bg-dark-100 hover:text-primary-500'
            }`}
          >
            <Icon className="h-5 w-5 shrink-0" />
            {item.name}
          </Link>
        );
      })}
    </>
  );

  const ProfileSection = () => (
    <div className="mt-auto pt-4 border-t border-dark-50">
      <div className="px-4 py-3">
        <div className="flex items-center gap-3">
          <div className="h-10 w-10 rounded-full bg-dark-50 flex items-center justify-center">
            <User className="h-6 w-6 text-gray-400" />
          </div>
          <div>
            <div className="text-sm font-medium text-white">{user?.displayName}</div>
            <div className="text-xs text-gray-400">{user?.email}</div>
          </div>
        </div>
      </div>
      <nav className="mt-2">
        {profileNavigation.map((item) => {
          const isActive = location.pathname === item.href;
          const Icon = item.icon;
          return (
            <Link
              key={item.name}
              to={item.href}
              onClick={() => setMobileMenuOpen(false)}
              className={`flex items-center gap-x-3 rounded-md px-4 py-2 text-sm font-medium transition-colors ${
                isActive
                  ? 'bg-dark-50 text-primary-500'
                  : 'text-gray-400 hover:bg-dark-100 hover:text-primary-500'
              }`}
            >
              <Icon className="h-4 w-4 shrink-0" />
              {item.name}
            </Link>
          );
        })}
        <button
          onClick={() => {
            setMobileMenuOpen(false);
            handleLogout();
          }}
          className="w-full flex items-center gap-x-3 rounded-md px-4 py-2 text-sm font-medium text-red-500 hover:bg-dark-100 transition-colors"
        >
          <LogOut className="h-4 w-4 shrink-0" />
          Sign out
        </button>
      </nav>
    </div>
  );

  return (
    <div className="min-h-screen bg-dark-300">
      {/* Mobile menu overlay */}
      {mobileMenuOpen && (
        <div className="fixed inset-0 z-50 lg:hidden">
          <div 
            className="fixed inset-0 bg-dark-900/80 backdrop-blur-sm"
            onClick={() => setMobileMenuOpen(false)}
          />
          
          <div className="fixed inset-y-0 left-0 w-full bg-dark-200 px-4 py-4 flex flex-col h-full">
            <div className="flex items-center justify-between mb-4">
              <span className="text-xl font-bold text-primary-500">PitPrep</span>
              <button 
                onClick={() => setMobileMenuOpen(false)}
                className="rounded-md p-2 text-gray-400 hover:bg-dark-100"
              >
                <X className="h-6 w-6" />
              </button>
            </div>
            <nav className="flex flex-col space-y-1 flex-1">
              <NavLinks />
            </nav>
            <ProfileSection />
          </div>
        </div>
      )}

      {/* Desktop sidebar */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-64 lg:flex-col">
        <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-dark-200 px-4 py-4">
          <div className="flex h-16 shrink-0 items-center">
            <span className="text-xl font-bold text-primary-500">PitPrep</span>
          </div>
          <nav className="flex flex-1 flex-col">
            <ul role="list" className="flex flex-1 flex-col gap-y-1">
              <NavLinks />
            </ul>
          </nav>
          <ProfileSection />
        </div>
      </div>

      {/* Mobile header */}
      <div className="sticky top-0 z-40 lg:hidden">
        <div className="flex h-16 items-center gap-x-4 bg-dark-200 px-4">
          <button
            type="button"
            className="text-gray-400 hover:text-gray-300"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Menu className="h-6 w-6" />
          </button>
          <span className="text-xl font-bold text-primary-500">PitPrep</span>
        </div>
      </div>

      {/* Main content */}
      <div className="lg:pl-64 relative z-30">
        <main className="py-6 px-4 sm:px-6 lg:px-8">
          <Outlet />
        </main>
      </div>
    </div>
  );
}