import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Plus } from 'lucide-react';
import ChecklistCard from '../components/checklists/ChecklistCard';
import type { Checklist } from '../types/checklist';
import type { Event } from '../types/event';
import { Dispatch, SetStateAction } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { doc, deleteDoc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../config/firebase';
import { toast } from 'react-hot-toast';

interface ChecklistsProps {
  checklists: Checklist[];
  setChecklists: Dispatch<SetStateAction<Checklist[]>>;
}

export default function Checklists({ checklists, setChecklists }: ChecklistsProps) {
  const navigate = useNavigate();
  const { programId } = useAuth();

  const handleDeleteChecklist = async (id: string) => {
    if (!programId) return;

    try {
      const checklist = checklists.find(c => c.id === id);
      if (!checklist) return;

      // If this checklist is associated with an event, we need to update that event
      if (checklist.eventId) {
        const eventRef = doc(db, 'programs', programId, 'events', checklist.eventId);
        try {
          const eventDoc = await getDoc(eventRef);
          if (eventDoc.exists()) {
            const event = eventDoc.data() as Event;
            // Remove this checklist ID from the event's checklistIds
            await updateDoc(eventRef, {
              checklistIds: event.checklistIds.filter(cId => cId !== id),
              updatedAt: new Date().toISOString()
            });
          }
        } catch (error) {
          console.error('Error updating event:', error);
          // Continue with deletion even if event update fails
        }
      }

      // Delete the checklist document
      await deleteDoc(doc(db, 'programs', programId, 'checklists', id));
      
      // Update local state
      setChecklists(prev => prev.filter(checklist => checklist.id !== id));
      toast.success('Checklist deleted successfully');
    } catch (error) {
      console.error('Error deleting checklist:', error);
      toast.error('Failed to delete checklist');
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold text-white">Checklists</h1>
        <Link
          to="/app/checklists/new"
          className="flex items-center gap-2 px-4 py-2 bg-primary-500 text-white rounded-md hover:bg-primary-600"
        >
          <Plus className="w-4 h-4" />
          New Checklist
        </Link>
      </div>

      {checklists.length === 0 ? (
        <div className="text-center py-12">
          <p className="text-gray-400">No checklists created yet.</p>
          <Link
            to="/app/checklists/new"
            className="mt-4 text-primary-500 hover:text-primary-400"
          >
            Create your first checklist
          </Link>
        </div>
      ) : (
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          {checklists.map(checklist => (
            <ChecklistCard
              key={checklist.id}
              checklist={checklist}
              onEdit={() => navigate(`/app/checklists/${checklist.id}`)}
              onDelete={handleDeleteChecklist}
            />
          ))}
        </div>
      )}
    </div>
  );
}