import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';
import { Loader2, Car, Plus, Wrench } from 'lucide-react';
import { collection, query, onSnapshot } from 'firebase/firestore';
import { db } from '../config/firebase';
import { useAuth } from '../contexts/AuthContext';
import { DndContext, DragEndEvent, DragOverlay, useSensor, useSensors, PointerSensor, DragStartEvent } from '@dnd-kit/core';
import { SortableContext, arrayMove } from '@dnd-kit/sortable';
import { toast } from 'react-hot-toast';
import VehicleForm from '../components/vehicles/VehicleForm';
import DroppableVehicleCard from '../components/vehicles/DroppableVehicleCard';
import DraggablePartCard from '../components/parts/DraggablePartCard';
import PartCard from '../components/parts/PartCard';
import type { Vehicle } from '../types/vehicle';
import type { Part } from '../types/part';

interface GarageProps {
  vehicles: Vehicle[];
  setVehicles: Dispatch<SetStateAction<Vehicle[]>>;
  vehicleParts: Part[];
  onUpdatePart: (partId: string | null, updates: Partial<Part>) => Promise<void>;
  onDeletePart: (partId: string) => Promise<boolean | undefined>;
}

export default function Garage({ 
  vehicles, 
  setVehicles, 
  vehicleParts, 
  onUpdatePart, 
  onDeletePart 
}: GarageProps) {
  const { programId } = useAuth();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [showAddVehicle, setShowAddVehicle] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState<string | null>(null);
  const [uninstalledParts, setUninstalledParts] = useState<Part[]>([]);
  const [activePart, setActivePart] = useState<Part | null>(null);
  const [activeVehicle, setActiveVehicle] = useState<string | null>(null);

  // Configure sensors for drag and drop
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  );

  // Filter for uninstalled parts
  useEffect(() => {
    // Filter for uninstalled parts (not installed on any vehicle)
    const uninstalled = vehicleParts.filter(part => {
      // Check if the part is not installed
      const isNotInstalled = part.metadata?.isInstalled !== true;
      
      return isNotInstalled;
    });
    
    setUninstalledParts(uninstalled);
  }, [vehicleParts, vehicles]);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  const handleVehicleClick = (vehicleId: string) => {
    navigate(`/app/vehicle/${vehicleId}`);
  };

  const handleEditVehicle = (vehicleId: string) => {
    navigate(`/app/vehicle/${vehicleId}?edit=true`);
  };

  const handleDeleteVehicle = (vehicleId: string) => {
    setShowDeleteConfirm(vehicleId);
  };

  const confirmDeleteVehicle = async (vehicleId: string) => {
    // This would be implemented in the VehicleDetail component
    // For now, we'll just navigate to the vehicle detail page
    navigate(`/app/vehicle/${vehicleId}`);
  };

  // Handle adding a new part to the garage (not installed on any vehicle)
  const handleAddNewPart = () => {
    navigate('/app/parts/new', { 
      state: { 
        isNew: true,
        noVehicle: true // Indicate that this part is not associated with any vehicle
      } 
    });
  };

  // Handle drag start - set the active part
  const handleDragStart = (event: DragStartEvent) => {
    const { active } = event;
    const part = uninstalledParts.find(p => p.id === active.id);
    if (part) {
      setActivePart(part);
    }
  };

  // Handle drag end - install part on vehicle if dropped on a vehicle
  const handleDragEnd = async (event: DragEndEvent) => {
    const { active, over } = event;
    
    // Reset active states
    setActivePart(null);
    setActiveVehicle(null);
    
    if (!over) return;
    
    const partId = active.id as string;
    const vehicleId = over.id as string;
    
    // Check if we have a valid part and vehicle
    const part = uninstalledParts.find(p => p.id === partId);
    const vehicle = vehicles.find(v => v.id === vehicleId);
    
    if (part && vehicle) {
      try {
        // Install the part on the vehicle
        await onUpdatePart(part.id, {
          vehicleId: vehicle.id,
          metadata: {
            ...part.metadata,
            isInstalled: true,
            installationDate: new Date().toISOString().split('T')[0]
          }
        });
        
        // Show success toast
        toast.success(
          <div className="flex items-center gap-2">
            <span>
              <strong>{part.name}</strong> installed on <strong>{vehicle.year} {vehicle.make} {vehicle.model}</strong>
            </span>
          </div>
        );
      } catch (error) {
        console.error('Error installing part:', error);
        toast.error(`Failed to install ${part.name}`);
      }
    }
  };

  // Handle drag over - highlight the vehicle being dragged over
  const handleDragOver = (event: any) => {
    const { over } = event;
    setActiveVehicle(over ? over.id : null);
  };

  // Handle vehicle image update
  const handleVehicleImageUpdate = (vehicleId: string, imageUrl: string) => {
    // Update the vehicles array with the new image URL
    setVehicles(prevVehicles => 
      prevVehicles.map(vehicle => 
        vehicle.id === vehicleId 
          ? { ...vehicle, image: imageUrl } 
          : vehicle
      )
    );
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <Loader2 className="w-8 h-8 animate-spin text-primary-500" />
      </div>
    );
  }

  return (
    <DndContext
      sensors={sensors}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragOver={handleDragOver}
    >
      <div className="space-y-12">
        {/* Vehicles Section */}
        <section>
          <div className="flex items-center gap-3 mb-6">
            <div className="p-2 bg-primary-500/10 rounded-lg">
              <Car className="h-6 w-6 text-primary-500" />
            </div>
            <h2 className="text-2xl font-bold text-white">Your Vehicles</h2>
            <div className="ml-auto">
              <button
                onClick={() => setShowAddVehicle(true)}
                className="flex items-center gap-2 px-4 py-2 bg-primary-500 text-white rounded-lg hover:bg-primary-600 transition-colors"
              >
                <Plus className="h-5 w-5" />
                Add Vehicle
              </button>
            </div>
          </div>

          {vehicles.length === 0 ? (
            <div className="text-center py-8 bg-dark-200 rounded-lg">
              <p className="text-gray-400">No vehicles added yet.</p>
              <button
                onClick={() => setShowAddVehicle(true)}
                className="mt-4 px-4 py-2 bg-primary-500 text-white rounded-lg hover:bg-primary-600 transition-colors"
              >
                Add Your First Vehicle
              </button>
            </div>
          ) : (
            <div className="flex flex-wrap gap-6">
              {vehicles.map(vehicle => (
                <div key={vehicle.id} className="w-full sm:w-[calc(50%-12px)] lg:w-[calc(33.333%-16px)] max-w-md">
                  <DroppableVehicleCard
                    vehicle={vehicle}
                    onClick={() => handleVehicleClick(vehicle.id)}
                    onEdit={() => handleEditVehicle(vehicle.id)}
                    onDelete={() => handleDeleteVehicle(vehicle.id)}
                    isOver={activeVehicle === vehicle.id}
                  />
                </div>
              ))}
            </div>
          )}

          {/* Add Vehicle Form */}
          {showAddVehicle && (
            <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
              <VehicleForm
                vehicles={vehicles}
                setVehicles={setVehicles}
                onCancel={() => setShowAddVehicle(false)}
              />
            </div>
          )}

          {/* Delete Confirmation */}
          {showDeleteConfirm && (
            <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
              <div className="bg-dark-200 rounded-lg p-6 max-w-md w-full">
                <h2 className="text-xl font-bold text-white mb-4">Delete Vehicle?</h2>
                <p className="text-gray-300 mb-6">
                  Are you sure you want to delete this vehicle? This action cannot be undone.
                </p>
                <div className="flex justify-end gap-4">
                  <button
                    onClick={() => setShowDeleteConfirm(null)}
                    className="px-4 py-2 bg-dark-100 text-white rounded-lg hover:bg-dark-50 transition-colors"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => {
                      if (showDeleteConfirm) {
                        confirmDeleteVehicle(showDeleteConfirm);
                        setShowDeleteConfirm(null);
                      }
                    }}
                    className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          )}
        </section>

        {/* Garage Inventory Section */}
        <section>
          <div className="flex items-center gap-3 mb-6">
            <div className="p-2 bg-primary-500/10 rounded-lg">
              <Wrench className="h-6 w-6 text-primary-500" />
            </div>
            <h2 className="text-2xl font-bold text-white">Garage Inventory</h2>
            <div className="ml-auto">
              <button
                onClick={handleAddNewPart}
                className="flex items-center gap-2 px-4 py-2 bg-primary-500 text-white rounded-lg hover:bg-primary-600 transition-colors"
              >
                <Plus className="h-5 w-5" />
                Add Part
              </button>
            </div>
          </div>
          
          {uninstalledParts.length === 0 ? (
            <div className="text-center py-12 bg-dark-200 rounded-lg">
              <div className="flex justify-center mb-4">
                <div className="w-16 h-16 rounded-full bg-primary-500/10 flex items-center justify-center">
                  <Wrench className="w-8 h-8 text-primary-500" />
                </div>
              </div>
              <h3 className="text-xl font-semibold text-white mb-2">No Parts in Inventory</h3>
              <p className="text-gray-400 max-w-lg mx-auto mb-6">
                Your garage inventory is empty. Add parts to keep track of components not currently installed on any vehicle.
              </p>
              <button
                onClick={handleAddNewPart}
                className="px-4 py-2 bg-primary-500 text-white rounded-lg hover:bg-primary-600 transition-colors"
              >
                Add Your First Part
              </button>
            </div>
          ) : (
            <div className="bg-dark-200 rounded-lg p-6">
              <SortableContext items={uninstalledParts.map(part => part.id)}>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                  {uninstalledParts.map(part => (
                    <DraggablePartCard
                      key={part.id}
                      part={part}
                      onUpdate={onUpdatePart}
                      onDelete={onDeletePart}
                      vehicles={vehicles}
                      isGarageView={true}
                    />
                  ))}
                </div>
              </SortableContext>
            </div>
          )}
        </section>
        
        {/* Drag Overlay */}
        <DragOverlay>
          {activePart && (
            <div className="opacity-80 transform scale-105 pointer-events-none">
              <PartCard
                part={activePart}
                onUpdate={onUpdatePart}
                vehicles={vehicles}
                isGarageView={true}
                isDragging={true}
              />
            </div>
          )}
        </DragOverlay>
      </div>
    </DndContext>
  );
}