import React, { useState } from 'react';
import { Car, Edit, Trash2 } from 'lucide-react';
import type { Vehicle } from '../../types/vehicle';
import VehicleImageUploadButton from './VehicleImageUploadButton';

interface VehicleCardProps {
  vehicle: Vehicle;
  onClick: () => void;
  onEdit: () => void;
  onDelete: () => void;
  isDropTarget?: boolean;
}

export default function VehicleCard({ vehicle, onClick, onEdit, onDelete, isDropTarget = false }: VehicleCardProps) {
  const { id, year, make, model, primaryUse, image } = vehicle;
  const [vehicleImage, setVehicleImage] = useState<string | undefined>(image);
  const [isUploaderActive, setIsUploaderActive] = useState(false);
  
  // Format the primary use for display
  const formattedPrimaryUse = primaryUse && primaryUse.length > 0
    ? primaryUse.join(', ')
    : 'General Use';

  // Handle image update from the upload button
  const handleImageUpdated = (imageUrl: string) => {
    setVehicleImage(imageUrl);
  };

  // Handle card click - only navigate if uploader is not active
  const handleCardClick = (e: React.MouseEvent) => {
    if (!isUploaderActive) {
      onClick();
    }
  };

  return (
    <div 
      className={`bg-dark-200 rounded-lg overflow-hidden shadow-md hover:shadow-lg transition-all duration-300 border border-dark-50 hover:border-primary-500/30 cursor-pointer h-full ${
        isDropTarget ? 'bg-primary-500/10 border-primary-500/50' : ''
      }`}
      onClick={handleCardClick}
    >
      {/* Vehicle Image - 16:9 Aspect Ratio Container */}
      <div className="relative w-full pb-[56.25%] bg-dark-300 overflow-hidden group">
        {vehicleImage ? (
          <>
            <img 
              src={vehicleImage} 
              alt={`${year} ${make} ${model}`} 
              className="absolute inset-0 w-full h-full object-cover"
              loading="lazy"
            />
            <div className="absolute inset-0 bg-black/0 group-hover:bg-black/20 transition-colors duration-200" />
          </>
        ) : (
          <div className="absolute inset-0 flex items-center justify-center">
            <Car className="w-16 h-16 text-dark-100" />
          </div>
        )}
        
        {/* Image Upload Button - Only visible on hover if there's an image */}
        <div className={`${vehicleImage ? 'opacity-0 group-hover:opacity-100' : 'opacity-100'} transition-opacity duration-200`}>
          <VehicleImageUploadButton 
            vehicleId={id}
            currentImage={vehicleImage}
            onImageUpdated={handleImageUpdated}
            onUploaderStateChange={setIsUploaderActive}
          />
        </div>
        
        {/* Drop indicator */}
        {isDropTarget && (
          <div className="absolute inset-0 flex items-center justify-center bg-primary-500/20 backdrop-blur-sm">
            <div className="bg-primary-500 text-white px-4 py-2 rounded-lg shadow-lg font-medium animate-bounce">
              Drop to Install
            </div>
          </div>
        )}
      </div>
      
      {/* Vehicle Info */}
      <div className="p-4">
        <h3 className="text-lg font-semibold text-white mb-1">{year} {make} {model}</h3>
        <p className="text-sm text-gray-400 mb-3">{formattedPrimaryUse}</p>
        
        {/* Actions */}
        <div className="flex justify-between">
          <button
            onClick={(e) => {
              e.stopPropagation();
              onEdit();
            }}
            className="p-2 rounded-md text-gray-400 hover:text-primary-500 hover:bg-dark-300 transition-colors"
          >
            <Edit size={18} />
          </button>
          <button
            onClick={(e) => {
              e.stopPropagation();
              onDelete();
            }}
            className="p-2 rounded-md text-gray-400 hover:text-red-500 hover:bg-dark-300 transition-colors"
          >
            <Trash2 size={18} />
          </button>
        </div>
      </div>
    </div>
  );
}