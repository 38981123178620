import { geminiModel } from '../config/firebase';
import type { DrivetrainType, Vehicle } from '../types/vehicle';
import type { Part } from '../types/part';
import type { EventLocation } from '../types/event';
import { trackError, ErrorCategory, ErrorSeverity } from './errorTracking.tsx';

interface TrackTip {
  tip: string;
  confidence: number;
  category: string;
}

interface AITrackTipsResponse {
  tips: TrackTip[];
}

interface VehicleSpecs {
  power: number;
  weight: number;
  drivetrain: DrivetrainType;
}

interface ParsedEventData {
  title: string;
  description?: string;
  date: string;  // ISO string of the event date
  location?: string;
  type: string;
  confidence: {
    title: number;
    dates: number;
    location: number;
    type: number;
  };
}

export async function generateTrackTips(location: string): Promise<TrackTip[]> {
  try {
    const prompt = `
      As a motorsport expert, provide specific tips for racing at ${location}.
      Focus on track conditions, racing strategy, and weather considerations.
      
      Provide the response in the following JSON format:
      {
        "tips": [
          {
            "tip": "specific tip here",
            "category": "one of: surface, strategy, weather, general"
          }
        ]
      }
      
      Provide exactly 3 tips, each in a different category.
      Make the tips specific to the location and actionable for racers.
      Return ONLY the JSON with no markdown formatting.
    `;

    const result = await geminiModel.generateContent(prompt);
    const response = result.response;
    const text = response.text();
    
    try {
      // Clean up the response by removing markdown code blocks
      const cleanJson = text.replace(/```json\n?|\n?```/g, '').trim();
      const parsedResponse: AITrackTipsResponse = JSON.parse(cleanJson);
      return parsedResponse.tips;
    } catch (error: any) {
      trackError(error, {
        category: ErrorCategory.DATA,
        severity: ErrorSeverity.P2,
        action: 'parse_ai_track_tips',
        additionalData: {
          location,
          raw_response: text
        }
      });
      console.error('Failed to parse AI response:', error);
      console.error('Raw response:', text);
      return [];
    }
  } catch (error: any) {
    trackError(error, {
      category: ErrorCategory.API,
      severity: ErrorSeverity.P1,
      action: 'generate_track_tips',
      additionalData: {
        location,
        error_message: error.message
      }
    });
    console.error('Error generating track tips:', error);
    return [];
  }
}

export async function getVehicleSpecs(
  year: number,
  make: string,
  model: string,
  trim?: string
): Promise<VehicleSpecs | null> {
  try {
    const prompt = `
      Return ONLY a JSON object with the following specifications for a ${year} ${make} ${model} ${trim || ''}:
      - Factory horsepower (power)
      - Factory curb weight in pounds (weight)
      - Drivetrain type as either "FWD", "RWD", "AWD", or "4WD" (drivetrain)
      
      Format:
      {
        "power": number,
        "weight": number,
        "drivetrain": "FWD" | "RWD" | "AWD" | "4WD"
      }
    `;

    const result = await geminiModel.generateContent(prompt);
    const response = result.response;
    const text = response.text();
    
    try {
      const cleanJson = text.replace(/```json\n?|\n?```/g, '').trim();
      const specs = JSON.parse(cleanJson) as VehicleSpecs;
      return specs;
    } catch (error: any) {
      trackError(error, {
        category: ErrorCategory.DATA,
        severity: ErrorSeverity.P2,
        action: 'parse_vehicle_specs',
        additionalData: {
          vehicle: { year, make, model, trim },
          raw_response: text
        }
      });
      console.error('Failed to parse AI response:', error);
      return null;
    }
  } catch (error: any) {
    trackError(error, {
      category: ErrorCategory.API,
      severity: ErrorSeverity.P1,
      action: 'get_vehicle_specs',
      additionalData: {
        vehicle: { year, make, model, trim },
        error_message: error.message
      }
    });
    console.error('Error getting vehicle specs:', error);
    return null;
  }
}

export async function getPartInfo(userInput: string, vehicle: Vehicle | string): Promise<Partial<Part>> {
  try {
    const vehicleContext = typeof vehicle === 'string' 
      ? `This part is for ${vehicle}.`
      : `This part is installed on a ${vehicle.year} ${vehicle.make} ${vehicle.model}. The vehicle is primarily used for ${vehicle.primaryUse.join(', ').toLowerCase()}.`;

    const prompt = `
      Given this user input describing a car part: "${userInput}"
      Context: ${vehicleContext}
      Note: Use the vehicle context for determining compatibility and specifications.
      
      Please identify the part and return a standardized, user-friendly name and categorization.
      The response must use these exact categories and subcategories:

      Brakes:
        - Pads, Rotors, Fluid, Lines, Calipers, Master Cylinder, Brake Ducts
      Tires:
        - Summer, Winter, All Season, Track, Rain, Performance
      Engine:
        - Oil, Filter, Spark Plugs, Belts, Timing Components, Intake, Turbo, Supercharger, Engine Management
      Suspension:
        - Coilovers, Springs, Shocks, Sway Bars, Control Arms, Bushings, Alignment Parts, Wheels
      Transmission:
        - Fluid, Clutch, Flywheel, Shifter, Differential, Gears
      Exterior:
        - Aero, Body, Lighting, Paint, Wraps
      Interior:
        - Seats, Harnesses, Roll Cage, Gauges, Electronics
      Electrical:
        - Battery, Alternator, Starter, Wiring, Sensors
      Cooling:
        - Radiator, Oil Cooler, Intercooler, Fans, Hoses
      Fuel System:
        - Pump, Filter, Injectors, Lines, Tank
      Exhaust:
        - Headers, Catalytic Converter, Muffler, Full System
      Drivetrain:
        - Axles, Driveshaft, CV Joints, Transfer Case
      Other:
        - Miscellaneous
      
      Format the part name to be descriptive but concise, including ONLY:
      - Model/Series name (if applicable)
      - Part type and any distinguishing features
      Example: "BR Series Coilovers" not "BC Racing BR Series Coilovers"
      Example: "High Performance Radiator" not "Fluidyne High Performance Radiator"
      
      The manufacturer should be returned separately in the manufacturer field.

      When estimating the cost:
      1. Consider the vehicle's primary use (Track/Street/Drift/etc) to suggest appropriate grade parts, in the case the user has not been specific enough about the part.
      2. Look for plural usage in the user input. If it is plural, then the cost should be for the set:
         - "tires" should be 4 tires
         - "rotors" should be 2 rotors
         - "pads" should be 2 pads, etc.
      
      Additionally, provide specific details about the part based on its subcategory:
      
      For Engine > Oil:
      - viscosity (e.g., "5w-30")
      - oilType (e.g., "synthetic", "conventional")
      
      For Engine > Spark Plugs:
      - heatRange (e.g., "stock", "1-step-colder") 
      - gapSize (in mm)
      - material (e.g., "copper", "iridium")
      
      For Tires (any subcategory):
      - treadwear rating (numeric)
      - size (e.g., "245/40R18")
      
      For Brakes > Pads:
      - compound (e.g., "ceramic", "semi-metallic")
      - frictionRating (e.g., "HH", "FF")
      
      For Brakes > Rotors:
      - type (e.g., "drilled", "slotted")
      - diameter (in mm)
      - thickness (in mm)
      
      For Suspension > Coilovers:
      - springRate (in kg/mm for front)
      - springRateRear (in kg/mm for rear)
      - damperAdjustable (e.g., "single", "double", "triple")
      
      These specifications should be included in a "specifications" field in the returned JSON.
      
      Return ONLY a JSON object with these exact properties:
      {
        "name": "formatted name (no manufacturer or vehicle info)",
        "manufacturer": "brand name only",
        "category": "exact category from list above",
        "subcategory": "exact subcategory from list above",
        "cost": estimated cost as number,
        "description": "brief description including fitment details",
        "specifications": {
          // Include relevant specifications based on the subcategory as described above
        }
      }
    `;

    const result = await geminiModel.generateContent(prompt);
    const response = result.response;
    const text = response.text();
    
    try {
      const cleanJson = text.replace(/```json\n?|\n?```/g, '').trim();
      const partInfo = JSON.parse(cleanJson) as Partial<Part> & { specifications?: Record<string, string | number> };
      
      // If specifications exist in the root of the response, move them to metadata.specifications
      if ('specifications' in partInfo && !partInfo.metadata) {
        const { specifications, ...rest } = partInfo;
        return {
          ...rest,
          metadata: {
            specifications
          }
        };
      }
      
      return partInfo;
    } catch (error: any) {
      trackError(error, {
        category: ErrorCategory.DATA,
        severity: ErrorSeverity.P2,
        action: 'parse_part_info',
        additionalData: {
          user_input: userInput,
          vehicle: typeof vehicle === 'string' ? vehicle : {
            year: vehicle.year,
            make: vehicle.make,
            model: vehicle.model
          },
          raw_response: text
        }
      });
      console.error('Failed to parse AI response:', error);
      console.error('Raw response:', text);
      throw new Error('Failed to parse part information');
    }
  } catch (error: any) {
    trackError(error, {
      category: ErrorCategory.API,
      severity: ErrorSeverity.P1,
      action: 'get_part_info',
      additionalData: {
        user_input: userInput,
        vehicle: typeof vehicle === 'string' ? vehicle : {
          year: vehicle.year,
          make: vehicle.make,
          model: vehicle.model
        },
        error_message: error.message
      }
    });
    console.error('Error getting part info:', error);
    throw error;
  }
}

// Update the findPlace function
async function findPlace(searchText: string): Promise<EventLocation | undefined> {
  try {
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/place/findplacefromtext/json?` +
      `input=${encodeURIComponent(searchText)}` +
      `&inputtype=textquery` +
      `&fields=place_id,name,formatted_address,geometry,url` +
      `&key=${import.meta.env.VITE_GOOGLE_MAPS_API_KEY}`
    );

    const data = await response.json();
    
    if (data.status === 'OK' && data.candidates && data.candidates.length > 0) {
      const place = data.candidates[0];
      return {
        placeId: place.place_id,
        name: place.name,
        address: place.formatted_address,
        latitude: place.geometry?.location?.lat,
        longitude: place.geometry?.location?.lng,
        url: place.url
      };
    }
    
    return undefined;
  } catch (error) {
    console.error('Error finding place:', error);
    return undefined;
  }
}

export async function parseEventContent(rawContent: {
  title: string[];
  paragraphs: string[];
  metaData: Record<string, string>;
}): Promise<ParsedEventData> {
  try {
    // First, check content size to prevent processing extremely large pages
    const contentSize = JSON.stringify(rawContent).length;
    const MAX_CONTENT_SIZE = 100000; // 100KB limit
    
    if (contentSize > MAX_CONTENT_SIZE) {
      const error = new Error('CONTENT_TOO_LARGE');
      trackError(error, {
        category: ErrorCategory.DATA,
        severity: ErrorSeverity.P2,
        action: 'validate_event_content',
        additionalData: {
          content_size: contentSize,
          max_size: MAX_CONTENT_SIZE
        }
      });
      throw error;
    }

    const prompt = `
      As a motorsport event expert, analyze this raw content from a webpage and extract structured event information.
      First, determine if this content describes:
      1. No clear event (general page, article, etc.)
      2. Multiple separate events
      3. A single event

      If it's not a single event, respond with a JSON object containing only:
      {
        "error": "NO_EVENT" | "MULTIPLE_EVENTS",
        "details": "explanation of what was found"
      }

      If it is a single event, return a JSON object with the following fields:
      - title: A clean, concise event title
      - description: A well-formatted, concise description (max 250 words)
      - date: ISO string of the event date
      - locationText: Clean location string that would help identify the venue/track
      - type: One of: "race", "practice", "maintenance", "other"
      - confidence: Object with confidence scores (0-1) for title, dates, location, and type

      Raw Content:
      ${JSON.stringify(rawContent, null, 2)}

      Guidelines:
      1. For date: Look for patterns indicating event start date, convert to ISO format
      2. For locationText: Extract the most specific venue/track name and location details
      3. For type: Infer from context and keywords
      4. For description: Summarize key details about the event
      5. Set confidence scores based on how certain you are about each field

      Format the response as a JSON object with these exact fields.
      Do NOT include any explanation or markdown, just the JSON.
    `;

    const result = await geminiModel.generateContent(prompt);
    const response = result.response;
    const text = response.text();
    
    try {
      const cleanJson = text.replace(/```json\n?|\n?```/g, '').trim();
      const parsed = JSON.parse(cleanJson);

      // Check if the response indicates an error condition
      if (parsed.error) {
        const error = new Error(parsed.error + (parsed.details ? ': ' + parsed.details : ''));
        trackError(error, {
          category: ErrorCategory.DATA,
          severity: ErrorSeverity.P2,
          action: 'parse_event_content',
          additionalData: {
            error_type: parsed.error,
            error_details: parsed.details
          }
        });
        throw error;
      }

      // Try to get place details if we have a location
      let location: EventLocation | undefined;
      if (parsed.locationText && parsed.confidence.location > 0.5) {
        location = await findPlace(parsed.locationText);
      }

      return {
        ...parsed,
        location,
        // If we found a place, boost the confidence score
        confidence: {
          ...parsed.confidence,
          location: location ? Math.max(parsed.confidence.location, 0.8) : parsed.confidence.location
        }
      };
    } catch (error: any) {
      if (error.message.includes('NO_EVENT') || error.message.includes('MULTIPLE_EVENTS')) {
        throw error;
      }
      trackError(error, {
        category: ErrorCategory.DATA,
        severity: ErrorSeverity.P2,
        action: 'parse_event_json',
        additionalData: {
          raw_response: text
        }
      });
      console.error('Failed to parse AI response:', error);
      console.error('Raw response:', text);
      throw new Error('Failed to parse event data');
    }
  } catch (error: any) {
    if (!error.message.includes('CONTENT_TOO_LARGE')) {
      trackError(error, {
        category: ErrorCategory.API,
        severity: ErrorSeverity.P1,
        action: 'parse_event_content',
        additionalData: {
          content_size: JSON.stringify(rawContent).length,
          error_message: error.message
        }
      });
    }
    console.error('Error parsing event content:', error);
    throw error;
  }
} 