import React, { useState, useEffect } from 'react';
import { doc, setDoc, deleteDoc, getDoc } from 'firebase/firestore';
import type { Checklist, ChecklistItem } from '../../types/checklist';
import { db } from '../../config/firebase';
import { useAuth } from '../../contexts/AuthContext';
import { toast } from 'react-hot-toast';
import ChecklistItemsContainer from './ChecklistItemsContainer';

interface EventChecklistProps {
  eventId: string;
  eventTitle: string;
  checklistIds: string[];
  onUpdateEvent: (checklistIds: string[]) => Promise<void>;
}

export default function EventChecklist({ eventId, eventTitle, checklistIds, onUpdateEvent }: EventChecklistProps) {
  const { programId } = useAuth();
  const [items, setItems] = useState<ChecklistItem[]>([]);
  const [checklistId, setChecklistId] = useState<string | null>(
    checklistIds.length > 0 ? checklistIds[0] : null
  );
  const [isLoading, setIsLoading] = useState(false);

  // Load existing checklist items if there's a checklist
  useEffect(() => {
    const loadChecklist = async () => {
      if (!programId || !checklistId) return;
      
      setIsLoading(true);
      try {
        const checklistRef = doc(db, 'programs', programId, 'checklists', checklistId);
        const checklistSnap = await getDoc(checklistRef);
        
        if (checklistSnap.exists()) {
          const checklist = checklistSnap.data() as Checklist;
          setItems(checklist.items || []);
        }
      } catch (error) {
        console.error('Error loading checklist:', error);
        toast.error('Failed to load checklist items');
      } finally {
        setIsLoading(false);
      }
    };

    loadChecklist();
  }, [programId, checklistId]);

  const handleAddItem = async (text: string): Promise<void> => {
    if (!programId || !text.trim()) return;

    try {
      const newItem: ChecklistItem = {
        id: crypto.randomUUID(),
        text: text.trim(),
        completed: false,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString()
      };

      // If no checklist exists, create one
      if (!checklistId) {
        const newChecklistId = crypto.randomUUID();
        const newChecklist: Checklist = {
          id: newChecklistId,
          name: `${eventTitle} Checklist`,
          category: 'Event Prep',
          items: [newItem],
          eventId,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString()
        };

        // Save the new checklist
        await setDoc(
          doc(db, 'programs', programId, 'checklists', newChecklistId),
          newChecklist
        );

        // Update the event with the new checklist ID
        await onUpdateEvent([newChecklistId]);
        
        setChecklistId(newChecklistId);
        setItems([newItem]);
      } else {
        // Add to existing checklist
        const updatedItems = [...items, newItem];
        await setDoc(
          doc(db, 'programs', programId, 'checklists', checklistId),
          {
            items: updatedItems,
            updatedAt: new Date().toISOString()
          },
          { merge: true }
        );
        
        setItems(updatedItems);
      }

      toast.success('Item added successfully');
    } catch (error) {
      console.error('Error adding checklist item:', error);
      toast.error('Failed to add item');
      throw error;
    }
  };

  const handleToggleItem = async (itemId: string): Promise<void> => {
    if (!programId || !checklistId) return;

    try {
      const updatedItems = items.map(item => 
        item.id === itemId
          ? { ...item, completed: !item.completed, updatedAt: new Date().toISOString() }
          : item
      );

      await setDoc(
        doc(db, 'programs', programId, 'checklists', checklistId),
        {
          items: updatedItems,
          updatedAt: new Date().toISOString()
        },
        { merge: true }
      );

      setItems(updatedItems);
    } catch (error) {
      console.error('Error toggling item:', error);
      toast.error('Failed to update item');
      throw error;
    }
  };

  const handleUpdateItem = async (itemId: string, updates: Partial<ChecklistItem>): Promise<void> => {
    if (!programId || !checklistId) return;

    try {
      const updatedItems = items.map(item => 
        item.id === itemId
          ? { ...item, ...updates, updatedAt: new Date().toISOString() }
          : item
      );

      await setDoc(
        doc(db, 'programs', programId, 'checklists', checklistId),
        {
          items: updatedItems,
          updatedAt: new Date().toISOString()
        },
        { merge: true }
      );

      setItems(updatedItems);
    } catch (error) {
      console.error('Error updating item:', error);
      toast.error('Failed to update item');
      throw error;
    }
  };

  const handleDeleteItem = async (itemId: string): Promise<void> => {
    if (!programId || !checklistId) return;

    try {
      const updatedItems = items.filter(item => item.id !== itemId);

      // If this was the last item, delete the entire checklist
      if (updatedItems.length === 0) {
        await deleteDoc(doc(db, 'programs', programId, 'checklists', checklistId));
        await onUpdateEvent([]);
        setChecklistId(null);
        setItems([]);
      } else {
        // Otherwise just update the items
        await setDoc(
          doc(db, 'programs', programId, 'checklists', checklistId),
          {
            items: updatedItems,
            updatedAt: new Date().toISOString()
          },
          { merge: true }
        );
        setItems(updatedItems);
      }

      toast.success('Item deleted successfully');
    } catch (error) {
      console.error('Error deleting item:', error);
      toast.error('Failed to delete item');
      throw error;
    }
  };

  return (
    <div className="space-y-4">
      <ChecklistItemsContainer
        items={items}
        onAddItem={handleAddItem}
        onToggleItem={handleToggleItem}
        onUpdateItem={handleUpdateItem}
        onDeleteItem={handleDeleteItem}
        emptyStateMessage="No items added yet. Add your first item using the form above."
        addButtonLabel="Add"
        addPlaceholder="Add a new item..."
        compact={true}
      />
    </div>
  );
} 