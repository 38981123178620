import React, { useMemo } from 'react';
import { Plus } from 'lucide-react';
import type { TransactionListProps, GroupedTransactions } from './types';
import { TransactionItem } from './TransactionItem';

export const TransactionList = React.memo(({
  budget,
  onDeleteBudgetItem,
  onOpenModal,
  onEditItem,
  type,
  title,
  emptyStateIcon,
  emptyStateMessage,
  addButtonText,
  categoryIcons,
  categoryColors,
  amountColor
}: TransactionListProps) => {
  // Group and sort transactions by month
  const { groupedTransactions, sortedMonths } = useMemo(() => {
    const items = type === 'expense' ? budget.expenses : budget.income;
    if (!items) {
      return { groupedTransactions: {}, sortedMonths: [] };
    }

    const grouped = items.reduce((acc: GroupedTransactions, item) => {
      const date = new Date(item.createdAt);
      const monthYear = date.toLocaleString('default', { month: 'long', year: 'numeric' });
      
      if (!acc[monthYear]) {
        acc[monthYear] = [];
      }
      acc[monthYear].push(item);
      return acc;
    }, {});

    // Sort transactions within each month by date (newest first)
    Object.keys(grouped).forEach(month => {
      grouped[month].sort((a, b) => 
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );
    });

    const sorted = Object.keys(grouped).sort((a, b) => {
      const dateA = new Date(a);
      const dateB = new Date(b);
      return dateB.getTime() - dateA.getTime();
    });

    return { groupedTransactions: grouped, sortedMonths: sorted };
  }, [budget, type]);

  const items = type === 'expense' ? budget.expenses : budget.income;
  if (!items?.length) {
    return (
      <div className="bg-gradient-to-br from-dark-200 to-dark-300 rounded-2xl p-6 shadow-xl">
        <div className="flex items-center justify-between mb-6">
          <h3 className="text-xl font-semibold text-white">{title}</h3>
          <button
            onClick={onOpenModal}
            className={`flex items-center gap-2 text-sm ${type === 'expense' ? 'text-primary-500 hover:text-primary-400' : 'text-green-500 hover:text-green-400'}`}
          >
            <Plus className="w-4 h-4" />
            {addButtonText}
          </button>
        </div>
        <div className="text-center py-12">
          {emptyStateIcon}
          <p className="text-gray-400 mt-3">{emptyStateMessage}</p>
          <button
            onClick={onOpenModal}
            className={`mt-4 text-sm ${type === 'expense' ? 'text-primary-500 hover:text-primary-400' : 'text-green-500 hover:text-green-400'}`}
          >
            {addButtonText}
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-gradient-to-br from-dark-200 to-dark-300 rounded-2xl p-6 shadow-xl">
      <div className="flex items-center justify-between mb-6">
        <h3 className="text-xl font-semibold text-white">{title}</h3>
        <button
          onClick={onOpenModal}
          className={`flex items-center gap-2 text-sm ${type === 'expense' ? 'text-primary-500 hover:text-primary-400' : 'text-green-500 hover:text-green-400'}`}
        >
          <Plus className="w-4 h-4" />
          {addButtonText}
        </button>
      </div>
      
      <div className="-mx-6">
        {sortedMonths.map(month => (
          <div key={month}>
            <div className="px-6 py-1.5 bg-dark-100/50 sticky top-0 z-10 backdrop-blur-sm">
              <h4 className="text-sm font-medium text-gray-400">{month}</h4>
            </div>
            <div className="divide-y divide-dark-300">
              {groupedTransactions[month].map((item) => (
                <TransactionItem
                  key={item.id}
                  item={item}
                  onEdit={onEditItem}
                  onDelete={onDeleteBudgetItem}
                  categoryIcons={categoryIcons}
                  categoryColors={categoryColors}
                  amountColor={amountColor}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
});

TransactionList.displayName = 'TransactionList'; 