import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ArrowLeft, Save, X, Car, Calendar, Sparkles, Loader2 } from 'lucide-react';
import { doc, getDoc, setDoc, updateDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../config/firebase';
import { useAuth } from '../contexts/AuthContext';
import type { Checklist, ChecklistCategory, ChecklistItem } from '../types/checklist';
import { toast } from 'react-hot-toast';
import ChecklistItemsContainer from '../components/checklists/ChecklistItemsContainer';

const categories: ChecklistCategory[] = [
  'Event Prep',
  'Track Setup',
  'Maintenance',
  'Packing List',
  'Documentation',
  'Safety',
  'Sponsorship',
  'Other'
];

interface ChecklistDetailProps {
  checklists: Checklist[];
  onUpdateChecklist: (checklist: Checklist) => Promise<void>;
}

export default function ChecklistDetail({ checklists, onUpdateChecklist }: ChecklistDetailProps) {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { programId } = useAuth();
  const isNew = id === 'new';
  
  const [checklist, setChecklist] = useState<Checklist>(() => {
    if (isNew) {
      return {
        id: crypto.randomUUID(),
        name: '',
        category: 'Event Prep',
        items: [],
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString()
      };
    }
    // For existing checklists, try to find it in the checklists array
    const existingChecklist = checklists.find(c => c.id === id);
    if (existingChecklist) {
      return existingChecklist;
    }
    // Default checklist state if not found
    return {
      id: id!,
      name: '',
      category: 'Event Prep',
      items: [],
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString()
    };
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    // Skip everything if this is a new checklist
    if (isNew) {
      return;
    }

    // Skip if we don't have a program ID
    if (!programId) {
      return;
    }

    // Skip if we already have the checklist in our checklists array
    const existingChecklist = checklists.find(c => c.id === id);
    if (existingChecklist) {
      setChecklist(existingChecklist);
      return;
    }

    // Only try to fetch if we have a valid ID
    if (!id || id === 'new') {
      return;
    }

    setIsLoading(true);
    const checklistRef = doc(db, 'programs', programId, 'checklists', id);
    getDoc(checklistRef)
      .then((checklistSnap) => {
        if (checklistSnap.exists()) {
          setChecklist({ id: checklistSnap.id, ...checklistSnap.data() } as Checklist);
        } else {
          setError('Checklist not found');
          navigate('/app/checklists');
        }
      })
      .catch((err) => {
        console.error('Error fetching checklist:', err);
        setError('Failed to load checklist');
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [id, programId, isNew, navigate, checklists]);

  const handleAddItem = async (text: string): Promise<void> => {
    const newItem: ChecklistItem = {
      id: crypto.randomUUID(),
      text: text,
      completed: false,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    };

    setChecklist(prev => ({
      ...prev,
      items: [...prev.items, newItem],
      updatedAt: new Date().toISOString(),
    }));
  };

  const handleToggleItem = async (itemId: string): Promise<void> => {
    setChecklist(prev => ({
      ...prev,
      items: prev.items.map(item =>
        item.id === itemId
          ? { ...item, completed: !item.completed, updatedAt: new Date().toISOString() }
          : item
      ),
      updatedAt: new Date().toISOString(),
    }));
  };

  const handleUpdateItem = async (itemId: string, updates: Partial<ChecklistItem>): Promise<void> => {
    setChecklist(prev => ({
      ...prev,
      items: prev.items.map(item =>
        item.id === itemId
          ? { ...item, ...updates, updatedAt: new Date().toISOString() }
          : item
      ),
      updatedAt: new Date().toISOString(),
    }));
  };

  const handleDeleteItem = async (itemId: string): Promise<void> => {
    if (!window.confirm('Are you sure you want to delete this item?')) return;

    setChecklist(prev => ({
      ...prev,
      items: prev.items.filter(item => item.id !== itemId),
      updatedAt: new Date().toISOString(),
    }));
  };

  const handleSave = async () => {
    if (!programId || !checklist.name) {
      setError('Please fill in all required fields');
      return;
    }

    setIsSaving(true);
    try {
      await onUpdateChecklist(checklist);
      toast.success(isNew ? 'Checklist created' : 'Checklist saved');
      navigate('/app/checklists');
    } catch (err) {
      console.error('Error saving checklist:', err);
      setError('Failed to save checklist');
    } finally {
      setIsSaving(false);
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <Loader2 className="w-8 h-8 animate-spin text-primary-500" />
      </div>
    );
  }

  return (
    <div className="space-y-6 pb-20">
      {/* Header */}
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-4">
          <button
            onClick={() => navigate('/app/checklists')}
            className="p-2 text-gray-400 hover:text-primary-500 rounded-lg hover:bg-dark-50"
          >
            <ArrowLeft className="w-5 h-5" />
          </button>
          <h1 className="text-2xl font-bold text-white">
            {isNew ? 'New Checklist' : checklist.name}
          </h1>
        </div>
        <div className="flex gap-2">
          <button
            onClick={handleSave}
            disabled={isSaving}
            className="flex items-center gap-2 px-4 py-2 bg-primary-500 text-white rounded-md hover:bg-primary-600 disabled:opacity-50"
          >
            {isSaving ? (
              <Loader2 className="w-4 h-4 animate-spin" />
            ) : (
              <Save className="w-4 h-4" />
            )}
            {isNew ? 'Create Checklist' : 'Save Changes'}
          </button>
          <button
            onClick={() => navigate('/app/checklists')}
            className="flex items-center gap-2 px-4 py-2 bg-dark-100 text-gray-400 rounded-md hover:bg-dark-50"
          >
            <X className="w-4 h-4" />
            Cancel
          </button>
        </div>
      </div>

      {/* Basic Info */}
      <div className="bg-dark-200 rounded-lg p-6">
        {error && (
          <div className="mb-6 p-4 bg-red-900/30 border border-red-800 rounded-md text-red-200">
            {error}
          </div>
        )}

        <div className="space-y-4">
          <div className="flex flex-col md:flex-row gap-4">
            <div className="flex-1">
              <label className="block text-sm font-medium text-gray-400 mb-1">
                Name
              </label>
              <input
                type="text"
                value={checklist.name}
                onChange={(e) => setChecklist(prev => ({ 
                  ...prev, 
                  name: e.target.value,
                  updatedAt: new Date().toISOString()
                }))}
                className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500"
                placeholder="Enter checklist name..."
              />
            </div>
            <div className="md:w-1/3">
              <label className="block text-sm font-medium text-gray-400 mb-1">
                Category
              </label>
              <select
                value={checklist.category}
                onChange={(e) => setChecklist(prev => ({ 
                  ...prev, 
                  category: e.target.value as ChecklistCategory,
                  updatedAt: new Date().toISOString()
                }))}
                className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500"
              >
                {categories.map(category => (
                  <option key={category} value={category}>{category}</option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>

      {/* Checklist Items */}
      <div className="bg-dark-200 rounded-lg p-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-lg font-semibold text-white">Items</h2>
        </div>

        <ChecklistItemsContainer
          items={checklist.items}
          onAddItem={handleAddItem}
          onToggleItem={handleToggleItem}
          onUpdateItem={handleUpdateItem}
          onDeleteItem={handleDeleteItem}
          emptyStateMessage="No items in this checklist yet."
          addButtonLabel="Add"
          addPlaceholder="Add new item..."
        />
      </div>
    </div>
  );
}