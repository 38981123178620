import { geminiModel } from '../config/firebase';
import type { ChatMessage, ChatContext } from '../types/chat';
import { trackError, ErrorCategory, ErrorSeverity } from './errorTracking';

export async function sendMessage(
  message: string,
  context: ChatContext,
  previousMessages: ChatMessage[]
): Promise<ChatMessage> {
  try {
    // Format context into a summary
    const contextSummary = `
      You are Pit Bot, an AI assistant for a racing/motorsports app. You have access to the following context:
      
      User's Vehicles: ${context.vehicles.map(v => `${v.year} ${v.make} ${v.model}`).join(', ')}
      Budget: ${context.budget ? `Balance: $${context.budget.balance.toFixed(2)}` : 'No budget data'}
      Upcoming Events: ${context.events.map(e => `${e.title} (${e.date})`).join(', ')}
      Recent Parts: ${context.recentParts.map(p => p.name).join(', ')}
    `;

    // Convert previous messages to the format expected by Vertex AI
    const history = previousMessages.map(msg => ({
      role: msg.role === 'assistant' ? 'model' as const : 'user' as const,
      parts: [{ text: msg.content }]
    }));

    // Start or continue chat with context
    const chat = geminiModel.startChat({
      history: [
        {
          role: "user",
          parts: [{ text: contextSummary }]
        },
        {
          role: "model",
          parts: [{ text: "I understand my role and have access to the provided context. How can I help you today?" }]
        },
        ...history
      ],
      generationConfig: {
        maxOutputTokens: 200,
        temperature: 0.7,
      },
      systemInstruction: {
        role: "system",
        parts: [{ 
          text: `You are Pit Bot, an AI assistant for a racing/motorsports app. Your role is to:
          1. Provide concise, practical advice about racing and motorsports
          2. Use the provided context about vehicles, budget, events, and parts to give relevant advice
          3. Keep responses focused and under 3-4 sentences when possible
          4. Maintain a professional but friendly tone
          5. Only discuss racing/motorsports topics
          6. Focus on data driven insights and practical advice
          7. You may assume the user has a basic understanding of racing and motorsports terminology, do not dumb down your responses`
        }]
      }
    });

    // Send the new message
    const result = await chat.sendMessage(message);
    const response = await result.response;
    const text = response.text();

    return {
      id: crypto.randomUUID(),
      role: 'assistant',
      content: text,
      timestamp: new Date().toISOString()
    };
  } catch (error) {
    trackError(error as Error, {
      category: ErrorCategory.API,
      severity: ErrorSeverity.P1,
      action: 'send_chat_message',
      additionalData: {
        message,
        context_size: JSON.stringify(context).length,
        error_message: (error as Error).message
      }
    });
    throw error;
  }
} 