import React, { useEffect, useState } from 'react';
import { Loader2, Calendar } from 'lucide-react';
import { parseISO, isBefore, addDays, isAfter, format, isSameDay } from 'date-fns';

interface WeatherForecastProps {
  latitude?: number;
  longitude?: number;
  eventDate: string;
}

interface WeatherPeriod {
  startTime: string;
  endTime: string;
  isDaytime: boolean;
  temperature: number;
  temperatureUnit: string;
  shortForecast: string;
  detailedForecast: string;
  icon: string;
  windSpeed: string;
  windDirection: string;
  probabilityOfPrecipitation: {
    value: number | null;
  };
}

interface CachedForecast {
  timestamp: number;
  data: WeatherPeriod[];
}

// Cache forecasts for 1 hour
const CACHE_DURATION = 60 * 60 * 1000;
const forecastCache = new Map<string, CachedForecast>();

export default function WeatherForecast({ latitude, longitude, eventDate }: WeatherForecastProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [forecast, setForecast] = useState<WeatherPeriod | null>(null);

  useEffect(() => {
    const fetchForecast = async () => {
      if (!latitude || !longitude) return;

      // Check if event is within next 7 days
      const eventDateObj = parseISO(eventDate);
      const now = new Date();
      if (isBefore(eventDateObj, now) || isAfter(eventDateObj, addDays(now, 7))) {
        return;
      }

      const cacheKey = `${latitude},${longitude}`;
      const cachedData = forecastCache.get(cacheKey);
      
      // Use cached data if it's less than 1 hour old
      if (cachedData && (Date.now() - cachedData.timestamp) < CACHE_DURATION) {
        const matchingPeriod = cachedData.data.find((period: WeatherPeriod) => 
          isSameDay(parseISO(period.startTime), eventDateObj) && period.isDaytime
        );
        if (matchingPeriod) {
          setForecast(matchingPeriod);
          return;
        }
      }

      setIsLoading(true);
      setError(null);

      try {
        // First, get the grid endpoint
        const pointResponse = await fetch(
          `https://api.weather.gov/points/${latitude},${longitude}`,
          {
            headers: {
              'User-Agent': '(pitprep.com, contact@pitprep.com)'
            }
          }
        );
        
        if (!pointResponse.ok) {
          throw new Error('Failed to get forecast grid');
        }

        const pointData = await pointResponse.json();
        const forecastUrl = pointData.properties.forecast;

        // Then get the actual forecast
        const forecastResponse = await fetch(forecastUrl, {
          headers: {
            'User-Agent': '(pitprep.com, contact@pitprep.com)'
          }
        });

        if (!forecastResponse.ok) {
          throw new Error('Failed to get forecast data');
        }

        const forecastData = await forecastResponse.json();
        const periods = forecastData.properties.periods;

        // Cache the forecast data
        forecastCache.set(cacheKey, {
          timestamp: Date.now(),
          data: periods
        });

        // Find the matching period for our event date
        const matchingPeriod = periods.find((period: WeatherPeriod) => 
          isSameDay(parseISO(period.startTime), eventDateObj) && period.isDaytime
        );

        if (matchingPeriod) {
          setForecast(matchingPeriod);
        }
      } catch (err) {
        console.error('Error fetching weather:', err);
        setError('Failed to load weather forecast');
      } finally {
        setIsLoading(false);
      }
    };

    fetchForecast();
  }, [latitude, longitude, eventDate]);

  if (!latitude || !longitude) return null;

  // Check if event is within next 7 days
  const eventDateObj = parseISO(eventDate);
  const now = new Date();
  if (isBefore(eventDateObj, now) || isAfter(eventDateObj, addDays(now, 7))) {
    return null;
  }

  if (isLoading) {
    return (
      <div className="bg-dark-100 rounded-lg p-4">
        <div className="flex items-center gap-2 text-gray-400">
          <Loader2 className="w-5 h-5 animate-spin" />
          <span>Loading forecast...</span>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-dark-100 rounded-lg p-4">
        <div className="text-gray-400">{error}</div>
      </div>
    );
  }

  if (!forecast) {
    return null;
  }

  return (
    <div className="bg-dark-100 rounded-lg p-4">
      <div className="flex items-center gap-2 mb-3 text-sm text-gray-400">
        <Calendar className="w-4 h-4" />
        <span>Weather forecast for {format(parseISO(eventDate), 'MMMM d, yyyy')}</span>
      </div>
      <div className="flex items-start gap-4">
        <img 
          src={forecast.icon} 
          alt={forecast.shortForecast}
          className="w-12 h-12"
        />
        <div>
          <div className="flex items-baseline gap-2">
            <span className="text-lg font-medium text-white">
              {forecast.temperature}°{forecast.temperatureUnit}
            </span>
            <span className="text-sm text-gray-400">
              {forecast.shortForecast}
            </span>
          </div>
          <div className="text-sm text-gray-400 mt-1">
            {forecast.windSpeed} {forecast.windDirection}
          </div>
          {forecast.probabilityOfPrecipitation.value !== null && (
            <div className="text-sm text-gray-400 mt-1">
              {forecast.probabilityOfPrecipitation.value}% chance of precipitation
            </div>
          )}
          <div className="text-sm text-gray-400 mt-2">
            {forecast.detailedForecast}
          </div>
        </div>
      </div>
    </div>
  );
} 