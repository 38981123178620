import React from 'react';
import { Plus } from 'lucide-react';
import type { ExpensesListProps } from './types';
import { TransactionList } from './TransactionList';
import { EXPENSE_CATEGORY_ICONS, EXPENSE_CATEGORY_COLORS } from './constants';

export default function ExpensesList({ budget, onDeleteBudgetItem, onOpenExpenseModal, onEditExpense }: ExpensesListProps) {
  return (
    <TransactionList
      budget={budget}
      onDeleteBudgetItem={onDeleteBudgetItem}
      onOpenModal={onOpenExpenseModal}
      onEditItem={onEditExpense}
      type="expense"
      title="Expenses"
      emptyStateIcon={<Plus className="w-12 h-12 text-gray-400 mx-auto mb-3" />}
      emptyStateMessage="No expenses added yet"
      addButtonText="Add Expense"
      categoryIcons={EXPENSE_CATEGORY_ICONS}
      categoryColors={EXPENSE_CATEGORY_COLORS}
      amountColor="text-white"
    />
  );
} 