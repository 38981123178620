import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Trash2, ChevronDown, Wrench, X, Check } from 'lucide-react';
import { createPortal } from 'react-dom';
import { toast } from 'react-hot-toast';
import type { Part } from '../../types/part';
import type { Vehicle } from '../../types/vehicle';
import { getPartIcon } from '../../config/partIcons';
import { 
  iconContainerClasses, 
  iconImageClasses, 
  cardTitleClasses, 
  cardPaddingClasses,
  badgeClasses,
  badgeContainerClasses,
  actionButtonClasses,
  cornerButtonPositionClasses
} from '../../utils/cardStyles';

// Brand logo component that uses Brandfetch API
const BrandLogo = ({ manufacturer, size = 28, className = '' }: { manufacturer: string | undefined, size?: number, className?: string }) => {
  const [logoUrl, setLogoUrl] = useState<string | null>(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!manufacturer) return;
    
    // Convert manufacturer name to a domain-like format
    // This is a simplification - in a real app, you might want to have a mapping of manufacturer names to domains
    const domain = manufacturer.toLowerCase().replace(/[^a-z0-9]/g, '') + '.com';
    
    // Construct the Brandfetch API URL - using light theme for dark UI background
    const url = `https://cdn.brandfetch.io/${domain}/icon/theme/light/fallback/lettermark/h/${size}/w/${size}?c=1idiSpC3xARkv5gG2o8`;
    
    // Check if the image loads successfully
    const img = new Image();
    img.onload = () => setLogoUrl(url);
    img.onerror = () => setError(true);
    img.src = url;
  }, [manufacturer, size]);

  if (error || !manufacturer) {
    // Lettermark fallback
    return (
      <div 
        className={`flex items-center justify-center rounded-full bg-primary-500/20 text-primary-300 ${className}`}
        style={{ width: size, height: size }}
      >
        {manufacturer ? manufacturer.charAt(0).toUpperCase() : '?'}
      </div>
    );
  }

  if (!logoUrl) {
    // Loading state
    return (
      <div 
        className={`flex items-center justify-center rounded-full bg-dark-300 ${className}`}
        style={{ width: size, height: size }}
      >
        <div className="w-3 h-3 border-2 border-t-transparent border-primary-300 rounded-full animate-spin"></div>
      </div>
    );
  }

  // Loaded logo
  return (
    <img 
      src={logoUrl} 
      alt={manufacturer} 
      className={`rounded-full ${className}`}
      style={{ width: size, height: size }}
    />
  );
};

interface PartCardProps {
  part: Part;
  onUpdate: (partId: string, updates: Partial<Part>) => Promise<void>;
  onDelete?: (partId: string) => Promise<void>;
  vehicles?: Vehicle[];
  isGarageView?: boolean;
  isDragging?: boolean;
}

export default function PartCard({ part, onUpdate, onDelete, vehicles = [], isGarageView = false, isDragging = false }: PartCardProps) {
  const navigate = useNavigate();
  const [isUpdating, setIsUpdating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showVehicleDropdown, setShowVehicleDropdown] = useState(false);
  const [actionSuccess, setActionSuccess] = useState<'install' | 'uninstall' | null>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  
  // Determine if the part is currently installed
  const isInstalled = part.metadata?.isInstalled === true;
  const hasMultipleVehicles = vehicles.length > 1;
  
  // Reset success state after animation
  useEffect(() => {
    if (actionSuccess) {
      const timer = setTimeout(() => {
        setActionSuccess(null);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [actionSuccess]);
  
  // Close dropdown when clicking outside
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setShowVehicleDropdown(false);
      }
    }
    
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  
  // Update dropdown position when it's shown
  useEffect(() => {
    if (showVehicleDropdown && buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      setDropdownPosition({
        top: rect.bottom + window.scrollY + 5,
        left: rect.right - 256 + window.scrollX // 256px is the width of the dropdown
      });
    }
  }, [showVehicleDropdown]);
  
  const handleInstallClick = (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent navigation to detail page
    
    // If we have multiple vehicles, show the dropdown
    if (hasMultipleVehicles) {
      setShowVehicleDropdown(prev => !prev);
    } 
    // If we have only one vehicle, install to that vehicle
    else if (vehicles.length === 1) {
      installPart(vehicles[0].id);
    }
  };
  
  const handleUninstallClick = (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent navigation to detail page
    uninstallPart();
  };
  
  const installPart = async (vehicleId: string) => {
    setIsUpdating(true);
    try {
      const updates: Partial<Part> = {
        vehicleId: vehicleId,
        metadata: {
          ...part.metadata,
          isInstalled: true,
          installationDate: part.metadata?.installationDate || new Date().toISOString().split('T')[0]
        }
      };
      
      await onUpdate(part.id, updates);
      setShowVehicleDropdown(false);
      setActionSuccess('install');
      
      // Find vehicle name for toast
      const vehicleName = vehicles.find(v => v.id === vehicleId)?.name || 
                         `${vehicles.find(v => v.id === vehicleId)?.year} ${vehicles.find(v => v.id === vehicleId)?.make} ${vehicles.find(v => v.id === vehicleId)?.model}`;
      
      toast.success(
        <div className="flex items-center gap-2">
          <Check size={18} className="text-green-500" />
          <span><strong>{part.name}</strong> installed on <strong>{vehicleName}</strong></span>
        </div>
      );
    } catch (error) {
      console.error('Error installing part:', error);
      toast.error(`Failed to install ${part.name}`);
    } finally {
      setIsUpdating(false);
    }
  };
  
  const uninstallPart = async () => {
    setIsUpdating(true);
    try {
      const updates: Partial<Part> = {
        vehicleId: null,
        metadata: {
          ...part.metadata,
          isInstalled: false
        }
      };
      
      await onUpdate(part.id, updates);
      setActionSuccess('uninstall');
      
      // Toast notification is handled by PartsCardView or parent component
    } catch (error) {
      console.error('Error uninstalling part:', error);
      toast.error(`Failed to uninstall ${part.name}`);
    } finally {
      setIsUpdating(false);
    }
  };
  
  const handleVehicleSelect = (e: React.MouseEvent, vehicleId: string) => {
    e.stopPropagation(); // Prevent navigation and dropdown toggle
    installPart(vehicleId);
  };

  const handleDelete = async (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent navigation to detail page
    
    if (!onDelete || isDeleting) return;
    
    // Delete without confirmation
    setIsDeleting(true);
    try {
      await onDelete(part.id);
      toast.success(`${part.name} deleted successfully`);
    } catch (error) {
      console.error('Error deleting part:', error);
      toast.error(`Failed to delete ${part.name}`);
    } finally {
      setIsDeleting(false);
    }
  };

  const handleCardClick = () => {
    // Don't navigate if dragging
    if (isDragging) return;
    navigate(`/app/parts/${part.id}`);
  };

  // Determine if we should show the install button
  const showInstallButton = !isInstalled && isGarageView;
  const showUninstallButton = isInstalled;

  // Success animation classes
  const successAnimationClass = actionSuccess ? 
    (actionSuccess === 'install' ? 'animate-pulse-green' : 'animate-pulse-blue') : '';

  return (
    <div 
      className={`bg-dark-200 rounded-lg overflow-hidden shadow-md hover:shadow-lg transition-all duration-300 border border-dark-50 hover:border-primary-500/30 cursor-pointer h-full flex flex-col ${successAnimationClass} ${isDragging ? 'shadow-xl ring-2 ring-primary-500' : ''}`}
      onClick={handleCardClick}
    >
      <div className={`${cardPaddingClasses} flex flex-col flex-1 relative`}>
        {/* Delete button - moved to top right corner */}
        {onDelete && !isDragging && (
          <button
            onClick={handleDelete}
            disabled={isDeleting}
            className={`${cornerButtonPositionClasses} p-1 sm:p-1.5 ipad:p-1 rounded-md text-gray-400 hover:text-red-500 hover:bg-dark-300 z-10`}
            title="Delete part"
          >
            {isDeleting ? (
              <div className="w-4 h-4 sm:w-5 sm:h-5 ipad:w-4 ipad:h-4 border-2 border-t-transparent border-red-500 rounded-full animate-spin"></div>
            ) : (
              <>
                <Trash2 size={16} className="sm:hidden ipad:block" />
                <Trash2 size={18} className="hidden sm:block ipad:hidden" />
              </>
            )}
          </button>
        )}
        
        <div className="flex flex-1">
          {/* Left side - Icon - with improved responsive sizing */}
          <div className={iconContainerClasses}>
            <img 
              src={`/icons/${getPartIcon(part.category, part.subcategory)}`} 
              alt={part.category} 
              className={iconImageClasses}
            />
          </div>
          
          {/* Right side - Content */}
          <div className="flex-1 min-w-0 flex flex-col justify-between overflow-hidden">
            <div>
              <h3 className={cardTitleClasses}>{part.name}</h3>
              
              {/* Manufacturer with brand logo */}
              <div className="flex items-center gap-1 sm:gap-2 mb-1">
                <BrandLogo manufacturer={part.manufacturer} size={18} className="flex-shrink-0 hidden xs:block" />
                <p className="text-xs sm:text-sm ipad:text-xs text-gray-400 truncate">{part.manufacturer || 'No manufacturer'}</p>
              </div>
              
              {/* Responsive layout for badges and price */}
              <div className="flex flex-col xs:flex-row flex-wrap justify-between gap-y-1 gap-x-2 mb-1 sm:mb-2">
                {/* Category badge */}
                <div className={badgeContainerClasses}>
                  <span className={`${badgeClasses} bg-primary-500/20 text-primary-300`}>
                    {part.category}{part.subcategory ? ` • ${part.subcategory}` : ''}
                  </span>
                  
                  {/* Installation status badge */}
                  {isInstalled && (
                    <span className={`${badgeClasses} bg-green-500/20 text-green-300`}>
                      Installed
                    </span>
                  )}
                </div>
                
                {/* Price display */}
                {part.cost > 0 && (
                  <p className="text-xs sm:text-sm ipad:text-xs font-medium text-primary-300 text-left xs:text-right flex-shrink-0 mt-1 xs:mt-0">
                    ${part.cost.toFixed(2)}
                  </p>
                )}
              </div>
            </div>
            
            {/* Install/Uninstall buttons - moved to content area */}
            <div className="mt-1 sm:mt-auto">
              <div className="relative">
                {/* Show Install button if not installed */}
                {showInstallButton && !isDragging && (
                  <button
                    ref={buttonRef}
                    onClick={handleInstallClick}
                    disabled={isUpdating}
                    className={`${actionButtonClasses} bg-primary-500 hover:bg-primary-600`}
                    title="Install part"
                  >
                    {isUpdating ? (
                      <div className="w-3 h-3 sm:w-4 sm:h-4 ipad:w-3 ipad:h-3 border-2 border-t-transparent border-white rounded-full animate-spin mr-1"></div>
                    ) : (
                      <Wrench 
                        size={12} 
                        className="mr-1 w-3 h-3 sm:w-4 sm:h-4 ipad:w-3 ipad:h-3"
                      />
                    )}
                    <span className="font-medium">
                      Install
                      {hasMultipleVehicles && (
                        <ChevronDown size={10} className="ml-1 inline w-2.5 h-2.5 sm:w-3 sm:h-3 ipad:w-2.5 ipad:h-2.5" />
                      )}
                    </span>
                  </button>
                )}
                
                {/* Show Uninstall button if installed */}
                {showUninstallButton && !isDragging && (
                  <button
                    onClick={handleUninstallClick}
                    disabled={isUpdating}
                    className={`${actionButtonClasses} bg-dark-100 hover:bg-dark-50`}
                    title="Uninstall part"
                  >
                    {isUpdating ? (
                      <div className="w-3 h-3 sm:w-4 sm:h-4 ipad:w-3 ipad:h-3 border-2 border-t-transparent border-white rounded-full animate-spin mr-1"></div>
                    ) : (
                      <X 
                        size={12} 
                        className="mr-1 w-3 h-3 sm:w-4 sm:h-4 ipad:w-3 ipad:h-3"
                      />
                    )}
                    <span className="font-medium">Uninstall</span>
                  </button>
                )}
                
                {/* Drag indicator when dragging */}
                {isDragging && (
                  <div className="text-xs text-primary-300 font-medium">
                    Drag to install on a vehicle
                  </div>
                )}
                
                {/* Vehicle selection dropdown - using portal to avoid clipping */}
                {showVehicleDropdown && hasMultipleVehicles && createPortal(
                  <div 
                    ref={dropdownRef}
                    className="w-64 bg-dark-100 rounded-md shadow-lg z-50 overflow-hidden fixed animate-fadeIn"
                    style={{ 
                      top: `${dropdownPosition.top}px`, 
                      left: `${dropdownPosition.left}px`
                    }}
                    onClick={(e) => e.stopPropagation()} // Prevent card click
                  >
                    <div className="py-1">
                      <div className="px-3 py-2 text-xs font-medium text-gray-400 border-b border-dark-200">
                        Select Vehicle to Install On
                      </div>
                      {vehicles.map(vehicle => (
                        <button
                          key={vehicle.id}
                          onClick={(e) => handleVehicleSelect(e, vehicle.id)}
                          className="w-full text-left px-4 py-2.5 text-sm text-white hover:bg-dark-200 transition-colors flex items-center"
                        >
                          {vehicle.year} {vehicle.make} {vehicle.model}
                        </button>
                      ))}
                    </div>
                  </div>,
                  document.body
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
} 