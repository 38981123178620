import React, { useState } from 'react';
import { format, eachMonthOfInterval, startOfYear, endOfYear, isSameMonth, getDate } from 'date-fns';
import { Calendar, MapPin, Flag, Wrench, Car, HelpCircle } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import type { Event, EventType, EventStatus } from '../../types/event';

interface SeasonTimelineProps {
  events: Event[];
}

const getEventIcon = (type: EventType) => {
  switch (type) {
    case 'race':
      return <Flag className="w-4 h-4" />;
    case 'practice':
      return <Car className="w-4 h-4" />;
    case 'maintenance':
      return <Wrench className="w-4 h-4" />;
    default:
      return <HelpCircle className="w-4 h-4" />;
  }
};

const getEventColor = (type: EventType) => {
  switch (type) {
    case 'race':
      return 'bg-red-500';
    case 'practice':
      return 'bg-blue-500';
    case 'maintenance':
      return 'bg-yellow-500';
    default:
      return 'bg-gray-500';
  }
};

const getStatusColor = (status: EventStatus) => {
  switch (status) {
    case 'confirmed':
      return 'ring-primary-500 ring-2';
    case 'cancelled':
      return 'opacity-50';
    case 'considering':
      return 'ring-gray-500 ring-2 opacity-75';
    default:
      return '';
  }
};

interface MonthGroup {
  month: Date;
  events: Event[];
}

export function SeasonTimeline({ events }: SeasonTimelineProps) {
  const navigate = useNavigate();
  
  // Get all months of the current year
  const currentYear = new Date().getFullYear();
  const yearStart = startOfYear(new Date(currentYear, 0));
  const yearEnd = endOfYear(new Date(currentYear, 0));
  const monthsOfYear = eachMonthOfInterval({ start: yearStart, end: yearEnd });

  // Group events by month
  const monthGroups: MonthGroup[] = monthsOfYear.map(month => ({
    month,
    events: events.filter(event => isSameMonth(new Date(event.date), month))
  }));

  // Calculate arc positions for events within a month
  const getEventPosition = (dayOfMonth: number, totalEvents: number, eventIndex: number) => {
    // Normalize the day to be between 0 and 1 for the month
    const normalizedDay = (dayOfMonth - 1) / 31;
    
    // Calculate vertical position based on number of events
    const verticalOffset = totalEvents > 1 
      ? -20 + (eventIndex * (40 / (totalEvents - 1))) 
      : 0;
    
    return {
      top: verticalOffset
    };
  };

  const handleEventClick = (event: Event, e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent event bubbling
    if (event.id) {
      navigate(`/app/schedule/${event.id}`);
    }
  };

  return (
    <>
      {/* Desktop Timeline (Horizontal) */}
      <div className="hidden md:block bg-dark-200 rounded-lg p-6 mb-6">
        <h2 className="text-lg font-semibold text-white mb-4">Season Timeline</h2>
        <div className="relative pt-16 pb-8">
          {/* Timeline Line */}
          <div className="absolute left-0 right-0 h-1 bg-dark-100 top-1/2 -translate-y-1/2" />
          
          {/* Months */}
          <div className="relative flex justify-between mb-8">
            {monthsOfYear.map((month) => (
              <div key={month.toString()} className="flex flex-col items-center">
                <div className="w-1 h-3 bg-dark-100 mb-2" />
                <span className="text-xs text-gray-400">
                  {format(month, 'MMM')}
                </span>
              </div>
            ))}
          </div>
          
          {/* Month Groups */}
          <div className="relative h-24">
            {monthGroups.map((group, monthIndex) => {
              const position = `${(monthIndex / (monthsOfYear.length - 1)) * 100}%`;
              
              return (
                <div
                  key={group.month.toString()}
                  className="absolute transform -translate-x-1/2"
                  style={{ left: position }}
                >
                  {group.events.map((event, eventIndex) => {
                    const eventDate = new Date(event.date);
                    const dayOfMonth = getDate(eventDate);
                    const position = getEventPosition(dayOfMonth, group.events.length, eventIndex);
                    
                    return (
                      <div
                        key={event.id}
                        className="absolute group"
                        style={{ 
                          top: position.top,
                          transition: 'all 0.3s ease'
                        }}
                      >
                        <button
                          onClick={(e) => handleEventClick(event, e)}
                          className={`w-8 h-8 rounded-full flex items-center justify-center ${getEventColor(event.type)} ${getStatusColor(event.status)} cursor-pointer transition-all duration-200 hover:scale-110 shadow-lg focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 focus:ring-offset-dark-200`}
                        >
                          {getEventIcon(event.type)}
                        </button>
                        
                        {/* Tooltip */}
                        <div className="absolute bottom-full left-1/2 -translate-x-1/2 mb-2 hidden group-hover:block z-50">
                          <div className="bg-dark-100 text-white text-sm rounded-lg py-2 px-3 shadow-lg whitespace-nowrap">
                            <p className="font-medium">{event.title}</p>
                            <p className="text-xs text-gray-400">{format(eventDate, 'MMM d, yyyy')}</p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
        
        {/* Legend */}
        <div className="mt-4 flex items-center justify-center gap-6">
          <div className="flex items-center gap-2">
            <div className="w-3 h-3 rounded-full bg-red-500" />
            <span className="text-sm text-gray-400">Race</span>
          </div>
          <div className="flex items-center gap-2">
            <div className="w-3 h-3 rounded-full bg-blue-500" />
            <span className="text-sm text-gray-400">Practice</span>
          </div>
          <div className="flex items-center gap-2">
            <div className="w-3 h-3 rounded-full bg-yellow-500" />
            <span className="text-sm text-gray-400">Maintenance</span>
          </div>
        </div>
      </div>

      {/* Mobile Timeline (Vertical) */}
      <div className="md:hidden bg-dark-200 rounded-lg p-6 mb-6">
        <h2 className="text-lg font-semibold text-white mb-4">Season Timeline</h2>
        <div className="relative pl-8">
          {/* Vertical Line */}
          <div className="absolute left-3 top-0 bottom-0 w-1 bg-dark-100" />
          
          {/* Events */}
          <div className="space-y-6">
            {events.map((event) => (
              <div key={event.id} className="relative">
                {/* Event Icon */}
                <button
                  onClick={(e) => handleEventClick(event, e)}
                  className={`absolute left-[-1.25rem] w-8 h-8 rounded-full z-10 flex items-center justify-center ${getEventColor(event.type)} ${getStatusColor(event.status)} cursor-pointer transition-all duration-200 hover:scale-110 shadow-lg focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 focus:ring-offset-dark-200`}
                >
                  {getEventIcon(event.type)}
                </button>
                
                {/* Event Details */}
                <button
                  onClick={(e) => handleEventClick(event, e)}
                  className="w-full text-left bg-dark-100 rounded-lg p-4 hover:bg-dark-50 transition-colors"
                >
                  <h3 className="text-white font-medium mb-2">{event.title}</h3>
                  <div className="flex flex-col gap-2 text-sm text-gray-400">
                    <div className="flex items-center gap-2">
                      <Calendar className="w-4 h-4" />
                      {format(new Date(event.date), 'MMM d, yyyy')}
                    </div>
                    {event.location && (
                      <div className="flex items-center gap-2">
                        <MapPin className="w-4 h-4" />
                        <span className="truncate">{event.location}</span>
                      </div>
                    )}
                  </div>
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
} 