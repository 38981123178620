import React from 'react';
import { X, Lightbulb } from 'lucide-react';
import type { Part, PartCategory, PartSubcategory } from '../../types/part';
import { getPartIcon } from '../../config/partIcons';
import { 
  iconContainerClasses, 
  iconImageClasses, 
  cardTitleClasses, 
  cardPaddingClasses,
  badgeClasses,
  badgeContainerClasses,
  cornerButtonPositionClasses
} from '../../utils/cardStyles';

interface SuggestedPartCardProps {
  part: Part & { isSuggestion: boolean; suggestionKey: string };
  onDismiss: (key: string) => void;
  onAdd: (category: PartCategory, subcategory: PartSubcategory) => void;
}

export default function SuggestedPartCard({ part, onDismiss, onAdd }: SuggestedPartCardProps) {
  return (
    <div 
      className="bg-dark-200/70 rounded-lg overflow-hidden shadow-none border-2 border-dashed border-yellow-500/15 hover:border-yellow-500/30 transition-all h-full cursor-pointer"
      style={{ borderRadius: '8px', borderWidth: '2px', borderStyle: 'dashed' }}
      onClick={() => onAdd(part.category, part.subcategory as PartSubcategory)}
    >
      <div className={`${cardPaddingClasses} flex flex-col h-full relative opacity-80`}>
        <div className="flex flex-1">
          {/* Left side - Icon - with improved responsive sizing */}
          <div className={iconContainerClasses}>
            <div className="relative">
              <img 
                src={`/icons/${getPartIcon(part.category, part.subcategory)}`} 
                alt={part.category} 
                className={`${iconImageClasses} opacity-50`}
              />
              <div className="absolute -top-1 -right-1 bg-yellow-400/80 rounded-full p-0.5 xs:p-1">
                <Lightbulb className="h-2.5 w-2.5 xs:h-3 xs:w-3 text-dark-300" />
              </div>
            </div>
          </div>
          
          {/* Right side - Content */}
          <div className="flex-1 min-w-0 flex flex-col justify-between overflow-hidden">
            <div>
              <div className="flex items-start gap-1 sm:gap-2 justify-between">
                <h3 className={`${cardTitleClasses} text-gray-400`}>{part.name}</h3>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    onDismiss(part.suggestionKey);
                  }}
                  className={`${cornerButtonPositionClasses} text-gray-500 hover:text-white p-0.5 sm:p-1`}
                  title="Dismiss suggestion"
                >
                  <X className="h-3.5 w-3.5 sm:h-4 sm:w-4" />
                </button>
              </div>
              
              <p className="text-xs sm:text-sm ipad:text-xs text-gray-500 mb-1 sm:mb-2 line-clamp-2">{part.description}</p>
              
              {/* Category badge */}
              <div className={badgeContainerClasses}>
                <span className={`${badgeClasses} bg-primary-500/5 text-primary-300/60 mr-1`}>
                  {part.category}{part.subcategory ? ` • ${part.subcategory}` : ''}
                </span>
                <span className={`${badgeClasses} bg-yellow-500/10 text-yellow-300/70`}>
                  Suggested
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
} 