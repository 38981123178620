import React, { useState, useRef, useEffect } from 'react';
import { Trash2, Check } from 'lucide-react';
import type { ChecklistItem as ChecklistItemType } from '../../types/checklist';

interface ChecklistItemProps {
  item: ChecklistItemType;
  onToggle: (id: string) => void;
  onUpdate: (id: string, updates: Partial<ChecklistItemType>) => void;
  onDelete: (id: string) => void;
  showDeleteButton?: boolean;
  allowEditing?: boolean;
  compact?: boolean;
}

export default function ChecklistItem({ 
  item, 
  onToggle, 
  onUpdate, 
  onDelete, 
  showDeleteButton = true,
  allowEditing = true,
  compact = false
}: ChecklistItemProps) {
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  // Focus the input when editing starts
  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  // Handle clicking outside to save edits
  useEffect(() => {
    if (!allowEditing) return;
    
    const handleClickOutside = (e: MouseEvent) => {
      if (isEditing && inputRef.current && !inputRef.current.contains(e.target as Node)) {
        setIsEditing(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isEditing, allowEditing]);

  return (
    <div className={`${compact ? 'bg-dark-300' : 'bg-dark-100'} rounded-lg ${compact ? 'p-3' : 'p-4'} hover:bg-dark-50 group`}>
      <div className="flex items-center gap-3">
        <button
          onClick={() => onToggle(item.id)}
          className={`p-1 rounded-md transition-colors ${
            item.completed
              ? 'bg-primary-500 text-white'
              : 'bg-dark-200 text-gray-400 hover:bg-primary-500/20 hover:text-primary-500'
          }`}
          aria-label={item.completed ? "Mark as incomplete" : "Mark as complete"}
        >
          <Check className="w-4 h-4" />
        </button>
        
        <div 
          className={`flex-1 ${allowEditing ? 'cursor-text' : ''}`}
          onClick={() => allowEditing && setIsEditing(true)}
        >
          {isEditing ? (
            <input
              ref={inputRef}
              type="text"
              value={item.text}
              onChange={(e) => onUpdate(item.id, { text: e.target.value })}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setIsEditing(false);
                } else if (e.key === 'Escape') {
                  setIsEditing(false);
                }
              }}
              className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500"
            />
          ) : (
            <p className={`text-white ${item.completed ? 'line-through text-gray-400' : ''}`}>
              {item.text}
            </p>
          )}
        </div>
        
        {showDeleteButton && (
          <button
            onClick={() => onDelete(item.id)}
            className={`p-1 text-gray-400 hover:text-red-500 ${compact ? 'opacity-0 group-hover:opacity-100 transition-opacity' : ''}`}
            aria-label="Delete item"
          >
            <Trash2 className="w-4 h-4" />
          </button>
        )}
      </div>
    </div>
  );
} 