import React, { useMemo } from 'react';
import { Car, DollarSign, TrendingUp } from 'lucide-react';
import type { Vehicle } from '../../types/vehicle';
import type { Part } from '../../types/part';
import type { Budget } from '../../types/budget';

interface VehicleBudgetOverviewProps {
  vehicle: Vehicle;
  parts: Part[];
  budget: Budget;
}

export default function VehicleBudgetOverview({ vehicle, parts, budget }: VehicleBudgetOverviewProps) {
  // Calculate parts costs for this vehicle
  const partsCosts = useMemo(() => {
    const vehicleParts = parts.filter(part => part.vehicleId === vehicle.id);
    return vehicleParts.reduce((sum, part) => sum + (part.cost || 0), 0);
  }, [parts, vehicle.id]);

  // Get all part IDs for this vehicle
  const vehiclePartIds = useMemo(() => {
    return parts
      .filter(part => part.vehicleId === vehicle.id)
      .map(part => part.id);
  }, [parts, vehicle.id]);

  // Calculate expenses for this vehicle
  const expenses = useMemo(() => {
    return budget.expenses.filter(expense => {
      // Only include expenses that are directly linked to this vehicle's parts
      return expense.partId && vehiclePartIds.includes(expense.partId);
    });
  }, [budget.expenses, vehiclePartIds]);

  // Calculate total expenses
  const totalExpenses = useMemo(() => {
    return expenses.reduce((sum, expense) => sum + (expense.amount * expense.quantity), 0);
  }, [expenses]);

  return (
    <div className="bg-dark-200 rounded-lg p-4">
      <div className="flex items-center gap-3 mb-4">
        <div className="p-2 rounded-md bg-dark-300">
          <Car className="w-5 h-5 text-primary-500" />
        </div>
        <div>
          <h3 className="text-lg font-bold text-white">{vehicle.year} {vehicle.make} {vehicle.model}</h3>
          <p className="text-sm text-gray-400">Budget Overview</p>
        </div>
      </div>

      {/* Initial Cost and Total Spending */}
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-6">
        <div className="bg-dark-300 rounded-lg p-4">
          <div className="flex items-center gap-2 mb-2">
            <DollarSign className="w-5 h-5 text-green-500" />
            <h4 className="text-sm font-medium text-gray-400">Initial Cost</h4>
          </div>
          <p className="text-2xl font-bold text-white">
            ${(vehicle.initialPrice || 0).toLocaleString()}
          </p>
        </div>
        <div className="bg-dark-300 rounded-lg p-4">
          <div className="flex items-center gap-2 mb-2">
            <TrendingUp className="w-5 h-5 text-primary-500" />
            <h4 className="text-sm font-medium text-gray-400">Total Spending</h4>
          </div>
          <p className="text-2xl font-bold text-white">
            ${(totalExpenses + partsCosts).toLocaleString()}
          </p>
        </div>
      </div>

      {/* Parts Cost Summary */}
      <div className="bg-dark-300 rounded-lg p-4">
        <div className="flex items-center justify-between">
          <h4 className="text-sm font-medium text-gray-400">Parts Investment</h4>
          <span className="text-lg font-bold text-white">${partsCosts.toLocaleString()}</span>
        </div>
      </div>
    </div>
  );
} 