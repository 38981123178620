import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc, setDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../config/firebase';
import { useAuth } from '../contexts/AuthContext';
import type { Budget, BudgetItem, BudgetGoal } from '../types/budget';
import type { Event } from '../types/event';
import type { Part } from '../types/part';
import type { Vehicle } from '../types/vehicle';
import { toast } from 'react-hot-toast';
import { Plus, Download, AlertTriangle, Target, Trophy, Star, PiggyBank, Car, Flag, LayoutDashboard, Receipt, Calendar, Settings } from 'lucide-react';
import {
  BudgetItemModal,
  BudgetGoalModal,
  SeasonOverview,
  TopExpenses,
  EventCosts,
  ExpensesList,
  IncomeList,
  PartsOverview,
  VehicleBudgetOverview
} from '../components/budget';

interface BudgetProps {
  budget: Budget | null;
  setBudget: (budget: Budget | null) => void;
  events?: Event[];
}

interface EventRelatedItem extends BudgetItem {
  event: {
    title: string;
    date: string;
    id: string;
  } | null;
}

type BudgetView = 'overview' | 'transactions' | 'goals' | 'vehicles' | 'events';

export default function Budget({ budget, setBudget, events = [] }: BudgetProps) {
  const navigate = useNavigate();
  const { programId } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [isExpenseModalOpen, setIsExpenseModalOpen] = useState(false);
  const [isIncomeModalOpen, setIsIncomeModalOpen] = useState(false);
  const [isGoalModalOpen, setIsGoalModalOpen] = useState(false);
  const [selectedView, setSelectedView] = useState<BudgetView>('overview');
  const [showOnboarding, setShowOnboarding] = useState(false);
  const [recentParts, setRecentParts] = useState<Part[]>([]);
  const [vehicles, setVehicles] = useState<Vehicle[]>([]);
  const [editingItem, setEditingItem] = useState<BudgetItem | undefined>(undefined);

  // Calculate budget metrics
  const budgetMetrics = useMemo(() => {
    if (!budget) return null;

    const totalExpenses = budget.expenses.reduce((sum, item) => sum + (item.amount * item.quantity), 0);
    const totalIncome = budget.income.reduce((sum, item) => sum + (item.amount * item.quantity), 0);
    const balance = totalIncome - totalExpenses;

    // Calculate per-category totals
    const expensesByCategory = budget.expenses.reduce((acc, item) => {
      if (typeof item.category === 'string' && item.category in acc) {
        acc[item.category] = (acc[item.category] || 0) + (item.amount * item.quantity);
      }
      return acc;
    }, {} as Record<string, number>);

    // Calculate budget vs goals
    const goalsProgress = budget.goals?.map(goal => {
      let actualSpent = 0;
      let progress = 0;
      let isOverBudget = false;

      switch (goal.type) {
        case 'total':
          actualSpent = goal.timeframe === 'month' 
            ? totalExpenses / 12 // Monthly average
            : totalExpenses;
          progress = (actualSpent / goal.amount) * 100;
          isOverBudget = actualSpent > goal.amount;
          break;

        case 'category':
          actualSpent = goal.timeframe === 'month'
            ? (expensesByCategory[goal.category!] || 0) / 12 // Monthly average
            : (expensesByCategory[goal.category!] || 0);
          progress = (actualSpent / goal.amount) * 100;
          isOverBudget = actualSpent > goal.amount;
          break;

        case 'event':
          // Calculate average spending per event
          const eventCount = events.length;
          actualSpent = totalExpenses / (eventCount || 1);
          progress = (actualSpent / goal.amount) * 100;
          isOverBudget = actualSpent > goal.amount;
          break;

        case 'emergency':
          // Calculate remaining emergency fund
          actualSpent = goal.amount - (totalIncome - totalExpenses);
          progress = ((goal.amount - actualSpent) / goal.amount) * 100;
          isOverBudget = actualSpent < 0;
          break;

        case 'vehicle':
          // Calculate total spending for this vehicle
          const vehicleExpenses = budget.expenses.filter(expense => {
            if (!expense.partId) return false;
            const part = recentParts.find(p => p.id === expense.partId);
            return part?.vehicleId === goal.vehicleId;
          });
          actualSpent = goal.timeframe === 'month'
            ? vehicleExpenses.reduce((sum, expense) => sum + (expense.amount * expense.quantity), 0) / 12 // Monthly average
            : vehicleExpenses.reduce((sum, expense) => sum + (expense.amount * expense.quantity), 0);
          progress = (actualSpent / goal.amount) * 100;
          isOverBudget = actualSpent > goal.amount;
          break;
      }

      // Handle rollover
      if (goal.rollover && !isOverBudget) {
        // If we're under budget and rollover is enabled, adjust the progress bar
        // to show how much of the budget is still available
        progress = 0; // Reset progress to show full bar
        isOverBudget = false;
      }

      return {
        id: goal.id,
        type: goal.type,
        category: goal.category,
        vehicleId: goal.vehicleId,
        amount: goal.amount,
        actualSpent,
        progress,
        isOverBudget,
        timeframe: goal.timeframe,
        rollover: goal.rollover,
        description: goal.description
      };
    }) || [];

    // Calculate upcoming expenses
    const upcomingExpenses = budget.expenses
      .filter(item => item.frequency === 'per-event' && item.eventId)
      .map(item => {
        const event = events.find(e => e.id === item.eventId);
        return { ...item, event };
      })
      .filter(item => item.event && new Date(item.event.date) > new Date())
      .sort((a, b) => new Date(a.event!.date).getTime() - new Date(b.event!.date).getTime());

    return {
      totalExpenses,
      totalIncome,
      balance,
      expensesByCategory,
      goalsProgress,
      upcomingExpenses,
      hasOverBudgetCategories: goalsProgress.some(g => g.isOverBudget)
    };
  }, [budget, events]);

  useEffect(() => {
    if (!programId) return;

    const fetchBudget = async () => {
      try {
        const budgetRef = doc(db, 'programs', programId, 'budget', 'main');
        const budgetSnap = await getDoc(budgetRef);

        if (budgetSnap.exists()) {
          setBudget({ id: budgetSnap.id, ...budgetSnap.data() } as Budget);
        } else {
          setBudget(null);
        }
      } catch (err) {
        console.error('Error fetching budget:', err);
        setError('Failed to load budget data');
        toast.error('Failed to load budget data');
      } finally {
        setIsLoading(false);
      }
    };

    const fetchRecentParts = async () => {
      try {
        // Get parts from the last 30 days
        const thirtyDaysAgo = new Date();
        thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

        const partsRef = collection(db, 'programs', programId, 'parts');
        const partsQuery = query(
          partsRef,
          where('createdAt', '>=', thirtyDaysAgo.toISOString())
        );

        const partsSnap = await getDocs(partsQuery);
        const parts = partsSnap.docs.map(doc => ({ id: doc.id, ...doc.data() } as Part));
        setRecentParts(parts);
      } catch (err) {
        console.error('Error fetching parts:', err);
      }
    };

    const fetchVehicles = async () => {
      try {
        const vehiclesRef = collection(db, 'programs', programId, 'vehicles');
        const vehiclesQuery = query(vehiclesRef);
        const vehiclesSnap = await getDocs(vehiclesQuery);
        const vehicles = vehiclesSnap.docs.map(doc => ({ id: doc.id, ...doc.data() } as Vehicle));
        setVehicles(vehicles);
      } catch (err) {
        console.error('Error fetching vehicles:', err);
      }
    };

    setIsLoading(true);
    setError('');
    Promise.all([fetchBudget(), fetchRecentParts(), fetchVehicles()]);
  }, [programId]);

  const handleCreateBudget = async () => {
    if (!programId) return;

    try {
      setIsLoading(true);
      const budgetRef = doc(db, 'programs', programId, 'budget', 'main');

      const newBudget: Budget = {
        id: 'main',
        year: new Date().getFullYear(),
        expenses: [],
        income: [],
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString()
      };

      await setDoc(budgetRef, newBudget);
      setBudget(newBudget);
      toast.success('Created new budget');
    } catch (err) {
      console.error('Error creating budget:', err);
      setError('Failed to create budget');
      toast.error('Failed to create budget');
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddBudgetItem = async (newItem: BudgetItem, type: 'expense' | 'income') => {
    if (!programId || !budget) return;

    try {
      // Clean up the item object to remove undefined values
      const cleanItem: BudgetItem = {
        id: newItem.id || crypto.randomUUID(),
        category: newItem.category || (type === 'expense' ? 'Entry Fees' : 'Sponsorship'),
        description: newItem.description || '',
        amount: newItem.amount,
        quantity: newItem.quantity || 1,
        frequency: newItem.frequency || 'one-time',
        createdAt: newItem.createdAt || new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        ...(newItem.eventId && { eventId: newItem.eventId }),
        ...(newItem.partId && { partId: newItem.partId })
      };

      const updatedBudget = { 
        ...budget,
        [type === 'expense' ? 'expenses' : 'income']: editingItem
          ? budget[type === 'expense' ? 'expenses' : 'income'].map(item => 
              item.id === editingItem.id ? cleanItem : item
            )
          : [...budget[type === 'expense' ? 'expenses' : 'income'], cleanItem],
        updatedAt: new Date().toISOString()
      };

      const budgetRef = doc(db, 'programs', programId, 'budget', 'main');
      await setDoc(budgetRef, updatedBudget);
      setBudget(updatedBudget);
      toast.success(`${editingItem ? 'Updated' : 'Added'} ${type === 'expense' ? 'expense' : 'income'} successfully`);
      setEditingItem(undefined);
    } catch (err) {
      console.error('Error adding budget item:', err);
      toast.error(`Failed to ${editingItem ? 'update' : 'add'} ${type === 'expense' ? 'expense' : 'income'}`);
    }
  };

  const handleEditExpense = (item: BudgetItem) => {
    setEditingItem(item);
    setIsExpenseModalOpen(true);
  };

  const handleEditIncome = (item: BudgetItem) => {
    setEditingItem(item);
    setIsIncomeModalOpen(true);
  };

  const handleDeleteBudgetItem = async (itemId: string, type: 'expense' | 'income') => {
    if (!programId || !budget) return;

    try {
      const updatedBudget = {
        ...budget,
        [type === 'expense' ? 'expenses' : 'income']: budget[type === 'expense' ? 'expenses' : 'income'].filter((item: BudgetItem) => item.id !== itemId),
        updatedAt: new Date().toISOString()
      };

      const budgetRef = doc(db, 'programs', programId, 'budget', 'main');
      await setDoc(budgetRef, updatedBudget);
      setBudget(updatedBudget);
      toast.success(`${type === 'expense' ? 'Expense' : 'Income'} deleted successfully`);
    } catch (err) {
      console.error('Error deleting budget item:', err);
      toast.error(`Failed to delete ${type === 'expense' ? 'expense' : 'income'}`);
    }
  };

  // Get all budget items associated with events
  const eventRelatedItems = useMemo(() => {
    if (!budget || !events) return [] as EventRelatedItem[];
    
    const items = [
      ...budget.expenses.filter((item: BudgetItem) => item.eventId && item.eventId.length > 0),
      ...budget.income.filter((item: BudgetItem) => item.eventId && item.eventId.length > 0)
    ];

    return items.map((item: BudgetItem): EventRelatedItem => {
      const event = events.find(e => e.id === item.eventId);
      if (!event?.id) {
        return {
          ...item,
          event: null
        };
      }
      return {
        ...item,
        event: {
          title: event.title,
          date: event.date,
          id: event.id
        }
      };
    }).sort((a: EventRelatedItem, b: EventRelatedItem) => {
      if (a.event?.date && b.event?.date) {
        return new Date(a.event.date).getTime() - new Date(b.event.date).getTime();
      }
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    });
  }, [budget, events]);

  const handleAddBudgetGoal = async (goal: BudgetGoal) => {
    if (!programId || !budget) return;

    try {
      // Clean up the goal object to remove undefined values
      const cleanGoal: BudgetGoal = {
        id: goal.id,
        type: goal.type,
        amount: goal.amount,
        timeframe: goal.timeframe,
        createdAt: goal.createdAt,
        updatedAt: goal.updatedAt,
        ...(goal.category && { category: goal.category }),
        ...(goal.vehicleId && { vehicleId: goal.vehicleId }),
        ...(goal.rollover && { rollover: goal.rollover }),
        ...(goal.description && { description: goal.description })
      };

      const updatedBudget = {
        ...budget,
        goals: [...(budget.goals || []), cleanGoal],
        updatedAt: new Date().toISOString()
      };

      const budgetRef = doc(db, 'programs', programId, 'budget', 'main');
      await setDoc(budgetRef, updatedBudget);
      setBudget(updatedBudget);
      toast.success('Budget goal added successfully');
    } catch (err) {
      console.error('Error adding budget goal:', err);
      toast.error('Failed to add budget goal');
    }
  };

  const handleExportBudget = () => {
    if (!budget) return;

    const exportData = {
      ...budget,
      metrics: budgetMetrics,
      exportDate: new Date().toISOString()
    };

    const blob = new Blob([JSON.stringify(exportData, null, 2)], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `racing-budget-${budget.year}.json`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-96">
        <div className="w-8 h-8 border-2 border-primary-500 border-t-transparent rounded-full animate-spin"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center py-12">
        <p className="text-red-500">{error}</p>
        <button
          onClick={() => window.location.reload()}
          className="mt-4 text-primary-500 hover:text-primary-400"
        >
          Try Again
        </button>
      </div>
    );
  }

  if (!budget) {
    return (
      <div className="p-6">
        <div className="bg-dark-200 rounded-lg p-8 text-center">
          <Plus className="w-16 h-16 text-gray-400 mx-auto mb-4" />
          <h2 className="text-2xl font-display font-bold text-white mb-2">Welcome to Racing Budget Management</h2>
          <p className="text-gray-400 mb-6 max-w-lg mx-auto">
            Track your racing expenses, set budget goals, and monitor your spending across events. 
            Get insights into your racing season's financial health and stay on top of your motorsport budget.
          </p>
          <button
            onClick={handleCreateBudget}
            className="inline-flex items-center gap-2 bg-primary-500 text-white rounded-md px-6 py-3 hover:bg-primary-600"
          >
            <Plus className="w-5 h-5" />
            Create Your Racing Budget
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="p-6 space-y-6">
      {/* Header with Quick Stats */}
      <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4 mb-8">
        <div>
          <h1 className="text-3xl font-display font-bold text-white">{budget.year} Racing Budget</h1>
          <p className="text-gray-400 mt-1">Track and manage your racing season expenses</p>
        </div>
        
        <div className="flex flex-wrap gap-3">
          <button
            onClick={() => setIsExpenseModalOpen(true)}
            className="flex-1 sm:flex-initial flex items-center justify-center gap-2 bg-primary-500 text-white rounded-lg px-4 py-2.5 hover:bg-primary-600 transition-colors shadow-lg shadow-primary-500/20"
          >
            <Plus className="w-4 h-4" />
            Add Expense
          </button>
          <button
            onClick={() => setIsIncomeModalOpen(true)}
            className="flex-1 sm:flex-initial flex items-center justify-center gap-2 bg-green-600 text-white rounded-lg px-4 py-2.5 hover:bg-green-700 transition-colors shadow-lg shadow-green-500/20"
          >
            <Plus className="w-4 h-4" />
            Add Income
          </button>
        </div>
      </div>

      {/* View Selector - Desktop */}
      <div className="hidden sm:flex bg-dark-200 rounded-lg p-2 gap-2 mb-6">
        <button
          onClick={() => setSelectedView('overview')}
          className={`flex items-center gap-2 px-4 py-2 rounded-md transition-all duration-200 ${
            selectedView === 'overview' 
              ? 'bg-primary-500 text-white shadow-lg shadow-primary-500/20' 
              : 'text-gray-400 hover:text-white hover:bg-dark-300'
          }`}
        >
          <LayoutDashboard className="w-4 h-4" />
          Overview
        </button>
        <button
          onClick={() => setSelectedView('transactions')}
          className={`flex items-center gap-2 px-4 py-2 rounded-md transition-all duration-200 ${
            selectedView === 'transactions' 
              ? 'bg-primary-500 text-white shadow-lg shadow-primary-500/20' 
              : 'text-gray-400 hover:text-white hover:bg-dark-300'
          }`}
        >
          <Receipt className="w-4 h-4" />
          Transactions
        </button>
        <button
          onClick={() => setSelectedView('goals')}
          className={`flex items-center gap-2 px-4 py-2 rounded-md transition-all duration-200 ${
            selectedView === 'goals' 
              ? 'bg-primary-500 text-white shadow-lg shadow-primary-500/20' 
              : 'text-gray-400 hover:text-white hover:bg-dark-300'
          }`}
        >
          <Target className="w-4 h-4" />
          Budget Goals
        </button>
        <button
          onClick={() => setSelectedView('vehicles')}
          className={`flex items-center gap-2 px-4 py-2 rounded-md transition-all duration-200 ${
            selectedView === 'vehicles' 
              ? 'bg-primary-500 text-white shadow-lg shadow-primary-500/20' 
              : 'text-gray-400 hover:text-white hover:bg-dark-300'
          }`}
        >
          <Car className="w-4 h-4" />
          Vehicles
        </button>
        <button
          onClick={() => setSelectedView('events')}
          className={`flex items-center gap-2 px-4 py-2 rounded-md transition-all duration-200 ${
            selectedView === 'events' 
              ? 'bg-primary-500 text-white shadow-lg shadow-primary-500/20' 
              : 'text-gray-400 hover:text-white hover:bg-dark-300'
          }`}
        >
          <Flag className="w-4 h-4" />
          Events
        </button>
      </div>

      {/* Mobile View Selector - Bottom Navigation */}
      <div className="sm:hidden fixed bottom-0 left-0 right-0 bg-dark-200 border-t border-dark-300 z-40">
        <div className="grid grid-cols-5 gap-1 p-2">
          <button
            onClick={() => setSelectedView('overview')}
            className={`flex flex-col items-center justify-center py-2 rounded-lg transition-all duration-200 ${
              selectedView === 'overview' 
                ? 'text-primary-500' 
                : 'text-gray-400'
            }`}
          >
            <LayoutDashboard className="w-5 h-5 mb-1" />
            <span className="text-xs font-medium">Overview</span>
          </button>
          <button
            onClick={() => setSelectedView('transactions')}
            className={`flex flex-col items-center justify-center py-2 rounded-lg transition-all duration-200 ${
              selectedView === 'transactions' 
                ? 'text-primary-500' 
                : 'text-gray-400'
            }`}
          >
            <Receipt className="w-5 h-5 mb-1" />
            <span className="text-xs font-medium">Transactions</span>
          </button>
          <button
            onClick={() => setSelectedView('goals')}
            className={`flex flex-col items-center justify-center py-2 rounded-lg transition-all duration-200 ${
              selectedView === 'goals' 
                ? 'text-primary-500' 
                : 'text-gray-400'
            }`}
          >
            <Target className="w-5 h-5 mb-1" />
            <span className="text-xs font-medium">Goals</span>
          </button>
          <button
            onClick={() => setSelectedView('vehicles')}
            className={`flex flex-col items-center justify-center py-2 rounded-lg transition-all duration-200 ${
              selectedView === 'vehicles' 
                ? 'text-primary-500' 
                : 'text-gray-400'
            }`}
          >
            <Car className="w-5 h-5 mb-1" />
            <span className="text-xs font-medium">Vehicles</span>
          </button>
          <button
            onClick={() => setSelectedView('events')}
            className={`flex flex-col items-center justify-center py-2 rounded-lg transition-all duration-200 ${
              selectedView === 'events' 
                ? 'text-primary-500' 
                : 'text-gray-400'
            }`}
          >
            <Flag className="w-5 h-5 mb-1" />
            <span className="text-xs font-medium">Events</span>
          </button>
        </div>
      </div>

      {/* Add padding to main content to account for mobile bottom nav */}
      <div className="pb-20 sm:pb-0">
        {/* View Content */}
        {selectedView === 'overview' && (
          <div className="space-y-6">
            {/* Quick Stats Grid - Only visible in overview */}
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
              <div className="bg-dark-200 rounded-lg p-4">
                <h3 className="text-gray-400 text-sm font-medium">Total Budget</h3>
                <p className="text-2xl font-bold text-white mt-1">
                  ${budgetMetrics?.totalExpenses.toLocaleString()}
                </p>
              </div>
              <div className="bg-dark-200 rounded-lg p-4">
                <h3 className="text-gray-400 text-sm font-medium">Total Income</h3>
                <p className="text-2xl font-bold text-green-500 mt-1">
                  ${budgetMetrics?.totalIncome.toLocaleString()}
                </p>
              </div>
              <div className="bg-dark-200 rounded-lg p-4">
                <h3 className="text-gray-400 text-sm font-medium">Balance</h3>
                <p className={`text-2xl font-bold mt-1 ${budgetMetrics?.balance && budgetMetrics.balance >= 0 ? 'text-green-500' : 'text-red-500'}`}>
                  ${budgetMetrics?.balance?.toLocaleString()}
                </p>
              </div>
              <div className="bg-dark-200 rounded-lg p-4">
                <h3 className="text-gray-400 text-sm font-medium">Upcoming Expenses</h3>
                <p className="text-2xl font-bold text-white mt-1">
                  ${budgetMetrics?.upcomingExpenses.reduce((sum, item) => sum + (item.amount * item.quantity), 0).toLocaleString()}
                </p>
              </div>
            </div>

            <div className="flex justify-between items-center">
              <h2 className="text-xl font-bold text-white">Season Overview</h2>
              <button
                onClick={handleExportBudget}
                className="text-gray-400 hover:text-white transition-colors"
                title="Export Budget Data"
              >
                <Download className="w-4 h-4" />
              </button>
            </div>
            <SeasonOverview 
              budget={budget} 
              events={events}
              metrics={budgetMetrics}
            />
            {/* Next Steps section */}
            {(!budget.expenses || budget.expenses.length < 2 || !budget.goals || budget.goals.length === 0) && (
              <div className="bg-dark-200 rounded-lg p-6">
                <h3 className="text-lg font-bold text-white mb-4">What's Next?</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                  {/* Income Suggestions */}
                  {(!budget.income || budget.income.length === 0) && (
                    <div className="bg-dark-300 rounded-lg p-4 flex items-start gap-3">
                      <Trophy className="w-5 h-5 text-yellow-500 flex-shrink-0 mt-0.5" />
                      <div>
                        <h4 className="text-white font-medium">Add Your First Income!</h4>
                        <p className="text-gray-400 text-sm mt-1">
                          {[
                            "Got a sponsorship? Add it here! 🏆",
                            "Won a prize? Time to celebrate! 🎉",
                            "Selling old parts? Track that income! 💰",
                            "Got a racing scholarship? Add it! 🎓"
                          ][Math.floor(Math.random() * 4)]}
                        </p>
                        <button
                          onClick={() => setIsIncomeModalOpen(true)}
                          className="text-primary-500 text-sm hover:text-primary-400 mt-2 flex items-center gap-1"
                        >
                          <Plus className="w-4 h-4" />
                          Add Income
                        </button>
                      </div>
                    </div>
                  )}

                  {/* Budget Goals Suggestions */}
                  {(!budget.goals || budget.goals.length === 0) && (
                    <div className="bg-dark-300 rounded-lg p-4 flex items-start gap-3">
                      <Target className="w-5 h-5 text-blue-500 flex-shrink-0 mt-0.5" />
                      <div>
                        <h4 className="text-white font-medium">Set Your First Budget Goal!</h4>
                        <p className="text-gray-400 text-sm mt-1">
                          {[
                            "How much do you want to spend on tires this season? 🛞",
                            "Set a monthly racing budget! 💸",
                            "Plan your emergency fund for repairs! 🔧",
                            "Track spending per race weekend! 🏁"
                          ][Math.floor(Math.random() * 4)]}
                        </p>
                        <button
                          onClick={() => setIsGoalModalOpen(true)}
                          className="text-primary-500 text-sm hover:text-primary-400 mt-2 flex items-center gap-1"
                        >
                          <Plus className="w-4 h-4" />
                          Set Budget Goal
                        </button>
                      </div>
                    </div>
                  )}

                  {/* Expenses Suggestions */}
                  {(!budget.expenses || budget.expenses.length < 2) && (
                    <div className="bg-dark-300 rounded-lg p-4 flex items-start gap-3">
                      <PiggyBank className="w-5 h-5 text-green-500 flex-shrink-0 mt-0.5" />
                      <div>
                        <h4 className="text-white font-medium">Track Your First Expense!</h4>
                        <p className="text-gray-400 text-sm mt-1">
                          {[
                            "Just bought new tires? Add them! 🛞",
                            "Track your entry fees! 🏁",
                            "Log your fuel costs! ⛽",
                            "Add your maintenance costs! 🔧"
                          ][Math.floor(Math.random() * 4)]}
                        </p>
                        <button
                          onClick={() => setIsExpenseModalOpen(true)}
                          className="text-primary-500 text-sm hover:text-primary-400 mt-2 flex items-center gap-1"
                        >
                          <Plus className="w-4 h-4" />
                          Add Expense
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            <TopExpenses budget={budget} />
          </div>
        )}

        {selectedView === 'transactions' && (
          <div className="space-y-6">
            <div className="bg-dark-200 rounded-lg overflow-hidden">
              <ExpensesList
                budget={budget}
                onDeleteBudgetItem={handleDeleteBudgetItem}
                onOpenExpenseModal={() => setIsExpenseModalOpen(true)}
                onEditExpense={handleEditExpense}
              />
            </div>
            <div className="bg-dark-200 rounded-lg overflow-hidden">
              <IncomeList
                budget={budget}
                onDeleteBudgetItem={handleDeleteBudgetItem}
                onOpenIncomeModal={() => setIsIncomeModalOpen(true)}
                onEditIncome={handleEditIncome}
              />
            </div>
          </div>
        )}

        {selectedView === 'goals' && (
          <div className="space-y-6">
            <div className="bg-dark-200 rounded-lg p-6">
              <div className="flex justify-between items-center mb-6">
                <h3 className="text-lg font-bold text-white">Budget Goals</h3>
                <button
                  onClick={() => setIsGoalModalOpen(true)}
                  className="flex items-center gap-2 bg-primary-500 text-white rounded-lg px-4 py-2 hover:bg-primary-600"
                >
                  <Plus className="w-4 h-4" />
                  New Goal
                </button>
              </div>
              <div className="space-y-6">
                {budgetMetrics?.goalsProgress.map(goal => (
                  <div key={goal.id} className="space-y-2">
                    <div className="flex justify-between text-sm">
                      <span className="text-gray-400">
                        {goal.type === 'total' ? 'Total Budget' : 
                         goal.type === 'emergency' ? 'Emergency Fund' :
                         goal.type === 'event' ? 'Per Event' :
                         goal.type === 'vehicle' ? 'Per Vehicle' :
                         goal.category}
                      </span>
                      <span className={goal.isOverBudget ? 'text-red-500' : 'text-green-500'}>
                        ${goal.actualSpent.toLocaleString()} / ${goal.amount.toLocaleString()}
                      </span>
                    </div>
                    <div className="h-2 bg-dark-300 rounded-full overflow-hidden">
                      <div 
                        className={`h-full rounded-full ${goal.isOverBudget ? 'bg-red-500' : 'bg-green-500'}`}
                        style={{ width: `${Math.min(goal.progress, 100)}%` }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        {selectedView === 'vehicles' && (
          <div className="space-y-6">
            {vehicles.map(vehicle => (
              <VehicleBudgetOverview
                key={vehicle.id}
                vehicle={vehicle}
                parts={recentParts}
                budget={budget}
              />
            ))}
            {recentParts.length > 0 && (
              <PartsOverview 
                budget={budget}
                recentParts={recentParts}
              />
            )}
          </div>
        )}

        {selectedView === 'events' && (
          <div className="space-y-6">
            <EventCosts 
              eventRelatedItems={eventRelatedItems}
              upcomingExpenses={budgetMetrics?.upcomingExpenses}
            />
          </div>
        )}
      </div>

      {/* Modals */}
      <BudgetItemModal
        isOpen={isExpenseModalOpen}
        onClose={() => {
          setIsExpenseModalOpen(false);
          setEditingItem(undefined);
        }}
        onAdd={(item) => handleAddBudgetItem(item, 'expense')}
        type="expense"
        item={editingItem}
      />

      <BudgetItemModal
        isOpen={isIncomeModalOpen}
        onClose={() => {
          setIsIncomeModalOpen(false);
          setEditingItem(undefined);
        }}
        onAdd={(item) => handleAddBudgetItem(item, 'income')}
        type="income"
        item={editingItem}
      />

      <BudgetGoalModal
        isOpen={isGoalModalOpen}
        onClose={() => setIsGoalModalOpen(false)}
        onAdd={handleAddBudgetGoal}
        vehicles={vehicles}
      />
    </div>
  );
}